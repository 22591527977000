// tslint:disable: max-line-length
import * as React from 'react';
import layout from '../../../../utils/layout';
import SvgTemplate from '../SvgTemplate';
import { Svg } from '../../Svg';

const icon = ({ color, ...rest }: Svg): JSX.Element => (
	<SvgTemplate {...rest} viewBox="0 0 21 19">
		<g
			id="Symbols"
			stroke="none"
			strokeWidth="1"
			fill="none"
			fillRule="evenodd"
		>
			<g id="purple" fill={color}>
				<path
					d="M1.93489956,5.37 C2.35293655,3.07 3.88897946,1.57 5.82361577,1.57 C7.13605749,1.57 8.59432607,2.22 10.0623164,3.47 C10.3248048,3.68 10.6942328,3.68 10.9567212,3.47 C12.4149897,2.25 13.8635365,1.62 15.1759783,1.62 C17.1106146,1.62 18.6272139,3.09 19.0646945,5.37 C19.784107,9.18 17.4119901,14.34 10.5192406,17.36 C3.60704753,14.4 1.21548706,9.22 1.93489956,5.37 M15.1759783,0.14 C13.6885443,0.14 12.0844489,0.77 10.5192406,1.96 C8.92486695,0.73 7.32077151,0.1 5.82361577,0.1 C3.16956696,0.1 1.09882558,2.05 0.534961726,5.09 C-0.301112259,9.6 2.38210193,15.59 10.2567522,18.85 C10.3442484,18.88 10.4317445,18.9 10.5192406,18.9 C10.6067367,18.9 10.6942328,18.88 10.7817289,18.85 C18.6272139,15.52 21.3007063,9.55 20.4646323,5.08 C19.8910467,2.07 17.8300271,0.14 15.1759783,0.14"
					id="Fill-1"
				/>
			</g>
		</g>
	</SvgTemplate>
);

icon.defaultProps = {
	width: layout.spacing.two,
	color: layout.color.primary
};

export default icon;

import * as React from 'react';
import Svg from '../Svg';
import layout from '../../utils/layout';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'
import 'tippy.js/themes/light.css';
import './Hint.scss';

interface Props {
	text: string;
	icon?: any;
	className?: string;
	iconWidth?: string | number;
}

export const Hint = (props: Props): JSX.Element => {
	const { text, icon, className, iconWidth } = props;
	let iconClass = 'tippy__icon';
	if (className) {
		iconClass += ` ${className}`;
	}
	return (
		<Tippy
			arrow
			theme="light"
			content={<span className="hint-text">{text}</span>}
			duration={50}
			delay={[0, 150]}
		>
			<div className={iconClass}>
				<Svg icon={icon} width={iconWidth} />
			</div>
		</Tippy>
	);
};

Hint.defaultProps = {
	icon: 'help',
	iconWidth: layout.spacing.two,
	text: '',
	className: ''
};

export default React.memo(Hint);

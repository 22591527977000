import * as Yup from 'yup';

Yup.setLocale({
	mixed: {
		required: 'This field is required.',
		// @ts-ignore
		typeError: 'type error'
	},
	string: {
		min: 'This field must be at least ${min} characters.',
		max: 'This field must be ${max} characters max.',
		email: 'This email is not valid.',
		// @ts-ignore
		typeError: 'type error',
		default: 'default'
	},
	number: {
		min: 'Value must be greater than ${min}.',
		max: 'Value must not be greater than ${max}.',
		positive: 'Value must be a positive number.',
		negative: 'Value must be a negative number.'
	}
});

export default Yup;

// tslint:disable: max-line-length

import * as React from 'react';
import layout from '../../../../utils/layout';
import SvgTemplate from '../SvgTemplate';
import { Svg } from '../../Svg';

const icon = ({ color, ...rest }: Svg): JSX.Element => (
	<SvgTemplate {...rest} viewBox="0 0 367.9 355.1">
		<path
			d="M367.9,196.7V25.2c0-10.8-8.8-19.5-19.5-19.5h-58.3c-6.7,0-12.5,3.3-16.1,8.4C264.7,5.4,252.3,0,238.6,0L82.4,0
                     c-35,0-57.3,18.4-61.3,50.4L0.1,184C0,184.5,0,185,0,185.5c0,28.5,23.2,51.7,51.7,51.7h78.1v56.1c0,24.3,7.1,41.8,21.2,52.2
                     c22.3,16.3,52.8,7,54.1,6.6c4.1-1.3,6.9-5.1,6.9-9.4v-63.8c0-49.7,57.8-66.7,60.2-67.4c1.2-0.3,2.4-0.9,3.3-1.7
                     c3.6,4,8.8,6.5,14.5,6.5h58.4C359.2,216.3,367.9,207.5,367.9,196.7z M270.5,192.1c-1.2-0.1-2.4,0-3.6,0.3
                     c-3.1,0.9-74.7,21.6-74.7,86.4v55.9c-7.7,1.2-20.4,1.6-29.5-5.2c-8.7-6.4-13.1-18.6-13.1-36.1v-66c0-5.5-4.4-9.9-9.9-9.9h-88
                     c-17.4,0-31.5-13.9-31.9-31.2L40.7,53.4c0-0.1,0-0.2,0-0.3C43.4,31.1,57.4,20,82.4,20h156.2c17.6,0,31.9,14.3,31.9,31.9L270.5,192.1
                     L270.5,192.1z M348.1,196.5h-57.8v-171h57.8V196.5z"
			fill={color}
		/>
	</SvgTemplate>
);

icon.defaultProps = {
	width: layout.spacing.three,
	color: layout.color.primary
};

export default icon;

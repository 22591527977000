import * as React from 'react';
import './Dialog.scss';

interface Props {
	title?: string | JSX.Element;
	description?: string | JSX.Element;
	children: any;
	className?: string;
	style?: object;
	error?: any;
}

export const Dialog = (props: Props) => {
	const { title, description, children, className, style, error } = props;
	let dialogClass = 'dialog box-shadow--dialog';
	if (className) {
		dialogClass += ` ${className}`;
	}
	if (error) {
		dialogClass += ' has-error';
	}
	return (
		<div className={dialogClass} style={style}>
			{title && <h3 className="dialog__title">{title}</h3>}
			{description && <h4 className="dialog__description">{description}</h4>}
			<div className="dialog__content">{children}</div>
		</div>
	);
};

export default Dialog;

import { post } from '../utils';
import {
	Request$SendSMSMessage
} from './models';

async function sendSMSMessage(data: Request$SendSMSMessage): Promise<any> {
	return post(`/message/send-sms-message`, data);
}

export default {
	sendSMSMessage
};

import {
	ADDED_Contact,
	CHECKED_DUPLICATE,
	LOADED_CONTACTS,
	LOADED_CONTACT,
	EDITED_CONTACT,
	LOADED_CALLS,
	ADDED_CALL,
	UPDATED_CONTACT,
	LOADED_EMAILS,
	DELETED_CONTACT,
	LOADED_MESSAGES,
	ADDED_CALL_REVIEW,
	LOADED_EDIT_HISTORY,
	LOADED_VIEW_HISTORY
} from './constants';
import { Action } from '../../../models';
import api, {
	FormData$AddContact,
	FormData$CheckDuplicate,
	FormData$ShowContact,
	FormData$EditContact,
	FormData$AddCall,
	FormData$UpdateContact, FormData$AddCallReview
} from '../../api/Contact';

export type Action$AddContact = (data: FormData$AddContact) => Promise<Action>;

export type Action$CheckDuplicate = (
	data: FormData$CheckDuplicate
) => Promise<Action>;

export type Action$ListContacts = (
	data: Pagination$ListContacts
) => Promise<Action>;

export type Action$ShowContact = (
	data: FormData$ShowContact
) => Promise<Action>;

export type Action$EditContact = (
	data: FormData$EditContact & { id: string }
) => Promise<Action>;

export type Action$AddCall = (data: FormData$AddCall) => Promise<Action>;

export type Action$AddCallReview = (data: FormData$AddCallReview) => Promise<Action>;

export type Pagination$ListContacts = {
	page: number;
	limit: number;
	filters: {
		text?: string;
		call_status?: number;
		to_be_checked?: number;
		listed?: number;
		country?: number;
		second_langs?: string;
		lead_status?: number,
		email_status?: number,
		have_seen_email_status?: number,
		boat_types?: number,
		main_language?: string;
		region?: number;
		to_be_called_today?: number;
		assigned_to?: number;
		callStartDate?: string;
		callEndDate?: string;
		emailStartDate?: string;
		emailEndDate?: string;
		smsStartDate?: string;
		smsEndDate?: string;
		mergeSearch?: boolean;
	};
	sorting: {
		id: string;
		desc: boolean;
	}[];
	columns: {
		id: string;
		value: string;
	}[];
};

export const addContact = async (
	data: FormData$AddContact
): Promise<Action> => {
	const res = await api.addContact(data);
	return {
		type: ADDED_Contact,
		payload: res.data
	};
};

export const checkDuplicate = async (
	data: FormData$CheckDuplicate
): Promise<Action> => {
	const res = await api.checkDuplicate(data);
	return {
		type: CHECKED_DUPLICATE,
		payload: res
	};
};

export const listContacts = async (
	data: Pagination$ListContacts
): Promise<Action> => {
	const {
		page,
		limit,
		filters: {
			text,
			call_status,
			to_be_checked,
			listed,
			country,
			boat_types,
			second_langs,
			main_language,
			lead_status,
			email_status,
			have_seen_email_status,
			to_be_called_today,
			assigned_to,
			region,
			callStartDate,
			callEndDate,
			emailStartDate,
			emailEndDate,
			smsStartDate,
			smsEndDate,
			mergeSearch,
		},
		columns,
		sorting
	} = data;
	let params = `?page=${page}&limit=${limit}`;
	if (text) params += `&text=${text}`;
	if (call_status) params += `&call_status=${call_status}`;
	if (to_be_checked) params += `&to_be_checked=${to_be_checked}`;
	if (listed || listed == 0) params += `&listed=${listed}`;
	if (country) params += `&country=${country}`;
	if (boat_types) params += `&boat_types=${boat_types}`;
	if (second_langs) params += `&second_langs=${second_langs}`;
	if (main_language) params += `&main_language=${main_language}`;
	if (lead_status) params += `&lead_status=${lead_status}`;
	if (email_status) params += `&email_status=${email_status}`;
	if (have_seen_email_status) params += `&have_seen_email_status=${have_seen_email_status}`;
	if (region) params += `&region=${region}`;
	if (to_be_called_today) params += `&to_be_called_today=true`;
	if (assigned_to) params += `&assigned_to=${assigned_to}`;
	if (callStartDate) params += `&call_start_date=${callStartDate}`;
	if (callEndDate) params += `&call_end_date=${callEndDate}`;
	if (emailStartDate) params += `&email_start_date=${emailStartDate}`;
	if (emailEndDate) params += `&email_end_date=${emailEndDate}`;
	if (smsStartDate) params += `&sms_start_date=${smsStartDate}`;
	if (smsEndDate) params += `&sms_end_date=${smsEndDate}`;
	if (mergeSearch) params += '&merge_search=true';

	if (sorting && sorting.length) {
		for (let i = 0; i < sorting.length; i++) {
			// add query name or divider
			params += i === 0 ? '&sort_by=' : '$';
			// add each object in this format: `id,desc` (values seperated by comma)
			params += `${sorting[i].id},${sorting[i].desc ? 'desc' : 'asc'}`;
			// result looks like this:
			// `&sort_by=id,desc+name,asc`
		}
	}
	if (columns && columns.length) {
		for (let i = 0; i < columns.length; i++) {
			// add query name or divider
			params += i === 0 ? '&columns=' : '$';
			// add each object in this format: `id,desc` (values seperated by comma)
			params += `${columns[i].id},${columns[i].value}`;
			// result looks like this:
			// `&sort_by=id,desc+name,asc`
		}
	}

	const res = await api.listContacts(params);
	return {
		type: LOADED_CONTACTS,
		payload: res.data
	};
};

export const showContact = async (
	data: FormData$ShowContact
): Promise<Action> => {
	const res = await api.showContact(data);
	return {
		type: LOADED_CONTACT,
		payload: res.data
	};
};

export const editContact = async (
	data: FormData$EditContact
): Promise<Action> => {
	const res = await api.editContact(data);
	return {
		type: EDITED_CONTACT,
		payload: res.data
	};
};

export const updateContact = async (
	data: FormData$UpdateContact
): Promise<Action> => {
	const res = await api.updateContact(data);
	return {
		type: UPDATED_CONTACT,
		payload: res.data
	};
};

export const getCalls = async (id: string): Promise<Action> => {
	const res = await api.getCalls(id);
	return {
		type: LOADED_CALLS,
		payload: res.data
	};
};

export const addCall = async (data: FormData$AddCall): Promise<Action> => {
	const res = await api.addCall(data);
	return {
		type: ADDED_CALL,
		payload: res.data
	};
};

export const addCallReview = async (data: FormData$AddCallReview): Promise<Action> => {
	const res = await api.addCallReview(data);
	return {
		type: ADDED_CALL_REVIEW,
		payload: res.data
	};
};

export const getEmails = async (id: string): Promise<Action> => {
	const res = await api.getEmails(id);
	return {
		type: LOADED_EMAILS,
		payload: res.data
	};
};

export const getMessages = async (id: string): Promise<Action> => {
	const res = await api.getMessages(id);
	return {
		type: LOADED_MESSAGES,
		payload: res.data
	};
};

export const getEditHistory = async (id: string): Promise<Action> => {
	const res = await api.getEditHistory(id);
	return {
		type: LOADED_EDIT_HISTORY,
		payload: res.data
	};
};

export const getViewHistory = async (id: string): Promise<Action> => {
	const res = await api.getViewHistory(id);
	return {
		type: LOADED_VIEW_HISTORY,
		payload: res.data
	};
};

export const deleteContact = async (id: string): Promise<Action> => {
	const res = await api.deleteContact(id);
	return {
		type: DELETED_CONTACT,
		payload: res.data
	};
};

// tslint:disable: max-line-length

import * as React from 'react';
import layout from '../../../../utils/layout';
import SvgTemplate from '../SvgTemplate';
import { Svg } from '../../Svg';

const icon = ({ color, ...rest }: Svg): JSX.Element => (
	<SvgTemplate {...rest} viewBox="0 0 12 16">
		<g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<g id="locker-opened" transform="translate(0.000000, 1.000000)">
				<g
					id="Page-1_2_"
					transform="translate(0.000000, 5.000000)"
					fill={color}
					fillRule="nonzero"
				>
					<g id="Profile_x2F_EditProfile_2_">
						<g id="Group-8_2_">
							<g id="Group-5_2_">
								<path
									d="M1.4,0.3 L1.3,0.3 C0.6,0.3 0,0.9 0,1.5 L0,8.3 C0,9 0.6,9.5 1.2,9.5 L10.7,9.5 C11.4,9.5 11.9,8.9 11.9,8.3 L11.9,1.5 C11.9,0.8 11.3,0.3 10.7,0.3 L1.4,0.3 Z M6.7,5.2 C6.6,5.3 6.6,5.3 6.6,5.4 C6.6,5.9 6.6,6.4 6.6,6.9 C6.6,7.1 6.5,7.3 6.3,7.4 C5.9,7.6 5.4,7.3 5.4,6.9 C5.4,6.4 5.4,5.9 5.4,5.4 C5.4,5.3 5.4,5.2 5.3,5.2 C4.9,4.9 4.8,4.3 5,3.8 C5.2,3.3 5.8,3 6.3,3.1 C6.9,3.2 7.3,3.7 7.3,4.2 C7.2,4.7 7.1,5 6.7,5.2 Z M8.7,0.3 L3.4,0.3 L8.7,0.3 Z"
									id="Shape_2_"
								/>
							</g>
						</g>
					</g>
				</g>
				<path
					d="M9.6,6.1 L9.6,3.6 C9.6,1.6 8,0 6,0 C4,0 2.4,1.6 2.4,3.6"
					id="Shape"
					stroke={color}
					strokeWidth="2"
				/>
			</g>
		</g>
	</SvgTemplate>
);

icon.defaultProps = {
	width: layout.spacing.three,
	color: '#D8D8D8'
};

export default icon;

// tslint:disable: max-line-length
import * as React from 'react';
import './formElement.scss';
import Label from '../../Form/Label';
import { FieldProps, getIn } from 'formik';
import FormError from '../../Form/FormError';

interface Props extends FieldProps {
	label?: string;
	className?: string;
	style?: object;
	hint?: string;
	onChange: () => any;
	onBlur: () => any;
	placeholder?: string;
	type?: string;
	prive?: boolean;
	/* to show validation error even if form is not submitted yet */
	validateWithoutSubmit?: boolean;
	/* to show validation error on demand */
	validateWhen?: boolean;
	/* a custom input error */
	error?: string | object;
	caption?: string;
	children?: any;
}

const FormElementHOC = (
	ChildComponent: React.ComponentType<any>
): React.ComponentClass<Props, {}> => {
	return class FormInput extends React.Component<Props, {}> {
		constructor(props: Props) {
			super(props);
			if (!props.field || !props.form) {
				throw new Error(
					"`formElement` HOC should be used only in Formik's <Field />. import the actual component instead."
				);
			}
		}

		/* istanbul ignore next */
		shouldComponentUpdate(nextProps: Props) {
			if (nextProps.field.value !== this.props.field.value) return true;
			if (nextProps.field.name !== this.props.field.name) return true;
			if (nextProps.form.submitCount !== this.props.form.submitCount) {
				return true;
			}
			if (nextProps.validateWhen !== this.props.validateWhen) {
				return true;
			}
			if (
				JSON.stringify(nextProps.form.errors) !==
				JSON.stringify(this.props.form.errors)
			) {
				return true;
			}
			if (nextProps.children !== this.props.children) {
				return true;
			}
			return false;
		}

		render(): JSX.Element {
			const {
				label,
				className,
				style,
				hint,
				form,
				field,
				children,
				prive,
				validateWithoutSubmit,
				error,
				caption,
				validateWhen,
				onChange,
				...rest
			} = this.props;

			let inputClass = 'form-input';
			if (className) {
				inputClass += ` ${className}`;
			}

			if (caption) {
				inputClass += ' has-caption';
			}
			const errorMessage =
				(form &&
					(form.submitCount || validateWithoutSubmit || validateWhen) &&
					getIn(form.errors, field.name)) ||
				error;
			if (errorMessage) {
				inputClass += ' has-error';
			}

			return (
				<div className={inputClass} style={style}>
					{label && <Label prive={prive} title={label} hint={hint} />}
					<ChildComponent
						{...rest}
						form={form}
						validationError={errorMessage}
						{...field}
						onChange={onChange || field.onChange}
					>
						{children}
					</ChildComponent>
					{caption && <div className="form-input__caption">{caption}</div>}
					<FormError text={errorMessage} />
				</div>
			);
		}
	};
};

export default FormElementHOC;

import * as React from 'react';
// import './InputRadioMultiple.scss';
import { SelectOption } from '../../../models';
import InputRadio from './InputRadio';

interface Props {
	name?: string;
	options: SelectOption[];
	value?: string;
	onChange?: (e: React.SyntheticEvent) => any;
	validationError?: string;
}

export const InputRadioMultiple = (props: Props): JSX.Element => {
	const { name, options, onChange, validationError } = props;
	return (
		<div className="radio-wrapper">
			{options &&
				options.map(
					(option: SelectOption): JSX.Element => {
						const key = `${name}-${option.value}`;
						return (
							<InputRadio
								key={key}
								value={option.value}
								name={name}
								onChange={onChange}
								validationError={validationError}
								radioLabel={option.label}
							/>
						);
					}
				)}
		</div>
	);
};

export default React.memo(InputRadioMultiple);

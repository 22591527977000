import * as React from 'react';
import './Loader.scss';
import withDelayedRender from '../HOC/withDelayedRender/withDelayedRender';
import Popup from '../Popups/Popup';

interface Props {
	white?: boolean;
	full?: boolean;
}

const Loader = (props: Props): JSX.Element => {
	const { full, white } = props;
	let loaderClass = 'loader loader-wrapper';
	if (full) {
		loaderClass += ' full';
	}
	if (white) {
		loaderClass += ' white';
	}
	return (
		<div className={loaderClass}>
			<div className="lds-ellipsis">
				<div />
				<div />
				<div />
				<div />
			</div>
		</div>
	);
};

export default Loader;

interface FullScreenLoaderProps {
	fixed?: boolean;
}

// FULLSCREEN LOADER

const FullScreenLoader = (props: FullScreenLoaderProps): JSX.Element => {
	const { fixed } = props;
	let cl = 'fullscreen-loader';
	if (fixed) {
		cl += ' fixed';
	}
	return (
		<div className={cl}>
			<Loader />
		</div>
	);
};

export { FullScreenLoader as TestFullScreenLoader }; // for testing

// const DelayedFullScreenLoader = withDelayedRender()(FullScreenLoader);
export { FullScreenLoader };

// export { DelayedFullScreenLoader as FullScreenLoader };

// POPUP LOADER

const PopupLoader = (props: FullScreenLoaderProps): JSX.Element => {
	return (
		<div className="popup-loader">
			<Popup active width={200}>
				<Loader />
			</Popup>
		</div>
	);
};

export { PopupLoader as TestPopupLoader }; // for testing

const DelayedPopupLoader = withDelayedRender(100)(PopupLoader);

export { DelayedPopupLoader as PopupLoader };

// tslint:disable: max-line-length
import * as React from 'react';
import layout from '../../../../utils/layout';
import SvgTemplate from '../SvgTemplate';
import { Svg } from '../../Svg';

const icon = ({ color, ...rest }: Svg): JSX.Element => (
	<SvgTemplate {...rest} viewBox="0 0 492 454">
		<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<g id="paperclip" fill={color} fillRule="nonzero">
				<path
					d="M87.344,453.17 C64.033,453.17 42.129,444.086 25.655,427.615 C-8.355,393.601 -8.355,338.254 25.655,304.244 L295.343,34.543 C317.07,12.793 345.988,0.83 376.733,0.83 C407.483,0.83 436.4,12.793 458.128,34.543 C479.876,56.287 491.854,85.188 491.854,115.932 C491.854,146.682 479.89,175.582 458.146,197.313 L245.843,409.596 C239.469,415.967 229.147,415.967 222.776,409.596 C216.402,403.223 216.402,392.901 222.776,386.526 L435.074,174.243 C450.657,158.663 459.227,137.952 459.227,115.932 C459.227,93.916 450.639,73.192 435.06,57.61 C419.477,42.03 398.766,33.456 376.733,33.456 C354.704,33.456 333.993,42.03 318.41,57.61 L48.725,327.311 C27.442,348.612 27.442,383.249 48.725,404.545 C69.37,425.162 105.314,425.162 125.958,404.545 L307.049,223.44 C311.973,218.516 314.794,211.7 314.794,204.752 C314.794,197.707 312.039,191.066 307.035,186.063 C296.744,175.772 279.968,175.741 269.677,186.063 L146,309.754 C139.63,316.129 129.303,316.129 122.933,309.754 C116.559,303.383 116.559,293.057 122.933,286.688 L246.606,162.997 C269.611,139.993 307.067,139.993 330.103,162.997 C341.272,174.163 347.42,188.997 347.42,204.753 C347.42,220.3 341.112,235.515 330.12,246.507 L149.029,427.615 C132.555,444.086 110.65,453.17 87.344,453.17 Z"
					id="Shape"
				/>
			</g>
		</g>
	</SvgTemplate>
);

icon.defaultProps = {
	width: layout.spacing.four,
	color: layout.color.primary
};

export default icon;

import * as React from 'react';
import Popup from '../Popup';
import { hot } from 'react-hot-loader';
import {
	FormikForm,
	FormikInputText,
	FormikButtonSubmit,
	FormGroup,
	FormikInputSelect,
	formikSubmit
} from '../../Form';
import { FormikProps, FormikActions } from 'formik';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import { Store } from '../../../models';
import { getOptions, Action$GetOptions } from '../../../redux/actions';
import { Options, Role } from '../../../models/Options';
import { Request$AddUser } from '../../../api/User/AddUser';
import {
	ActionCreator$AddUser,
	addNewUserActionCreator
} from '../../../redux/UserList/UserListActions';
import { User } from '../../../models/User';

interface Props {
	active: boolean;
	user: User;
	options: Options;
	getOptions: Action$GetOptions;
	addUser: ActionCreator$AddUser;
	onClose: () => any;
}

type Values = Request$AddUser;

const validationSchema = Yup.object().shape({
	display_name: Yup.string().required(),
	role_id: Yup.number().required(),
	user_job_title: Yup.string().required(),
	user_phone: Yup.string(),
	user_app_phone: Yup.string(),
	user_email: Yup.string()
		.email()
		.required(),
	password: Yup.string()
		.required()
		.min(4)
});

const ChangeUserPassword = (props: Props): JSX.Element => {
	const { active, addUser, options, getOptions, onClose } = props;

	React.useEffect(() => {
		if (!options) {
			getOptions();
		}
	}, []);

	const onSubmit = (values: Values, actions: FormikActions<Values>) => {
		formikSubmit(values, actions, addUser)
			.then(() => {
				window.popup.pop();
				onClose && onClose();
			})
			.catch(() => {})
			.then(() => {
				actions.setSubmitting(false);
			});
	};

	const initialValues = {
		display_name: '',
		user_email: '',
		role_id: '',
		user_job_title: '',
		user_phone: '',
		user_app_phone: ''
	};

	return (
		<Popup active={active} title="Add new member" className="popup--add-user">
			<FormikForm
				initialValues={initialValues}
				validationSchema={validationSchema}
				onSubmit={onSubmit}
				render={(formProps: FormikProps<Values>) => (
					<Form {...props} {...formProps} />
				)}
			/>
		</Popup>
	);
};

const Form = (props: Props & FormikProps<Values>) => {
	const { handleSubmit, options, user } = props;
	return (
		<form onSubmit={handleSubmit}>
			<FormGroup double>
				<FormikInputText name="display_name" label="Display name:" flat />
				<FormikInputText name="user_job_title" label="Job title:" flat />
			</FormGroup>
			<FormGroup double>
				<FormikInputText label="Email:" name="user_email" flat />
				<FormikInputText label="Phone:" name="user_phone" flat />
				<FormikInputText label="Apps Phone:" name="user_app_phone" flat />
			</FormGroup>
			<FormGroup double>
				<FormikInputText
					label="Password:"
					name="password"
					type="password"
					flat
				/>
				<FormikInputSelect label="Role:" fullWidth name="role_id" flat>
					{options &&
						options.roles &&
						options.roles.map((item: Role) => {
							const { id, role } = item;
							if(user && user.role_id == 6){
								if(id == 3 || id == 5)
									return (
										<option value={id} key={id}>
											{role}
										</option>
									);
							} else {
								return (
									<option value={id} key={id}>
										{role}
									</option>
								);
							}
						})}
				</FormikInputSelect>
			</FormGroup>
			<FormikButtonSubmit fullWidth {...props} />
		</form>
	);
};

const mapStateToProps = (store: Store) => {
	const { options } = store;
	return { options };
};

const mapDispatchToProps = (dispatch: any) => {
	return {
		getOptions: () => dispatch(getOptions()),
		addUser: (data: Request$AddUser) => dispatch(addNewUserActionCreator(data))
	};
};


export default hot(module)(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(ChangeUserPassword)
);

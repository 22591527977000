// tslint:disable: max-line-length

import * as React from 'react';
import layout from '../../../../utils/layout';
import SvgTemplate from '../SvgTemplate';
import { Svg } from '../../Svg';

const icon = ({ color, ...rest }: Svg): JSX.Element => (
	<SvgTemplate {...rest} viewBox="0 0 512 512">
		<g fill={color}>
			<path d="M354.5,39.4L354.5,39.4V19.7c0-10.9-8.8-19.7-19.7-19.7s-19.7,8.8-19.7,19.7v19.7H157.5c-21.8,0-39.4,17.6-39.4,39.4v393.8
		c0,21.8,17.6,39.4,39.4,39.4h196.9c21.8,0,39.4-17.6,39.4-39.4V78.8C393.8,57,376.2,39.4,354.5,39.4z M196.9,472.6h-39.4v-39.4
		h39.4V472.6z M196.9,393.8h-39.4v-39.4h39.4V393.8z M196.9,315.1h-39.4v-39.4h39.4V315.1z M275.7,472.6h-39.4v-39.4h39.4V472.6z
		 M275.7,393.8h-39.4v-39.4h39.4V393.8z M275.7,315.1h-39.4v-39.4h39.4V315.1z M354.5,472.6h-39.4v-39.4h39.4V472.6z M354.5,393.8
		h-39.4v-39.4h39.4V393.8z M354.5,315.1h-39.4v-39.4h39.4V315.1z M354.5,216.6c0,10.9-8.8,19.7-19.7,19.7H177.2
		c-10.9,0-19.7-8.8-19.7-19.7V98.5c0-10.9,8.8-19.7,19.7-19.7h157.5c10.9,0,19.7,8.8,19.7,19.7V216.6z"/>
		</g>
	</SvgTemplate>
);

icon.defaultProps = {
	width: layout.spacing.three,
	color: layout.color.primary
};

export default icon;

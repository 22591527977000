// tslint:disable: max-line-length

import * as React from 'react';
import layout from '../../../../utils/layout';
import SvgTemplate from '../SvgTemplate';
import { Svg } from '../../Svg';

const icon = ({ color, ...rest }: Svg): JSX.Element => (
	<SvgTemplate {...rest} viewBox="0 0 24 20">
		<g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<g id="iconmonstr-eye-10" fill={color} fillRule="nonzero">
				<path
					d="M11.885,12.988 L14.989,9.89 L15,10 C15,11.654 13.654,13 12,13 L11.885,12.988 Z M19.933,4.956 L16.659,8.224 C16.871,8.778 17,9.373 17,10 C17,12.757 14.757,15 12,15 C11.369,15 10.771,14.87 10.215,14.656 L7.838,17.028 C9.114,17.616 10.509,18 12.015,18 C19.748,18 24,9.551 24,9.551 C24,9.551 22.585,7.073 19.933,4.956 Z M21.364,1.42 L2.745,20 L1.363,18.578 L4.818,15.131 C1.796,12.681 1.77635684e-15,9.551 1.77635684e-15,9.551 C1.77635684e-15,9.551 4.446,2 12.015,2 C13.84,2 15.471,2.426 16.901,3.075 L19.982,-3.55271368e-15 L21.364,1.42 Z M7.613,12.342 L9.132,10.827 C9.055,10.563 9,10.289 9,10 C9,8.346 10.346,7 12,7 C12.291,7 12.567,7.055 12.833,7.134 L14.351,5.619 C13.647,5.237 12.855,5 12,5 C9.243,5 7,7.243 7,10 C7,10.852 7.235,11.641 7.613,12.342 Z"
					id="Shape"
				/>
			</g>
		</g>
	</SvgTemplate>
);

icon.defaultProps = {
	width: layout.spacing.three,
	color: layout.color.primaryLight
};

export default icon;

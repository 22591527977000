// tslint:disable: max-line-length
import * as React from 'react';
import SvgTemplate from '../SvgTemplate';
import { Svg } from '../../Svg';

const icon = ({ color, ...rest }: Svg): JSX.Element => (
	<SvgTemplate {...rest} viewBox="0 0 53 27">
		<g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<g id="bednblue" fillRule="nonzero">
				<g id="Group-3">
					<polygon
						id="Fill-1"
						fill="#FEFEFE"
						points="0 13.42 13.09 13.42 13.09 0 0 0"
					/>
					<polygon
						id="Fill-2"
						fill="#FEFEFE"
						points="0 26.84 13.09 26.84 13.09 13.42 0 13.42"
					/>
					<polygon
						id="Fill-3"
						fill="#EC2626"
						points="0 0 0 13.42 13.09 13.42 4.39 6.71 13.09 0"
					/>
					<polygon
						id="Fill-4"
						fill="#EC2626"
						points="0 13.42 0 26.84 13.09 26.84 4.39 20.13 13.09 13.42"
					/>
					<polygon
						id="Fill-5"
						fill="#EC2626"
						points="39.27 26.84 52.35 26.84 52.35 20.13 39.27 20.13"
					/>
					<polygon
						id="Fill-6"
						fill="#1F1A50"
						points="39.27 20.13 52.35 20.13 52.35 13.42 39.27 13.42"
					/>
					<polygon
						id="Fill-7"
						fill="#EC2626"
						points="13.09 13.42 26.18 13.42 26.18 6.71 13.09 6.71"
					/>
					<polygon
						id="Fill-8"
						fill="#1F1A50"
						points="13.09 6.71 26.18 6.71 26.18 0 13.09 0"
					/>
					<polygon
						id="Fill-9"
						fill="#F6EC41"
						points="26.18 13.42 39.27 13.42 39.27 0 26.18 0"
					/>
					<polygon
						id="Fill-10"
						fill="#F6EC41"
						points="13.09 20.13 19.63 20.13 19.63 13.42 13.09 13.42"
					/>
					<polygon
						id="Fill-11"
						fill="#221E1F"
						points="19.63 20.13 26.18 20.13 26.18 13.42 19.63 13.42"
					/>
					<polygon
						id="Fill-12"
						fill="#221E1F"
						points="13.09 26.84 19.63 26.84 19.63 20.13 13.09 20.13"
					/>
					<polygon
						id="Fill-13"
						fill="#F6EC41"
						points="19.63 26.84 26.18 26.84 26.18 20.13 19.63 20.13"
					/>
					<polygon
						id="Fill-14"
						fill="#EC2626"
						points="26.18 20.13 32.72 20.13 32.72 13.42 26.18 13.42"
					/>
					<polygon
						id="Fill-15"
						fill="#FEFEFE"
						points="32.72 20.13 39.27 20.13 39.27 13.42 32.72 13.42"
					/>
					<polygon
						id="Fill-16"
						fill="#FEFEFE"
						points="26.18 26.84 32.72 26.84 32.72 20.13 26.18 20.13"
					/>
					<polygon
						id="Fill-17"
						fill="#EC2626"
						points="32.72 26.84 39.27 26.84 39.27 20.13 32.72 20.13"
					/>
					<polygon
						id="Fill-18"
						fill="#1F1A50"
						points="26.18 10.06 39.27 10.06 39.27 3.36 26.18 3.36"
					/>
					<polygon
						id="Fill-19"
						fill="#1F1A50"
						points="39.27 3.36 42.54 3.36 42.54 0 39.27 0"
					/>
					<polygon
						id="Fill-20"
						fill="#FEFEFE"
						points="42.54 3.36 45.81 3.36 45.81 0 42.54 0"
					/>
					<polygon
						id="Fill-21"
						fill="#1F1A50"
						points="45.81 3.36 49.08 3.36 49.08 0 45.81 0"
					/>
					<polygon
						id="Fill-22"
						fill="#FEFEFE"
						points="49.08 3.36 52.35 3.36 52.35 0 49.08 0"
					/>
					<polygon
						id="Fill-23"
						fill="#FEFEFE"
						points="39.27 6.71 42.54 6.71 42.54 3.36 39.27 3.36"
					/>
					<polygon
						id="Fill-24"
						fill="#1F1A50"
						points="42.54 6.71 45.81 6.71 45.81 3.36 42.54 3.36"
					/>
					<polygon
						id="Fill-25"
						fill="#FEFEFE"
						points="45.81 6.71 49.08 6.71 49.08 3.36 45.81 3.36"
					/>
					<polygon
						id="Fill-26"
						fill="#1F1A50"
						points="49.08 6.71 52.35 6.71 52.35 3.36 49.08 3.36"
					/>
					<polygon
						id="Fill-27"
						fill="#1F1A50"
						points="39.27 10.06 42.54 10.06 42.54 6.71 39.27 6.71"
					/>
					<polygon
						id="Fill-28"
						fill="#FEFEFE"
						points="42.54 10.06 45.81 10.06 45.81 6.71 42.54 6.71"
					/>
					<polygon
						id="Fill-29"
						fill="#1F1A50"
						points="45.81 10.06 49.08 10.06 49.08 6.71 45.81 6.71"
					/>
					<polygon
						id="Fill-30"
						fill="#FEFEFE"
						points="49.08 10.06 52.35 10.06 52.35 6.71 49.08 6.71"
					/>
					<polygon
						id="Fill-31"
						fill="#FEFEFE"
						points="39.27 13.42 42.54 13.42 42.54 10.06 39.27 10.06"
					/>
					<polygon
						id="Fill-32"
						fill="#1F1A50"
						points="42.54 13.42 45.81 13.42 45.81 10.06 42.54 10.06"
					/>
					<polygon
						id="Fill-33"
						fill="#FEFEFE"
						points="45.81 13.42 49.08 13.42 49.08 10.06 45.81 10.06"
					/>
					<polygon
						id="Fill-34"
						fill="#1F1A50"
						points="49.08 13.42 52.35 13.42 52.35 10.06 49.08 10.06"
					/>
				</g>
			</g>
		</g>
	</SvgTemplate>
);

icon.defaultProps = {
	width: 53
};

export default icon;

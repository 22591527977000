// tslint:disable: max-line-length

import * as React from 'react';
import layout from '../../../../utils/layout';
import SvgTemplate from '../SvgTemplate';
import { Svg } from '../../Svg';

const icon = ({ color, ...rest }: Svg): JSX.Element => (
	<SvgTemplate {...rest} viewBox="0 0 14 13">
		<g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<g id="star" fill={color} fillRule="nonzero">
				<polygon
					id="Shape"
					points="6.99452459 0.0140859951 9.15570492 4.28064128 13.989082 4.96478624 10.4918033 8.2858231 11.3171475 12.9752776 6.99452459 10.7612531 2.67163934 12.9752776 3.49727869 8.2858231 0 4.96478624 4.83308197 4.28064128"
				/>
			</g>
		</g>
	</SvgTemplate>
);

icon.defaultProps = {
	width: layout.spacing.three,
	color: layout.color.yellow
};

export default icon;

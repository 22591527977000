// tslint:disable: max-line-length
import * as React from 'react';
import layout from '../../../../utils/layout';
import SvgTemplate from '../SvgTemplate';
import { Svg } from '../../Svg';

const icon = ({ color, ...rest }: Svg): JSX.Element => (
	<SvgTemplate {...rest} viewBox="0 0 98.26 92.261">
		<>
			<g id="Layer_1" display="none" opacity="0.3">
				<g display="inline" opacity="0.5">
					<line
						fill="none"
						stroke="#ED1C24"
						strokeWidth="0.25"
						strokeMiterlimit="10"
						x1="12.087"
						y1="0.088"
						x2="12.087"
						y2="96.088"
					/>
					<line
						fill="none"
						stroke="#ED1C24"
						strokeWidth="0.25"
						strokeMiterlimit="10"
						x1="84.088"
						y1="0.088"
						x2="84.088"
						y2="96.088"
					/>
					<line
						fill="none"
						stroke="#ED1C24"
						strokeWidth="0.25"
						strokeMiterlimit="10"
						x1="96.13"
						y1="12.088"
						x2="0.13"
						y2="12.088"
					/>
					<line
						fill="none"
						stroke="#ED1C24"
						strokeWidth="0.25"
						strokeMiterlimit="10"
						x1="96.089"
						y1="84.088"
						x2="0.089"
						y2="84.088"
					/>
				</g>
				<g display="inline" opacity="0.5">
					<line
						fill="none"
						stroke="#00AEEF"
						strokeWidth="0.25"
						strokeMiterlimit="10"
						x1="24.088"
						y1="0.088"
						x2="24.088"
						y2="96.088"
					/>
					<line
						fill="none"
						stroke="#00AEEF"
						strokeWidth="0.25"
						strokeMiterlimit="10"
						x1="48.088"
						y1="0.088"
						x2="48.088"
						y2="96.088"
					/>
					<line
						fill="none"
						stroke="#00AEEF"
						strokeWidth="0.25"
						strokeMiterlimit="10"
						x1="72.088"
						y1="0.088"
						x2="72.088"
						y2="96.088"
					/>
					<line
						fill="none"
						stroke="#00AEEF"
						strokeWidth="0.25"
						strokeMiterlimit="10"
						x1="96.089"
						y1="24.088"
						x2="0.089"
						y2="24.088"
					/>
					<line
						fill="none"
						stroke="#00AEEF"
						strokeWidth="0.25"
						strokeMiterlimit="10"
						x1="96.089"
						y1="48.088"
						x2="0.089"
						y2="48.088"
					/>
					<line
						fill="none"
						stroke="#00AEEF"
						strokeWidth="0.25"
						strokeMiterlimit="10"
						x1="96.089"
						y1="72.088"
						x2="0.089"
						y2="72.088"
					/>
					<line
						fill="none"
						stroke="#00AEEF"
						strokeWidth="0.25"
						strokeMiterlimit="10"
						x1="0.087"
						y1="0.088"
						x2="96.172"
						y2="96.173"
					/>
					<line
						fill="none"
						stroke="#00AEEF"
						strokeWidth="0.25"
						strokeMiterlimit="10"
						x1="0.087"
						y1="96.088"
						x2="95.838"
						y2="0.338"
					/>
				</g>
			</g>
			<g id="Layer_2">
				<path
					className="fill"
					fill="#fff"
					stroke={'#8B8AA0'}
					strokeWidth="2"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeMiterlimit="10"
					d="
          M39.318,12.004h-0.166c-7.53,13.503-15.071,26.483-15.071,39.988s4.734,28.589,8.592,32.096h6.479h0.166h6.477
          c3.859-3.507,8.596-18.591,8.596-32.096S46.848,25.507,39.318,12.004z M46.089,56.243H32.587V42.742h13.502V56.243z"
				/>
				<line
					fill="#FFFFFF"
					stroke={'#8B8AA0'}
					strokeWidth="2"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeMiterlimit="10"
					x1="72.087"
					y1="12.574"
					x2="72.087"
					y2="84.088"
				/>
				<polyline
					fill="none"
					stroke={'#8B8AA0'}
					strokeWidth="2"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeMiterlimit="10"
					points="
          77.161,79.016 72.087,84.088 67.015,79.016 	"
				/>
				<polyline
					fill="none"
					stroke={'#8B8AA0'}
					strokeWidth="2"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeMiterlimit="10"
					points="
          67.015,17.162 72.087,12.088 77.162,17.162 	"
				/>
			</g>
		</>
	</SvgTemplate>
);

icon.defaultProps = {
	width: layout.spacing.four,
	color: layout.color.primary
};

export default icon;

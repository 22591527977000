import {post} from "../../redux/api/utils";

export type Request$SetSupervisors = {
	id: string | number;
	supervisors: number[];
};

export type Response$SetSupervisors = { message: 'ok' };

/**
 * Set Supervisors for User
 */
export async function setSupervisors(
	data: Request$SetSupervisors
): Promise<Response$SetSupervisors> {
	const { id, supervisors } = data;
	return post(`/user/${id}/set-supervisors`, { supervisors });
}

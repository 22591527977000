// tslint:disable: max-line-length

import * as React from 'react';
import layout from '../../../../utils/layout';
import SvgTemplate from '../SvgTemplate';
import { Svg } from '../../Svg';

const icon = ({ color, ...rest }: Svg): JSX.Element => (
	<SvgTemplate {...rest} viewBox="0 0 22 28">
		<g
			id="Symbols"
			stroke="none"
			strokeWidth="1"
			opacity="0.223675272"
			fill="none"
			fillRule="evenodd"
		>
			<g
				id="Sign-up"
				transform="translate(-325.000000, -266.000000)"
				fillRule="nonzero"
				fill={color}
			>
				<g id="Group-6" transform="translate(325.000000, 266.000000)">
					<path
						d="M22,27.2876939 L22,23.3613237 C22,18.5025853 18.7989418,14.3735264 14.3931217,12.9489142 C16.5116402,11.7617373 17.9492063,9.50320579 17.9492063,6.91458118 C17.9492063,3.10403309 14.8296296,0 11,0 C7.17037037,0 4.05079365,3.10403309 4.05079365,6.91458118 C4.05079365,9.50320579 5.48835979,11.7617373 7.60687831,12.9489142 C3.1952381,14.3735264 0,18.5025853 0,23.3613237 L0,27.2876939 C0,27.6814891 0.32010582,28 0.715873016,28 L21.2957672,28 C21.6798942,27.9942089 22,27.6814891 22,27.2876939 Z M5.47089947,6.91458118 C5.47089947,3.88583247 7.95026455,1.4188211 10.9941799,1.4188211 C14.0380952,1.4188211 16.5174603,3.88583247 16.5174603,6.91458118 C16.5174603,9.94332989 14.0380952,12.4103413 10.9941799,12.4103413 C7.95026455,12.4103413 5.47089947,9.94332989 5.47089947,6.91458118 Z M20.5740741,26.5753878 L1.41428571,26.5753878 L1.41428571,23.3555326 C1.41428571,18.102999 5.70952381,13.8233713 10.9941799,13.8233713 C16.278836,13.8233713 20.5740741,18.0972079 20.5740741,23.3555326 L20.5740741,26.5753878 Z"
						id="Shape"
					/>
				</g>
			</g>
		</g>
	</SvgTemplate>
);

icon.defaultProps = {
	width: 20,
	color: layout.color.primary
};

export default icon;

import * as React from 'react';
import Dot from '../../../../components/Dot';

const columns = [
	{
		Header: 'ID',
		accessor: 'id',
		// sortable: true,
		// filterable: true,
		width: 60,
		minResizeWidth: 60,
		Cell: (props: any) => <span className="row__id">{props.original.id}</span>
	},
	{
		Header: 'Name',
		accessor: 'contact_name',
		// sortable: true,
		// filterable: true,
		minResizeWidth: 60
	},
	{
		Header: 'Email',
		accessor: 'emails',
		// filterable: true,
		minResizeWidth: 60,
		Cell: (props: any) => {
			return (
				<span>
					{props.original.emails.map((em: any) => (
						<div key={em.email}>{em.email}</div>
					))}
				</span>
			);
		}
	},
	{
		Header: 'Phone',
		accessor: 'phone',
		// filterable: true,
		minResizeWidth: 60,
		Cell: (props: any) => {
			return (
				<span>
					{props.original.phones.map((ph: any) => (
						<div key={ph.phone}>{ph.phone}</div>
					))}
				</span>
			);
		}
	},
	{
		Header: 'Link',
		accessor: 'link',
		// filterable: true,
		minResizeWidth: 60,
		Cell: (props: any) => {
			return (
				<span>
					{props.original.links.map((url: any) => (
						<div key={url.link}>{url.link}</div>
					))}
				</span>
			);
		}
	},
	{
		Header: 'Country',
		// sortable: true,
		accessor: 'country',
		width: 110,
		minResizeWidth: 60,
		Cell: (props: any) => {
			const { country } = props.original;
			if (!country) return null;
			return <span>{country.country}</span>;
		}
	},
	{
		Header: 'Status',
		width: 60,
		minResizeWidth: 60,
		className: 'flex--center',
		Cell: (props: any) => {
			const { to_be_checked } = props.original;
			if (to_be_checked == 0) {
				return (
					<span>
						<Dot color="success" />
					</span>
				);
			} else if (to_be_checked == 2) {
				return (
					<span>
						<Dot color="danger" />
					</span>
				);
			}
			return (
				<span>
					<Dot color="warning" />
				</span>
			);
		}
	},
	{
		Header: 'Listed',
		width: 60,
		minResizeWidth: 60,
		className: 'flex--center',
		Cell: (props: any) => {
			const { listed } = props.original;
			if (listed == 1) {
				return (
					<span>
						<Dot color="success" />
					</span>
				);
			} else if (listed == 0) {
				return (
					<span>
						<Dot color="danger" />
					</span>
				);
			}
		}
	},
	{
		Header: 'Check comments',
		width: 150,
		minResizeWidth: 60,
		Cell: (props: any) => props.original.check_comments
	}
];

export default columns;

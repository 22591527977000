import * as React from 'react';
import './Users.scss';
import ReactTable from 'react-table';
import User from '../../../redux/entities/User';
import { connect } from 'react-redux';
import { Store, Dispatch } from '../../../models';
import Dialog from '../../../components/Dialog';
import Button from '../../../components/Button';
import Svg from '../../../components/Svg';
import { pushPopup, popups } from '../../../utils/popups';
import {Options, Role, SipAccount} from '../../../models/Options';
import { getOptions } from '../../../redux/actions';
import InputSelect from '../../../components/Form/InputSelect';
import {
	FormData$ChangeRole,
	FormData$ChangeUserStatus,
	FormData$ChangeSipAccount
} from '../../../redux/entities/User/models';
import Toggle from '../../../components/Toggle';
import { listUsersActionCreator } from '../../../redux/UserList/UserListActions';
import changeRoleActionCreator from '../../../redux/User/UserActions/ChangeRole';
import changeSipAccountActionCreator from "../../../redux/User/UserActions/ChangeSipAccount";
import routes from "../../routes";

export class Users extends React.Component<any> {
	componentDidMount() {
		const { getUsers, options, getOptions, deleteUser, user } = this.props;
		getUsers();
		if (!options) {
			getOptions();
		}
		// Redirects non-admins to home
		if(user.role_id > 2 && user.role_id != 6){
			this.props.history.push(routes.home.base);
		}
	}

	changeRole = (id: number, role_id: number) => {
		const { changeRole } = this.props;
		changeRole({ id, role_id });
	};

	changeSipAccount = (id: number, sip_account_id: number) => {
		const { changeSipAccount, getUsers } = this.props;
		changeSipAccount({ id, sip_account_id });
		setTimeout(function(){
			getUsers();
		},150)
	};

	renderColumns = () => {
		const columns = [];
		columns.push(
			{
				Header: 'ID',
				accessor: 'id',
				width: 60,
				minResizeWidth: 60,
				Cell: (props: any) => <span className="row__id">{props.original.id}</span>
			},
			{
				Header: 'Display name',
				accessor: 'display_name',
				minResizeWidth: 60,
				Cell: (props: any) => <span>{props.original.display_name}</span>
			},
			{
				Header: 'Email',
				accessor: 'user_email',
				minResizeWidth: 60,
				Cell: (props: any) => <span>{props.original.user_email}</span>
			},
			{
				Header: 'Phone',
				accessor: 'user_phone',
				minResizeWidth: 60,
				Cell: (props: any) => <span>{props.original.user_phone}</span>
			},
			{
				Header: 'Apps Phone',
				accessor: 'user_app_phone',
				minResizeWidth: 60,
				Cell: (props: any) => <span>{props.original.user_app_phone}</span>
			},
			{
				Header: 'Job title',
				accessor: 'user_job_description',
				minResizeWidth: 60,
				Cell: (props: any) => <span>{props.original.user_job_title}</span>
			},
			{
				Header: 'Role',
				minResizeWidth: 60,
				accessor: 'user_job_description',

				Cell: (props: any) => {
					const { options, user } = this.props;
					return (
						<span>
						<InputSelect
							number
							flat
							fullWidth
							value={props.original.role_id}
							disabled={user.role_id != 1 && props.original.role_id <= user.role_id}
							onChange={(e: any) =>
								this.changeRole(props.original.id, e.target.value)
							}
						>
							{options &&
							options.roles &&
							options.roles.map((item: Role) => {
								const { id, role } = item;
								if(user.role_id == 1 || props.original.role_id <= user.role_id || user.role_id < id){
									return (
										<option value={id} key={id} disabled={user.role_id != 1 && id <= user.role_id}>
											{role}
										</option>
									);
								}
							})}
						</InputSelect>
					</span>
					);
				}
			}
		);

		if(this.props.user.role_id != 6)
			columns.push({
				Header: 'Supervisors',
				accessor: 'supervised_by_users',
				minResizeWidth: 60,
				Cell: (props: any) => {
					const { options, userList, getUsers } = this.props;
					return (
						<div className="flex--center" style={{position: "relative", width: "100%", height: "100%", padding: 15}}>
							{props.original.supervised_by && props.original.supervised_by.length > 0 && (
								<ul>
									{props.original.supervised_by && props.original.supervised_by.length > 0 && props.original.supervised_by.map((user: any) => {
										const { supervisors, display_name } = user;
										return (
											<li key={supervisors.id}>
												{display_name}
											</li>
										);
									})}
								</ul>
							)}
							{props.original.supervised_by && props.original.supervised_by.length == 0 && (
								<span>-</span>
							)}
							{(this.props.user.role_id < 3 && (props.original.role_id > 2 && props.original.role_id != 6)) && (
								<div
									style={{ right: 0 , position: "absolute", margin: 5, cursor: "pointer"}}
									onClick={() =>
										pushPopup(popups.EditSupervisors, {
											user: props.original,
											options: options,
											userList: userList,
											onClose: getUsers
										})
									}
								>
									<Svg icon={props.original.supervised_by && props.original.supervised_by.length > 0 ? "pencil" : "new-contact"} />
								</div>
							)}
						</div>
					)
				}
			});

		columns.push({
				Header: 'Caller account',
				minResizeWidth: 60,
				accessor: 'sip_account',
				Cell: (props: any) => {
					const { options } = this.props;
					return (
						<span>
						<InputSelect
							number
							flat
							fullWidth
							value={props.original.sip_account ? props.original.sip_account.id : null}
							placeholder={'default'}
							onChange={(e: any) =>
								this.changeSipAccount(props.original.id, e.target.value)
							}
						>
							<option value={""}>
										default
									</option>
							{options &&
							options.sip_accounts &&
							options.sip_accounts.map((item: SipAccount) => {
								const { id, sip_username } = item;
								return (
									<option value={id} key={id}>
										{sip_username}
									</option>
								);
							})}
						</InputSelect>
					</span>
					);
				}
			},
			{
				Header: 'Actions',
				width: 80,
				Cell: (props: any) => (
					<span className="flex flex--center" style={{ width: '100%' }}>
				{(this.props.user.role_id == 1 || this.props.user.role_id < props.original.role_id || this.props.user.role_id == 6) && (
					<div
						style={{ marginRight: 8 }}
						onClick={() =>
							pushPopup(popups.ChangeUserPassword, { user: props.original })
						}
					>
						<Svg icon="change-password" />
					</div>
				)}
				</span>
				)
			},
			{
				Header: 'Active status',
				width: 120,
				Cell: (props: any) => (
					<span className="flex flex--center" style={{ width: '100%' }}>
					<Toggle
						active={props.original.is_active}
						disabled={this.props.user.role_id != 1}
						onChange={() => {
							this.props.changeUserStatus({
								id: props.original.id,
								status: props.original.is_active ? 'inactive' : 'active'
							});
						}}
					/>
				</span>
				)
			});

		return columns;
	};

	render(): JSX.Element {
		const { userList, options, user, getUsers } = this.props;
		return (
			<div className="page page--users">
				<Dialog title="Members">
					<Button
						small
						className="btn--add-new"
						onClick={() => pushPopup(popups.AddUser,{
							onClose: getUsers,
							user: user
						})}
					>
						+ Add new Member
					</Button>
					<ReactTable
						data={userList.filter((e: any) => e.id !== user.id)}
						columns={this.renderColumns()}
						sortable={false}
						resizable={true}
						showPagination={false}
						collapseOnPageChange={false}
						multiSort={false}
						manual
					/>
				</Dialog>
			</div>
		);
	}
}

const mapStateToProps = (store: Store) => {
	const { userList, options, user } = store;
	return { userList, options, user };
};

const user = new User();

const mapDispatchToProps = (dispatch: any) => {
	return {
		getUsers: () => dispatch(listUsersActionCreator()),
		getOptions: () => dispatch(getOptions()),
		changeRole: (data: FormData$ChangeRole) =>
			dispatch(changeRoleActionCreator(data)),
		changeUserStatus: (data: FormData$ChangeUserStatus) =>
			dispatch(user.changeUserStatusAction(data)),
		changeSipAccount: (data: FormData$ChangeSipAccount) =>
			dispatch(changeSipAccountActionCreator(data)),

	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Users);

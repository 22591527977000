import * as React from 'react';
import Svg from '../../Svg';
import './InputCheckbox.scss';
import layout from '../../../utils/layout';
import { Hint } from '../../Hint/Hint';

interface Props {
	name?: string;
	value?: boolean;
	onChange?: (e: React.SyntheticEvent) => any;
	className?: string;
	checkboxLabel?: any;
	white?: boolean;
	validationError?: string;
	circle?: boolean;
	hint?: string;
	reverse?: boolean;
	small?: boolean;
	style?: object;
	dataCy?: string;
}

export const InputCheckbox = (props: Props): JSX.Element => {
	const {
		name,
		onChange,
		value,
		validationError,
		small,
		circle,
		hint,
		reverse,
		checkboxLabel,
		white,
		style,
		dataCy
	} = props;

	let inputClass = 'input-checkbox';

	if (validationError) inputClass += ' required';

	if (white) inputClass += ' white';

	if (small) inputClass += ' small';

	if (circle) inputClass += ' circle';

	if (reverse) inputClass += ' reverse';

	return (
		<div className={inputClass} style={style}>
			<label
				className={value ? 'checked' : ''}
				{...(dataCy ? { 'data-cy': dataCy } : {})}
			>
				<input
					type="checkbox"
					name={name}
					checked={value}
					onChange={onChange}
				/>
				<span className="input-checkbox__fake">
					<Svg
						icon="check"
						color={circle ? layout.color.white : layout.color.primary}
						width={circle ? layout.spacing.two + 2 : layout.spacing.two}
					/>
				</span>
				{checkboxLabel && (
					<span className="input-checkbox__text noselect">
						{typeof checkboxLabel === 'string'
							? checkboxLabel
							: React.createElement(checkboxLabel)}
					</span>
				)}
			</label>
			{hint && <Hint text={hint} />}
		</div>
	);
};

export default React.memo(InputCheckbox);

import * as React from 'react';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './CustomEmail.scss';
import { hot } from 'react-hot-loader';
import {RouterProps, RouteProps, RouteComponentProps, Prompt} from 'react-router';
import RichEditor from './RichEditor/RichEditor';
import {
	FormikInputText,
	FormikInputSelect,
	FormikButtonSubmit,
	FormGroup
} from '../../../components/Form';
import { Formik, FormikProps, FormikActions } from 'formik';
import { connect } from 'react-redux';
import { Store, Dispatch } from '../../../models';
import {Language, Options} from '../../../models/Options';
import { Email } from '../../../models/Contact';
import { getOptions } from '../../../redux/actions';
import emailApi from '../../../redux/api/Email';
import * as Yup from 'yup';
import Label from '../../../components/Form/Label';
import Svg from '../../../components/Svg';
import InputCheckbox from '../../../components/Form/InputCheckbox';
import { showContact } from '../../../redux/actions/Contact';
import { Action } from '../../../redux/models';
import { AxiosError } from 'axios';
import layout from '../../../utils/layout';

type Props = {
	options: Options;
	getOptions: any;
} & RouteComponentProps<{ id: string }>;

type Values = {
	language_id: number | string;
	subject: string;
	emails: string[];
};

let editorTimeout: any = null;

const CustomEmail = (props: Props) => {
	const [markup, setMarkup] = React.useState('');

	const { id } = props.match.params;
	const { options, getOptions } = props;

	window.onbeforeunload = function() {
		return (markup.trim() != "<br>") ? ' ' : null;
	}

	React.useEffect(() => {
		if (!options || !options.languages || !options.languages.length) {
			getOptions();
		}
	}, []);

	const [languageId, setLanguageId] = React.useState(1);
	const [subject, setSubject] = React.useState('');

	React.useEffect(() => {}, [markup]);

	const handleEditorChange = (markup: string) => {
		setMarkup(markup.split('<p></p>').join('<br>'));
	};

	/**
	 * Update preview on markup or language change
	 */
	React.useEffect(() => {
		clearTimeout(editorTimeout);
		editorTimeout = setTimeout(() => {
			preview();
		}, 500);
	}, [markup, languageId]);

	const preview = () => {
		emailApi
			.previewCustomEmail({
				contact_id: Number(id),
				language_id: languageId,
				content: markup,
				subject
			})
			.then((res: any) => {
				setEmailPreview(res.data);
			});
	};

	const [emailPreview, setEmailPreview] = React.useState('');
	const [emailSent, setEmailSent] = React.useState(false);

	const handleSubmit = (values: Values, actions: FormikActions<Values>) => {
		if (markup.length < 10) {
			if (!window.confirm('Send empty email?')) {
				actions.setSubmitting(false);
				return;
			}
		}
		emailApi
			.sendCustomEmail({
				contact_id: Number(id),
				language_id: values.language_id,
				content: markup,
				subject: values.subject,
				emails: values.emails
			})
			.then(() => {
				setEmailSent(true);
			})
			.catch(() => {})
			.then(() => {
				actions.setSubmitting(false);
			});
	};

	const validationSchema = Yup.object().shape({
		subject: Yup.string().required(),
		language_id: Yup.number().required(),
		emails: Yup.array().of(Yup.string())
	});

	const [contact, setContact] = React.useState(undefined);
	const [error, setError] = React.useState(false);

	React.useEffect(() => {
		showContact({ id })
			.then((res: any) => {
				if (!res.payload) {
					setError(true);
				}
				setContact(res.payload);
			})
			.catch((ex: AxiosError) => {
				setError(true);
			});
	}, []);

	if (error) {
		return <div className="page page--custom-email">Error</div>;
	}

	if (emailSent) {
		return (
			<div className="page page--custom-email">
				<div className="cm__back" onClick={() => props.history.goBack()}>
					<Svg icon="arrow-left" /> Back
				</div>
				<div className="flex flex--center--start">
					<Svg icon="check-circle" />
					<span style={{ marginLeft: layout.spacing.two }}>Email sent!</span>
				</div>
			</div>
		);
	}

	return (
		<div className="page page--custom-email">
			<div className="cm__back" onClick={() => props.history.goBack()}>
				<Svg icon="arrow-left" /> Back
			</div>

			<RichEditor onChange={handleEditorChange} />

			{emailPreview && (
				<div style={{ marginTop: '24px' }}>
					<Label title="Preview:" />
					<div
						className="email-preview"
						dangerouslySetInnerHTML={{ __html: emailPreview }}
					/>
				</div>
			)}

			<Formik
				onSubmit={handleSubmit}
				render={(formProps: FormikProps<Values>) => (
					<Form
						{...formProps}
						{...props}
						onLanguageChange={setLanguageId}
						onSubjectChange={setSubject}
						contact={contact}
					/>
				)}
				validationSchema={validationSchema}
				initialValues={{
					subject: '',
					language_id: '',
					emails: []
				}}
			/>
			<Prompt when={markup.trim() != "<br>"} message="You have unsaved changes. Are you sure you want to leave?" />
		</div>
	);
};

const Form = (props: Props & FormikProps<Values> & any) => {
	const {
		handleSubmit,
		options,
		onLanguageChange,
		values,
		contact,
		setFieldValue
	} = props;

	const handleEmailChange = (e: any, index: number): void => {
		const { checked } = e.target;
		const emailClicked = contact.emails[index].email;

		let newEmails = [...values.emails];

    const indexOfEmailClicked = newEmails.indexOf(emailClicked);
    if (checked) {
      if (indexOfEmailClicked < 0) {
        newEmails.push(emailClicked)
      }
    } else {
      newEmails.splice(indexOfEmailClicked, 1);
    }

		setFieldValue('emails', newEmails);
	};

	return (
		<form style={{ marginBottom: '60px', width: '600px', maxWidth: 'calc(100vw - 50px)'}} onSubmit={handleSubmit}>
			<FormikInputText flat name="subject" label="Subject:" />
			<FormikInputSelect
				number
				flat
				name="language_id"
				label="Signature Language:"
				onChange={(e: any) => onLanguageChange(e.target.value)}
				// onChange={() => console.log('asdf')}
			>
				{options &&
					options.languages_on_emails &&
					options.languages_on_emails.map((lang: Language) => {
						const { id, language } = lang;
						return (
							<option key={id} value={id}>
								{language}
							</option>
						);
					})}
			</FormikInputSelect>

			{contact &&
				contact.emails &&
				contact.emails.map((em: Email, i: number) => (
					<FormGroup key={i}>
						<InputCheckbox
							value={values.emails.some((e: any) => e === em.email)}
							onChange={(e: React.SyntheticEvent) => handleEmailChange(e, i)}
							checkboxLabel={em.email}
						/>
					</FormGroup>
				))}

			<div style={{ paddingTop: '40px' }}><FormikButtonSubmit title="Send" {...props} /></div>
		</form>
	);
};

const mapStateToProps = (store: Store) => {
	const { options } = store;
	return { options };
};

const mapDispatchToProps = (dispatch: any) => {
	return { getOptions: () => dispatch(getOptions()) };
};

export default hot(module)(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(CustomEmail)
);

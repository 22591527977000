import { CCAccountListEnum } from '../enums';
import { Action } from '../../models';
import {
	Request$MakeAccountDefault,
	Response$MakeAccountDefault,
	makeAccountDefault
} from '../../../api/CallCenterAccounts/MakeAccountDefault';

export type Action$MakeAccountDefault = Action<CCAccountListEnum.MADE_ACCOUNT_DEFAULT, any>;

export type ActionCreator$MakeAccountDefault = (
	data: Request$MakeAccountDefault
) => Promise<Action$MakeAccountDefault>;

const makeAccountDefaultActionCreator: ActionCreator$MakeAccountDefault = async (
	data: Request$MakeAccountDefault
): Promise<Action$MakeAccountDefault> => {
	const response: Response$MakeAccountDefault = await makeAccountDefault(data);
	return {
		type: CCAccountListEnum.MADE_ACCOUNT_DEFAULT,
		payload: response
	};
};

export default makeAccountDefaultActionCreator;

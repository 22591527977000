// tslint:disable: max-line-length

import * as React from 'react';
import layout from '../../../../utils/layout';
import SvgTemplate from '../SvgTemplate';
import { Svg } from '../../Svg';

const icon = ({ color, ...rest }: Svg): JSX.Element => (
	<SvgTemplate {...rest} viewBox="0 0 24 16">
		<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<g id="iconmonstr-eye-9" fill={color} fillRule="nonzero">
				<path
					d="M15,8 C15,9.654 13.654,11 12,11 C10.346,11 9,9.654 9,8 C9,6.346 10.346,5 12,5 C13.654,5 15,6.346 15,8 Z M24,7.551 C24,7.551 19.748,16 12.015,16 C4.835,16 0,7.551 0,7.551 C0,7.551 4.446,0 12.015,0 C19.709,0 24,7.551 24,7.551 Z M17,8 C17,5.243 14.757,3 12,3 C9.243,3 7,5.243 7,8 C7,10.757 9.243,13 12,13 C14.757,13 17,10.757 17,8 Z"
					id="Shape"
				/>
			</g>
		</g>
	</SvgTemplate>
);

icon.defaultProps = {
	width: layout.spacing.three,
	color: layout.color.primaryLight
};

export default icon;

import {patch} from "../../redux/api/utils";

export type Request$ChangeAccountPassword = {
	id: string | number;
	password: string;
};

export type Response$ChangeAccountPassword = { message: 'ok' };

/**
 * Super admin's change password end point
 */
export async function changeAccountPassword(
	data: Request$ChangeAccountPassword
): Promise<Response$ChangeAccountPassword> {
	const { id, password } = data;
	return patch(`/sip-account/${id}/change-password`, { new_password: password });
}

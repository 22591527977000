import * as React from 'react';
import Popup from '../Popup';
import { hot } from 'react-hot-loader';
import {
	FormikForm,
	FormikInputText,
	FormikButtonSubmit,
	FormGroup,
	FormikInputSelect,
	formikSubmit, FormikInputTextarea
} from '../../Form';
import { FormikProps, FormikActions } from 'formik';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import { Store } from '../../../models';
import { getOptions, Action$GetOptions } from '../../../redux/actions';
import {CountryOption, Options} from '../../../models/Options';
import { Request$AddAccount } from '../../../api/CallCenterAccounts/AddCallCenterAccount'
import {
	ActionCreator$AddAccount,
	addNewAccountActionCreator
} from '../../../redux/CCAccountList/CCAccountListActions';



import { User } from '../../../models/User';

interface Props {
	active: boolean;
	user: User;
	options: Options;
	getOptions: Action$GetOptions;
	addAccount: ActionCreator$AddAccount;
}

type Values = Request$AddAccount;

const validationSchema = Yup.object().shape({
	sip_username: Yup.string().required(),
	sip_password: Yup.string().required().min(4),
	country_id: Yup.number(),
	description: Yup.string()
});

const AddCallCenterAccount = (props: Props): JSX.Element => {
	const { active, addAccount, options, getOptions } = props;

	React.useEffect(() => {
		if (!options)
			getOptions();
	}, []);

	const onSubmit = (values: Values, actions: FormikActions<Values>) => {
		formikSubmit(values, actions, addAccount)
			.then(() => {
				window.popup.pop();
			})
			.catch(() => {})
			.then(() => {
				actions.setSubmitting(false);
			});
	};

	const initialValues = {
		sip_username: '',
		sip_password: '',
		country_id: '',
		description: ''
	};

	return (
		<Popup active={active} title="Add new account" className="popup--add-cc-account">
			<FormikForm
				initialValues={initialValues}
				validationSchema={validationSchema}
				onSubmit={onSubmit}
				render={(formProps: FormikProps<Values>) => (
					<Form {...props} {...formProps} />
				)}
			/>
		</Popup>
	);
};

const Form = (props: Props & FormikProps<Values>) => {
	const { handleSubmit, options } = props;
	return (
		<form onSubmit={handleSubmit}>
			<FormGroup double>
				<FormikInputText name="sip_username" label="SIP username:" flat />
				<FormikInputText name="sip_password" type="password" label="SIP password:" flat />
			</FormGroup>
			<FormGroup double>
				<FormikInputTextarea
					label="Description:"
					name="description"
					flat
				/>
				<FormikInputSelect label="Country:" name="country_id" flat placeholder={"Worldwide"}>
					{options &&
					options.countries &&
					options.countries.map((item: CountryOption) => {
						const { id, country } = item;
						return (
							<option value={id} key={id}>
								{country}
							</option>
						);
					})}
				</FormikInputSelect>
			</FormGroup>
			<FormikButtonSubmit fullWidth {...props} />
		</form>
	);
};

const mapStateToProps = (store: Store) => {
	const { options } = store;
	return { options };
};

const mapDispatchToProps = (dispatch: any) => {
	return {
		getOptions: () => dispatch(getOptions()),
		addAccount: (data: Request$AddAccount) => dispatch(addNewAccountActionCreator(data))
	};
};


export default hot(module)(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(AddCallCenterAccount)
);

import * as React from 'react';
import './EditContact.scss';
import ContactForm from '../../../components/Forms/ContactForm';
import { RouteComponentProps } from 'react-router';
import { Dispatch, Action, Store } from '../../../models';
import {
	showContact,
	Action$ShowContact,
	editContact,
	Action$EditContact,
	deleteContact
} from '../../../redux/actions/Contact';
import {
	FormData$ShowContact,
	FormData$EditContact
} from '../../../redux/api/Contact';
import { connect } from 'react-redux';
import { AxiosError } from 'axios';
import { Email, Link, Boat, Phone, SecondLang, Contact, Location } from '../../../models/Contact';
import { User } from '../../../models/User';
import { Options } from '../../../models/Options';
import Statuses from './Statuses';
import Calls from './Calls';
import Emails from './Emails';
import Button from '../../../components/Button';
import routes from '../../routes';
import {popups, pushPopup} from "../../../utils/popups";
import Messages from "./Messages/Messages";
import History from "./History/History";

export type EditContactProps = {
	showContact: Action$ShowContact;
	editContact: Action$EditContact;
	user: User;
	options: Options;
} & RouteComponentProps<any>;

const EditContact = (props: EditContactProps): JSX.Element => {
	const [contact, setContact] = React.useState<Contact>();
	const [error, setError] = React.useState(false);
	const { id } = props.match.params;
	const { role_id } = props.user;

	const handleDelete = () => {
		if (!window.confirm('Are you sure you want to delete this contact?')) {
			return;
		}
		// handle delete
		deleteContact(id)
			.then(() => {
				alert('Contact deleted.');
				props.history.push(routes.contact.list);
			})
			.catch(() => {})
			.then(() => {});
	};

	const handleMerge = () => {
		pushPopup(popups.MergeContact, {
			targetContact: contact,
			onSubmit: submitMerge,
			canCommit: role_id < 2 || role_id == 6
		});
	}

	const submitMerge = () => {
		showContact({ id })
			.then((res: Action) => {
				if (!res.payload) {
					setError(true);
				}
				setContact(res.payload);
			})
			.catch((ex: AxiosError) => {
				setError(true);
			});
	};

	React.useEffect(() => {
		showContact({ id })
			.then((res: Action) => {
				if (!res.payload) {
					setError(true);
				}
				setContact(res.payload);
			})
			.catch((ex: AxiosError) => {
				setError(true);
			});
	}, []);
	if (error) {
		return <div>error</div>;
	}
	return (
		<div className="page page--edit-contact">
			<div>
				<ContactView
					{...props}
					contact={contact}
					setError={setError}
					setContact={setContact}
				/>
				{(role_id < 2 ||  role_id == 4 || role_id == 6) && <History {...props} contact={contact!} />}
			</div>
			<div>
				{(role_id != 5) && <Statuses {...props} contact={contact!} setContact={setContact} />}
				{(role_id < 4 || role_id == 6) && <Calls {...props} contact={contact!} />}
				{(role_id < 4 || role_id == 6) && <Messages {...props} contact={contact!} />}
				{(role_id < 4 || role_id == 6) && <Emails {...props} contact={contact!} />}
				<div className="edit--contact-buttons-container">
					{role_id === 1 && (
						<Button onClick={handleDelete} danger>
							Delete contact
						</Button>
					)}
					<Button onClick={handleMerge}>
						Merge from contact
					</Button>
				</div>
			</div>
		</div>
	);
};

const ContactView = (props: EditContactProps & any) => {
	const { setContact, contact } = props;
	const { id } = props.match.params;
	const initialValues = {
		id,
		contact_name: contact ? contact.contact_name : '',
		emails: contact ? contact.emails.map((email: Email) => {
			return {
				email: email.email,
				is_default: email.is_default
			};
		}) : [],
		phones: contact
			? contact.phones.map((phone: Phone) => {
					return {
						phone: phone.phone,
						country_id: phone.country_id,
						is_default: phone.is_default,
						is_mobile: phone.is_mobile,
						comment: phone.comment,
					};
			  })
			: [],

		links: contact ? contact.links.map((link: Link) => link.link) : [],
		second_langs: contact ? contact.second_langs.map((second_lang: SecondLang) => second_lang.second_lang) : [],
		boats: contact
			? contact.boats.map((boat: Boat) => {
					const { boat_num, boat_type_id } = boat;
					return { count: boat_num, type: boat_type_id };
			  })
			: [],
		locations: contact ? contact.locations.map((location: Location) => {
					const { is_default, name, place_id, latlng } = location;
					return { is_default, name, place_id, latlng };
				}) : [],
		country_id: contact ? contact.country_id : '',
		locale: contact ? contact.locale : '',
		region_id: contact ? contact.region_id || undefined : undefined,
		yachts: contact ? contact.yachts || 0 : 0,
		marina: contact ? contact.marina || '' : '',
		comments: contact ? contact.comments || '' : '',
		address: contact ? contact.address || '' : ''
	};

	const onSuccess = (res: any) => {
		if (res) {
			setContact(res);
		}
	};

	return (
		<ContactForm
			initialValues={initialValues}
			contact={contact}
			onSubmit={editContact}
			onSuccess={onSuccess}
		/>
	);
};

const mapStateToProps = (store: any) => {
	const { user, options } = store;
	return {
		user,
		options
	};
};

const mapDispatchToProps = (dispatch: Dispatch): object => {
	return {
		showContact: (id: FormData$ShowContact) => dispatch(showContact(id)),
		editContact: (id: FormData$EditContact) => dispatch(editContact(id))
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(EditContact);

// tslint:disable: max-classes-per-file
import * as React from 'react';
import { connect } from 'react-redux';
import { RouterProps } from 'react-router';
import { compose } from 'redux';
import { Dispatch, Store } from '../../../models';
import Login from '../../../router/pages/Login';
import { FullScreenLoader } from '../../Loader';
import { persistor } from '../../../redux/store';
import { User } from '../../../models/User';
import validateTokenActionCreator from '../../../redux/User/UserActions/ValidateToken';
import { Request$ValidateToken } from '../../../api/Auth';

type Props = {
	user: User;
	prive?: boolean;
	token?: string;
	ChildComponent: any;
} & RouterProps;

const withAuth = (ChildComponent: any): any => {
	return () => {
		return <ConnectedHOC ChildComponent={ChildComponent} />;
	};
};

const mapStateToProps = (store: Store): object => {
	return {
		user: store.user,
		token: store.token
	};
};

const WithAuthHOC = (props: Props) => {
	const { user, ChildComponent, token } = props;
	if (!token) return <Login {...props} />; // No token found in localstorage
	if (token && user) return <ChildComponent user={user} />; // Authenticated!
	if (token && !user) return <Authenticate />;
	return null;
};

const ConnectedHOC: any = compose(connect(mapStateToProps))(WithAuthHOC);

export default withAuth;

const authStateToProps = (store: Store) => {
	const { token, user } = store;
	return { token, user };
};

const authDispatchToProps = (dispatch: any) => {
	return {
		validateToken: (data: Request$ValidateToken) =>
			dispatch(validateTokenActionCreator(data))
	};
};

const Authenticate = connect(
	authStateToProps,
	authDispatchToProps
)((props: any) => {
	React.useEffect(() => {
		const { token, validateToken } = props;
		token &&
			validateToken &&
			validateToken({ token })
				.then(() => {})
				.catch(() => {
					persistor.purge();
				});
	}, []);
	return <FullScreenLoader />;
});

import { LOADED_PROFILE, UPDATED_PROFILE } from './constants';
import { Action } from '../../../models';
import api, {
	FormData$UpdateProfile,
	FormData$UpdatePreferred
} from '../../api/Profile';
import { UserEnum } from '../../User/enums';

export type Action$GetProfile = () => Promise<Action>;
export type Action$UpdateProfile = (
	data: FormData$UpdateProfile
) => Promise<Action>;
export type Action$UpdatePreferred = (
	data: FormData$UpdatePreferred
) => Promise<Action>;

export const getProfile = async (): Promise<Action> => {
	const res = await api.getProfile();
	return {
		type: LOADED_PROFILE,
		payload: res.data
	};
};

export const updateProfile = async (
	data: FormData$UpdateProfile
): Promise<Action> => {
	const res = await api.updateProfile(data);
	return {
		type: UserEnum.UPDATED_PROFILE,
		payload: res.data
	};
};

export const updatePreferred = async (
	data: FormData$UpdatePreferred
): Promise<Action> => {
	const res = await api.updatePreferred(data);
	return {
		type: UserEnum.UPDATED_PROFILE,
		payload: res
	};
};

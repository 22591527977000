// tslint:disable: max-line-length

import * as React from 'react';
import layout from '../../../../utils/layout';
import SvgTemplate from '../SvgTemplate';
import { Svg } from '../../Svg';

const icon = ({ color, ...rest }: Svg): JSX.Element => (
	<SvgTemplate {...rest} viewBox="0 0 512 511">
		<g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<g id="mail" fill={color} fillRule="nonzero">
				<path
					d="M304.765625,0.425781 C296.195312,3.015625 288.355469,7.683594 281.867188,14.171875 L49.480469,246.554688 C42.863281,253.171875 38.289062,261.070312 35.746094,269.453125 L259.925781,224.617188 L304.765625,0.425781 Z"
					id="Shape"
				/>
				<path
					d="M495.867188,150.160156 L359.878906,14.167969 C353.003906,7.296875 344.609375,2.480469 335.441406,0 L287.382812,240.304688 C286.195312,246.242188 281.554688,250.886719 275.617188,252.074219 L35.308594,300.136719 C37.746094,309.089844 42.460938,317.550781 49.480469,324.570312 L61.425781,336.515625 L4.390625,393.550781 C-1.464844,399.410156 -1.464844,408.90625 4.394531,414.765625 C7.324219,417.691406 11.160156,419.15625 15,419.15625 C18.839844,419.15625 22.675781,417.691406 25.605469,414.765625 L82.640625,357.730469 L106.867188,381.957031 L4.390625,484.433594 C-1.464844,490.289062 -1.464844,499.789062 4.394531,505.644531 C7.324219,508.574219 11.160156,510.039062 15,510.039062 C18.839844,510.039062 22.675781,508.574219 25.605469,505.644531 L128.082031,403.167969 L152.308594,427.398438 L95.277344,484.433594 C89.417969,490.289062 89.417969,499.789062 95.277344,505.644531 C98.203125,508.574219 102.042969,510.039062 105.882812,510.039062 C109.722656,510.039062 113.558594,508.574219 116.488281,505.644531 L173.523438,448.613281 L185.46875,460.558594 C195.859375,470.945312 209.710938,476.667969 224.476562,476.667969 C239.242188,476.667969 253.097656,470.945312 263.484375,460.558594 L495.867188,228.175781 C517.378906,206.664062 517.378906,171.667969 495.867188,150.160156 Z"
					id="Shape"
				/>
			</g>
		</g>
	</SvgTemplate>
);

icon.defaultProps = {
	width: layout.spacing.three,
	color: layout.color.primary
};

export default icon;

import { LOADED_PENDING_MERGES } from './constants';
import { Action } from '../../../models';
import api from '../../api/PendingMerges';

export type FormData$GetPendingMerges = {
	user_id?: string | number | null;
	page?: number | null;
	limit?: number | null;
};

export type Action$GetPendingMerges = (
	data?: FormData$GetPendingMerges
) => Promise<Action>;

export const getPendingMerges = async (
	data: FormData$GetPendingMerges = {}
): Promise<Action> => {
	const { user_id, page, limit } = data;
	let params = '?';
	if (user_id) {
		params += `&user_id=${user_id}`;
	}

	if (page) {
		params += `&page=${page}`;
	}

	if (limit) {
		params += `&limit=${limit}`;
	}

	const res = await api.getPendingMerges(params);
	return {
		type: LOADED_PENDING_MERGES,
		payload: res.data
	};
};

import { LOADED_OPTIONS } from './constants';
import { Action } from '../../../models';
import api from '../../api/Options';

declare const window: any;

export type Action$GetOptions = () => Promise<Action>;

export const getOptions = async (): Promise<Action> => {
	const res = await api.getOptions();
	return {
		type: LOADED_OPTIONS,
		payload: res.data
	};
};

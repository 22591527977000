const spacingBase = 8;

export const spacing = {
	half: spacingBase / 2,
	base: spacingBase,
	ss: spacingBase * 1.5,
	two: spacingBase * 2,
	three: spacingBase * 3,
	four: spacingBase * 4,
	five: spacingBase * 5,
	six: spacingBase * 6,
	seven: spacingBase * 7,
	eight: spacingBase * 8,
	nine: spacingBase * 9,
	ten: spacingBase * 10
};

export const color = {
	primary: '#423d6c',
	primaryLight: '#8f8ca7',
	primaryDark: '#201a50',
	danger: '#ee2319',
	warning: '#ffc107',
	success: '#00c853',
	white: '#ffffff',
	light: '#f2f2f2',
	dark: '#3a3a3a',
	medium: '#f1f1f4',
	facebook: '#375C8E',
	google: '#DF8071',
	link: '#6d85f5',
	textGrey: '#808080',
	yellow: '#f6ed49',
	grey: '#a7a7a7'
};

export default {
	spacing,
	color
};

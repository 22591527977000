import * as React from 'react';
import { Options, CountryOption, Region } from '../../../../models/Options';
import { connect } from 'react-redux';
import Label from '../../../../components/Form/Label';
import InputSelect from '../../../../components/Form/InputSelect';
import Dialog from '../../../../components/Dialog';
import User from '../../../../redux/entities/User';
import { Request$UpdatePreferred } from '../../../../api/User';
import {
	updatePreferred as updatePreferredAction,
	Action$UpdatePreferred
} from '../../../../redux/actions/Profile';
import { FormGroup } from '../../../../components/Form';

type PreferredProps = {
	options?: Options;
	user?: User;
	updatePreferred: Action$UpdatePreferred;
};

const Preferred = (props: PreferredProps) => {
	const { options, updatePreferred, user } = props;
	const onCountryChange = (e: any) => {
		updatePreferred({ default_country_id: e.target.value });
	};
	const onRegionChange = (e: any) => {
		updatePreferred({ default_region_id: e.target.value });
	};
	const onCountryCodeChange = (e: any) => {
		updatePreferred({ default_country_phone_id: e.target.value });
	};
	const onMainLanguageChange = (e: any) => {
		console.log(e.target)
		updatePreferred({ default_main_language: e.target.value });
	};

	return (
		<Dialog title="Add Contact Values">
			<FormGroup>
				<Label title="Default Country:" />
				<InputSelect
					name="default_country_id"
					flat
					fullWidth
					number
					onChange={onCountryChange}
					value={user!.default_country_id}
				>
					<option value={null as any}>-</option>
					{options &&
						options.countries &&
						options.countries.map((country: CountryOption) => {
							return (
								<option key={country.id} value={country.id}>
									{country.country}
								</option>
							);
						})}
				</InputSelect>
			</FormGroup>
			<FormGroup>
				<Label title="Default Region:" />
				<InputSelect
					name="default_region_id"
					flat
					fullWidth
					number
					onChange={onRegionChange}
					value={user!.default_region_id}
				>
				<option value={null as any}>-</option>
					{options &&
						options.regions &&
						options.regions.map((region: Region) => {
							return (
								<option key={region.id} value={region.id}>
									{region.region}
								</option>
							);
						})}
				</InputSelect>
			</FormGroup>
			<FormGroup>
				<Label title="Default Country Code:" />
				<InputSelect
					name="default_country_phone_id"
					flat
					fullWidth
					number
					onChange={onCountryCodeChange}
					value={user!.default_country_phone_id}
				>
					<option value={null as any}>-</option>
					{options &&
						options.countries &&
						options.countries.map((country: CountryOption) => {
							return (
								<option key={country.id} value={country.id}>
									{country.country_iso} +{country.country_phone_code}
								</option>
							);
						})}
				</InputSelect>
			</FormGroup>
			<FormGroup style={{ marginBottom: 14 }}>
				<Label title="Default Main Language:" />
				<InputSelect
					name="default_main_language"
					flat
					fullWidth
					number
					onChange={onMainLanguageChange}
					value={user!.default_main_language}
					isStringValue
				>
					{options &&
						options.languages &&
						options.languages.map((languages: any) => {
							return (
								<option key={languages.id} value={languages.country_iso}>
									{languages.language}
									</option>
							);
						})}
				</InputSelect>
			</FormGroup>
		</Dialog>
	);
};

function mapStateToProps(store: any) {
	const { options, user } = store;
	return {
		options,
		user
	};
}

function mapDispatchToProps(dispatch: any) {
	return {
		updatePreferred: (data: Request$UpdatePreferred) =>
			dispatch(updatePreferredAction(data))
	};
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Preferred);

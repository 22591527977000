// tslint:disable: max-line-length

import * as React from 'react';
import layout from '../../../../utils/layout';
import SvgTemplate from '../SvgTemplate';
import { Svg } from '../../Svg';

const icon = ({ color, ...rest }: Svg): JSX.Element => (
	<SvgTemplate {...rest} viewBox="0 0 367.9 355.1">
		<path
			d="M0,158.4v171.5c0,10.8,8.8,19.5,19.5,19.5h58.3c6.7,0,12.5-3.3,16.1-8.4c9.3,8.7,21.7,14.1,35.4,14.1h156.2
                       c35,0,57.3-18.4,61.3-50.4l21-133.6c0.1-0.5,0.1-1,0.1-1.5c0-28.5-23.2-51.7-51.7-51.7h-78.1V61.8c0-24.3-7.1-41.8-21.2-52.2
                       c-22.3-16.3-52.8-7-54.1-6.6c-4.1,1.3-6.9,5.1-6.9,9.4v63.8c0,49.7-57.8,66.7-60.2,67.4c-1.2,0.3-2.4,0.9-3.3,1.7
                       c-3.6-4-8.8-6.5-14.5-6.5H19.5C8.7,138.8,0,147.6,0,158.4z M97.4,163c1.2,0.1,2.4,0,3.6-0.3c3.1-0.9,74.7-21.6,74.7-86.4V20.4
                       c7.7-1.2,20.4-1.6,29.5,5.2c8.7,6.4,13.1,18.6,13.1,36.1v66c0,5.5,4.4,9.9,9.9,9.9h88c17.4,0,31.5,13.9,31.9,31.2l-20.9,132.9
                       c0,0.1,0,0.2,0,0.3c-2.7,22-16.7,33.1-41.7,33.1H129.3c-17.6,0-31.9-14.3-31.9-31.9L97.4,163L97.4,163z M19.8,158.6h57.8v171H19.8
                       V158.6z"
			fill={color}
		/>
	</SvgTemplate>
);

icon.defaultProps = {
	width: layout.spacing.three,
	color: layout.color.primary
};

export default icon;

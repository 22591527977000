import * as React from 'react';
import './PhoneInput.scss';
import {
	FormikInputSelect,
	FormikInputText
} from '../../../../components/Form';
import { CountryOption } from '../../../../models/Options';
import {ButtonIcon} from "../../../Button";
import {FieldArrayRenderProps, FormikProps} from "formik";
import {Phone} from "../ContactForm";
import layout from "../../../../utils/layout";
import Svg from "../../../Svg";

const PhoneInput = (props: FieldArrayRenderProps & FormikProps<any> & any): JSX.Element => {
	const { options, setFieldValue, values, value, setFieldTouched } = props;
	const setDefaultPhone = () => {
		let newPhones = [...values.phones];
		newPhones.forEach((phone: Phone) => {
			if(phone == value){
				phone.is_default = true;
			} else {
				phone.is_default = false;
			}
		});
		newPhones.sort(function (a, b) {
			return a.is_default > b.is_default ? -1 : 0;
		});
		setFieldValue('phones', newPhones);
		props.setStatus(
			{ dirty: true }
		);
	};
	return (
	<div className={`phone-input ${props.value.is_default ? 'default' : ''}`}>
		<div className={`phone-input`}>
			{options && (
				<div style={{display: 'flex', flexDirection: 'column', width: '100%'}}>
					<div style={{display: 'flex', flexDirection: 'row'}}>
						{props.value.is_mobile && (
							<span  style={{marginTop: 10, marginRight: 11, marginLeft: -1}}>
								<Svg icon={'mobile'} height={18}/>
							</span>
						)}
						{!props.value.is_mobile && (
							<span  style={{marginTop: 10, marginRight: 10}}>
								<Svg icon={'phone'} height={18}/>
							</span>
						)}
						<FormikInputSelect
							name={`${props.name}.country_id`}
							flat
							number
							placeholder="code"
						>
							{options.countries &&
							options.countries.map((country: CountryOption, i: number) => {
								return (
									<option key={`code-${country.id}`} value={country.id}>
										{country.country_iso} +{country.country_phone_code}
									</option>
								);
							})}
						</FormikInputSelect>
						<FormikInputText style={{marginLeft: 5}} noSpaces name={`${props.name}.phone`} flat />
					</div>
					<div style={{display: 'flex', flexDirection: 'row', marginTop: 10}}>
						{!props.value.is_default && (
							<ButtonIcon
								className={'make-default-icon'}
								style={{marginTop: 12, marginRight: 6, marginLeft: -5}}
								onClick={setDefaultPhone}
								icon="favorite-stroke"
							/>
						)}
						{props.value.is_default && (
							<div className={"favorite-static"}>
								<Svg icon="favorite" />
							</div>
						)}
						<FormikInputText name={`${props.name}.comment`} placeholder="comment" flat />
					</div>
				</div>
			)}
		</div>
	</div>
	);
};

export default PhoneInput;

// tslint:disable: max-line-length

import * as React from 'react';
import layout from '../../../../utils/layout';
import SvgTemplate from '../SvgTemplate';
import { Svg } from '../../Svg';

const icon = ({ color, ...rest }: Svg): JSX.Element => (
	<SvgTemplate {...rest} viewBox="0 0 487 446">
		<g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<g id="padlock" fill={color} fillRule="nonzero">
				<path
					d="M420.725,65.413 C378.625,23.313 322.625,0.113 263.125,0.113 C202.525,0.113 145.825,24.013 103.525,67.413 C98.925,72.113 99.025,79.713 103.725,84.413 C108.425,89.013 116.025,88.913 120.725,84.213 C158.525,45.513 209.025,24.213 263.125,24.213 C372.825,24.113 462.025,113.313 462.025,223.013 C462.025,332.713 372.825,421.913 263.125,421.913 C153.425,421.913 64.225,332.713 64.225,223.013 L64.225,220.513 L84.025,240.313 C86.325,242.613 89.425,243.813 92.525,243.813 C95.625,243.813 98.625,242.613 101.025,240.313 C105.725,235.613 105.725,228.013 101.025,223.313 L60.825,183.013 C56.125,178.313 48.525,178.313 43.825,183.013 L3.525,223.313 C-1.175,228.013 -1.175,235.613 3.525,240.313 C5.825,242.613 8.925,243.813 12.025,243.813 C15.125,243.813 18.125,242.613 20.525,240.313 L40.325,220.513 L40.325,223.013 C40.325,282.513 63.525,338.513 105.625,380.613 C147.725,422.713 203.725,445.913 263.225,445.913 C322.725,445.913 378.725,422.713 420.825,380.613 C462.925,338.513 486.025,282.513 486.025,223.013 C486.025,163.513 462.825,107.513 420.725,65.413 Z"
					id="Shape"
				/>
				<path
					d="M263.125,93.413 C224.125,93.413 192.425,125.113 192.425,164.113 L192.425,198.213 L170.425,198.213 C163.825,198.213 158.425,203.613 158.425,210.213 L158.425,329.913 C158.425,336.513 163.825,341.913 170.425,341.913 L355.925,341.913 C362.525,341.913 367.925,336.513 367.925,329.913 L367.925,210.313 C367.925,203.713 362.525,198.313 355.925,198.313 L333.925,198.313 L333.925,164.213 C333.925,125.213 302.125,93.413 263.125,93.413 Z M216.425,164.213 C216.425,138.413 237.425,117.513 263.125,117.513 C288.825,117.513 309.825,138.513 309.825,164.213 L309.825,198.313 L216.425,198.313 L216.425,164.213 Z M343.925,317.913 L182.425,317.913 L182.425,222.213 L343.925,222.213 L343.925,317.913 Z"
					id="Shape"
				/>
			</g>
		</g>
	</SvgTemplate>
);

icon.defaultProps = {
	width: layout.spacing.three,
	color: layout.color.primary
};

export default icon;

import { UserEnum } from '../enums';
import { Action } from '../../models';
import {
	Request$ChangeRole,
	changeRole,
	Response$ChangeRole
} from '../../../api/User';

export type Action$ChangeRole = Action<UserEnum.CHANGED_ROLE, any>;

export type ActionCreator$ChangeRole = (
	data: Request$ChangeRole
) => Promise<Action$ChangeRole>;

const changeRoleActionCreator: ActionCreator$ChangeRole = async (
	data: Request$ChangeRole
): Promise<Action$ChangeRole> => {
	const response: Response$ChangeRole = await changeRole(data);
	return {
		type: UserEnum.CHANGED_ROLE,
		payload: response
	};
};

export default changeRoleActionCreator;

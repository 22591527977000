import * as React from 'react';
import './FormGroup.scss';

interface Props {
	children: any;
	double?: boolean;
	className?: string;
	style?: object;
}

export const FormGroup = (props: Props) => {
	const { children, double, className, style } = props;
	let clname = 'form-group';

	if (double) {
		clname += ' form-group--double';
	}

	if (className) {
		clname += ` ${className}`;
	}

	return (
		<div className={clname} style={style}>
			{children}
		</div>
	);
};

export default FormGroup;

import * as React from 'react';
import './SendMessage.scss';
import Popup from '../Popup';
import { connect } from 'react-redux';
import { hot } from 'react-hot-loader';
import {
	FormikForm,
	FormikInputTextarea,
	FormikButtonSubmit,
	FormikInputSelect, FormikInputText
} from '../../Form';
import { FormikProps, FormikActions } from 'formik';
import { Contact } from '../../../models/Contact';
import Yup from '../../../utils/yup';
import {Language, Options} from '../../../models/Options';
import { RouteComponentProps } from 'react-router';
import {
	action$SendSMSMessage,
	Action$SendSMSMessage
} from '../../../redux/actions/Message';
import { Request$SendSMSMessage } from '../../../redux/api/Message/models';
import {User} from "../../../models/User";

type Props = {
	active: boolean;
	options: Options;
	user: User;
	contact: Contact;
	onDismiss?: () => any;
	sendSMSMessage: Action$SendSMSMessage;
} & RouteComponentProps<{ id: string }>;

type Values = {
	phone: any;
	phone_other: string;
	language_id: number;
	content: string;
};

let otherNumber: boolean = false;

const SendMessage = (props: Props): JSX.Element => {
	const { active, contact, onDismiss, sendSMSMessage, options } = props;
	// Adds country prefix to number
	contact.phones.forEach(function(phone :any ){
		phone.country_code = options!.countries.filter(c => c.id == phone.country_id)![0]!.country_phone_code;
	});
	const initialValues = {
		language_id: '',
		phone: contact.phones.length > 0 ? (contact.phones[0].country_code + contact.phones[0].phone) : ''
	};
	const validationSchema = Yup.object().shape({
		phone: Yup.string().required(),
		language_id: Yup.string().required(),
		content: Yup.string().required()
	});
	const onSubmit = (values: Values, actions: FormikActions<Values>) => {
		let number_to_send;
		if(otherNumber) {
			number_to_send = values.phone_other;
		} else {
			number_to_send = values.phone;
		}
		if(window.confirm(`Are you sure that you want to send this SMS to ${number_to_send}?`)){
			sendSMSMessage({
				contact_id: contact.id,
				content: values.content,
				language_id: values.language_id,
				phone: number_to_send
			}).then(() => {
				otherNumber = false;
				window.popup.pop();
				onDismiss && onDismiss();
			})
				.catch(() => {})
				.then(() => {
					actions.setSubmitting(false);
				});
		} else {
			actions.setSubmitting(false);
		}
	};
	otherNumber = false;
	return (
		<Popup
			active={active}
			title="Send new SMS"
			className="popup--send_email"
			width={400}
		>
			<FormikForm
				render={(formProps: FormikProps<Values>) => (
					<Form {...formProps} {...props} />
				)}
				initialValues={initialValues}
				validationSchema={validationSchema}
				onSubmit={onSubmit}
			/>
		</Popup>
	);
};

type FormProps = {} & Props & FormikProps<Values>;

const Form = (props: FormProps) => {
	const { handleSubmit, contact, options, user } = props;
	const handlePhoneChange = (e: any) => {
		if(e.target.value == 'other'){
			otherNumber = true;
		} else {
			otherNumber = false;
		}
	};
	return (
		<form onSubmit={handleSubmit}>
			<FormikInputSelect flat fullWidth name="phone" label="Phone number:" onChange={handlePhoneChange}>
				{contact && contact.phones &&
				contact.phones.map((phone: any) => {
					return (
						<option key={phone.id} value={+ phone.country_code + phone.phone}>
							{phone.phone}
						</option>
					);
				})}
				{props.user.role_id == 1 && (
					<option value="other">Other</option>
				)}

			</FormikInputSelect>
			{otherNumber && (
				<FormikInputText
				name="phone_other"
				placeholder="Enter Phone Number"
				flat
				noSpaces
				style={{marginBottom: 10}}
				/>
			)}
			<FormikInputSelect
				name="language_id"
				label="Language:"
				number
				flat
				fullWidth
			>
				{options &&
				options.languages &&
				options.languages.map((language: Language) => {
					return (
						<option key={language.id} value={language.id}>
							{language.language}
						</option>
					);
				})}
			</FormikInputSelect>
			<FormikInputTextarea name="content" label="Message:" flat resize />
			<FormikButtonSubmit {...props} title="Submit" fullWidth />
		</form>
	);
};

const mapStateToProps = (store: any) => {
	const { options } = store;
	return { options };
};

const mapDispatchToProps = (dispatch: any) => {
	return {
		sendSMSMessage: (data: Request$SendSMSMessage) => dispatch(action$SendSMSMessage(data))
	};
};

export default hot(module)(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(SendMessage)
);

import {post} from "../../redux/api/utils";

export type Request$RejectMergeContacts = {
	from_user_id: number;
	to_user_id: number;
};

export type Response$RejectMergeContacts = {
	message: 'ok';
};

function rejectMergeContacts(
	data: Request$RejectMergeContacts
): Promise<Response$RejectMergeContacts> {
	return post('/contact/reject-merge-contacts', data);
}

export { rejectMergeContacts };

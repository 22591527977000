// tslint:disable: max-line-length
import * as React from 'react';
import layout from '../../../../utils/layout';
import SvgTemplate from '../SvgTemplate';
import { Svg } from '../../Svg';

const icon = ({ color, ...rest }: Svg): JSX.Element => (
	<SvgTemplate {...rest} viewBox="0 0 98.26 92.261">
		<>
			<g id="Layer_1" display="none" opacity="0.3">
				<g display="inline" opacity="0.5">
					<line
						fill="none"
						stroke="#ED1C24"
						strokeWidth="0.25"
						strokeMiterlimit="10"
						x1="12.089"
						y1="0.088"
						x2="12.089"
						y2="96.088"
					/>
					<line
						fill="none"
						stroke="#ED1C24"
						strokeWidth="0.25"
						strokeMiterlimit="10"
						x1="84.089"
						y1="0.088"
						x2="84.089"
						y2="96.088"
					/>
					<line
						fill="none"
						stroke="#ED1C24"
						strokeWidth="0.25"
						strokeMiterlimit="10"
						x1="96.131"
						y1="12.088"
						x2="0.131"
						y2="12.088"
					/>
					<line
						fill="none"
						stroke="#ED1C24"
						strokeWidth="0.25"
						strokeMiterlimit="10"
						x1="96.089"
						y1="84.088"
						x2="0.089"
						y2="84.088"
					/>
				</g>
				<g display="inline" opacity="0.5">
					<line
						fill="none"
						stroke="#00AEEF"
						strokeWidth="0.25"
						strokeMiterlimit="10"
						x1="24.089"
						y1="0.088"
						x2="24.089"
						y2="96.088"
					/>
					<line
						fill="none"
						stroke="#00AEEF"
						strokeWidth="0.25"
						strokeMiterlimit="10"
						x1="48.089"
						y1="0.088"
						x2="48.089"
						y2="96.088"
					/>
					<line
						fill="none"
						stroke="#00AEEF"
						strokeWidth="0.25"
						strokeMiterlimit="10"
						x1="72.089"
						y1="0.088"
						x2="72.089"
						y2="96.088"
					/>
					<line
						fill="none"
						stroke="#00AEEF"
						strokeWidth="0.25"
						strokeMiterlimit="10"
						x1="96.089"
						y1="24.088"
						x2="0.089"
						y2="24.088"
					/>
					<line
						fill="none"
						stroke="#00AEEF"
						strokeWidth="0.25"
						strokeMiterlimit="10"
						x1="96.089"
						y1="48.088"
						x2="0.089"
						y2="48.088"
					/>
					<line
						fill="none"
						stroke="#00AEEF"
						strokeWidth="0.25"
						strokeMiterlimit="10"
						x1="96.089"
						y1="72.088"
						x2="0.089"
						y2="72.088"
					/>
					<line
						fill="none"
						stroke="#00AEEF"
						strokeWidth="0.25"
						strokeMiterlimit="10"
						x1="0.089"
						y1="0.088"
						x2="96.172"
						y2="96.173"
					/>
					<line
						fill="none"
						stroke="#00AEEF"
						strokeWidth="0.25"
						strokeMiterlimit="10"
						x1="0.089"
						y1="96.088"
						x2="95.839"
						y2="0.338"
					/>
				</g>
			</g>
			<g id="Layer_2">
				<path
					className="fill"
					fill="#fff"
					stroke="#8B8AA0"
					strokeWidth="2"
					strokeLinejoin="round"
					strokeMiterlimit="10"
					d="M64.964,73.242
      		c0,5.992-4.858,10.85-10.849,10.85H41.813c-5.992,0-10.849-4.857-10.849-10.85V22.938c0-5.991,4.857-10.849,10.849-10.849h12.302
      		c5.992,0,10.849,4.858,10.849,10.849V73.242z"
				/>
				<circle fill="#8B8AA0" cx="37.559" cy="48.005" r="1.879" />
				<path
					className="fill"
					fill="#fff"
					stroke="#8B8AA0"
					strokeWidth="2"
					strokeLinejoin="round"
					strokeMiterlimit="10"
					d="M55.928,74.368
      		c0-1.426-1.157-2.583-2.583-2.583H42.584c-1.427,0-2.584,1.156-2.584,2.583l0,0c0,1.427,1.157,2.584,2.584,2.584h10.761
      		C54.771,76.952,55.928,75.795,55.928,74.368L55.928,74.368z"
				/>
			</g>
		</>
	</SvgTemplate>
);

icon.defaultProps = {
	width: layout.spacing.four,
	color: layout.color.primary
};

export default icon;

import { FAQ, Scenario } from '../../models/Helpdesk';
import {get} from "../../redux/api/utils";

export type Response$faqList = { data: FAQ[] };

export type Response$scenarioList = { data: Scenario[] };

/**
 * API GET Request to fetch faq.
 */
export async function listFAQ(): Promise<Response$faqList> {
    return get('/helpdesk/faq');
}

/**
 * API GET Request to fetch scenarios.
 */
export async function listScenarios(): Promise<Response$scenarioList> {
    return get('/helpdesk/scenarios');
}
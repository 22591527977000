import * as React from 'react';
import './RichEditor.scss';
import { Editor } from 'react-draft-wysiwyg';
import {convertToRaw, convertFromRaw, EditorState, convertFromHTML, ContentState} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import Label from '../../../../components/Form/Label';

type Props = {
	onChange: (markup: string) => any;
	title?: string;
	defaultValue?: string;
};

const RichEditor = (props: Props) => {
	const { onChange, title, defaultValue } = props;

	const blocksFromHTML =  defaultValue ? convertFromHTML(defaultValue) : undefined;

	const defaultContent = blocksFromHTML ? ContentState.createFromBlockArray(
		blocksFromHTML.contentBlocks,
		blocksFromHTML.entityMap
	) : undefined;

	const [editorState, setEditorState] = React.useState(
		defaultValue && defaultContent ? EditorState.createWithContent(defaultContent) : EditorState.createEmpty()
	);

	const handleChange = (e: EditorState) => {
		setEditorState(e);
	};

	React.useEffect(() => {
		const raw = convertToRaw(editorState.getCurrentContent());
		const resultString = createMarkup(raw);
		onChange && onChange(resultString);
	}, [editorState]);

	return (
		<div className="page--custom-email__editor">
		<Label title={title ? title : "Write the content:"} />
			<Editor
				editorState={editorState}
				toolbarClassName="rich-editor__toolbar"
				wrapperClassName="rich-editor__wrapper"
				editorClassName="rich-editor__editor"
				onEditorStateChange={handleChange}
			/>
		</div>
	);
};

export default RichEditor;

const createMarkup = (rawContentState: any) => {
	return draftToHtml(rawContentState);
};

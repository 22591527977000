import * as React from 'react';
import './ViewHelpdesk.scss';
import Popup from '../Popup';
import {Component} from "react";
import Button, {ButtonIcon} from '../../../components/Button';
import CoolTabs from 'react-cool-tabs';
import {listFAQActionCreator, listScenariosActionCreator} from "../../../redux/Helpdesk/HelpdeskActions";
import {getOptions} from "../../../redux/actions";
import {connect} from "react-redux";
import {Helpdesk} from "../../../router/pages/Helpdesk/Helpdesk";
import {Language, Options} from "../../../models/Options";
import {User} from "../../../models/User";
import { FAQ, Scenario } from "../../../models/Helpdesk";
import { hot } from "react-hot-loader";

interface Props {
	active: boolean;
	onDismiss?: () => any;
	getScenarios: () => any;
	getFAQ: () => any;
	getOptions: () => any;
	options: Options;
	user: User;
	faqList: FAQ[];
	scenarioList: Scenario[];
}

const ViewHelpdesk = (props: Props): JSX.Element => {
	const { getScenarios, getFAQ, faqList, scenarioList, options, getOptions, user, active, onDismiss } = props;

	React.useEffect(() => {
		getOptions();
		getScenarios();
		getFAQ();
	}, []);


	const handleOk = () => {
		onDismiss && onDismiss();
		window && window.popup.pop();
	};

	let language_id = user.default_main_language && options && options!.languages ? options.languages.filter(l => l.country_iso == user.default_main_language)[0].id : 1;
	let scenario_id = scenarioList.filter(sc => sc.language_id == language_id)[0]
	? scenarioList.filter(sc => sc.language_id == language_id)[0].id : undefined;
	let faq_id = -1;

	class Scenarios extends Component {
		languageChanged = (event: any) => {
			language_id = event.target.value;
			scenario_id = scenarioList.filter(sc => sc.language_id == language_id)[0]
				? scenarioList.filter(sc => sc.language_id == language_id)[0].id : undefined;
			this.setState({language_id, scenario_id});
		};

		scenarioChanged = (event: any) => {
			scenario_id = event.target.value;
			this.setState({scenario_id});
		};

		render() {
			return (
				<div className="scenario-tab-container">
					<div className="top-menu">
						<div className="input-select flat scenario--select">
							<label className="label">
								<div className="label__title">Choose scenario:</div>
							</label>
							<select
								className="box-shadow--input"
								name="scenario_id"
								value={scenarioList.filter(sc => sc.language_id == language_id).length == 0 ? '' : scenario_id}
								onChange={this.scenarioChanged}
								style={{width: '100%'}}
							>
								{options &&
								scenarioList &&
								scenarioList.filter(sc => sc.language_id == language_id).map((item: Scenario) => {
									const { id, title } = item;
									return (
										<option value={id} key={id}>
											{title}
										</option>
									);
								})}
								{scenarioList &&
								scenarioList.filter(sc => sc.language_id == language_id).length == 0 && (
									<option value="" disabled>No scenarios found</option>
								)}
							</select>
						</div>
						<div className="input-select flat language--select">
							<label className="label">
								<div className="label__title">Language:</div>
							</label>
							<select className="box-shadow--input" name="language_id" value={language_id} onChange={this.languageChanged}>
								{options &&
								options.languages &&
								options.languages.map((item: Language) => {
									const { id, language } = item;
									return (
										<option value={id} key={id}>
											{language}
										</option>
									);
								})}
							</select>
						</div>
					</div>
					<div className="scenario-show">
						{scenarioList.map(
							(scenario: Scenario, i: number): JSX.Element => {
								return (
									<div className="scenario" key={`scenario-${i}`}
										 style={{
										 	display: scenario.id != scenario_id ? 'none' : 'block'
										 }}
									>
										<div
											dangerouslySetInnerHTML={{ __html: scenario.content }}
										/>
									</div>
								);
							}
						)}
					</div>
				</div>
			)
		}
	}

	class FAQs extends Component {
		languageChanged = (event: any) => {
			language_id = event.target.value;
			faq_id = -1;
			this.setState({language_id, faq_id});
		};

		faqChanged = (event: any) => {
			faq_id = event.target.value;
			this.setState({faq_id});
		};
		render() {
			return (
				<div className="faq-tab-container">
					<div className="top-menu">
						<div className="input-select flat gaq--select">
							<label className="label">
								<div className="label__title label__title__big">Question:</div>
							</label>
							<select
								className="box-shadow--input"
								name="faq_id"
								value={faqList.filter(f => f.language_id == language_id).length == 0 ? '' : faq_id}
								onChange={this.faqChanged}
								style={{width: '100%'}}
							>
								{faqList.filter(sc => sc.language_id == language_id).length > 0 && (
									<option value="-1">Show all</option>
								)}
								{options &&
								faqList &&
								faqList.filter(sc => sc.language_id == language_id).map((item: FAQ) => {
									const { id, question } = item;
									return (
										<option value={id} key={id}>
											{question}
										</option>
									);
								})}
								{faqList &&
								faqList.filter(f => f.language_id == language_id).length == 0 && (
									<option value="" disabled>No FAQs found</option>
								)}
							</select>
						</div>
						<div className="input-select flat language--select">
							<label className="label">
								<div className="label__title">Language:</div>
							</label>
							<select className="box-shadow--input" name="language_id" value={language_id} onChange={this.languageChanged}>
								{options &&
								options.languages &&
								options.languages.map((item: Language) => {
									const { id, language } = item;
									return (
										<option value={id} key={id}>
											{language}
										</option>
									);
								})}
							</select>
						</div>
					</div>
					<div className="faq-show">
						{faqList.map(
							(faq: FAQ, i: number): JSX.Element => {
								return (
									<div className="faq" key={`faq-${i}`}
										 style={{
											 display: faq.id != faq_id ? 'none' : 'block'
										 }}
									>
										<div className="faq-single">
											<label className="label" style={{marginLeft: -10}}>
												<div className="label__title label__title__big">Answer:</div>
											</label>
											<div style={{marginTop: 15, 'lineHeight': '27px'}}>{faq.answer}</div>
										</div>
									</div>
								);
							}
						)}
						{faq_id == -1 && (
							<div className="faq__show-all">
								{options && faqList && faqList.filter(sc => sc.language_id == language_id).map((item: FAQ) => {
									const { question, answer, id } = item;
									return (
										<div key={id} className="faq-single">
											<div className="faq-question">Q: {question} </div>
											<div className="faq-answer">A: {answer} </div>
										</div>
									);
								})}
							</div>
						)}
					</div>
				</div>
			)
		}
	}

	return (
		<Popup active={active} title={"Helpdesk"} className="popup--view-helpdesk">
			<CoolTabs
				tabKey={'1'}
				style={{ width:  '55vw', height:  '60vh', background:  'white' }}
				activeTabStyle={{ background:  '#423d6c', color:  'white' }}
				unActiveTabStyle={{ background:  '#f2f2f2', color:  '#423d6c', border : '1px solid #423d6c' }}
				leftContentStyle={{ background:  'white' }}
				rightContentStyle={{ background:  'white' }}
				leftTabTitle={'Scenarios'}
				rightTabTitle={'FAQs'}
				leftContent={<Scenarios/>}
				rightContent={<FAQs/>}
				contentTransitionStyle={'transform 0.5s ease-in'}
				borderTransitionStyle={'all 0.5s ease-in'}/>
		</Popup>
	);
};

const mapStateToProps = (store: any) => {
	const { faqList, scenarioList, options, user } = store;
	return { faqList, scenarioList, options, user };
};

const mapDispatchToProps = (dispatch: any) => {
	return {
		getScenarios: () => dispatch(listScenariosActionCreator()),
		getFAQ: () => dispatch(listFAQActionCreator()),
		getOptions: () => dispatch(getOptions())
	};
};

export default hot(module)(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(ViewHelpdesk)
);


import * as React from 'react';
import './ToBeChecked.scss';
import { RouterProps } from 'react-router';
import ReactTable from 'react-table';
import Dialog from '../../../components/Dialog';
import contactApi from '../../../redux/api/Contact';
import { cpus } from 'os';
import layout from '../../../utils/layout';
import { Link } from 'react-router-dom';
import routes from '../../routes';
import Svg from '../../../components/Svg';

const columns: any = [
	{
		Header: 'ID',
		accessor: 'id',
		width: 60,
		minResizeWidth: 60,
		Cell: (props: any) => <span className="row__id">{props.original.id}</span>
	},
	{
		Header: 'Name',
		accessor: 'contact_name',
		minResizeWidth: 60
	},
	{
		Header: 'Country',
		// sortable: true,
		accessor: 'country',
		minResizeWidth: 60,
		Cell: (props: any) => {
			const { country } = props.original;
			if (!country) return null;
			return <span>{country.country}</span>;
		}
	},
	{
		Header: 'Actions',
		width: 80,
		minResizeWidth: 60,
		className: 'flex--center',
		Cell: (props: any) => {
			return (
				<div>
					<Link
						className="link"
						target="_blank"
						to={routes.contact.edit(props.original.id)}
					>
						<div>
							<Svg icon="pencil" width={16} />
						</div>
					</Link>
				</div>
			);
		}
	}
];

type Props = {} & RouterProps;

const ToBeChecked = (props: Props): JSX.Element => {
	const [total, setTotal] = React.useState(undefined);
	const [limit, setLimit] = React.useState(100);
	const [currentPage, setCurrentPage] = React.useState(1); // current page
	const [pages, setPages] = React.useState(1); // all pages
	const [contactList, setContactList] = React.useState([]);
	const [loading, setLoading] = React.useState(false);

	const fetch = () => {
		contactApi.getToBeChecked({ page: currentPage, limit }).then((res: any) => {
			// console.log(res.data);
			const { meta, contacts } = res.data;
			setTotal(meta.total);
			setPages(meta.pages);
			setContactList(contacts);
		});
	};

	const handlePageChange = (page: number) => {
		setCurrentPage(page + 1);
	};

	const handlePageSizeChange = (limit: number) => {
		setLimit(limit);
		contactApi.getToBeChecked({ page: currentPage, limit }).then((res: any) => {
			// console.log(res.data);
			const { meta, contacts } = res.data;
			setTotal(meta.total);
			setPages(meta.pages);
			setContactList(contacts);
		});
	};

	React.useEffect(() => {
		fetch();
	}, [currentPage]);

	return (
		<div className="page page--to-be-checked">
			<Dialog title={`To be checked: ${total}`}>
				<ReactTable
					data={contactList.map(e => e)}
					columns={columns}
					loading={loading}
					sortable={false}
					resizable={true}
					onPageChange={handlePageChange}
					onPageSizeChange={handlePageSizeChange}
					pageSizeOptions= {getPageSizeOption(total)}
					page={currentPage - 1}
					pages={pages}
					collapseOnPageChange={false}
					pageSize={limit}
					multiSort={false}
					manual
				/>
			</Dialog>
		</div>
	);
};

const getPageSizeOption = (total :any) => {
	return [20, 50, 100, 200, 300, 500, total].sort((a, b) => a - b);
}

export default ToBeChecked;

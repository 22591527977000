// tslint:disable: max-line-length

import * as React from 'react';
import layout from '../../../../utils/layout';
import SvgTemplate from '../SvgTemplate';
import { Svg } from '../../Svg';

const icon = ({ color, ...rest }: Svg): JSX.Element => (
	<SvgTemplate {...rest} viewBox="0 0 26 24">
		<React.Fragment>
			<defs>
				<polygon id="path-1" points="14 13 0.05 13 0.05 0.0022 14 0.0022" />
				<path
					d="M0.79,4.5 C0.79,6.985 2.805,9 5.29,9 C7.775,9 9.79,6.985 9.79,4.5 C9.79,2.015 7.775,0 5.29,0 C2.805,0 0.79,2.015 0.79,4.5 Z"
					id="path-3"
				/>
				<polygon
					id="path-5"
					points="0 18.375 0 0 19.9997 0 19.9997 18.375 8.88110657e-16 18.375"
				/>
			</defs>
			<g
				id="Page-1"
				stroke="none"
				strokeWidth="1"
				fill="none"
				fillRule="evenodd"
			>
				<g id="navbar-message-new">
					<g id="Group-3" transform="translate(10.000000, 10.000000)">
						<g id="Fill-1-Clipped">
							<mask id="mask-2" fill="white">
								<use href="#path-1" />
							</mask>
							<g id="path-1" />
							<path
								d="M14,4.0782 C14,2.5482 13.354,1.1402 12.275,0.0022 C12.047,0.0422 11.813,0.0672 11.576,0.0792 C11.558,1.3682 11.24,2.6162 10.617,3.7892 C10.013,4.9272 9.155,5.9442 8.067,6.8112 C5.909,8.5292 3.064,9.4802 0.05,9.4902 C1.512,10.3782 3.344,10.9062 5.333,10.9062 C5.793,10.9062 6.244,10.8782 6.685,10.8232 C8.546,12.6282 10.518,12.9522 12.667,13.0002 L12.667,12.5582 C11.507,12.0072 10.75,11.0032 10.75,9.8562 C10.75,9.6962 10.763,9.5382 10.787,9.3852 C12.747,8.1332 14,6.2212 14,4.0782"
								id="Fill-1"
								fill={color}
								fillRule="nonzero"
								mask="url(#mask-2)"
							/>
						</g>
					</g>
					<g id="Group-6" transform="translate(16.000000, 0.000000)">
						<g id="Fill-4-Clipped">
							<mask id="mask-4" fill="white">
								<use href="#path-3" />
							</mask>
							<g id="path-3" />
							<polygon
								id="Fill-4"
								fill="#EC2726"
								fillRule="nonzero"
								mask="url(#mask-4)"
								points="-4.21 14 14.79 14 14.79 -5 -4.21 -5"
							/>
						</g>
					</g>
					<g id="Group-9" transform="translate(0.000000, 2.000000)">
						<g id="Fill-7-Clipped">
							<mask id="mask-6" fill="white">
								<use href="#path-5" />
							</mask>
							<g id="path-5" />
							<path
								d="M15.6967,2.5 C15.6967,2.152 15.7327,1.812 15.7937,1.481 C14.1577,0.552 12.1617,0 9.9997,0 C4.4767,0 -0.0003,3.567 -0.0003,7.966 C-0.0003,10.466 1.4457,12.697 3.7077,14.157 C3.7347,14.337 3.7497,14.52 3.7497,14.707 C3.7497,16.045 2.6717,17.217 1.3337,17.86 L1.3337,18.375 C3.8127,18.32 6.2927,17.942 8.4407,15.836 C8.9487,15.899 9.4697,15.932 9.9997,15.932 C15.5227,15.932 19.9997,12.366 19.9997,7.966 C19.9997,7.957 19.9987,7.947 19.9977,7.938 C17.5327,7.354 15.6967,5.144 15.6967,2.5"
								id="Fill-7"
								fill={color}
								fillRule="nonzero"
								mask="url(#mask-6)"
							/>
						</g>
					</g>
				</g>
			</g>
		</React.Fragment>
	</SvgTemplate>
);

icon.defaultProps = {
	width: layout.spacing.three,
	color: layout.color.primary
};

export default icon;

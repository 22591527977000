import {patch} from "../../redux/api/utils";

export type Request$ChangeSipAccount = {
	id: number;
	sip_account_id: number;
};

export type Response$ChangeSipAccount = any;

/**
 * Super admin change role end point
 */
export async function changeSipAccount(
	data: Request$ChangeSipAccount
): Promise<Response$ChangeSipAccount> {
	const { id, sip_account_id } = data;
	return patch(`/user/${id}/sip-account`, { sip_account_id });
}

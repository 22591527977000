import * as React from 'react';
import './InputRadio.scss';

interface Props {
	name?: string;
	value?: string;
	onChange?: (e: React.ChangeEvent<HTMLInputElement>) => any;
	validationError?: string;
	radioLabel?: string | JSX.Element;
	checked?: any;
}

export const InputRadio = (props: Props): JSX.Element => {
	const { name, value, radioLabel, onChange, validationError, checked } = props;
	let inputClass = 'input-radio';
	if (validationError) {
		inputClass += ' required';
	}
	return (
		<div className={inputClass}>
			<label htmlFor={value} className="label--radio noselect">
				<input
					checked={checked}
					className="radio-hidden"
					id={value}
					type="radio"
					name={name}
					value={value}
					onChange={onChange}
				/>
				<div className="fake-radio" />
				{radioLabel && <div className="label--radio__text">{radioLabel}</div>}
			</label>
		</div>
	);
};

export default React.memo(InputRadio);

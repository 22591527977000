import * as React from 'react';
import Popup from '../Popup';
import './AddHelpdeskScenario.scss';
import { hot } from 'react-hot-loader';
import {
	FormikForm,
	FormikInputText,
	FormikButtonSubmit,
	FormGroup,
	FormikInputSelect,
	formikSubmit
} from '../../Form';
import { FormikProps, FormikActions } from 'formik';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import { Store } from '../../../models';
import { getOptions, Action$GetOptions } from '../../../redux/actions';
import {Language, Options} from '../../../models/Options';
import { Request$EditScenario } from "../../../api/Helpdesk/Api";
import {
	ActionCreator$AddScenario,
	ActionCreator$EditScenario,
	addScenarioActionCreator, editScenarioActionCreator
} from '../../../redux/Helpdesk/HelpdeskActions/Actions';
import RichEditor from "../../../router/pages/CustomEmail/RichEditor/RichEditor";
import {Scenario} from "../../../models/Helpdesk";

interface Props {
	active: boolean;
	options: Options;
	getOptions: Action$GetOptions;
	addScenario: ActionCreator$AddScenario;
	editScenario: ActionCreator$EditScenario;
	toUpdate?: Scenario;
}

type Values = Request$EditScenario;

const validationSchema = Yup.object().shape({
	title: Yup.string().required(),
	content: Yup.string(),
	language_id: Yup.number().required()
});

const AddHelpdeskScenario = (props: Props): JSX.Element => {
	const [markup, setMarkup] = React.useState('');
	const { addScenario , editScenario, toUpdate, active, options, getOptions } = props;

	React.useEffect(() => {
		if (!options || !options.languages || !options.languages.length) {
			getOptions().then();
		}
	}, []);

	React.useEffect(() => {}, [markup]);
	const handleEditorChange = (markup: string) => {
		setMarkup(markup.split('<p></p>').join('<br>'));
	};

	const onClose = () => {
		setMarkup('<br>');
	};

	const onSubmit = (values: Values, actions: FormikActions<Values>) => {
		let method = toUpdate ? editScenario : addScenario;
		if(toUpdate)
			values.id = toUpdate.id;
		if(markup.trim() == "<br>") {
			if (!window.confirm('Do you want to add empty content?')) {
				actions.setSubmitting(false);
				return;
			}
		}
		values.content = markup;
		formikSubmit(values, actions, method)
			.then(() => {
				setMarkup('<br>');
				window.popup.pop();
			})
			.catch(() => {})
			.then(() => {
				setMarkup('<br>');
				actions.setSubmitting(false);
			});
	};

	const initialValues = {
		title: toUpdate ? toUpdate.title : '',
		content: toUpdate ? toUpdate.content : '',
		language_id: toUpdate ? toUpdate.language_id.toString() : ''
	};

	const Form = (props: Props & FormikProps<Values>) => {
		const { handleSubmit, options } = props;
		return (
			<form onSubmit={handleSubmit}>
				<FormGroup>
					<FormikInputSelect label="Language:" name="language_id" flat>
						{options &&
						options.languages &&
						options.languages.map((item: Language) => {
							const { id, language } = item;
							return (
								<option value={id} key={id}>
									{language}
								</option>
							);
						})}
					</FormikInputSelect>
				</FormGroup>
				<FormGroup>
					<FormikInputText name="title" label="Title:" flat />
				</FormGroup>
				<FormikButtonSubmit
					className="submit-scenario-btn"
					fullWidth
					title={ toUpdate ? 'Update' : 'Save' }
					{...props}
					dirty={toUpdate ? true : props.dirty}
				/>
			</form>
		);
	};

	return (
		<Popup active={active} title={ toUpdate ? 'Edit Scenario' : 'Add new Scenario' } className="popup--add-new-scenario" onClose={onClose}>
			<FormikForm
				initialValues={initialValues}
				validationSchema={validationSchema}
				onSubmit={onSubmit}
				render={(formProps: FormikProps<Values>) => (
					<span>
						<Form {...props} {...formProps} />
					</span>
				)}
			/>
			<RichEditor onChange={handleEditorChange} title={"Content:"} defaultValue={toUpdate ? toUpdate.content : undefined} />
		</Popup>
	);
};


const mapStateToProps = (store: Store) => {
	const { options } = store;
	return { options };
};

const mapDispatchToProps = (dispatch: any) => {
	return {
		getOptions: () => dispatch(getOptions()),
		addScenario: (data: Request$EditScenario) => dispatch(addScenarioActionCreator(data)),
		editScenario: (data: Request$EditScenario) => dispatch(editScenarioActionCreator(data))
	};
};


export default hot(module)(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(AddHelpdeskScenario)
);

import * as React from 'react';

import Popup from '../Popup';
import { hot } from 'react-hot-loader';
import { FormikForm, FormikInputText, FormikButtonSubmit } from '../../Form';
import { FormikProps, FormikActions } from 'formik';
import * as Yup from 'yup';
import { changeAccountPassword } from '../../../api/CallCenterAccounts/ChangeAccountPassword';

interface Props {
	active: boolean;
	account: any;
}

type Values = {
	password: string;
};

const validationSchema = Yup.object().shape({
	password: Yup.string()
		.required()
		.min(4)
});

const ChangeCallCenterAccountPassword = (props: Props): JSX.Element => {
	const { active, account } = props;

	const onSubmit = (values: Values, actions: FormikActions<Values>) => {
		changeAccountPassword({ id: account.id, password: values.password })
			.then(res => {
				window.popup.pop();
				window.location.reload();
			})
			.catch(() => {})
			.then(() => {
				actions.setSubmitting(false);
			});
	};

	return (
		<Popup
			active={active}
			title={`Change ${account.sip_username}'s password`}
			className="popup--change-cc-account-password"
		>
			<FormikForm
				initialValues={{ password: '' }}
				validationSchema={validationSchema}
				onSubmit={onSubmit}
				render={(formProps: FormikProps<Values>) => (
					<Form {...props} {...formProps} />
				)}
			/>
		</Popup>
	);
};

const Form = (props: Props & FormikProps<Values>) => {
	const { handleSubmit } = props;
	return (
		<form onSubmit={handleSubmit}>
			<FormikInputText
				label="New password:"
				name="password"
				type="password"
				flat
			/>
			<FormikButtonSubmit fullWidth {...props} />
		</form>
	);
};

export default hot(module)(ChangeCallCenterAccountPassword);

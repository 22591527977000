import bednblue from './bednblue';
import close from './close';
import closeThin from './close-thin';
import facebook from './facebook';
import twitter from './twitter';
import instagram from './instagram';
import googlePlus from './google-plus';
import arrowLeft from './arrow-left';
import arrowRight from './arrow-right';
import favorite from './favorite';
import favoriteStroke from './favorite-stroke';
import messages from './messages';
import messagesBadge from './messages-badge';
import notifications from './notifications';
import notificationsBadge from './notifications-badge';
import avatarMale from './avatar-male';
import avatarMaleCircle from './avatar-male-circle';
import avatarFemale from './avatar-female';
import avatarFemaleCircle from './avatar-female-circle';
import help from './help';
import phone from './phone';
import check from './check';
import checkCircle from './check-circle';
import mail from './mail';
import lock from './lock';
import locker from './locker';
import lockerOpened from './locker-opened';
import user from './user';
import search from './search';
import carretRight from './carret-right';
import carretLeft from './carret-left';
import carretDown from './carret-down';
import carretDownSelect from './carret-down-select';
import governmentId from './government-id';
import passport from './passport';
import drivingLicense from './driving-license';
import upload from './upload';
import trash from './trash';
import motorYacht from './motor-yacht';
import sailingYacht from './sailing-yacht';
import catamaranYacht from './catamaran-yacht';
import otherYacht from './other-yacht';
import flag from './flag';
import share from './share';
import boatLength from './boat-length';
import guests from './guests';
import cabin from './cabin';
import bed from './bed';
import clock from './clock';
import clock2 from './clock-2';
import instantBook from './instant-book';
import refresh from './refresh';
import calendar from './calendar';
import filter from './filter';
import photos from './photos';
import attachment from './attachment';
import eye from './eye';
import eyeBlind from './eye-blind';
import copy from './copy';
import printer from './printer';
import expand from './expand';
import star from './star';
import thumbsUp from './thumbs-up';
import thumbsDown from './thumbs-down';
import payout from './payout';
import pencil from './pencil';
import settings from './settings';
import newContact from './new-contact';
import list from './list';
import duplicate from './duplicate';
import members from './members';
import changePassword from './change-password';
import dashboard from './dashboard';
import mailSent from './mail-sent';
import hourglass from './hourglass';
import hyperlink from './hyperlink';
import lifesaver from "./lifesaver";
import registered from "./registered";
import mobile from "./mobile";

export const misc = {
    bednblue: bednblue,
    close: close,
    'close-thin': closeThin,
    facebook: facebook,
    twitter: twitter,
    instagram: instagram,
    'google-plus': googlePlus,
    'arrow-left': arrowLeft,
    'arrow-right': arrowRight,
    favorite: favorite,
    'favorite-stroke': favoriteStroke,
    messages: messages,
    'messages-badge': messagesBadge,
    notifications: notifications,
    'notifications-badge': notificationsBadge,
    'avatar-male': avatarMale,
    'avatar-male-circle': avatarMaleCircle,
    'avatar-female': avatarFemale,
    'avatar-female-circle': avatarFemaleCircle,
    help: help,
    phone: phone,
    mobile: mobile,
    'carret-right': carretRight,
    'carret-left': carretLeft,
    'carret-down': carretDown,
    'carret-down-select': carretDownSelect,
    check: check,
    'check-circle': checkCircle,
    mail: mail,
    lock: lock,
    locker: locker,
    'locker-opened': lockerOpened,
    user: user,
    search: search,
    'government-id': governmentId,
    passport: passport,
    'driving-license': drivingLicense,
    upload: upload,
    trash: trash,
    'motor-yacht': motorYacht,
    'sailing-yacht': sailingYacht,
    'catamaran-yacht': catamaranYacht,
    'other-yacht': otherYacht,
    flag: flag,
    share: share,
    'boat-length': boatLength,
    guests: guests,
    cabin: cabin,
    bed: bed,
    clock: clock,
    'clock-2': clock2,
    'instant-book': instantBook,
    refresh: refresh,
    calendar: calendar,
    filter: filter,
    photos: photos,
    attachment: attachment,
    eye: eye,
    'eye-blind': eyeBlind,
    copy: copy,
    printer: printer,
    expand: expand,
    star: star,
    'thumbs-up': thumbsUp,
    'thumbs-down': thumbsDown,
    payout: payout,
    pencil: pencil,
    settings: settings,
    'new-contact': newContact,
    list: list,
    duplicate: duplicate,
    members: members,
    'change-password': changePassword,
    dashboard: dashboard,
    'mail-sent': mailSent,
    hourglass: hourglass,
    hyperlink: hyperlink,
    lifesaver: lifesaver,
    registered: registered
};

export type MiscIcons =
    | 'bednblue'
    | 'close'
    | 'close-thin'
    | 'facebook'
    | 'twitter'
    | 'instagram'
    | 'google-plus'
    | 'arrow-left'
    | 'arrow-right'
    | 'favorite'
    | 'favorite-stroke'
    | 'messages'
    | 'messages-badge'
    | 'notifications'
    | 'notifications-badge'
    | 'avatar-male'
    | 'avatar-male-circle'
    | 'avatar-female'
    | 'avatar-female-circle'
    | 'help'
    | 'phone'
    | 'carret-right'
    | 'carret-left'
    | 'carret-down'
    | 'carret-down-select'
    | 'check'
    | 'check-circle'
    | 'mail'
    | 'lock'
    | 'user'
    | 'search'
    | 'locker'
    | 'locker-opened'
    | 'government-id'
    | 'passport'
    | 'driving-license'
    | 'upload'
    | 'trash'
    | 'motor-yacht'
    | 'sailing-yacht'
    | 'catamaran-yacht'
    | 'other-yacht'
    | 'flag'
    | 'share'
    | 'boat-length'
    | 'guests'
    | 'cabin'
    | 'bed'
    | 'clock'
    | 'clock-2'
    | 'instant-book'
    | 'refresh'
    | 'calendar'
    | 'filter'
    | 'photos'
    | 'attachment'
    | 'eye'
    | 'eye-blind'
    | 'copy'
    | 'printer'
    | 'expand'
    | 'star'
    | 'thumbs-up'
    | 'thumbs-down'
    | 'payout'
    | 'pencil'
    | 'settings'
    | 'new-contact'
    | 'list'
    | 'duplicate'
    | 'members'
    | 'change-password'
    | 'dashboard'
    | 'mail-sent'
    | 'hourglass'
    | 'hyperlink'
    | 'lifesaver'
    | 'registered'
    | 'mobile';

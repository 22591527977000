import { User } from '../../models/User';
import {post} from "../../redux/api/utils";

export type Request$UpdateProfile = {
	display_name: string;
	user_email: string;
	user_phone: string;
	user_app_phone: string;
	user_job_title: string;
};
export type Request$UpdatePreferred = {
	default_country_id?: number;
	default_region_id?: number;
	default_country_phone_id?: number;
	default_main_language?: string;
};

export type Response$UpdateProfile = { data: User };

/**
 * API POST Request to update profile.
 */
export async function updateProfile(
	data: Request$UpdateProfile
): Promise<Response$UpdateProfile> {
	return post('/profile', data);
}

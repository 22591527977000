// tslint:disable: max-line-length
import * as React from 'react';
import layout from '../../../../utils/layout';
import SvgTemplate from '../SvgTemplate';
import { Svg } from '../../Svg';

const icon = ({ color, ...rest }: Svg): JSX.Element => (
	<SvgTemplate {...rest} viewBox="0 0 30 29">
		<defs>
			<polygon
				id="path-1"
				points="29.6466378 28.3559739 0 28.3559739 0 4.53782718e-05 29.6466378 4.53782718e-05"
			/>
		</defs>
		<g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<g id="instant-book">
				<mask id="mask-2" fill="white">
					<use xlinkHref="#path-1" />
				</mask>
				<g id="path-1" />
				<path
					d="M27.5239935,19.8899465 L29.6466378,13.229929 L22.8175106,14.7215127 L23.670017,15.6578177 C23.8584881,15.8647426 23.8418494,16.1854158 23.6332606,16.3716179 L21.065758,18.5847162 C18.6239533,20.8081002 15.0452716,21.0136638 12.3941217,19.2992727 L22.5261821,10.2003244 C22.7317456,10.015181 23.0483347,10.0309121 23.2348394,10.2355682 L23.9614968,11.0336207 L26.0842923,4.37360312 L19.255165,5.86533815 L20.1090328,6.80300448 C20.2968989,7.00932435 20.2808652,7.32893864 20.0736378,7.51544334 L9.94581277,16.6104589 C8.48644757,13.8109221 9.02493639,10.2670304 11.4667411,8.04364641 L14.041353,5.82404392 C14.2473703,5.64026192 14.5627493,5.65644684 14.7484977,5.8604978 L15.4766677,6.66006293 L17.5993119,4.53782718e-05 L10.7700334,1.49162914 L11.6228424,2.42808539 C11.811011,2.63485904 11.7945235,2.95553216 11.5859347,3.14173433 L9.01843217,5.3548326 C5.61264168,8.45628615 4.65969799,13.258366 6.27108039,17.305049 C6.73787154,18.477321 6.42536651,19.8162825 5.49238926,20.665915 L-4.5378271e-05,25.6673567 L2.44841486,28.3560193 L7.94069824,23.3544263 C8.87367549,22.504945 10.2359312,22.3187429 11.3594972,22.8930805 C15.237978,24.8752034 20.1082765,24.3748323 23.514067,21.2733788 L26.0228803,18.9887341 C26.2284439,18.8017756 26.5465456,18.8165992 26.7335041,19.0220115 L27.5239935,19.8899465 Z"
					id="Fill-1"
					stroke="#EB2726"
					strokeWidth="0.3"
					fill="#F4E839"
					fillRule="nonzero"
					mask="url(#mask-2)"
				/>
			</g>
		</g>
	</SvgTemplate>
);

icon.defaultProps = {
	width: layout.spacing.two,
	color: layout.color.primary
};

export default icon;

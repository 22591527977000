import initialState from '../../store/initialState';
import { UserEnum } from '../enums';
import { Action$Login } from '../UserActions';
import { User } from '../../../models/User';
import { Action$UpdateProfile } from '../UserActions/UpdateProfile';

type Actions = Action$Login | Action$UpdateProfile;

function userReducer(
	user: User | null = initialState.user,
	action: Actions
): User | null {
	const { type, payload } = action;
	switch (type) {
		case UserEnum.LOGGED_IN:
			return payload.data;
		// case UserEnum.LOADED_PROFILE:
		// 	return payload;
		case UserEnum.UPDATED_PROFILE:
			return payload.data;
		// case UserEnum.LOGGED_OUT:
		// 	return null;
		default:
			return user;
	}
}

export default userReducer;

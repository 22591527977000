import * as React from 'react';

/*
	This HOC takes a comopnent and renders it after a duration specified (ms).
*/

const withDelayedRender = (delay: number = 200) => (ChildComponent: any) => (
	props: any
) => {
	const [active, setActive] = React.useState(false);

	let _isActive: boolean = true; // to unsubscribe on unmount

	React.useEffect(() => {
		setTimeout(() => {
			_isActive && setActive(true);
		}, 200);
	}, []);

	return active ? <ChildComponent {...props} /> : null;
};

export default withDelayedRender;

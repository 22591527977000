import { UserEnum } from '../enums';

import { Action } from '../../models';
import {
	Response$ValidateToken,
	Request$ValidateToken,
	validateToken
} from '../../../api/Auth';

export type Action$ValidateToken = Action<
	UserEnum.LOGGED_IN,
	Response$ValidateToken
>;

export type ActionCreator$ValidateToken = (
	data: Request$ValidateToken
) => Promise<Action$ValidateToken>;

const validateTokenActionCreator: ActionCreator$ValidateToken = async (
	data: Request$ValidateToken
): Promise<Action$ValidateToken> => {
	const response: Response$ValidateToken = await validateToken(data);
	return {
		type: UserEnum.LOGGED_IN,
		payload: response
	};
};

export default validateTokenActionCreator;

// tslint:disable: max-line-length

import * as React from 'react';
import layout from '../../../../utils/layout';
import SvgTemplate from '../SvgTemplate';
import { Svg } from '../../Svg';

const icon = ({ color, ...rest }: Svg): JSX.Element => (
	<SvgTemplate {...rest} viewBox="0 0 411 296">
		<g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<g id="government-id" fill={color} fillRule="nonzero">
				<path
					d="M126.02,152.9 L95.1,152.9 C68.397,152.966 46.766,174.597 46.7,201.3 L46.7,216.8 C46.7,219.727 49.073,222.1 52,222.1 L169.1,222.1 C169.12,222.1 169.14,222.1 169.16,222.1 C172.087,222.078 174.442,219.687 174.42,216.76 L174.42,201.3 C174.354,174.597 152.723,152.966 126.02,152.9 Z M163.82,211.46 L57.3,211.46 L57.3,201.3 C57.409,180.469 74.269,163.609 95.1,163.5 L126.02,163.5 C146.851,163.609 163.711,180.469 163.82,201.3 L163.82,211.46 Z"
					id="Shape"
				/>
				<path
					d="M358.26,200.38 L216.26,200.38 C213.589,200.544 211.46,202.674 211.296,205.344 C211.116,208.266 213.339,210.78 216.26,210.96 L358.26,210.96 C360.931,210.796 363.06,208.666 363.224,205.996 C363.404,203.074 361.182,200.56 358.26,200.38 Z"
					id="Shape"
				/>
				<path
					d="M135.48,135.38 L135.8,135.08 C135.804,135.076 135.808,135.072 135.813,135.067 C149.751,121.122 149.746,98.518 135.8,84.58 C129.116,77.868 120.033,74.096 110.56,74.1 C101.234,74.096 92.279,77.751 85.62,84.28 L85.3,84.58 C85.296,84.584 85.292,84.588 85.287,84.593 C71.349,98.538 71.354,121.142 85.3,135.08 C99.115,148.923 121.501,149.057 135.48,135.38 Z M92.84,92.1 L93.1,91.84 C102.958,82.336 118.604,82.451 128.32,92.1 L128.38,92.08 C138.189,101.896 138.189,117.804 128.38,127.62 L128.12,127.88 C118.26,137.429 102.569,137.322 92.84,127.64 C83.031,117.824 83.031,101.916 92.84,92.1 Z"
					id="Shape"
				/>
				<path
					d="M374.9,0.38 L35.3,0.38 C15.827,0.435 0.055,16.207 0,35.68 L0,260.52 C0.055,279.993 15.827,295.765 35.3,295.82 L374.9,295.82 C394.373,295.765 410.145,279.993 410.2,260.52 L410.2,35.68 C410.145,16.207 394.373,0.435 374.9,0.38 Z M399.62,260.52 C399.576,274.154 388.534,285.196 374.9,285.24 L35.3,285.24 C21.666,285.196 10.624,274.154 10.58,260.52 L10.58,35.68 C10.624,22.046 21.666,11.004 35.3,10.96 L374.9,10.96 C388.534,11.004 399.576,22.046 399.62,35.68 L399.62,260.52 Z"
					id="Shape"
				/>
				<path
					d="M358.26,85.26 L216.26,85.26 C213.589,85.424 211.46,87.554 211.295,90.225 C211.115,93.147 213.338,95.661 216.26,95.84 L358.26,95.84 C360.931,95.676 363.06,93.546 363.225,90.875 C363.404,87.954 361.182,85.44 358.26,85.26 Z"
					id="Shape"
				/>
				<path
					d="M358.26,142.82 L216.26,142.82 C213.589,142.984 211.46,145.114 211.295,147.785 C211.115,150.707 213.338,153.221 216.26,153.4 L358.26,153.4 C360.931,153.236 363.06,151.106 363.225,148.435 C363.404,145.514 361.182,143 358.26,142.82 Z"
					id="Shape"
				/>
				<path
					d="M358.26,114.04 L216.26,114.04 C213.589,114.204 211.46,116.334 211.295,119.005 C211.115,121.927 213.338,124.441 216.26,124.62 L358.26,124.62 C360.931,124.456 363.06,122.326 363.225,119.655 C363.404,116.734 361.182,114.22 358.26,114.04 Z"
					id="Shape"
				/>
				<path
					d="M358.26,171.6 L216.26,171.6 C213.589,171.764 211.46,173.894 211.296,176.564 C211.116,179.486 213.339,182 216.26,182.18 L358.26,182.18 C360.931,182.016 363.06,179.886 363.224,177.216 C363.404,174.294 361.182,171.78 358.26,171.6 Z"
					id="Shape"
				/>
			</g>
		</g>
	</SvgTemplate>
);

icon.defaultProps = {
	width: layout.spacing.three,
	color: layout.color.primary
};

export default icon;

// tslint:disable: max-line-length

import * as React from 'react';
import layout from '../../../../utils/layout';
import SvgTemplate from '../SvgTemplate';
import { Svg } from '../../Svg';

const icon = ({ color, ...rest }: Svg): JSX.Element => (
	<SvgTemplate {...rest} viewBox="0 0 14 14">
		<g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<g id="posted" fill={color} fillRule="nonzero">
				<g id="Page-1">
					<g id="AddYacht/Features">
						<g id="Group-5">
							<path
								d="M7,0 C3.11818182,0 0,3.11818182 0,7 C0,10.8818182 3.11818182,14 7,14 C10.8818182,14 14,10.8818182 14,7 C14,3.11818182 10.8818182,0 7,0 Z M11.0409091,4.80454545 L5.95,10.0863636 C5.88636364,10.15 5.82272727,10.1818182 5.72727273,10.1818182 C5.63181818,10.1818182 5.53636364,10.15 5.50454545,10.0863636 L3.02272727,7.41363636 L2.95909091,7.35 C2.89545455,7.28636364 2.86363636,7.19090909 2.86363636,7.12727273 C2.86363636,7.06363636 2.89545455,6.96818182 2.95909091,6.90454545 L3.40454545,6.45909091 C3.53181818,6.33181818 3.72272727,6.33181818 3.85,6.45909091 L3.88181818,6.49090909 L5.63181818,8.36818182 C5.69545455,8.43181818 5.79090909,8.43181818 5.85454545,8.36818182 L10.1181818,3.94545455 L10.15,3.94545455 C10.2772727,3.81818182 10.4681818,3.81818182 10.5954545,3.94545455 L11.0409091,4.39090909 C11.1681818,4.48636364 11.1681818,4.67727273 11.0409091,4.80454545 Z"
								id="Shape"
							/>
						</g>
					</g>
				</g>
			</g>
		</g>
	</SvgTemplate>
);

icon.defaultProps = {
	width: layout.spacing.three,
	color: layout.color.primaryLight
};

export default icon;

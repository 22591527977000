import * as React from 'react';
import $ from 'jquery';
import { InputText } from '../../Form/InputText/InputText';
import { FormikFormProps, FormikProps } from 'formik';
interface Props {
	placeholder?: string;
	types?: string[];
	flat?: boolean;
	value?: any;
	onPlaceChange?: (place: any) => void;
	form?: FormikProps<any>;
	validationError?: any;
	dataCy?: string;
}

interface State {
	value: any;
}

export class GoogleAutoComplete extends React.Component<
	Props & FormikFormProps,
	State
> {
	state = {
		value: this.props.value || ''
	};

	input: any = React.createRef();

	autocomplete: any;

	componentDidMount() {
		this.initLoader();
	}

	componentDidUpdate(prevProps: Props, prevState: State) {
		/*
		 * This is a controlled input.
		 * But it might take props from a drag event of a map,
		 * so the value must be able to change from the outside aswell.
		 * To achieve that we compare prevProps with this.props and change the state.
		 */
		if (
			prevState.value === this.state.value &&
			this.state.value !== this.props.value &&
			prevProps.value !== this.props.value
		) {
			this.setState({ value: this.props.value });
		}
	}

	initLoader = () => {
		if (!process.env.SERVER) {
			import('../../../utils/google').then(({ GoogleMapsLoader }) => {
				GoogleMapsLoader.load((google: any) => {
					this.initAutocomplete(google);
				});
			});
		}
	};

	initAutocomplete = (google: any) => {
		this.autocomplete = new google.maps.places.Autocomplete(this.input.current);
		this.selectFirstOnEnter(this.input.current);
		this.autocomplete.addListener('place_changed', () => {
			const place = this.autocomplete.getPlace();
			const { form, name, onPlaceChange } = this.props;

			place.pretty_address = this.input.current.value;

			if (!place.geometry)
				return;

			this.userTyped = false; // this means that the user picked a valid location from google.

			// Instead of `onChange` we pass `onPlaceChange` to get the place in parent component.
			onPlaceChange && onPlaceChange(place);

			// For formik <Field /> only.
			this.updateFormikField(place.pretty_address);

			this.setState({
				value: place.pretty_address
			});
		});
	};

	selectFirstOnEnter = (input: any) => {  // store the original event binding function
		let _addEventListener = (input.addEventListener) ? input.addEventListener : input.attachEvent;
		const addEventListenerWrapper = (type: any, listener: any) => {  // Simulate a 'down arrow' keypress on hitting 'return' when no pac suggestion is selected, and then trigger the original listener.
			if (type == "keydown") {
				let orig_listener = listener;
				listener = (event: any) => {
					let suggestion_selected = $(".pac-item-selected").length > 0;
					if (event.which == 13 && !suggestion_selected) {
						let simulated_down_arrow = $.Event("keydown", {keyCode: 40, which: 40});
						orig_listener.apply(input, [simulated_down_arrow]);
					}
					orig_listener.apply(input, [event]);
				};
			}
			_addEventListener.apply(input, [type, listener]); // add the modified listener
		}
		if (input.addEventListener) {
			input.addEventListener = addEventListenerWrapper;
		} else if (input.attachEvent) {
			input.attachEvent = addEventListenerWrapper;
		}
	}

	// tells if the user has picked a valid location from google after he/she typed something.
	userTyped: boolean = false;

	onBlur = () => {
		if (this.userTyped) {
			this.setState({
				value: ''
			});
			this.updateFormikField('');
		}
	};

	updateFormikField(value: string) {
		// For formik <Field /> only.
		const { name, form } = this.props;
		if (form && name) {
			form.setFieldValue(name, value);
		}
	}

	onChange = (e: any): void => {
		this.userTyped = true;
		this.setState({
			value: e.target.value
		});
	};

	handleKeyPress = (e: React.KeyboardEvent) => {
		if (e.which === 13) {
			// enter
			e.preventDefault();
		}
	};

	render(): JSX.Element {
		const { placeholder, flat, name, validationError, dataCy } = this.props;
		const { value } = this.state;
		return (
			<InputText
				inputRef={this.input}
				name={name}
				placeholder={placeholder}
				flat={flat}
				onBlur={this.onBlur}
				onChange={this.onChange}
				value={value}
				validationError={validationError}
				dataCy={dataCy}
				onKeyPress={this.handleKeyPress}
			/>
		);
	}
}

export default GoogleAutoComplete;

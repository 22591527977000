// tslint:disable: max-line-length

import * as React from 'react';
import layout from '../../../../utils/layout';
import SvgTemplate from '../SvgTemplate';
import { Svg } from '../../Svg';

const icon = ({ color, ...rest }: Svg): JSX.Element => (
	<SvgTemplate {...rest} viewBox="0 0 21 21">
		<g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<g id="instagram" fill={color} fillRule="nonzero">
				<path
					d="M15.1207412,0 L5.76314118,0 C2.58534706,0 0,2.58547059 0,5.76326471 L0,15.1208647 C0,18.2987824 2.58534706,20.8841294 5.76314118,20.8841294 L15.1207412,20.8841294 C18.2987824,20.8841294 20.8841294,18.2986588 20.8841294,15.1208647 L20.8841294,5.76326471 C20.8842529,2.58547059 18.2987824,0 15.1207412,0 Z M19.0313118,15.1208647 C19.0313118,17.2770706 17.2770706,19.0311882 15.1208647,19.0311882 L5.76314118,19.0311882 C3.60705882,19.0313118 1.85294118,17.2770706 1.85294118,15.1208647 L1.85294118,5.76326471 C1.85294118,3.60718235 3.60705882,1.85294118 5.76314118,1.85294118 L15.1207412,1.85294118 C17.2769471,1.85294118 19.0311882,3.60718235 19.0311882,5.76326471 L19.0311882,15.1208647 L19.0313118,15.1208647 Z"
					id="Shape"
				/>
				<path
					d="M10.4420647,5.061 C7.47476471,5.061 5.06075294,7.47501176 5.06075294,10.4423118 C5.06075294,13.4094882 7.47476471,15.8233765 10.4420647,15.8233765 C13.4093647,15.8233765 15.8233765,13.4094882 15.8233765,10.4423118 C15.8233765,7.47501176 13.4093647,5.061 10.4420647,5.061 Z M10.4420647,13.9703118 C8.4966,13.9703118 6.91369412,12.3876529 6.91369412,10.4421882 C6.91369412,8.4966 8.49647647,6.91381765 10.4420647,6.91381765 C12.3876529,6.91381765 13.9704353,8.4966 13.9704353,10.4421882 C13.9704353,12.3876529 12.3875294,13.9703118 10.4420647,13.9703118 Z"
					id="Shape"
				/>
				<path
					d="M16.0490647,3.48982941 C15.6920647,3.48982941 15.3413647,3.63435882 15.0892412,3.88759412 C14.8358824,4.13959412 14.6902412,4.49041765 14.6902412,4.84865294 C14.6902412,5.20577647 14.8360059,5.55647647 15.0892412,5.80971176 C15.3412412,6.06171176 15.6920647,6.20747647 16.0490647,6.20747647 C16.4073,6.20747647 16.7568882,6.06171176 17.0101235,5.80971176 C17.2633588,5.55647647 17.4078882,5.20565294 17.4078882,4.84865294 C17.4078882,4.49041765 17.2633588,4.13959412 17.0101235,3.88759412 C16.7581235,3.63435882 16.4073,3.48982941 16.0490647,3.48982941 Z"
					id="Shape"
				/>
			</g>
		</g>
	</SvgTemplate>
);

icon.defaultProps = {
	width: layout.spacing.two,
	color: layout.color.primary
};

export default icon;

import * as React from 'react';
import { InputSelect } from '../InputSelect/InputSelect';
import './DoubleSelect.scss';
import { getIn } from 'formik';

interface Props {
	value: any;
	children: any;
	validationError?: string;
	placeholder?: object;
	name: string;
	number?: any;
}

export const DoubleSelect = (props: any) => {
	const {
		value,
		children,
		validationError,
		placeholder,
		name,
		errors,
		number
	} = props;
	if (!value || !children) {
		throw new Error(
			// tslint:disable-next-line: max-line-length
			'No values passed to DoubleSelect! Use an object\'s property as the name and add children <Option /> with the child prop as name! example: <Option name="year" value="2000">2000</Option>'
		);
	}
	const keys = Object.keys(props.value);

	if (keys.length !== 2) {
		throw new Error('Double select must have exactly 2 values.');
	}

	const wrapperClass = 'double-select box-shadow--input';

	// we use `getIn` for field array validation.
	const error = validationError || getIn(errors, name);

	// concat react components with Arrays of react components (came from .map())
	const concatChildren : any[] = [];
	for (const child of children) {
		if (child instanceof Array) {
			for (const c of child) {
				concatChildren.push(c);
			}
		} else {
			concatChildren.push(child);
		}
	}

	return (
		<div className={wrapperClass}>
			<InputSelect
				{...props}
				value={props.value[keys[0]]}
				name={`${props.name}.${keys[0]}`}
				validationError={error ? error[keys[0]] : null}
				placeholder={placeholder && placeholder[keys[0]]}
				number={number && number[keys[0]]}
			>
				{concatChildren &&
					concatChildren.filter((option: any) => {
						return option.props.name === keys[0];
					})}
			</InputSelect>
			<div className="double-select__divider">
				<div className="double-select__divider__inner" />
			</div>
			<InputSelect
				{...props}
				value={props.value[keys[1]]}
				name={`${props.name}.${keys[1]}`}
				validationError={validationError ? validationError[keys[1]] : null}
				placeholder={placeholder && placeholder[keys[1]]}
				number={number && number[keys[1]]}
			>
				{concatChildren &&
					concatChildren.filter((option: any) => {
						return option.props.name === keys[1];
					})}
			</InputSelect>
		</div>
	);
};

export default React.memo(DoubleSelect);

import * as React from 'react';
import { RouterProps } from 'react-router';

type Props = {} & RouterProps;

const NotFound = (props: Props): JSX.Element => {
	return <div className="page">Page not found.</div>;
};

export default NotFound;

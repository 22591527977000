import { User } from '../../models/User';
import {get} from "../../redux/api/utils";

export type Response$UserList = { data: User[] };
export type Response$AssignUserList = { data: User[] };
/**
 * API GET Request to fetch users.
 */
export async function listUsers(): Promise<Response$UserList> {
	return get('/user/list');
}

export async function listUsersToAssign(): Promise<Response$AssignUserList> {
	return get('/user/list-to-assign');
}

import {
	addNewUser,
	Request$AddUser,
	Response$AddUser
} from '../../../api/User/AddUser';
import { UserListEnum } from '../enums';

type Action$AddUser = {
	type: UserListEnum.ADDED_USER;
	payload: Response$AddUser;
};

export type ActionCreator$AddUser = (
	data: Request$AddUser
) => Promise<Action$AddUser>;

export async function addNewUserActionCreator(
	data: Request$AddUser
): Promise<Action$AddUser> {
	const res = await addNewUser(data);
	return {
		type: UserListEnum.ADDED_USER,
		payload: res
	};
}

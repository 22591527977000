import { Action$ListToAssignUsers } from '../UserListActions';
import initialState from '../../store/initialState';
import { UserToAssignListEnum } from '../enums';
import User from '../../entities/User';
import { Action$ChangeRole } from '../../User/UserActions';
import { UserEnum } from '../../User/enums';

type Actions = Action$ListToAssignUsers | Action$ChangeRole;

function userToAssignListReducer(
	userToAssignList: any = initialState.userToAssignList,
	action: Actions
) {
	const list = [...userToAssignList];
	const { type, payload } = action;
	switch (type) {
		case UserToAssignListEnum.LOADED_USER_LIST:
			return payload.data;
		case UserToAssignListEnum.ADDED_USER:
			list.push(payload.data);
			return list;
		case UserEnum.CHANGED_ROLE:
			return list.map((user: User) => {
				if (user.id === payload.data.id) {
					user.role_id = payload.data.role_id;
				}
				return user;
			});
		default:
			return userToAssignList;
	}
}

export default userToAssignListReducer;

import * as React from 'react';
import './Dot.scss';

type Props = {
	color: 'warning' | 'success' | 'danger';
};

const Dot = (props: Props): JSX.Element => {
	const { color } = props;
	let cl = 'dot';
	if (color) {
		cl += ` ${color}`;
	}

	return <div className={cl} />;
};

export default Dot;

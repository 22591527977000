import { Response$UserList, listUsers, listUsersToAssign } from '../../../api/User/List';
import { UserListEnum, UserToAssignListEnum } from '../enums';
import { Action } from '../../models';

export type Action$ListUsers = Action<any, Response$UserList>;
export type Action$ListToAssignUsers = Action<any, Response$UserList>;

export async function listUsersActionCreator(): Promise<Action$ListUsers> {
	const response = await listUsers();
	return {
		type: UserListEnum.LOADED_USER_LIST,
		payload: response
	};
}
export async function listToAssignUsersActionCreator(): Promise<Action$ListToAssignUsers> {
	const response = await listUsersToAssign();
	return {
		type: UserToAssignListEnum.LOADED_USER_LIST,
		payload: response
	};
}

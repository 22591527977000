import * as React from 'react';
declare const document: any;

interface Props {
	close: () => any;
	clickOutsideActive?: boolean;
}

const withClickOutside = (ChildComponent: React.ComponentType<any>): any => {
	return class ClickOutSide extends React.Component<Props> {
		ref: any = React.createRef();

		componentDidMount() {
			const { clickOutsideActive } = this.props;
			if (clickOutsideActive) {
				document && document.addEventListener('click', this.listener);
			}
		}

		componentWillUnmount() {
			document && document.removeEventListener('click', this.listener);
		}

		listener = (e: any) => {
			const { close } = this.props;
			if (this.ref && !this.ref.current.contains(e.target)) {
				close && close();
			}
		};

		render(): JSX.Element {
			const { close, clickOutsideActive, ...rest } = this.props;
			return (
				<div ref={this.ref}>
					<ChildComponent {...rest} />
				</div>
			);
		}
	};
};

export default withClickOutside;

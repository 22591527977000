// tslint:disable: max-line-length

import * as React from 'react';
import layout from '../../../../utils/layout';
import SvgTemplate from '../SvgTemplate';
import { Svg } from '../../Svg';

const icon = ({ color, ...rest }: Svg): JSX.Element => (
	<SvgTemplate {...rest} viewBox="0 0 49 59">
		<g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<g id="upload" fill={color} fillRule="nonzero">
				<path
					d="M12.479,12.019 C12.735,12.019 12.991,11.921 13.186,11.726 L21.499,3.413 L21.499,38.999 C21.499,39.552 21.946,39.999 22.499,39.999 C23.052,39.999 23.499,39.552 23.499,38.999 L23.499,3.413 L31.771,11.685 C32.162,12.076 32.794,12.076 33.185,11.685 C33.576,11.294 33.576,10.662 33.185,10.271 L23.207,0.293 C23.115,0.2 23.004,0.127 22.88,0.076 C22.636,-0.025 22.361,-0.025 22.116,0.076 C21.993,0.127 21.882,0.201 21.79,0.293 L11.772,10.312 C11.381,10.703 11.381,11.335 11.772,11.726 C11.967,11.922 12.223,12.019 12.479,12.019 Z"
					id="Shape"
				/>
				<path
					d="M29.499,15.999 C28.946,15.999 28.499,16.446 28.499,16.999 C28.499,17.552 28.946,17.999 29.499,17.999 L42.499,17.999 L42.499,56.999 L2.499,56.999 L2.499,17.999 L15.499,17.999 C16.052,17.999 16.499,17.552 16.499,16.999 C16.499,16.446 16.052,15.999 15.499,15.999 L0.499,15.999 L0.499,58.999 L44.499,58.999 L44.499,15.999 L29.499,15.999 Z"
					id="Shape"
				/>
			</g>
		</g>
	</SvgTemplate>
);

icon.defaultProps = {
	width: layout.spacing.three,
	color: layout.color.primary
};

export default icon;

import { Action } from '../../../models';
import { LOADED_STATISTICS } from '../../actions/Statistics/constants';
import initialState from '../../store/initialState';

function reducer(options = initialState.statistics, action: Action): any {
	switch (action.type) {
		case LOADED_STATISTICS:
			return action.payload;
		default:
			return options;
	}
}

export default reducer;

import '../../store/initialState';
import initialState from '../../store/initialState';
import { UserEnum } from '../../User/enums';
import {
	Action$ValidateToken,
	Action$Logout,
	Action$Login
} from '../../User/UserActions';

type Actions = Action$ValidateToken | Action$Logout | Action$Login;

function tokenReducer(
	token: string | null = initialState.token,
	action: Actions
): string | null {
	const { type, payload } = action;
	switch (type) {
		case UserEnum.LOGGED_IN:
			return payload && payload.data && payload.data.token;
		case UserEnum.LOGGED_OUT:
			return null;
		default:
			return token;
	}
}

export { tokenReducer };

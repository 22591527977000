// tslint:disable: max-line-length
import * as React from 'react';
import layout from '../../../../utils/layout';
import SvgTemplate from '../SvgTemplate';
import { Svg } from '../../Svg';

const icon = ({ color, ...rest }: Svg): JSX.Element => (
	<SvgTemplate {...rest} viewBox="0 0 18 16">
		<g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<g id="filters" fill={color} fillRule="nonzero">
				<g id="Group">
					<path
						d="M16.9889542,0.0283865672 L0.511045752,0.0283865672 C0.228815359,0.0283865672 0,0.258294776 0,0.541873134 L0,3.875025 C0,4.15860336 0.228815359,4.38851157 0.511045752,4.38851157 L1.75048611,4.38851157 L6.81765114,10.8827146 L6.81765114,14.8580981 C6.81765114,15.0387034 6.91207108,15.2059485 7.0661969,15.298722 C7.14689134,15.347278 7.23767974,15.3715847 7.3286969,15.3715847 C7.41153595,15.3715847 7.49457516,15.3513578 7.57000817,15.3107317 L10.4126144,13.7808164 C10.5786928,13.6913757 10.6823489,13.5174937 10.6823489,13.3281828 L10.6823489,10.8827146 L15.7495139,4.3884541 L16.9889257,4.3884541 C17.271156,4.3884541 17.4999714,4.1585459 17.4999714,3.87496754 L17.4999714,0.541873134 C17.5,0.258294776 17.2711846,0.0283865672 16.9889542,0.0283865672 Z M9.76914624,10.388478 C9.69863154,10.4788955 9.66025735,10.5904881 9.66025735,10.7052985 L9.66025735,13.0206425 L7.83974265,14.0004675 L7.83974265,10.7052698 C7.83974265,10.5904306 7.80142565,10.4788668 7.73085376,10.3884493 L3.04923203,4.38848284 L14.4508252,4.38848284 L9.76914624,10.388478 Z M16.4779085,3.36148097 L1.0220915,3.36148097 L1.0220915,1.05533097 L16.4779085,1.05533097 L16.4779085,3.36148097 Z"
						id="Shape"
					/>
				</g>
			</g>
		</g>
	</SvgTemplate>
);

icon.defaultProps = {
	width: layout.spacing.two,
	color: layout.color.primary
};

export default icon;

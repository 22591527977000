import * as React from 'react';
import './EmailInput.scss';
import { FormikInputText } from '../../../../components/Form';
import {ButtonIcon} from "../../../Button";
import {FieldArrayRenderProps, FormikProps} from "formik";
import {Email} from "../ContactForm";
import Svg from "../../../Svg";

const EmailInput = (props: FieldArrayRenderProps & FormikProps<any> & any): JSX.Element => {
	const { setFieldValue, values, value } = props;
	const setDefaultMail = () => {
		let newEmails = [...values.emails];
		newEmails.forEach((email: Email) => {
			if(email == value){
				email.is_default = true;
			} else {
				email.is_default = false;
			}
		});
		newEmails.sort(function (a, b) {
			return a.is_default > b.is_default ? -1 : 0;
		});
		setFieldValue('emails', newEmails);
		props.setStatus(
			{ dirty: true }
		);
	};
	return (
	<div className={`email-input ${props.value.is_default ? 'default' : ''}`}>
		{!props.value.is_default && (
			<ButtonIcon
				className={'make-default-icon'}
				style={{marginTop: 12}}
				onClick={setDefaultMail}
				icon="favorite-stroke"
			/>
		)}
		{props.value.is_default && (
			<div className={"favorite-static"}>
				<Svg icon="favorite" />
			</div>
		)}
		<div className={`email-input`}>
			<FormikInputText noSpaces name={`${props.name}.email`} flat />
		</div>
	</div>
	);
};

export default EmailInput;

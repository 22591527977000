// tslint:disable: max-line-length

import * as React from 'react';
import layout from '../../../../utils/layout';
import SvgTemplate from '../SvgTemplate';
import { Svg } from '../../Svg';

const icon = ({ color, ...rest }: Svg): JSX.Element => (
	<SvgTemplate {...rest} viewBox="0 0 540 612">
		<g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<g id="new-user" fill={color} fillRule="nonzero">
				<path
					d="M233.272,310.198 C319.449,310.193 350.456,223.907 358.573,153.029 C368.572,65.715 327.282,0 233.272,0 C139.274,0 97.963,65.71 107.97,153.029 C116.095,223.907 147.093,310.204 233.272,310.198 Z"
					id="Shape"
				/>
				<path
					d="M421.707,346.115 C424.48,346.115 427.235,346.198 429.971,346.35 C425.87,340.5 421.123,335.34 415.568,331.192 C399.009,318.833 377.563,314.778 358.604,307.328 C349.375,303.703 341.111,300.102 333.353,296.002 C307.169,324.717 273.024,339.738 233.262,339.742 C193.513,339.742 159.371,324.721 133.19,296.002 C125.432,300.103 117.166,303.703 107.939,307.328 C88.98,314.779 67.535,318.833 50.975,331.192 C22.337,352.567 14.936,400.652 9.121,433.452 C4.322,460.528 1.098,488.159 0.157,515.661 C-0.572,536.964 9.946,539.951 27.768,546.382 C50.083,554.43 73.124,560.405 96.32,565.303 C141.117,574.763 187.293,582.032 233.27,582.357 C255.548,582.198 277.871,580.401 300.062,577.524 C283.631,553.717 273.994,524.879 273.994,493.829 C273.995,412.378 340.258,346.115 421.707,346.115 Z"
					id="Shape"
				/>
				<path
					d="M421.707,375.658 C356.445,375.658 303.536,428.567 303.536,493.829 C303.536,559.091 356.444,612 421.707,612 C486.97,612 539.879,559.091 539.879,493.829 C539.878,428.566 486.969,375.658 421.707,375.658 Z M473.407,514.103 L441.982,514.103 L441.982,545.527 C441.982,556.725 432.905,565.803 421.708,565.803 C410.51,565.803 401.432,556.725 401.432,545.527 L401.432,514.103 L370.008,514.103 C358.81,514.103 349.732,505.026 349.732,493.827 C349.732,482.629 358.809,473.551 370.008,473.551 L401.432,473.551 L401.432,442.127 C401.432,430.929 410.51,421.851 421.708,421.851 C432.906,421.851 441.982,430.929 441.982,442.127 L441.982,473.551 L473.407,473.551 C484.605,473.551 493.683,482.629 493.683,493.827 C493.682,505.027 484.606,514.103 473.407,514.103 Z"
					id="Shape"
				/>
			</g>
		</g>
	</SvgTemplate>
);

icon.defaultProps = {
	width: layout.spacing.two,
	color: layout.color.primary
};

export default icon;

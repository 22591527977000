import {patch} from "../../redux/api/utils";

export type Request$MakeAccountDefault = {
	id: number;
};

export type Response$MakeAccountDefault = any;

/**
 * Make Call Center Account the default
 */
export async function makeAccountDefault (
	data: Request$MakeAccountDefault
): Promise<Response$MakeAccountDefault> {
	const { id } = data;
	return patch(`/sip-account/${id}/make-default`, {  });
}

/*
 * This component is used to show a general form error (usually on top of the submit button)
 * It takes the `FormikProps` as props and shows only if there is a validation error in the `errors` object.
 */

import * as React from 'react';
import { FormError } from '../FormError/FormError';
import { FormikProps } from 'formik';

const FormikSubmitError = (props: FormikProps<any>): JSX.Element | null => {
	const { errors, submitCount } = props;
	let active: boolean = false;
	if (submitCount && errors && typeof errors === 'object') {
		for (const error in errors) {
			if (error) {
				active = true;
				break;
			}
		}
	}
	return active ? (
		<FormError text="Please fill in all the required fields." />
	) : null;
};

export { FormikSubmitError };

export default React.memo(FormikSubmitError);

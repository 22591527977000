// tslint:disable: max-line-length

import * as React from 'react';
import layout from '../../../../utils/layout';
import SvgTemplate from '../SvgTemplate';
import { Svg } from '../../Svg';

const icon = ({ color, ...rest }: Svg): JSX.Element => (
	<SvgTemplate {...rest} viewBox="0 0 81 68">
		<g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<g id="multiple-users-silhouette" fill={color} fillRule="nonzero">
				<path
					d="M48.355,11.922 C52.06,14.245 54.658,18.176 55.131,22.739 C56.642,23.445 58.319,23.851 60.097,23.851 C66.588,23.851 71.849,18.59 71.849,12.1 C71.849,5.609 66.588,0.348 60.097,0.348 C53.668,0.35 48.453,5.517 48.355,11.922 Z M40.656,35.984 C47.147,35.984 52.408,30.722 52.408,24.232 C52.408,17.742 47.146,12.481 40.656,12.481 C34.166,12.481 28.902,17.743 28.902,24.233 C28.902,30.723 34.166,35.984 40.656,35.984 Z M45.641,36.785 L35.669,36.785 C27.372,36.785 20.622,43.536 20.622,51.833 L20.622,64.028 L20.653,64.219 L21.493,64.482 C29.411,66.956 36.29,67.781 41.952,67.781 C53.011,67.781 59.421,64.628 59.816,64.427 L60.601,64.03 L60.685,64.03 L60.685,51.833 C60.688,43.536 53.938,36.785 45.641,36.785 Z M65.084,24.653 L55.189,24.653 C55.082,28.612 53.392,32.177 50.719,34.741 C58.094,36.934 63.49,43.773 63.49,51.851 L63.49,55.609 C73.26,55.251 78.89,52.482 79.261,52.296 L80.046,51.898 L80.13,51.898 L80.13,39.699 C80.13,31.403 73.38,24.653 65.084,24.653 Z M20.035,23.853 C22.334,23.853 24.473,23.182 26.285,22.039 C26.861,18.282 28.875,14.999 31.752,12.763 C31.764,12.543 31.785,12.325 31.785,12.103 C31.785,5.612 26.523,0.351 20.035,0.351 C13.543,0.351 8.283,5.612 8.283,12.103 C8.283,18.591 13.543,23.853 20.035,23.853 Z M30.589,34.741 C27.929,32.19 26.245,28.644 26.122,24.709 C25.755,24.682 25.392,24.653 25.018,24.653 L15.047,24.653 C6.75,24.653 0,31.403 0,39.699 L0,51.896 L0.031,52.084 L0.871,52.349 C7.223,54.332 12.892,55.246 17.816,55.534 L17.816,51.851 C17.818,43.773 23.212,36.936 30.589,34.741 Z"
					id="Shape"
				/>
			</g>
		</g>
	</SvgTemplate>
);

icon.defaultProps = {
	width: layout.spacing.three,
	color: layout.color.primary
};

export default icon;

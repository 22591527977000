import {
	addNewAccount,
	Request$AddAccount,
	Response$AddAccount
} from '../../../api/CallCenterAccounts/AddCallCenterAccount';
import { CCAccountListEnum } from '../enums';

type Action$AddAccount = {
	type: CCAccountListEnum.ADDED_ACCOUNT;
	payload: Response$AddAccount;
};

export type ActionCreator$AddAccount = (
	data: Request$AddAccount
) => Promise<Action$AddAccount>;

export async function addNewAccountActionCreator(
	data: Request$AddAccount
): Promise<Action$AddAccount> {
	const res = await addNewAccount(data);
	return {
		type: CCAccountListEnum.ADDED_ACCOUNT,
		payload: res
	};
}

// tslint:disable: max-line-length
import * as React from 'react';
import layout from '../../../../utils/layout';
import SvgTemplate from '../SvgTemplate';
import { Svg } from '../../Svg';

const icon = ({ color, ...rest }: Svg): JSX.Element => (
	<SvgTemplate {...rest} viewBox="0 0 263 263">
		<g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<g id="profile-default-circle-female" fillRule="nonzero">
				<rect
					id="Rectangle-path"
					fill={color}
					x="0.9"
					y="0.9"
					width="262.1"
					height="261"
					rx="130.5"
				/>
				<g
					id="Group"
					transform="translate(35.000000, 52.000000)"
					fill="#FFFFFF"
				>
					<path
						d="M138.9,39.9 C138.9,63 120.2,81.7 97.1,81.7 C74,81.7 55.3,63 55.3,39.9 C55.3,27.2 58.5,18.6 67.4,11 C74.7,4.7 85.3,1.2 93.5,0.7 C102.2,0.2 117.6,3.1 120,11.1 C132.8,12.3 138.9,27 138.9,39.9 Z"
						id="Shape"
					/>
					<path
						d="M138.9,39.3 C138.9,81.8 125.8,116.3 97.1,116.3 C68.4,116.3 55.3,81.8 55.3,39.3"
						id="Shape"
					/>
					<path
						d="M0.8,210.7 L0.8,186.3 C0.8,156.6 44.7,149 61.3,140.3 C77.9,131.6 75.4,110.8 69.3,100.3 L124.8,100.3 C118.6,110.8 116.1,131.6 132.8,140.3 C149.4,149 193.3,156.6 193.3,186.3 L193.3,210.7"
						id="Shape"
					/>
					<path
						d="M58.2,66.1 C55.3,65 53.3,70.6 54.6,76.2 C55.9,81.8 56.2,89.4 63.9,90.1"
						id="Shape"
					/>
					<path
						d="M134.9,66.1 C137.8,65 139.8,70.6 138.5,76.2 C137.2,81.8 136.9,89.4 129.2,90.1"
						id="Shape"
					/>
					<path
						d="M60.7,18.4 C50.9,32.3 41,54.3 52.3,85.6 C55.6,94.7 47.4,100.4 45.8,101.7 C44.2,102.9 67.9,114.9 96.4,114.9"
						id="Shape"
					/>
					<path
						d="M132.5,18.4 C142.3,32.3 152.2,54.3 140.9,85.6 C137.6,94.7 145.8,100.4 147.4,101.7 C149,102.9 125.3,114.9 96.8,114.9"
						id="Shape"
					/>
					<g transform="translate(59.000000, 106.000000)" id="Shape">
						<path d="M15.7,0.4 C9.3,6.7 0.7,8 0.7,8 C0.7,8 15.2,15.4 27.6,7.7" />
						<path d="M60.4,0.4 C66.8,6.7 75.4,8 75.4,8 C75.4,8 60.9,15.4 48.5,7.7" />
					</g>
				</g>
			</g>
		</g>
	</SvgTemplate>
);

icon.defaultProps = {
	width: layout.spacing.six,
	color: '#9E9BB3'
};

export default icon;

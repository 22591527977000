import { FAQ, Scenario } from '../../models/Helpdesk';
import {deleteData, post, put} from "../../redux/api/utils";

export type Request$EditFAQ = {
	id?: number;
	question: string;
	answer: string;
	language_id: number;
};

export type Response$AddFAQ = { data: FAQ };

export type Response$DeleteFAQ = { data: number };

export type Request$EditScenario = {
	id?: number;
	title: string;
	content: string;
	language_id: number;
};

export type Response$AddScenario = { data: Scenario };

export type Response$DeleteScenario = { data: number };

/**
 * FAQs
 */
export async function addFAQ(
	data: Request$EditFAQ
): Promise<Response$AddFAQ> {
	return post(`/helpdesk/faq/new`, data);
}

export async function editFAQ(
	data: Request$EditFAQ
): Promise<any> {
	const { id, ...rest } = data;
	return put(`/helpdesk/faq/${id}`, rest);
}

export async function deleteFAQ(id: string): Promise<Response$DeleteFAQ> {
	return deleteData(`/helpdesk/faq/${id}/`, {});
}

/**
 *  Scenarios
 */
export async function addScenario(
	data: Request$EditScenario
): Promise<Response$AddScenario> {
	return post(`/helpdesk/scenario/new`, data);
}

export async function editScenario(
	data: Request$EditScenario
): Promise<any> {
	const { id, ...rest } = data;
	return put(`/helpdesk/scenario/${id}`, rest);
}

export async function deleteScenario(id: string): Promise<Response$DeleteScenario> {
	return deleteData(`/helpdesk/scenario/${id}/`, {});
}

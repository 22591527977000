export const ADDED_Contact = 'ADDED_Contact';
export const SEARCHED_Contact = 'SEARCHED_Contact';
export const CHECKED_DUPLICATE = 'CHECKED_DUPLICATE';
export const LOADED_CONTACTS = 'LOADED_CONTACTS';
export const LOADED_CONTACT = 'LOADED_CONTACT';
export const EDITED_CONTACT = 'EDITED_CONTACT';
export const LOADED_CALLS = 'LOADED_CALLS';
export const ADDED_CALL = 'ADDED_CALL';
export const ADDED_CALL_REVIEW = 'ADDED_CALL_REVIEW';
export const UPDATED_CONTACT = 'UPDATED_CONTACT';
export const LOADED_EMAILS = 'LOADED_EMAILS';
export const LOADED_MESSAGES = 'LOADED_MESSAGES';
export const LOADED_VIEW_HISTORY = 'LOADED_VIEW_HISTORY';
export const LOADED_EDIT_HISTORY = 'LOADED_EDIT_HISTORY';
export const DELETED_CONTACT = 'DELETED_CONTACT';

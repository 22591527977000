// tslint:disable: max-line-length

import * as React from 'react';
import layout from '../../../../utils/layout';
import SvgTemplate from '../SvgTemplate';
import { Svg } from '../../Svg';

const icon = ({ color, ...rest }: Svg): JSX.Element => (
	<SvgTemplate {...rest} viewBox="0 0 24 16">
		<g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<g id="gplus" fill={color} fillRule="nonzero">
				<g id="Group">
					<path
						d="M7.83673468,6.78037731 L7.83673468,9.36188991 L12.2812828,9.36188991 C12.1021574,10.4697891 10.9378425,12.6102933 7.83673468,12.6102933 C5.16104956,12.6102933 2.97795918,10.4805454 2.97795918,7.85600756 C2.97795918,5.23146974 5.16104956,3.10172184 7.83673468,3.10172184 C9.35930029,3.10172184 10.3780758,3.72558738 10.9602332,4.26340252 L13.0873469,2.29499915 C11.7215161,1.06878066 9.95265303,0.326595785 7.83673468,0.326595785 C3.50413994,0.326595785 0,3.69331848 0,7.85600756 C0,12.0186967 3.50413994,15.3854193 7.83673468,15.3854193 C12.3596501,15.3854193 15.36,12.3306294 15.36,8.02810841 C15.36,7.53331849 15.3040233,7.1568479 15.2368513,6.78037731 L7.83673468,6.78037731 Z"
						id="Shape"
					/>
					<polyline
						id="Shape"
						points="24 6.7803773 21.8181818 6.7803773 21.8181818 4.62911679 19.6363637 4.62911679 19.6363637 6.7803773 17.4545455 6.7803773 17.4545455 8.93163781 19.6363637 8.93163781 19.6363637 11.0828984 21.8181818 11.0828984 21.8181818 8.93163781 24 8.93163781"
					/>
				</g>
			</g>
		</g>
	</SvgTemplate>
);

icon.defaultProps = {
	width: layout.spacing.three,
	color: layout.color.primary
};

export default icon;

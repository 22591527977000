// tslint:disable: max-line-length
import * as React from 'react';
import layout from '../../../../utils/layout';
import SvgTemplate from '../SvgTemplate';
import { Svg } from '../../Svg';

const icon = ({ color, ...rest }: Svg): JSX.Element => (
	<SvgTemplate {...rest} viewBox="0 0 14 19">
		<g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<g
				id="Messages-Open"
				transform="translate(-688.000000, -409.000000)"
				stroke={color}
				fill={color}
			>
				<g id="Group-8" transform="translate(689.000000, 410.000000)">
					<g id="Group-5">
						<path
							d="M11.8284545,0.891366667 C11.7032727,0.83895 8.73054545,-0.370033333 6.41754545,0.904116667 C4.58481818,1.91476667 1.36772727,1.15656667 0.545454545,0.937266667 L0.545454545,0.283333333 C0.545454545,0.12665 0.423545455,0 0.272727273,0 C0.121909091,0 0,0.12665 0,0.283333333 L0,1.13333333 C0,1.1373 0.00190909091,1.1407 0.00218181818,1.14466667 C0.00218181818,1.14806667 0,1.1509 0,1.1543 L0,9.35 L0,9.6543 L0,16.7166667 C0,16.87335 0.121909091,17 0.272727273,17 C0.423545455,17 0.545454545,16.87335 0.545454545,16.7166667 L0.545454545,10.0229167 C1.16536364,10.1835667 2.51645455,10.4853167 3.91854545,10.4853167 C4.88863636,10.4853167 5.88218182,10.3405333 6.67336364,9.90476667 C8.76027273,8.75415 11.5977273,9.9059 11.6260909,9.91751667 C11.7103636,9.95265 11.8052727,9.94188333 11.8802727,9.88861667 C11.955,9.8362 12,9.74836667 12,9.6543 L12,1.1543 C12,1.03841667 11.9320909,0.934433333 11.8284545,0.891366667 Z M11.4545455,9.25508333 C10.5654545,8.96098333 8.27836364,8.37788333 6.41754545,9.40383333 C4.58509091,10.4142 1.36909091,9.65656667 0.545454545,9.43726667 L0.545454545,9.35 L0.545454545,1.52291667 C1.59627273,1.7952 4.74027273,2.4701 6.67336364,1.40476667 C8.46409091,0.417916667 10.8073636,1.12568333 11.4545455,1.35348333 L11.4545455,9.25508333 Z"
							id="Shape"
						/>
					</g>
				</g>
			</g>
		</g>
	</SvgTemplate>
);

icon.defaultProps = {
	width: layout.spacing.two,
	color: layout.color.primary
};

export default icon;

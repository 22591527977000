// tslint:disable: max-line-length

import * as React from 'react';
import layout from '../../../../utils/layout';
import SvgTemplate from '../SvgTemplate';
import { Svg } from '../../Svg';

const icon = ({ color, ...rest }: Svg): JSX.Element => (
	<SvgTemplate {...rest} viewBox="0 0 50 60">
		<g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<g id="hourglass" fill={color} fillRule="nonzero">
				<path
					d="M49,58 L46,58 L46,54 L41,54 L41,43.778 C41,41.078 39.658,38.57 37.411,37.072 L26.803,30 L37.411,22.928 C39.658,21.43 41,18.922 41,16.222 L41,6 L46,6 L46,2 L49,2 C49.552,2 50,1.553 50,1 C50,0.447 49.552,0 49,0 L46,0 L45,0 L5,0 L4,0 L1,0 C0.448,0 0,0.447 0,1 C0,1.553 0.448,2 1,2 L4,2 L4,6 L9,6 L9,16.222 C9,18.922 10.342,21.43 12.589,22.928 L23.197,30 L12.589,37.072 C10.342,38.57 9,41.078 9,43.778 L9,54 L4,54 L4,58 L1,58 C0.448,58 0,58.447 0,59 C0,59.553 0.448,60 1,60 L4,60 L5,60 L45,60 L46,60 L49,60 C49.552,60 50,59.553 50,59 C50,58.447 49.552,58 49,58 Z M13.698,21.264 C12.009,20.137 11,18.252 11,16.222 L11,6 L39,6 L39,16.222 C39,18.252 37.991,20.137 36.302,21.264 L25,28.798 L13.698,21.264 Z M11,43.778 C11,41.748 12.009,39.863 13.698,38.736 L25,31.202 L36.302,38.736 C37.991,39.863 39,41.748 39,43.778 L39,54 L11,54 L11,43.778 Z"
					id="Shape"
				/>
				<path
					d="M15.917,17.936 C15.343,17.553 15,16.912 15,16.222 L15,14 C15,13.447 14.552,13 14,13 C13.448,13 13,13.447 13,14 L13,16.222 C13,17.583 13.676,18.846 14.808,19.6 L19.446,22.692 C19.616,22.805 19.809,22.86 20,22.86 C20.323,22.86 20.64,22.704 20.833,22.415 C21.139,21.955 21.015,21.335 20.556,21.028 L15.917,17.936 Z"
					id="Shape"
				/>
				<path
					d="M35.192,41.26 L30.554,38.168 C30.094,37.861 29.474,37.985 29.167,38.445 C28.861,38.905 28.985,39.525 29.444,39.832 L34.082,42.924 C34.657,43.307 35,43.947 35,44.638 L35,46.86 C35,47.413 35.448,47.86 36,47.86 C36.552,47.86 37,47.413 37,46.86 L37,44.638 C37,43.276 36.324,42.014 35.192,41.26 Z"
					id="Shape"
				/>
			</g>
		</g>
	</SvgTemplate>
);

icon.defaultProps = {
	width: layout.spacing.two,
	color: layout.color.primaryLight
};

export default icon;

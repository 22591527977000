import * as React from 'react';
import { hot } from 'react-hot-loader';
import { CSSTransition } from 'react-transition-group';
import Svg from '../Svg';
import './Snackbar.scss';
import { errorSubject } from '../../utils/streams';

class SnackBar extends React.Component<{}> {
	state = {
		error: '',
		errorActive: false
	};

	errorSubscription: any;

	componentDidMount() {
		this.errorSubscription = errorSubject.subscribe(error => {
			this.setState({ error, errorActive: true });
			this.clearError(5000);
		});
	}

	componentWillUnmount() {
		this.errorSubscription.unsubscribe();
	}

	clearError = (timeout: number = 0) => {
		setTimeout(() => {
			this.setState({
				errorActive: false
			});
		}, timeout);
	};

	render() {
		const { error, errorActive } = this.state;
		return (
			<CSSTransition
				in={errorActive}
				timeout={300}
				classNames="snackbar"
				unmountOnExit
			>
				<div className="snackbar box-shadow danger">
					<div>{error}</div>
					<div className="close" onClick={() => this.clearError()}>
						<Svg icon="close" color="#fff" />
					</div>
				</div>
			</CSSTransition>
		);
	}
}

export default hot(module)(SnackBar);

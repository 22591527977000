import * as React from 'react';
import { icons, Icons } from './icons';

export interface Svg {
	icon?: Icons;
	width?: string | number;
	height?: string | number;
	color?: string;
}

const icon = (props: Svg) => {
	const { icon } = props;
	if (!icon) {
		return null;
	}
	if (!icons || !icons.hasOwnProperty(icon)) {
		return null;
	}
	return React.createElement(icons[icon], { ...props });
};

export default icon;

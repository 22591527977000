import { Action } from '../../../models';
import initialState from '../../store/initialState';
import { LOADED_CONTACTS } from '../../actions/Contact/constants';

function reducer(contactList = initialState.contactList, action: Action): any {
	switch (action.type) {
		case LOADED_CONTACTS:
			return action.payload.contactList;
		default:
			return contactList;
	}
}

export default reducer;

import * as React from 'react';
import './ContactForm.scss';
import {
	FormikForm,
	FormikInputText,
	FormikInputSelect,
	FormikInputTextarea,
	FormikButtonSubmit,
	FormikMultiSelectOnForm,
	FormGroup,
	formikSubmit
} from '../../../components/Form';
import {
	FormikActions,
	FormikProps,
	FieldArray,
	FieldArrayRenderProps
} from 'formik';
import { Store } from '../../../models';
import { connect } from 'react-redux';
import { getOptions } from '../../../redux/actions';
import Dialog from '../../../components/Dialog';
import Yup from '../../../utils/yup';
import Label from '../../../components/Form/Label';
import Boats from './Boats';
import Locations from './Locations';
import PhoneInput from './PhoneInput';
import InputText from '../../../components/Form/InputText';
import Multiple from '../../../components/Form/Mulitple';
import FormError from '../../../components/Form/FormError';
import moment from 'moment';
import { Prompt } from 'react-router';
import env from '../../../config/env';
import EmailInput from "./EmailInput/EmailInput";

function hasDifferentCountryCode(country_id: number, phones: Phone[]) {
	for (const phone of phones) {
		if (phone.country_id != country_id) {
			return true;
		}
	}
	return false;
}

export interface Phone {
	country_id: number;
	phone: number;
	is_default: boolean;
	comment: string;
}

export interface Email {
	email: number;
	is_default: boolean;
}

export interface Boat {
	count: string | number;
	type: number;
}

export interface Location {
	name: string;
	place_id: string;
	latlng: string;
	is_default: boolean;
}

export interface Values {
	contact_name: string;
	emails: Email[];
	phones: Phone[];
	links: string[];
	boats: Boat[];
	locations: Location[];
	country_id: number;
	locale: string;
	second_langs: string[];
	region_id: number;
	yachts: string | number;
	marina: string;
	comments: string;
	address: string;
}

const boatTypeValidation = Yup.object().shape({
	type: Yup.string().required(),
	count: Yup.number()
		.typeError('Must be a number.')
		.required()
		.min(1)
});

const emailValidation = Yup.object().shape({
	email: Yup.string()
		.required()
		.email(),
	is_default: Yup.boolean().nullable()
});

const phoneValidation = Yup.object().shape({
	phone: Yup.string()
		.required()
		.min(5)
		.max(50),
	country_id: Yup.string().required(),
	is_default: Yup.boolean().nullable(),
	comment: Yup.string().nullable()
});

const validationSchema = Yup.object().shape({
	contact_name: Yup.string()
		.required()
		.min(4)
		.max(120),
	locale: Yup.string().min(2).max(5).nullable().required(),
	second_langs: Yup.array().of(
		Yup.string()
	),
	emails: Yup.array().of(emailValidation),
	country_id: Yup.string().required(),
  	region_id: Yup.string().nullable(),
	phones: Yup.array().of(phoneValidation),
	boats: Yup.array().of(boatTypeValidation),
	marina: Yup.string().max(100),
	address: Yup.string().max(100),
	comments: Yup.string().max(10000),
	links: Yup.array().of(
		Yup.string()
			.required()
			.min(5)
	)
});

const ContactForm = (props: any): JSX.Element => {
	const { contact, onSubmit, onSuccess, user } = props;

	React.useEffect(() => {
		const { getOptions } = props;
		getOptions && getOptions();
	}, []);

	const submit = (values: Values, actions: FormikActions<Values>) => {
		actions.setError(null);
		if (
			(!values.emails || !values.emails.length) &&
			(!values.phones || !values.phones.length)
		) {
			actions.setError('Email or phone number is required.');
			actions.setSubmitting(false);
			return;
		}
		values.phones = values.phones.map((phone: any) => {
			return {
				country_id: phone.country_id,
				is_default: phone.is_default,
				phone: phone.phone,
				comment: phone.comment && phone.comment.trim() != '' ? phone.comment : null
			}
		});
		if (env.backOffice === 'Owners' && (!values.links || !values.links.length)) {
			actions.setError('At least 1 url is required.');
			actions.setSubmitting(false);
			return;
		}
		if (hasDifferentCountryCode(values.country_id, values.phones)) {
			if (
				!window.confirm(
					'Country phone code does not match the Country of this Contact. Continue?'
				)
			) {
				actions.setSubmitting(false);
				return;
			}
		}

    if (values.country_id == 226 && !values.region_id) {
			actions.setError('Select a region.');
			actions.setSubmitting(false);
			return;
		}
		onSubmit &&
			formikSubmit(values, actions, onSubmit).then(res => {
				onSuccess && onSuccess(res);
			});
	};

	return (
		<Dialog
			className="dialog--contact-form"
			title={ contact
					? `Contact - ID: ${contact.id}` : 'Contact Form' }
			description={ contact
					? `Created ${contact.created_by_user &&
							'by ' + contact.created_by_user.display_name} at ${moment(
							contact.created_at
					  ).format('DD-MM-YYYY')}` : '' }
						>
			<FormikForm
				validationSchema={validationSchema}
				initialValues={props.initialValues}
				onSubmit={submit}
				enableReinitialize={true}
				render={(formProps: FormikProps<Values>) => (
					<Form {...formProps} {...props} />
				)}
			/>
		</Dialog>
	);
};
const Form = (props: any) => {
  	const { dirty } = props;
  	window.onbeforeunload = function() {
		return dirty ? ' ' : null;
  	}
	const { handleSubmit, options, error, values, user } = props;
	let inputTile;
	switch(env.backOffice) {
		case 'Owners': {
			inputTile = 'Yachts from each type:';
			break;
		}
		case 'Guests': {
			inputTile = 'Yachts from each type:';
			break;
		}
		case 'Collaborators': {
			inputTile = 'Collaborator type:';
			break;
		}
		default: {
			inputTile = 'Types:';
			break;
		}
	}

	return (
		<form onSubmit={handleSubmit}>
			<FormGroup double>
				<FormikInputText label="Contact name:" name="contact_name" flat />
			</FormGroup>
			{props && props.initialValues.comments && (
				<FormikInputTextarea name="comments" label="Comments:" flat resize />
				)}
			<Multiple
				component={
					<EmailInput
						flat
						{...props}
					/>}
				name="emails"
				className={"scroll"}
				label="Emails:"
				insertValue={{
					email: '',
					is_default: values.emails.length > 0 ? false : true
				}}
				noError
			/>
			<Multiple
				component={
					<InputText noSpaces flat />
				}
				name="links"
				className={"scroll"}
				label="URLs:"
				insertValue={''}
				showLink={true}
			/>
			<Multiple
				component={
					<PhoneInput
						flat
						{...props}
					/>}
				name="phones"
				className={"scroll phones-container"}
				label="Phones:"
				insertValue={{
					country_id: (user && user.default_country_phone_id) || '',
					phone: '',
					is_default: values.phones.length > 0 ? false : true,
				}}
				noError
			/>

			<FormGroup double>
				{options && (
					<FormikInputSelect fullWidth name="locale" label="Main language:" flat>
						{options.languages &&
							options.languages.map((locale: any) => {
								return (
									<option key={locale.id} value={locale.country_iso}>
										{locale.language}
									</option>
								);
							})}
					</FormikInputSelect>
				)}
				{options && (
					<FormikMultiSelectOnForm
					  	name="second_langs"
						label="Second languages:"
					  	optionsArray={options.languages}
						optionsKey="id"
						optionsValue="country_iso"
						optionsText="language"
					>
					</FormikMultiSelectOnForm>
				)}
			</FormGroup>

			<FormGroup double>
				{options && (
					<FormikInputSelect fullWidth name="country_id" label="Country:" flat>
						{options.countries &&
							options.countries.map((country: any) => {
								return (
									<option key={country.id} value={country.id}>
										{country.country}
									</option>
								);
							})}
					</FormikInputSelect>
				)}
				{options && (
					<FormikInputSelect
						fullWidth
						name="region_id"
						label="Region:"
						flat
						number
					>
						<option value={null as any}>-</option>
						{options.regions &&
							options.regions.map((region: any) => {
								return (
									<option key={region.id} value={region.id}>
										{region.region}
									</option>
								);
							})
						}
					</FormikInputSelect>
				)}
			</FormGroup>
			<FormGroup>
				<FormikInputText name="address" label="Full Address:" flat />
			</FormGroup>
			{env.backOffice === 'Owners' && (
				<FormGroup double>
					<FormikInputText name="yachts" label="Total yachts:" flat type="number" />
					<FormikInputText name="marina" label="Marina name:" flat />
				</FormGroup>
			)}
			<Label title={"Locations:"} />
			{values.locations && (
				<FieldArray
					name="locations"
					render={(fieldArrayProps: FieldArrayRenderProps) => (
						<Locations {...fieldArrayProps} {...props} />
					)}
				/>
			)}
			<Label title={inputTile} />
			{options && values.boats && (
				<FieldArray
					name="boats"
					render={(fieldArrayProps: FieldArrayRenderProps) => (
						<Boats {...fieldArrayProps} {...props} />
					)}
				/>
			)}
			{props && !props.initialValues.comments && (
				<FormikInputTextarea name="comments" label="Comments:" flat resize />
				)}
			{error && <FormError text={error} />}
			<FormikButtonSubmit {...props} />
      <Prompt when={dirty} message="You have unsaved changes. Are you sure you want to leave?" />
		</form>
	);
};

const mapStateToProps = (store: Store) => {
	const { options, user } = store;
	return { options, user };
};

const mapDispatchToProps = (dispatch: any) => {
	return {
		getOptions: () => dispatch(getOptions())
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ContactForm);

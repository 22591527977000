import * as React from 'react';

import Popup from '../Popup';
import { hot } from 'react-hot-loader';
import {FormikForm, FormikButtonSubmit, FormikInputText, FormikInputSelect} from '../../Form';
import {FormikProps, FormikActions} from 'formik';
import * as Yup from 'yup';
import {Contact} from "../../../models/Contact";
import {
	listContacts,
	Pagination$ListContacts
} from '../../../redux/actions/Contact';
import {popups, pushPopup} from "../../../utils/popups";
import {mergeContacts} from "../../../api/Contacts/MergeContacts";
declare const window: any;

interface Props {
	active: boolean;
	targetContact: Contact;
	sourceContact?: Contact;
	canCommit?: boolean;
	setSubmitting: (b: boolean) => any;
	setErrors: (b: any) => any;
	onSubmit: () => any;
}

type Values = {
	search_type: string;
	search_value: string;
};

const validationSchema = Yup.object().shape({
	search_type: Yup.string().required(),
	search_value: Yup.string().required()
});

const MergeContact = (props: Props): JSX.Element => {
	const { active, targetContact, onSubmit, canCommit } = props;

	const initialValues = {
		search_type: 'id',
		search_value: ''
	};

	const handleSearch = (values: Values, props: Props) => {
		const columns: {
			id: string;
			value: string;
		}[] = [];
		props.targetContact = targetContact;
		if(values.search_value && values.search_value.toString().trim().length > 0) {
			if(values.search_type == 'id' && targetContact.id == values.search_value.toString()) {
				setTimeout(function() {
					props.setSubmitting(false);
					props.sourceContact = undefined;
					props.setErrors({'search_value': 'You cannot merge a contact to itself'});
				}, 500);
				values.search_value = undefined;
				return;
			}
			columns.push({ id: values.search_type, value: values.search_value.toString().trim()});
		} else {
			setTimeout(function() {
				props.setSubmitting(false);
			}, 500);
			return;
		}

		const data: Pagination$ListContacts = {
			page: 1,
			limit: 10000,
			filters: {
				mergeSearch: true
			},
			sorting: [],
			columns: columns
		};

		listContacts(data)
			.then(res => {
				const { payload } = res;
				setTimeout(function() {
					props.setSubmitting(false);
				}, 500);
				if(payload.contactList.length > 0){
					props.sourceContact = payload.contactList[0];
					const confirmMessage = 	<div className="confirm-contact-merge">
												<strong>From: </strong>
												<p>ID: {payload.contactList[0].id}</p>
												<p>Name: {payload.contactList[0].contact_name}</p>
												<br></br>
												<strong>To: </strong>
												<p>ID: {targetContact.id}</p>
												<p>Name: {targetContact.contact_name}</p>
											</div>;

					pushPopup(popups.Confirm, {
						title: 'Confirm Merge',
						onConfirm: onConfirm,
						onCancel: onCancel,
						cancelText: 'Back',
						description: 'Are you sure that you want to merge these contacts? **Contact ' + payload.contactList[0].id + ' will be deleted**',
						htmlDescription: confirmMessage,
						checkboxText: canCommit ? 'Commit Merge' : undefined,
						inputLabel: 'Comment'
					});
				} else {
					let error = 'Contact ID ' + data.columns[0].value + ' not found!';
					props.sourceContact = undefined;
					props.setErrors({'contact_id': error});
					values.search_value = undefined;
				}
			})
			.catch(() => {
				props.setSubmitting(false);
			})
			.then(res => {
				props.setSubmitting(false);
			});

		const onCancel = () => {
			window.popup.pop();
		};

		const onConfirm = (value?: any, text?: any) => {
			if(!props.sourceContact || !props.targetContact) {
				window.popup.pop();
				window.popup.pop();
				alert('Contacts merge failed, please try again.');
				return;
			}
			let data;
			if(canCommit){
				data = {
					from_user_id: parseInt(props.sourceContact.id),
					to_user_id: parseInt(props.targetContact.id),
					commit_merge: value,
					comment: text
				};
			} else {
				data = {
					from_user_id: parseInt(props.sourceContact.id),
					to_user_id: parseInt(props.targetContact.id),
					comment: text
				};
			}

			mergeContacts(data)
				.then(res => {
					window.popup.pop();
					window.popup.pop();
					onSubmit && onSubmit();
				})
				.catch(() => {
					window.popup.pop();
					window.popup.pop();
					alert('Contacts merge failed, please try again.');
				})
				.then(() => {});
		};
	};

	return (
		<Popup
			active={active}
			title={`Merge from Contact`}
			className="popup--merge-contact"
		>
			<FormikForm
				onSubmit={handleSearch}
				validationSchema={validationSchema}
				initialValues={initialValues}
				render={(formikProps: FormikProps<Values>) => (
					<FormSearch {...props} {...formikProps}/>
				)}
			/>
		</Popup>
	);
};

const FormSearch = (props: Props & FormikProps<Values>) => {
	const { handleSubmit, setSubmitting} = props;

	return (
		<form onSubmit={handleSubmit} >
			<FormikInputSelect fullWidth label={'Search by:'} name="search_type" flat>
				<option value={'id'}>ID</option>
				<option value={'emails'}>Email</option>
				<option value={'phone'}>Phone</option>
			</FormikInputSelect>
			<FormikInputText name="search_value" flat />
			<FormikButtonSubmit title="Search" {...props} setSubmitting={setSubmitting} />
		</form>
	);
};

export default hot(module)(MergeContact);

// tslint:disable: max-line-length

import * as React from 'react';
import layout from '../../../../utils/layout';
import SvgTemplate from '../SvgTemplate';
import { Svg } from '../../Svg';

const icon = ({ color, ...rest }: Svg): JSX.Element => (
	<SvgTemplate {...rest} viewBox="0 0 96.26 96.261">
		<g>
			<g id="motor">
				<polygon
					className="fill" // to target with css and fill with animnation
					fill={color}
					points="
				69.213,71.402 47.963,61.102 26.763,71.542 30.713,83.783 65.213,83.783"
					stroke="#8B8AA0"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeMiterlimit="10"
					strokeWidth="2"
				/>
				<polygon
					className="fill" // to target with css and fill with animnation
					fill={color}
					points="
				65.233,57.218 59.603,35.496 36.323,35.496 30.693,57.222 47.963,47.552"
					stroke="#8B8AA0"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeMiterlimit="10"
					strokeWidth="2"
				/>
			</g>
			<polyline
				fill="#fff"
				points="
		43.353,27.174 42.379,35.496 53.546,35.493 52.577,27.175 "
				stroke="#8B8AA0"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeMiterlimit="10"
				strokeWidth="2"
			/>
			<polygon
				fill="#fff"
				points="
		72.483,61.283 69.213,71.402 47.963,61.102 26.763,71.542 23.443,61.283 47.963,47.552 "
				stroke="#8B8AA0"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeMiterlimit="10"
				strokeWidth="2"
			/>
			<line
				fill="#fff"
				stroke="#8B8AA0"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeMiterlimit="10"
				strokeWidth="2"
				x1="57.468"
				x2="38.458"
				y1="19.977"
				y2="19.977"
			/>
			<line
				fill="#fff"
				stroke="#8B8AA0"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeMiterlimit="10"
				strokeWidth="2"
				x1="34.262"
				x2="61.663"
				y1="26.912"
				y2="26.912"
			/>
			<line
				fill="#fff"
				stroke="#8B8AA0"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeMiterlimit="10"
				strokeWidth="2"
				x1="39.813"
				x2="56.11"
				y1="41.846"
				y2="41.846"
			/>
			<path
				d="
		M45.427,16.378c0-1.989-1.614-3.603-3.604-3.603c-1.99,0-3.605,1.614-3.605,3.603v3.599h7.209V16.378z"
				fill="#fff"
				stroke="#8B8AA0"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeMiterlimit="10"
				strokeWidth="2"
			/>
			<path
				d="
		M57.708,16.378c0-1.989-1.616-3.603-3.605-3.603c-1.99,0-3.604,1.614-3.604,3.603v3.599h7.21V16.378z"
				fill="#fff"
				stroke="#8B8AA0"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeMiterlimit="10"
				strokeWidth="2"
			/>
			<line
				fill="#fff"
				stroke="#8B8AA0"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeMiterlimit="10"
				strokeWidth="2"
				x1="47.963"
				x2="47.963"
				y1="26.552"
				y2="19.977"
			/>
			<line
				fill="#fff"
				stroke="#8B8AA0"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeMiterlimit="10"
				strokeWidth="2"
				x1="47.962"
				x2="47.962"
				y1="61.106"
				y2="83.787"
			/>
		</g>
	</SvgTemplate>
);

icon.defaultProps = {
	width: layout.spacing.four,
	color: layout.color.white
};

export default icon;

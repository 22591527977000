import {
	Action$Login,
	Action$Logout,
	LoginFormData,
	ValidateTokenData,
	FormData$UpdateProfile,
	FormData$ChangePassword,
	FormData$SuperAdminChangePassword,
	FormData$AddUser,
	FormData$ChangeRole,
	FormData$ChangeUserStatus
} from './models';

import { get, post, patch, deleteData } from '../../api/utils';
import { Action } from '../../../models';
import initialState from '../../store/initialState';

interface LoginAction {
	type: typeof User.actionTypes.LOGGED_IN;
	payload: any;
}

class User {
	static actionTypes = {
		LOGGED_IN: 'LOGGED_IN',
		LOGGED_OUT: 'LOGGED_OUT',
		UPDATED_PROFILE: 'UPDATED_PROFILE',
		LOADED_PROFILE: 'LOADED_PROFILE',
		CHANGED_PASSWORD: 'CHANGED_PASSWORD',
		LOADED_USERS: 'LOADED_USERS',
		ADDED_USER: 'ADDED_USER',
		CHANGED_USER_ROLE: 'CHANGED_USER_ROLE',
		CHANGED_USER_STATUS: 'CHANGED_USER_STATUS'
	};

	id!: number;
	user_email!: string;
	display_name!: string;
	role_id!: number;
	token!: string;
	user_phone!: string;
	user_app_phone!: string;
	user_job_title!: string;
	default_country_id!: number;
	default_region_id!: number;
	default_country_phone_id!: number;
	default_main_language!: string;
	is_active!: boolean;
	sip_account_id!: number;
	extra_data?: any;
	/**
	 * Action that updates user details.
	 * Must be dispatched!
	 */
	// public changePasswordAction = async (
	// 	data: FormData$ChangePassword
	// ): Promise<Action> => {
	// 	const res = await this.changePassword(data);
	// 	return {
	// 		type: User.actionTypes.CHANGED_PASSWORD,
	// 		payload: res.data
	// 	};
	// };

	// private changePassword = async (
	// 	data: FormData$ChangePassword
	// ): Promise<any> => {
	// 	return post('/profile/change-password', data);
	// };

	private list = async () => {
		return get('/user/list');
	};

	public listAction = async () => {
		const res = await this.list();
		return {
			type: User.actionTypes.LOADED_USERS,
			payload: res.data
		};
	};

	public changeUserStatusAction = async (
		data: FormData$ChangeUserStatus
	): Promise<any> => {
		const res = await this.changeUserStatus(data);
		return {
			type: User.actionTypes.CHANGED_USER_STATUS,
			payload: res.data
		};
	};

	private changeUserStatus = async (
		data: FormData$ChangeUserStatus
	): Promise<any> => {
		const { id, status } = data;
		return patch(`/user/${id}/active-status`, { status });
	};

	/**
	 * User reducer.
	 */
	static userListReducer = function userReducer(
		userList: any = initialState.userList,
		action: Action
	) {
		const list = [...userList];
		const { type, payload } = action;
		switch (type) {
			case User.actionTypes.LOADED_USERS:
				return payload;
			case User.actionTypes.ADDED_USER:
				list.push(payload);
				return list;
			case User.actionTypes.CHANGED_USER_ROLE:
				return list.map((user: User) => {
					if (user.id === payload.id) {
						user.role_id = payload.role_id;
					}
					return user;
				});
			case User.actionTypes.CHANGED_USER_STATUS:
				return list.map((user: User) => {
					if (user.id === payload.id) {
						user.is_active = payload.is_active;
					}
					return user;
				});
			default:
				return userList;
		}
	};
}

export default User;

import { UserEnum } from '../enums';
import { Action } from '../../models';
import {
	Request$ChangePassword,
	changePassword,
	Response$ChangePassword
} from '../../../api/User';

export type Action$ChangePassword = Action<UserEnum.CHANGED_PASSWORD, any>;

export type ActionCreator$ChangePassword = (
	data: Request$ChangePassword
) => Promise<Action$ChangePassword>;

const changePasswordActionCreator: ActionCreator$ChangePassword = async (
	data: Request$ChangePassword
): Promise<Action$ChangePassword> => {
	const response: Response$ChangePassword = await changePassword(data);
	return {
		type: UserEnum.CHANGED_PASSWORD,
		payload: response
	};
};

export default changePasswordActionCreator;

/*
 * This component is an extension of the <Button /> component
 * You must spread the Formik form's props. ex <FormikButtonSubmit {...formProps} />
 * It automatically shows loading state, Form validation errors, and server errors.
 */

import * as React from 'react';
import './FormikButtonSubmit.scss';
import {FormikProps} from 'formik';
import Button, {ButtonProps} from '../../Button';
import Svg from '../../Svg';
import layout from '../../../utils/layout';
import FormikSubmitError from '../FormikSubmitError';
import FormError from '../FormError';

interface Props {
    title?: string;
    dataCy?: string;
    className?: string;
}

export const FormikButtonSubmit = (
    props: Props & ButtonProps & FormikProps<any>
) => {
    const {
        status,
        isSubmitting,
        handleSubmit,
        title,
        dirty,
        fullWidth,
        dataCy,
        className,
        ...rest
    } = props;

    return (
        <div className={`formik-submit ${className}`}>
            <FormikSubmitError {...props} />
            <FormError text={status && status.error}/>
            {(dirty || status) && (
                <Button
                    fullWidth={fullWidth}
                    type="submit"
                    className="formik-submit__btn--submit"
                    loading={isSubmitting}
                    disabled={isSubmitting}
                    onClick={() => {
                        handleSubmit && handleSubmit()
                    }}
                    {...rest}
                    dataCy={dataCy}
                >
                    {dirty && <>{title ? title : 'Save'}</>}

                    {/* Success Button */}
                    {!dirty && status && status.success && !status.dirty && (
                        <>
                            Saved
                            <Svg icon="check" width={20} color={layout.color.success}/>
                        </>
                    )}

                    {/* Error Button */}
                    {!dirty && status && status.error && (
                        <>
                            Retry
                            <Svg icon="close" width={14} color={layout.color.danger}/>
                        </>
                    )}
                    {!dirty && status && status.dirty && (
                        <>
                            {title ? title : 'Save'}
                        </>
                    )}
                </Button>
            )}

            {/* no Changes Button */}
            {!dirty && !status && (
                <div style={{opacity: '0.5'}}>
                    <Button fullWidth={fullWidth}
                            className="formik-submit__btn--submit">{title ? title : 'Save'}</Button>
                </div>
            )}
        </div>
    );
};

export default FormikButtonSubmit;

import * as React from 'react';
import { LatLng } from '../../../../models';
import env from '../../../../config/env';

interface Props {
	map: any;
	position: LatLng;
	draggable?: boolean;
	blue?: boolean;
	red?: boolean;
	yellow?: boolean;
	onDragEnd?: () => any;
}

export class Marker extends React.Component<any> {
	static defaultProps = {
		draggable: false
	};

	marker: any;

	componentDidMount() {
		const { map } = this.props;
		if (!map) {
			console.warn('no map found for marker.');
			return;
		}
		this.createMarker();
	}

	componentWillUnmount() {
		this.marker.setMap(null);
	}

	componentDidUpdate(prevProps: Props) {
		// change icon color on update
		if (
			prevProps.blue !== this.props.blue ||
			prevProps.red !== this.props.red ||
			prevProps.yellow !== this.props.yellow
		) {
			this.marker.setIcon(this.getIcon());
		}
		if (
			this.props.position &&
			JSON.stringify(prevProps.position) !== JSON.stringify(this.props.position)
		) {
			this.marker.setPosition(this.props.position);
		}
	}

	createMarker = () => {
		const { map, position, draggable, onDragEnd } = this.props;

		// Marker Initialization
		this.marker = new google.maps.Marker({
			map,
			position,
			draggable,
			icon: this.getIcon()
		});

		// Drag End Listener
		if (onDragEnd) {
			this.marker.addListener('dragend', (e: any) => {
				onDragEnd({ lat: e.latLng.lat(), lng: e.latLng.lng() });
			});
		}
	};

	getIcon = () => {
		const { blue, red, yellow } = this.props;
		const mediaPath = env.bucketUrl;
		if (blue) {
			return `${mediaPath}/static/img/svg/marker-blue.svg`;
		}
		if (red) {
			return `${mediaPath}/static/img/svg/marker-red.svg`;
		}
		if (yellow) {
			return `${mediaPath}/static/img/svg/marker-yellow.svg`;
		}
		return `${mediaPath}/static/img/svg/marker-red.svg`;
	};

	render(): any {
		return null;
	}
}

export default Marker;

import {
	addFAQ,
	addScenario,
	deleteScenario,
	editFAQ,
	editScenario,
	deleteFAQ,
	Response$AddFAQ,
	Response$AddScenario,
	Request$EditFAQ,
	Request$EditScenario, Response$DeleteScenario, Response$DeleteFAQ
} from '../../../api/Helpdesk/Api';
import { HelpdeskEnum } from '../enums';

export type Action$AddFAQ = {
	type: HelpdeskEnum.ADDED_FAQ;
	payload: Response$AddFAQ;
};

export type Action$EditFAQ = {
	type: HelpdeskEnum.UPDATED_FAQ;
	payload: Response$AddFAQ;
};

export type Action$DeleteFAQ = {
	type: HelpdeskEnum.DELETED_FAQ;
	payload: Response$DeleteFAQ;
};

export type Action$AddScenario = {
	type: HelpdeskEnum.ADDED_SCENARIO;
	payload: Response$AddScenario;
};

export type Action$EditScenario = {
	type: HelpdeskEnum.UPDATED_SCENARIO;
	payload: Response$AddScenario;
};

export type Action$DeleteScenario = {
	type: HelpdeskEnum.DELETED_SCENARIO;
	payload: Response$DeleteScenario;
};

export type ActionCreator$AddFAQ = (
	data: Request$EditFAQ
) => Promise<Action$AddFAQ>;

export async function addFAQActionCreator(
	data: Request$EditFAQ
): Promise<Action$AddFAQ> {
	const res = await addFAQ(data);
	return {
		type: HelpdeskEnum.ADDED_FAQ,
		payload: res
	};
}

export type ActionCreator$EditFAQ = (
	data: Request$EditFAQ
) => Promise<Action$EditFAQ>;

export async function editFAQActionCreator(
	data: Request$EditFAQ
): Promise<Action$EditFAQ> {
	const res = await editFAQ(data);
	return {
		type: HelpdeskEnum.UPDATED_FAQ,
		payload: res
	};
}

export const deleteFAQCreator = async (id: string): Promise<Action$DeleteFAQ> => {
	const res = await deleteFAQ(id);
	return {
		type: HelpdeskEnum.DELETED_FAQ,
		payload: res
	};
};

export type ActionCreator$AddScenario = (
	data: Request$EditScenario
) => Promise<Action$AddScenario>;

export async function addScenarioActionCreator(
	data: Request$EditScenario
): Promise<Action$AddScenario> {
	const res = await addScenario(data);
	return {
		type: HelpdeskEnum.ADDED_SCENARIO,
		payload: res
	};
}

export type ActionCreator$EditScenario = (
	data: Request$EditScenario
) => Promise<Action$EditScenario>;

export async function editScenarioActionCreator(
	data: Request$EditScenario
): Promise<Action$EditScenario> {
	const res = await editScenario(data);
	return {
		type: HelpdeskEnum.UPDATED_SCENARIO,
		payload: res
	};
}

export const deleteScenarioCreator = async (id: string): Promise<Action$DeleteScenario> => {
	const res = await deleteScenario(id);
	return {
		type: HelpdeskEnum.DELETED_SCENARIO,
		payload: res
	};
};

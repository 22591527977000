// tslint:disable: max-line-length

import * as React from 'react';
import layout from '../../../../utils/layout';
import SvgTemplate from '../SvgTemplate';
import { Svg } from '../../Svg';

const icon = ({ color, ...rest }: Svg): JSX.Element => (
	<SvgTemplate {...rest} viewBox="0 0 24 21">
		<g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<g id="messages" fill={color} fillRule="nonzero">
				<path
					d="M10,0 C15.522875,0 20,3.5665957 20,7.96621875 C20,12.3658418 15.522875,15.9324375 10,15.9324375 C9.469625,15.9324375 8.94904167,15.8992148 8.44095833,15.8358457 C6.29291667,17.941875 3.812375,18.3195059 1.33333333,18.375 L1.33333333,17.8595566 C2.67191667,17.2165547 3.75,16.0453945 3.75,14.7068496 C3.75,14.5201055 3.73516667,14.3367656 3.70770833,14.1574043 C1.44608333,12.6968789 0,10.4661621 0,7.96621875 C0,3.5665957 4.47716667,0 10,0 Z M20.75,17.8558652 C20.75,19.0031953 21.5065,20.0070938 22.6666667,20.5582207 L22.6666667,21 C20.51825,20.9524629 18.546125,20.6287676 16.6845833,18.8236289 C16.2441667,18.8779336 15.7930833,18.9065215 15.3333333,18.9065215 C13.3444167,18.9065215 11.5123333,18.3782813 10.0497083,17.4906211 C13.0637083,17.4802031 15.90925,16.529707 18.0670833,14.8108242 C19.1547917,13.9442871 20.0127917,12.9276328 20.617375,11.788998 C21.2582917,10.5817852 21.5833333,9.29561719 21.5833333,7.96621875 C21.5833333,7.75195313 21.5739583,7.53891797 21.557125,7.32703125 C23.069,8.55614648 24,10.2313887 24,12.0782402 C24,14.2210605 22.74675,16.1330449 20.7867083,17.3849648 C20.762875,17.5386094 20.75,17.6957813 20.75,17.8558652 Z"
					id="Shape"
				/>
			</g>
		</g>
	</SvgTemplate>
);

icon.defaultProps = {
	width: layout.spacing.three,
	color: layout.color.primary
};

export default icon;

// tslint:disable: max-line-length

import * as React from 'react';
import layout from '../../../../utils/layout';
import SvgTemplate from '../SvgTemplate';
import { Svg } from '../../Svg';

const icon = ({ color, ...rest }: Svg): JSX.Element => (
	<SvgTemplate {...rest} viewBox="0 0 23 23">
		<g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<g id="profile-default-circle-female" fill={color} fillRule="nonzero">
				<g id="navbar-help">
					<polygon
						id="SvgjsPath1020"
						points="10.5 18 10.5 16 12.5 16 12.5 18"
					/>
					<path
						d="M11.49,0 C5.14,0 0,5.14 0,11.49 C0,17.84 5.14,22.98 11.49,22.98 C17.84,22.98 22.98,17.84 22.98,11.49 C22.98,5.14 17.84,1.77635684e-15 11.49,0 Z M11.49,20.9 C6.29,20.9 2.08,16.69 2.08,11.49 C2.08,6.29 6.29,2.08 11.49,2.08 C16.69,2.09 20.9,6.3 20.9,11.49 C20.9,16.69 16.69,20.9 11.49,20.9 Z"
						id="SvgjsPath1021"
					/>
					<path
						d="M16.07,8.53 C15.48,6.06 12.99,4.54 10.53,5.13 C8.45,5.62 6.99,7.48 7,9.62 L9.3,9.62 C9.41,8.35 10.52,7.41 11.79,7.52 C13.06,7.62 14,8.74 13.89,10.01 C13.66,11.15 12.64,11.96 11.47,11.92 C10.91,11.92 10.45,12.37 10.45,12.94 L10.45,15.36 L12.75,15.36 L12.75,14.05 C15.18,13.43 16.65,10.97 16.07,8.53 Z"
						id="SvgjsPath1022"
					/>
				</g>
			</g>
		</g>
	</SvgTemplate>
);

icon.defaultProps = {
	width: layout.spacing.three,
	color: layout.color.primaryLight
};

export default icon;

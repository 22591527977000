import * as React from 'react';
import './ContactList.scss';
import { connect } from 'react-redux';
import { hot } from 'react-hot-loader';
import { RouterProps } from 'react-router';
import { Store } from '../../../models';
import {
	listContacts,
	Action$ListContacts,
	Pagination$ListContacts
} from '../../../redux/actions/Contact';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import Dialog from '../../../components/Dialog';
import routes from '../../routes';
import { Options } from '../../../models/Options';
import { getOptions, Action$GetOptions } from '../../../redux/actions';
import Svg from '../../../components/Svg';
import Dot from '../../../components/Dot';
import Filters from './Filters';
import { Link } from 'react-router-dom';
import Button from '../../../components/Button';
import { popups, pushPopup } from '../../../utils/popups';
import User from '../../../redux/entities/User';

const columns = [
	{
		Header: 'ID',
		accessor: 'id',
		sortable: true,
		filterable: true,
		width: 60,
		minResizeWidth: 60,
		Cell: (props: any) => <span className="row__id">{props.original.id}</span>
	},
	{
		Header: 'Name',
		accessor: 'contact_name',
		sortable: true,
		filterable: true,
		minResizeWidth: 60
	},
	{
		Header: 'Email',
		accessor: 'emails',
		filterable: true,
		minResizeWidth: 60,
		Cell: (props: any) => {
			return (
				<span>
					{props.original.emails.map((em: any) => (
						<div key={em.email}>{em.email}</div>
					))}
				</span>
			);
		}
	},
	{
		Header: 'Phone',
		accessor: 'phone',
		filterable: true,
		minResizeWidth: 60,
		Cell: (props: any) => {
			return (
				<span>
					{props.original.phones.map((ph: any) => (
						<div key={ph.phone}>{ph.phone}</div>
					))}
				</span>
			);
		}
	},
	{
		Header: 'Link',
		accessor: 'link',
		filterable: true,
		minResizeWidth: 60,
		Cell: (props: any) => {
			return (
				<span>
					{props.original.links.map((url: any) => (
						<div key={url.link}>{url.link}</div>
					))}
				</span>
			);
		}
	},
	{
		Header: 'Country',
		// sortable: true,
		accessor: 'country',
		minResizeWidth: 60,
		Cell: (props: any) => {
			const { country } = props.original;
			if (!country) return null;
			return <span>{country.country}</span>;
		}
	},
	{
		Header: 'Assigned to',
		accessor: 'assignments',
		minResizeWidth: 60,
		// sortable: true,
		Cell: (props: any) => {
			const { assignments } = props.original;
			return (
				(assignments && (<span>
					{assignments
						.map((assignment: any) => (assignment.user ? assignment.user.display_name : ''))
						.join(', ')}
				</span>))
			);
		}
	},
	{
		Header: 'Status',
		width: 60,
		minResizeWidth: 60,
		className: 'flex--center',
		Cell: (props: any) => {
			const { to_be_checked } = props.original;
			if (to_be_checked == 0) {
				return (
					<span>
						<Dot color="success" />
					</span>
				);
			} else if (to_be_checked == 2) {
				return (
					<span>
						<Dot color="danger" />
					</span>
				);
			}
			return (
				<span>
					<Dot color="warning" />
				</span>
			);
		}
	},
	{
		Header: 'Listed',
		width: 60,
		minResizeWidth: 60,
		className: 'flex--center',
		Cell: (props: any) => {
			const { listed } = props.original;
			if (listed == 1) {
				return <span>{<Dot color="success" />}</span>;
			}
			if (listed == 0) {
				return <span>{<Dot color="danger" />}</span>;
			}
		}
	},
	{
		Header: 'Actions',
		width: 80,
		minResizeWidth: 60,
		className: 'flex--center',
		Cell: (props: any) => {
			return (
				<div>
					<Link
						className="link"
						target="_blank"
						to={routes.contact.edit(props.original.id)}
					>
						<div>
							{/* <span>edit</span> */}
							<Svg icon="pencil" width={16} />
						</div>
					</Link>
				</div>
			);
		}
	}
];

type Props = {
	listContacts: Action$ListContacts;
	contactList?: object[];
	options: Options;
	getOptions: Action$GetOptions;
	user: User;
} & RouterProps;

class ContactList extends React.Component<Props> {
	state = {
		loading: true,
		page: 1,
		limit: 100,
		pages: 0,
		contactList: [],
		filters: {},
		sorting: [],
		columns: [],
		totalResults: undefined,
		all_contacts_id: []
	};

	componentDidMount() {
		this.getOptions();
		// Disables auto search for Admins
		if (this.props.user.role_id <= 2) {
			this.setState({
				loading: false
			});
		} else if(this.props.user.role_id == 3 || this.props.user.role_id == 6) {
			this.search();
		} else {
			// Redirects non-Callers to Dashboard
			this.props.history.push(routes.home.base);
		}
	}

	getOptions = () => {
		const { options, getOptions } = this.props;
		if (!options) {
			getOptions().then();
		}
	};

	search = () => {
		const { listContacts } = this.props;
		const { page, limit, filters, sorting, columns } = this.state;
		const data: Pagination$ListContacts = {
			page,
			limit,
			sorting,
			filters,
			columns
		};
		this.setState({
			loading: true
		});
		listContacts(data)
			.then(res => {
				const { payload } = res;
				this.setState({
					pages: payload.meta.pages,
					page: payload.meta.page,
					totalResults: payload.meta.total,
					contactList: payload.contactList,
					all_contacts_id: payload.contactList.map((contact: any) => {
						return contact.id
					})
				});
			})
			.catch(() => {
				this.setState({
					loading: false
				});
			})
			.then(() => {
				this.setState({
					loading: false
				});
			});
	};

	handlePageChange = (page: number) => {
		this.setState(
			{
				page: page + 1
			},
			() => {
				this.search();
			}
		);
	};

	handlePageSizeChange = (limit: number) => {
		this.setState(
			{
				limit: limit
			},
			() => {
				this.search();
			}
		);
	};

	handleSorting = (sorting: any) => {
		this.setState(
			{
				sorting
			},
			() => {
				this.search();
			}
		);
	};

	columnFilterTimeout: any;
	onColumnFilterChange = (columns: any) => {
		clearTimeout(this.columnFilterTimeout);
		this.columnFilterTimeout = setTimeout(() => {
			this.setState(
				{
					columns
				},
				() => {
					this.search();
				}
			);
		}, 300);
	};

	onFiltersChange = (filters: any) => {
		this.setState({ filters, page: 1 }, () => {
			this.search();
		});
	};

	handleAssign = () => {
		pushPopup(popups.AssignContactsToUser, {
			contacts: this.state.all_contacts_id,
			onSubmit: this.search
		});
	};

	handleUnassign = () => {
		pushPopup(popups.AssignContactsToUser, {
			contacts: this.state.all_contacts_id,
			onSubmit: this.search,
			unassign: true
		});
	};

	render(): JSX.Element {
		const { user } = this.props;
		const {
			loading,
			page,
			pages,
			contactList,
			limit,
			//sorting,
			totalResults,
		} = this.state;
		return (
			<div className="page page--contact-list">
				<Dialog
					title={`Contact list ${
						totalResults ? `: ${totalResults} results` : ''
					}`}
				>
					<Filters onChange={this.onFiltersChange} />
					{(user.role_id <= 2 || user.role_id == 6) && (
						<div className="section--buttons">
							<Button small onClick={this.handleAssign}>
								+ Assign below Contacts to User
							</Button>
							<Button
								danger
								style={{marginLeft: 15}}
								small onClick={this.handleUnassign}>
								- Remove below Contacts from User
							</Button>
						</div>
					)}
					<ReactTable
						data={contactList.map(e => e)}
						columns={columns}
						loading={loading}
						sortable={false}
						resizable={true}
						onPageChange={this.handlePageChange}
						onPageSizeChange={this.handlePageSizeChange}
						pageSizeOptions= {getPageSizeOption(totalResults)}
						onSortedChange={this.handleSorting}
						onFilteredChange={this.onColumnFilterChange}
						page={page - 1}
						pages={pages}
						collapseOnPageChange={false}
						pageSize={limit}
						multiSort={false}
						manual
					/>
				</Dialog>
			</div>
		);
	}
}

const getPageSizeOption = (totalResults :any) => {
	return [20, 50, 100, 200, 300, 500, totalResults].sort((a, b) => a - b);
}

const mapStateToProps = (store: Store): object => {
	const { contactList, options, user } = store;
	return { contactList, options, user };
};

const mapDispatchToProps = (dispatch: any) => {
	return {
		listContacts: (data: Pagination$ListContacts) =>
			dispatch(listContacts(data)),
		getOptions: () => dispatch(getOptions())
	};
};

export default hot(module)(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(ContactList)
);
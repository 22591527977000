export enum UserListEnum {
	LOADED_USER_LIST = 'UserList/LOADED',
	CHANGED_USER_ROLE = 'UserList/CHANGED_ROLE',
	ADDED_USER = 'UserList/ADDED_USER'
}

export enum UserToAssignListEnum {
	LOADED_USER_LIST = 'UserToAssignList/LOADED',
	CHANGED_USER_ROLE = 'UserToAssignList/CHANGED_ROLE',
	ADDED_USER = 'UserToAssignList/ADDED_USER'
}

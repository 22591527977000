// tslint:disable: max-line-length
import * as React from 'react';
import layout from '../../../../utils/layout';
import SvgTemplate from '../SvgTemplate';
import { Svg } from '../../Svg';

const icon = ({ color, ...rest }: Svg): JSX.Element => (
	<SvgTemplate {...rest} viewBox="0 0 20 18">
		<g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<g id="printer" fill={color} fillRule="nonzero">
				<path
					d="M17,5.00869565 L3,5.00869565 C1.3,5.00869565 0,6.30586957 0,8.00217391 L0,13.9891304 L4,13.9891304 L4,17.9804348 L16,17.9804348 L16,13.9891304 L20,13.9891304 L20,8.00217391 C20,6.30586957 18.7,5.00869565 17,5.00869565 Z M14,15.9847826 L6,15.9847826 L6,10.9956522 L14,10.9956522 L14,15.9847826 Z M17,9 C16.4,9 16,8.60086957 16,8.00217391 C16,7.40347826 16.4,7.00434783 17,7.00434783 C17.6,7.00434783 18,7.40347826 18,8.00217391 C18,8.60086957 17.6,9 17,9 Z M16,0.0195652174 L4,0.0195652174 L4,4.01086957 L16,4.01086957 L16,0.0195652174 Z"
					id="Shape"
				/>
			</g>
		</g>
	</SvgTemplate>
);

icon.defaultProps = {
	width: layout.spacing.two,
	color: layout.color.primary
};

export default icon;

import { UserEnum } from '../enums';
import { Action } from '../../models';
import { Response$Logout, logout } from '../../../api/User';

export type Action$Logout = Action<UserEnum.LOGGED_OUT, Response$Logout>;

export type ActionCreator$Logout = () => Promise<Action$Logout>;

const logoutActionCreator: ActionCreator$Logout = async (): Promise<
	Action$Logout
> => {
	const response: Response$Logout = await logout();
	return {
		type: UserEnum.LOGGED_OUT,
		payload: response
	};
};

export default logoutActionCreator;

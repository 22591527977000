import * as React from 'react';
import { FieldArrayRenderProps, FormikProps } from 'formik';
import {
	FormikGoogleAutoComplete
} from '../../../Form';
import Button, { ButtonIcon } from '../../../Button';
import './Locations.scss';
import GoogleMap from "../../../Google/GoogleMap/GoogleMap";
import Marker from "../../../Google/GoogleMap/Marker/Marker";
import env from "../../../../config/env";
import Label from "../../../Form/Label";

interface Location {
	name: string;
	place_id: string;
	latlng: {
		type: string,
		coordinates: number[]
	};
	is_default: boolean;
}
let map: any;
let showMap: boolean = false;
let editLocations: boolean = false;
let marinaHover: boolean = false;
const Locations = (
	props: FieldArrayRenderProps & FormikProps<any> & any
): JSX.Element => {
	const { insert, remove, values, replace, options } = props;
	const [addLocationClicked, changeAddLocationValidation] = React.useState(false);
	const toggleEdit = () : void => {
		editLocations = true;
		if(values.locations.length > 0 && values.locations[0].name.length > 0) {
			showMap = true;
		} else {
			showMap = false;
		}

		changeAddLocationValidation(true);
		props.form.validateForm().then(
			(res: any): void => {
				changeAddLocationValidation(false);
			}
		);
	}
	const toggleMarinaHover = (status: boolean) : void => {
		if(editLocations){
			marinaHover = status;
			changeAddLocationValidation(true);
			props.form.validateForm().then(
				(res: any): void => {
					changeAddLocationValidation(false);
				}
			);
		}
	}
	const addLocation = (i: number): void => {
		editLocations = true;
		changeAddLocationValidation(true);
		props.form.validateForm().then(
			(res: any): void => {
				changeAddLocationValidation(false);
				// Checks for empty locations
				let index = 0;
				if(props.form.values.locations) {
					let emptyLocations = props.form.values.locations.filter((location: any) => {
						return location.name.trim().length == 0;
					});
					if(props.form.values.locations.length > 0 && emptyLocations.length > 0)
						return;

					index = props.form.values.locations.length;
				}
				insert(index, {
					name: '',
					place_id: null,
					latlng: null,
					is_default: (i == -1 || i == 0) ? true : false
				});
			}
		);
	};
	const onDragEnd = (place: any, i: any): void => {
		changeAddLocationValidation(true);
		props.form.validateForm().then(
			(res: any): void => {
				replace(i, {
					is_default: values.locations[i].is_default,
					name: values.locations[i].name,
					place_id: values.locations[i].place_id,
					latlng: {
						type: 'Point',
						coordinates: [
							place.lat,
							place.lng
						]
					}
				})
			}
		);
	};
	return (
		<div className={`locations ${ (!editLocations) ? "preview" : "edit" }`}  >
			{values.locations && values.locations.length > 0 && showMap && (
				<GoogleMap
					key={"locations-map"}
					getMapRef={(ref: any): void => (map = ref)}
					className={"location-map"}
					height={300}
				>
					{values.locations && values.locations.length > 0 && values.locations.map((location: Location, i: number): JSX.Element | null => {
						if(values.locations[i].latlng){
							return (
								<Marker
									key={`marker-${i}`}
									position={{ lat: values.locations[i].latlng.coordinates[0], lng: values.locations[i].latlng.coordinates[1] }}
									draggable={true}
									blue={ !values.locations[i].is_default }
									onDragEnd={(location: any): void => {
										onDragEnd(location, i);
									}}
								/>
							);
						} else {
							return (null);
						}
					})}
				</GoogleMap>
			)}
			{values.locations && values.locations.length > 0 && (
				<div className={"locations-list"}>
					{values.locations.map(
						(location: Location, i: number): JSX.Element => {
							const setDefault = (index: number): void => {
								if(values.locations[index].name.length < 1)
									return;
								showMap = true;
								changeAddLocationValidation(true);
								props.form.validateForm().then(
									(): void => {
										for(let i = 0; i < values.locations.length; i++){
											values.locations[i].is_default = false;
										}
										values.locations[index].is_default = true;
										changeAddLocationValidation(false);
										props.setStatus(
											{ dirty: true }
										);
									}
								);
							};
							const onPlaceChange = (place: any): void => {
								changeAddLocationValidation(true);
								showMap = true;
								props.form.validateForm().then(
									(res: any): void => {
										replace(i, {
											is_default: values.locations[i].is_default,
											name: place.pretty_address ? place.pretty_address : place.formatted_address,
											place_id: place.place_id,
											latlng: {
												type: 'Point',
												coordinates: [
													place.geometry.location.lat(),
													place.geometry.location.lng()
												]
											}
										})
										changeAddLocationValidation(false);
									}
								);
							};
							const removeLocation = (i: number): void => {
								if(values.locations.length == 1)
									showMap = false;

								changeAddLocationValidation(true);
								props.form.validateForm().then(
									(res: any): void => {
										remove(i);
										if(values.locations[i].is_default && values.locations.length > 1){
											changeAddLocationValidation(false);
										}
									}
								);
							};
							if(showMap)
								setTimeout(function(){
									if(values.locations.length > 1) {
										let bounds = new google.maps.LatLngBounds();
										let counter = 0;
										for (let i = 0; i < values.locations.length; i++) {
											if(!values.locations[i].latlng || !values.locations[i].latlng.coordinates)
												continue;
											let position = new google.maps.LatLng(values.locations[i].latlng.coordinates[0], values.locations[i].latlng.coordinates[1]);
											counter++;
											bounds.extend(position);
										}
										if(showMap)
											map.fitBounds(bounds);
										if(counter == 1)
											map.setZoom(8);
									} else if(values.locations.length == 1) {
										if(values.locations[0].latlng && values.locations[0].latlng.coordinates)
											map.setCenter({
												lat: values.locations[0].latlng.coordinates[0],
												lng: values.locations[0].latlng.coordinates[1]
											});
										map.setZoom(8);
									}
								}, 500);
							return (
								<div className={`location-field ${ (location.is_default) ? "main-marina" : "" }`}   key={`location-${i}`}>
									<div
										className="label-marker"
										style={{
											backgroundImage: `url('${
												env.bucketUrl
											}/static/img/svg/marker-${ location.is_default && !marinaHover ? 'red' : 'blue' }.svg')`
										}}
										onMouseEnter={() => toggleMarinaHover(true)}
										onMouseLeave={() => setTimeout(function(){
											toggleMarinaHover(false)
										},50)}
										onMouseDown={(): void => {
											if(editLocations){
												toggleMarinaHover(true);
												setTimeout(function(){
													toggleMarinaHover(false);
												},50)
												setDefault(i);
											}
										}}
									/>
									{!editLocations && (
										<Label title={location.name} />
									)}
									{editLocations && (
										<div className={'location-input-container'}>
											<FormikGoogleAutoComplete
												name={`locations.${i}.name`}
												onPlaceChange={onPlaceChange}
												flat
											/>
											<ButtonIcon
												iconWidth={8}
												className="btn--remove-location"
												icon="close"
												iconColor="#fff"
												onClick={(): void => removeLocation(i)}
											/>
										</div>
									)}
								</div>
							);
						}
					)}
				</div>
			)}
			{(editLocations || (!values.locations || values.locations.length == 0)) && (
				<Button
					type="button"
					transparent
					dashed
					className="btn--add-location"
					onClick={(): void => {
						addLocation(values.locations ? values.locations.length : -1);
					}}
				>
					+ add new location
				</Button>
			)}
			{!editLocations && values.locations && values.locations.length > 0 && (
				<Button
					type="button"
					transparent
					dashed
					className="btn--edit-locations"
					onClick={(): void => {
						toggleEdit();
					}}
				>
					edit locations
				</Button>
			)}
		</div>
	);
};

export default Locations;

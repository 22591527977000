import * as React from 'react';
import './FormError.scss';
import { CSSTransition } from 'react-transition-group';

interface Props extends React.Props<Props> {
	text?: any;
	style?: object;
}

export class FormError extends React.Component<Props> {
	static defaultProps: Props = {
		style: {}
	};

	constructor(props: Props) {
		super(props);
		this.validationErrorText = React.createRef();
		this.validationErrorWrapper = React.createRef();
	}

	validationErrorText: any;
	validationErrorWrapper: any;

	validationErrortext = '';
	fixValidationHeight = () => {
		// With this we keep the height of the wrapper div after the text error string is dismissed, for a super smooth transition.
		setTimeout(() => {
			const textDiv = this.validationErrorText.current;
			const wrapperDiv = this.validationErrorWrapper.current;
			if (textDiv && wrapperDiv) {
				wrapperDiv.style.height = `${textDiv.scrollHeight}px`;
			}
		}, 10);
	};

	componentDidUpdate(prevProps: Props) {
		if (this.props.text && prevProps.text !== this.props.text) {
			this.fixValidationHeight();
		}
	}

	render(): JSX.Element {
		const { text, style } = this.props;
		return (
			<CSSTransition
				in={text ? true : false}
				timeout={200}
				classNames="form-validation"
				unmountOnExit
			>
				<div
					ref={this.validationErrorWrapper}
					className="form-input__validation-error"
					style={style}
				>
					<div
						className="form-input__validation-error__text"
						ref={this.validationErrorText}
					>
						{/* If error is a string */}
						{typeof text === 'string' && text}

						{/* If error is an object show the first property each time */}
						{typeof text === 'object' &&
							/*
							 * js bug fix:
							 * (typeof null === object) returns true
							 * So we also check if text !== null,
							 * otherwise it will try to access a null's property, and, explode!
							 */
							text !== null &&
							text[Object.keys(text)[0]]}
					</div>
				</div>
			</CSSTransition>
		);
	}
}

export default React.memo(FormError);

import * as React from 'react';
import ReactTable from 'react-table';
import { Store } from '../../../../models';
import { connect } from 'react-redux';
import { Statistics } from '../../../../models/Statistics';
import './Table.scss';
import columns from './columns';

type Props = {
	statistics: Statistics;
	page: number;
	limit?: number;
	total: number;
	onPageChange: (page: number) => any;
	onLimitChange: (limit: number) => any;
	pages: number;
	loading: boolean;
};

function Table(props: Props): JSX.Element {
	const {
		statistics,
		page,
		limit,
		onPageChange,
		onLimitChange,
		pages,
		total,
		loading
	} = props;

	const contacts =
		statistics && statistics.pagination && statistics.pagination.contacts;

	return (
		<div className="dashboard-table">
			<ReactTable
				data={contacts.map(e => e)}
				columns={columns}
				loading={loading}
				sortable={false}
				resizable={true}
				onPageChange={(page: number) => {
					onPageChange(page + 1);
				}}
				onPageSizeChange={onLimitChange}
				pageSizeOptions= {getPageSizeOption(total)}
				// onSortedChange={this.handleSorting}
				// onFilteredChange={this.onColumnFilterChange}
				page={page - 1}
				pages={pages}
				collapseOnPageChange={false}
				pageSize={limit}
				multiSort={false}
				manual
			/>
		</div>
	);
}

const getPageSizeOption = (total :number) => {
	return [20, 50, 100, 200, 300, 500, total].sort((a, b) => a - b);
}

function mapStateToProps(store: Store) {
	const { statistics } = store;
	return {
		statistics
	};
}

export default connect(mapStateToProps)(Table);

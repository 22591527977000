import * as React from 'react';
import './Calls.scss';
import env from '../../../../config/env';
import Dialog from '../../../../components/Dialog';
import moment from 'moment';
import { pushPopup, popups } from '../../../../utils/popups';
import { getCalls } from '../../../../redux/actions/Contact';
import Label from '../../../../components/Form/Label';
import { EditContactProps } from '../EditContact';
import { Contact as ContactType } from '../../../../models/Contact';
import { Call } from '../../../../models/Call';
import {Options} from '../../../../models/Options';
import {User} from "../../../../models/User";
import ReactAudioPlayer from 'react-audio-player';
import MakeCallEmbedded from "../../../../components/MakeCallEmbedded/MakeCallEmbedded";


type CallsProps = {
	contact: ContactType;
	user: User;
	options: Options;
} & EditContactProps;

const Calls = (props: CallsProps) => {
	const { id } = props.match.params;
	const { contact, user, options } = props;
	const [calls, setCalls] = React.useState([]);
	React.useEffect(() => {
		fetch();
	}, []);

	const fetch = () => {
		getCalls(id).then((res: any) => {
			setCalls(res.payload);
		});
	};

	const showRegisterCallForm = () => {
		pushPopup(popups.NewCall, {
			contactId: id,
			onDismiss: fetch,
			phones: contact.phones
		});
	};

	if (!calls) {
		return null;
	}
	return (
		<Dialog title="Calls" className="dialog--calls">
			<div className="phone-interface">
				<h1>Make a new call:{' '}</h1>
				{user && contact && contact.phones && (
					<MakeCallEmbedded
						contactId={id}
						fetchData={fetch}
						phones={contact.phones}
						user={user}
					/>
				)}
			</div>
			<div className="inner-content">
				{calls.map((call: Call, i: number) => {
					return (
						<div className="call" key={`call-${i}`}>
							<div className="date">
								{moment(call.call_date).format('dddd DD MMMM YYYY - HH:mm')}
							</div>

							<div className="caller">
								Phone: <span className="phone">{call.phone}</span>
							</div>

							{call.language && (
								<div className="caller">
									Language:{' '}
									<span className="phone">{call.language.language}</span>
								</div>
							)}
							<div className="caller">
								Caller:{' '}
								<span className="name">
									{call.user && call.user.display_name}
								</span>
							</div>
							<Label title="Comments:" />
							<div className="comments">{call.comments || '-'}</div>
							{(user.role_id < 2 || user.role_id == 6) && call.cc_content && call.cc_content.duration && (
								<div className="caller">
									<span className="title">Call Time:{' '}</span>
									<span className="phone">{call.cc_content.duration} seconds</span>
								</div>
							)}
							{(user.role_id < 2 || user.role_id == 6) && call.comment_duration && (
								<div className="caller">
									<span className="title">Comment Time:{' '}</span>
									<span className="phone">{call.comment_duration} seconds</span>
								</div>
							)}
							{(user.role_id < 2 || user.role_id == 6) && call.cc_content && call.cc_content.callRecordingPath && (
								<div className="caller" style={{marginBottom: '0'}}>
									<ReactAudioPlayer
										src={env.apiUrl + '/files/recordings/' + encodeURIComponent(call.cc_content.callRecordingPath)}
										controls
									/>
								</div>
							)}
						</div>
					);
				})}
			</div>
			<div className="dialog__footer">
				<div className="link" onClick={showRegisterCallForm}>
					Register old call
				</div>
			</div>
		</Dialog>
	);
};

export default Calls;

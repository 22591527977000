import * as React from 'react';
import Svg from '../../Svg';
import { Icons } from '../../Svg/icons';
import layout from '../../../utils/layout';
import './ButtonIcon.scss';

interface Props {
	onClick: (e: any) => any;
	icon: Icons;
	iconColor?: string;
	iconWidth?: number;
	style?: object;
	className?: string;
	disabled?: boolean;
	circle?: boolean;
	type?: 'submit' | 'reset' | 'button';
	tabIndex?: number;
}

export const ButtonIcon = (props: Props) => {
	const {
		onClick,
		icon,
		iconWidth,
		style,
		className,
		iconColor,
		disabled,
		circle,
		type,
		tabIndex
	} = props;
	let cl = 'button-icon';
	if (className) {
		cl += ` ${className}`;
	}
	if (circle) {
		cl += ' circle';
	}
	return (
		<button
			style={style}
			className={cl}
			onClick={onClick}
			disabled={disabled}
			type={type}
			tabIndex={tabIndex}
		>
			<Svg icon={icon} width={iconWidth} color={iconColor} />
		</button>
	);
};

ButtonIcon.defaultProps = {
	type: 'button',
	iconWidth: layout.spacing.two
};

export default ButtonIcon;

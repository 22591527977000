import { CCAccountListEnum } from '../enums';
import { Action } from '../../models';
import {
	Request$ChangeAccountCountry,
	Response$ChangeAccountCountry,
	changeAccountCountry
} from '../../../api/CallCenterAccounts/ChangeAccountCountry';

export type Action$ChangeAccountCountry = Action<CCAccountListEnum.CHANGED_ACCOUNT_COUNTRY, any>;

export type ActionCreator$ChangeCountry = (
	data: Request$ChangeAccountCountry
) => Promise<Action$ChangeAccountCountry>;

const changeAccountCountryActionCreator: ActionCreator$ChangeCountry = async (
	data: Request$ChangeAccountCountry
): Promise<Action$ChangeAccountCountry> => {
	const response: Response$ChangeAccountCountry = await changeAccountCountry(data);
	return {
		type: CCAccountListEnum.CHANGED_ACCOUNT_COUNTRY,
		payload: response
	};
};

export default changeAccountCountryActionCreator;

import * as React from 'react';
import './MakeCall.scss';
import Popup from '../Popup';
import {connect} from 'react-redux';
import {hot} from 'react-hot-loader';
import {ReactSipPhone} from '../../../utils/react-sip-phone';
import {Action$AddCall, addCall} from '../../../redux/actions/Contact';
import {FormData$AddCall} from '../../../redux/api/Contact';
import {Options} from '../../../models/Options';
import {User} from "../../../models/User";
import {popups, pushPopup} from "../../../utils/popups";

interface Props {
	active: boolean;
	onDismiss?: () => any;
	addCall: Action$AddCall;
	contactId: string | number;
	phones: any;
	options: Options;
	user: User;
	isAdmin?: boolean;
}

let currentCallId: string;
let currentCallNumber: string;
const MakeCall = (props: Props): JSX.Element => {
	const { active, addCall, contactId, onDismiss, options, user, phones, isAdmin } = props;
	if(props.phones){
		// Adds country prefix to number
		props.phones.forEach(function(phone :any ){
			phone.country_code = options!.countries.filter(c => c.id == phone.country_id)![0]!.country_phone_code;
		});
	}

	const callStarted = () => {
		if(!isAdmin){
			addCall({
				id: contactId,
				phone: currentCallNumber,
				cc_id: currentCallId
			}).then((e: any) => {
				onDismiss && onDismiss();
			});
		}
	};

	const callEnded = () => {
		if(!isAdmin){
			window && window.popup.pop();
			pushPopup(popups.MakeCallReview, {
				contactId: contactId,
				onDismiss: onDismiss,
				phones: phones,
				user: user,
				callId: currentCallId
			});
		}
	};

	const onOutgoingCall = (event: any) => {
		const {type ,call_id, to} = event;
		currentCallId = call_id;
		currentCallNumber = to;
		if(type == 'Call Established')
			callStarted();

		if(type == 'User hanged up')
			callEnded();
	};

	const onCallEnded = () => {
		callEnded();
	};

	if(!user.sip_account.sip_username || !user.sip_account.sip_password)
		return (
			<Popup
				active={active}
				title="Call Error"
				className="popup--new-call"
			>
				<h1>User Phone Credentials Error. Please contact a BednBlue Admin</h1>
			</Popup>
		)

	return (
		<Popup
			active={active}
			title="New call"
			className="popup--new-call"
		>
			<ReactSipPhone
				name={'BednBlue'}
				numberList={props.phones}
				isAdmin={isAdmin}
				sipCredentials={{
					sipuri: user.sip_account.sip_username + '@call.bnblu.com',
					password: user.sip_account.sip_password
				}}
				sipConfig={{
					websocket: 'wss://call.bnblu.com:8089/ws',
					defaultCountryCode: '',
					video: false,
					iceServers: [''],
					noAnswerTimeout: 10000
				}}
				phoneConfig={{
					disabledButtons: [user.role_id == 1 ? '' : 'transfer'],
					disabledFeatures: [''],
					defaultDial: '',
					sessionsLimit: 1,
					attendedTransferLimit: 2,
					autoAnswer: false
				}}
				appConfig={{
					mode: '',
					started: true,
					appSize: 'large'
				}}
				onOutgoingCall={onOutgoingCall}
				onCallEnded={onCallEnded}
				width={400}
			    />
		</Popup>
	);
};

const mapStateToProps = (store: any) => {
	const { options } = store;
	return { options };
};

const mapDispatchToProps = (dispatch: any) => {
	return {
		addCall: (data: FormData$AddCall) => dispatch(addCall(data))
	};
};

export default hot(module)(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(MakeCall)
);

import { Action$ListUsers } from '../UserListActions';
import initialState from '../../store/initialState';
// import { User } from '../../../models/User';
import { UserListEnum } from '../enums';
import User from '../../entities/User';
import { Action$ChangeRole } from '../../User/UserActions';
import { UserEnum } from '../../User/enums';

type Actions = Action$ListUsers | Action$ChangeRole;

function userListReducer(
	userList: any = initialState.userList,
	action: Actions
) {
	const list = [...userList];
	const { type, payload } = action;
	switch (type) {
		case UserListEnum.LOADED_USER_LIST:
			return payload.data;
		case UserListEnum.ADDED_USER:
			list.push(payload.data);
			return list;
		case UserEnum.CHANGED_ROLE:
			return list.map((user: User) => {
				if (user.id === payload.data.id) {
					user.role_id = payload.data.role_id;
				}
				return user;
			});
		default:
			return userList;
	}
}

export default userListReducer;

import { FormikActions } from 'formik';
// import store from '../../../redux/store';
import { Action } from '../../../models';
import { AxiosResponse } from 'axios';

// This function is a middleware that handles the Formik actions before resolving the promise inside the component.
async function formikSubmit(
	values: any,
	actions: FormikActions<any>,
	// dispatchAction is the redux action that makes the api call.
	dispatchAction?: (data: any) => Promise<Action>,
	// optional: pass the component to check `_isMounted`, to prevent memory leak in async requests
	component?: any
) {
	/*
	 * The value that we can return at the end of this function to use with `.then()` on our `handleSubmit()` call.
	 * Example: handleSubmit(values, actions, login).then((res) => // handle res )
	 */
	let payload;

	/*
	 * The value that we can throw at the end of this function to use with `.catch()` on our `handleSubmit()` call.
	 * Example: handleSubmit(values, actions, login).then((error) => // handle error )
	 */
	let error;

	/*
	 * Reset form status on every submit.
	 * We use this to set an custom string after the promise has caucht an error.
	 */
	actions.setStatus(null);

	// dispatchAction is always a Promise that returns a redux Action.
	dispatchAction &&
		(await dispatchAction(values)
			.then((action: any) => {
				payload = action.payload;
				// handling async memory leak. checking if the component is mounted (if passed)
				if (!component || (component && component._isMounted)) {
					// reset form to pass {dirty: false} for submit button text.
					actions.resetForm();

					// set success true for various things like submit button text.
					actions.setStatus({
						success: true
					});
				}

				return Promise.resolve(action);
			})
			.catch((ex: AxiosResponse) => {
				/* status code 422 means that we got a validation error from the backend.
				 * It contains an property `errors` which is an object that contains the validation errors.
				 * Each property of the object is the field name and the value is an array of strings (the validation errors).
				 *
				 * Example:
				 *
				 * errors: {
				 * 		email: ['Email already exists']
				 * }
				 *
				 * So, if we pass this object in Formik's setErrors, the strings are gettings rendered in each individual form field!
				 */

				// reset form to pass {dirty: false} for submit button text.
				// keeping values by passing them in resetForm function
				// actions.resetForm(values);

				if (ex.status === 400) {
					const { data } = ex;
					const key = data.validation.keys[0];
					key && actions.setErrors({ [key]: ex.data.message });
				}

				// bind error data to throw at the end
				error = ex.data;
			})
			.then(() => {
				actions.setSubmitting(false);
			}));

	// Return payload if we got one from the promise.
	if (payload) {
		return payload;
	}
	// Else throw error if we caught one in the promise.
	if (error) {
		throw error;
	}
}

export default formikSubmit;

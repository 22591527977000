import { CallCenterAccount } from '../../models/CallCenterAccount';
import {get} from "../../redux/api/utils";

export type Response$CallCenterAccountList = { data: CallCenterAccount[] };
/**
 * API GET Request to fetch call center sip accounts.
 */
export async function listCallCenterAccounts(): Promise<Response$CallCenterAccountList> {
    return get('/sip-account/list');
}

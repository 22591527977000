import { UserPicture } from '../models/User';

export const setAsyncState = (
	state: object,
	component: any,
	callback?: () => any
): void => {
	if (typeof component._isMounted !== 'boolean') {
		__DEV__ &&
			console.error(
				// tslint:disable: max-line-length
				"_isMounted is not a boolean! asyncSetState won't change the state. \n Set a default value (false) and change it in `componentDidMount` to true and in `componentWillUnmount` to false."
			);
	}
	if (!component._isMounted) {
		return;
	}
	component.setState(state, () => callback && callback());
};

export const metersToMiles = (meters: number, decimals: number = 3): any => {
	if (typeof meters !== 'number') {
		return null;
	}
	return (meters * 0.0005399568).toFixed(decimals);
};

export function litersToGallons(liters: any): any {
	const result = Math.round(liters * 0.2641720524);
	return isNaN(result) ? null : result;
}

export function gallonsToLiters(gallons: any): any {
	const result = Math.round(gallons * 3.78541);
	return isNaN(result) ? null : result;
}

export function hpToKw(hp: any): any {
	const result = Math.round(hp * 0.7457);
	return isNaN(result) ? null : result;
}

export function kwToHp(kw: any): any {
	const result = Math.round(kw * 1.34102);
	return isNaN(result) ? null : result;
}

export function metersToFeet(meters: any): any {
	const result = Math.round(meters * 3.28084);
	return isNaN(result) ? null : result;
}

export function feetToMeters(feet: any): any {
	const result = Math.round(feet * 0.3048);
	return isNaN(result) ? null : result;
}

/*
 * gets the array of user's pictures and returns the default one.
 */
export function getDefaultPicture(
	pictures: UserPicture[] | undefined
): string | null {
	if (!pictures || !pictures.length) {
		return null;
	}

	const defaultPic: UserPicture[] = pictures.filter(
		(picture: UserPicture): boolean => picture.default
	);
	if (defaultPic.length) {
		return defaultPic[0].file;
	}

	return null;
}

/*
 * This function takes a Base64 image and returns a Blob.
 */
declare const Blob: any;
declare const atob: any;

export function dataURItoBlob(dataURI: string): any {
	// convert base64/URLEncoded data component to raw binary data held in a string
	let byteString;
	let mimeString;
	if (dataURI.split(',')[0].indexOf('base64') >= 0) {
		byteString = atob(dataURI.split(',')[1]);
	}
	// seperate type type
	mimeString = dataURI.split(':')[1].split(';')[0];
	// write the bytes of the string to a typed array
	const ia = new Uint8Array(byteString.length);
	for (let i = 0; i < byteString.length; i++) {
		ia[i] = byteString.charCodeAt(i);
	}

	const image = new Blob([ia], { type: mimeString });

	return image;
}

export function uuid() {
	const hashTable = [
		'a',
		'b',
		'c',
		'd',
		'e',
		'f',
		'0',
		'1',
		'2',
		'3',
		'4',
		'5',
		'6',
		'7',
		'8',
		'9'
	]
	let uuid : any[] = []
	for (let i = 0; i < 35; i++) {
		if (i === 7 || i === 12 || i === 17 || i === 22) {
			uuid[i] = '-'
		} else {
			uuid[i] = hashTable[Math.floor(Math.random() * hashTable.length - 1)]
		}
	}
	return uuid.join('')
}

import { Action } from '../../../models';
import { Request$SendEmail } from '../../api/Email/models';
import emailApi from '../../api/Email';
import { SENT_EMAIL } from './constants';

export type Action$SendEmail = (data: Request$SendEmail) => Promise<Action>;

export async function action$SendEmail(data: Request$SendEmail) {
	const res = await emailApi.sendEmail(data);
	return {
		type: SENT_EMAIL,
		payload: res
	};
}

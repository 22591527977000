// tslint:disable: max-line-length
import * as React from 'react';
import layout from '../../../../utils/layout';
import SvgTemplate from '../SvgTemplate';
import { Svg } from '../../Svg';

const icon = ({ color, ...rest }: Svg): JSX.Element => (
	<SvgTemplate {...rest} viewBox="0 0 98.26 92.261">
		<>
			<g id="Layer_1" display="none" opacity="0.3">
				<g display="inline" opacity="0.5">
					<line
						fill="none"
						stroke="#ED1C24"
						strokeWidth="0.25"
						strokeMiterlimit="10"
						x1="8.422"
						y1="0.004"
						x2="8.422"
						y2="96.004"
					/>
					<line
						fill="none"
						stroke="#ED1C24"
						strokeWidth="0.25"
						strokeMiterlimit="10"
						x1="80.422"
						y1="0.004"
						x2="80.422"
						y2="96.004"
					/>
					<line
						fill="none"
						stroke="#ED1C24"
						strokeWidth="0.25"
						strokeMiterlimit="10"
						x1="92.464"
						y1="12.004"
						x2="-3.536"
						y2="12.004"
					/>
					<line
						fill="none"
						stroke="#ED1C24"
						strokeWidth="0.25"
						strokeMiterlimit="10"
						x1="92.422"
						y1="84.004"
						x2="-3.578"
						y2="84.004"
					/>
				</g>
				<g display="inline" opacity="0.5">
					<line
						fill="none"
						stroke="#00AEEF"
						strokeWidth="0.25"
						strokeMiterlimit="10"
						x1="20.422"
						y1="0.004"
						x2="20.422"
						y2="96.004"
					/>
					<line
						fill="none"
						stroke="#00AEEF"
						strokeWidth="0.25"
						strokeMiterlimit="10"
						x1="44.422"
						y1="0.004"
						x2="44.422"
						y2="96.004"
					/>
					<line
						fill="none"
						stroke="#00AEEF"
						strokeWidth="0.25"
						strokeMiterlimit="10"
						x1="68.422"
						y1="0.004"
						x2="68.422"
						y2="96.004"
					/>
					<line
						fill="none"
						stroke="#00AEEF"
						strokeWidth="0.25"
						strokeMiterlimit="10"
						x1="92.422"
						y1="24.004"
						x2="-3.578"
						y2="24.004"
					/>
					<line
						fill="none"
						stroke="#00AEEF"
						strokeWidth="0.25"
						strokeMiterlimit="10"
						x1="92.422"
						y1="48.004"
						x2="-3.578"
						y2="48.004"
					/>
					<line
						fill="none"
						stroke="#00AEEF"
						strokeWidth="0.25"
						strokeMiterlimit="10"
						x1="92.422"
						y1="72.004"
						x2="-3.578"
						y2="72.004"
					/>
					<line
						fill="none"
						stroke="#00AEEF"
						strokeWidth="0.25"
						strokeMiterlimit="10"
						x1="-3.578"
						y1="0.004"
						x2="92.505"
						y2="96.088"
					/>
					<line
						fill="none"
						stroke="#00AEEF"
						strokeWidth="0.25"
						strokeMiterlimit="10"
						x1="-3.578"
						y1="96.004"
						x2="92.172"
						y2="0.254"
					/>
				</g>
			</g>
			<g id="Layer_2">
				<path
					fill="#FFFFFF"
					stroke="#8B8AA0"
					strokeWidth="2"
					strokeLinejoin="round"
					strokeMiterlimit="10"
					d="M52.922,26.739
        		c0,7.867-6.374,17.239-14.242,17.239c-7.867,0-14.242-9.372-14.242-17.239c0-7.868,6.375-14.243,14.242-14.243
        		C46.548,12.497,52.922,18.872,52.922,26.739z"
				/>
				<path
					className="fill"
					fill="#fff"
					stroke="#8B8AA0"
					strokeWidth="2"
					strokeLinejoin="round"
					strokeMiterlimit="10"
					d="M24.547,24.969
        		c5.21-4.111,8.792,0.001,14.133,0.001s8.927-4.111,14.133,0l0.108,1.769c0-7.868-6.374-14.243-14.242-14.243
        		c-7.867,0-14.242,6.375-14.242,14.243"
				/>
				<path
					className="fill"
					fill="#fff"
					stroke="#8B8AA0"
					strokeWidth="2"
					strokeLinejoin="round"
					strokeMiterlimit="10"
					d="M39.772,73.621h-23.51
        		c0-12.019,4.94-16.539,9.38-20.33L39.772,73.621z"
				/>
				<path
					fill="#FFFFFF"
					stroke="#8B8AA0"
					strokeWidth="2"
					strokeLinejoin="round"
					strokeMiterlimit="10"
					d="M39.772,73.621
        		l-14.13-20.33c3.63-3.099,6.92-5.72,6.92-11.541"
				/>
				<path
					fill="#FFFFFF"
					stroke="#8B8AA0"
					strokeWidth="2"
					strokeLinejoin="round"
					strokeMiterlimit="10"
					d="M46.203,41.752
        		c0,12.956,16.297,10.031,16.297,31.865H38.993"
				/>
				<path
					fill="#FFFFFF"
					stroke="#8B8AA0"
					strokeWidth="2"
					strokeLinejoin="round"
					strokeMiterlimit="10"
					d="M48.7,48.655
        		c-2.284,2.283-5.439,3.697-8.926,3.697c-3.485,0-6.64-1.414-8.925-3.697"
				/>
				<ellipse
					className="fill"
					fill="#fff"
					stroke="#8B8AA0"
					strokeWidth="2"
					strokeLinejoin="round"
					strokeMiterlimit="10"
					cx="57.486"
					cy="23.236"
					rx="8.597"
					ry="6.923"
				/>
				<path
					className="fill"
					fill="#fff"
					stroke="#8B8AA0"
					strokeWidth="2"
					strokeLinejoin="round"
					strokeMiterlimit="10"
					d="M79.433,84.091h-43.83
        		c0-13.97,7.04-17.25,11.61-21.67c1.97,3.891,6.01,6.562,10.68,6.562c4.48,0,8.37-2.46,10.43-6.091
        		C72.893,67.091,79.433,70.621,79.433,84.091z"
				/>
				<path
					fill="#FFFFFF"
					stroke="#8B8AA0"
					strokeWidth="2"
					strokeLinejoin="round"
					strokeMiterlimit="10"
					d="M68.323,62.881v0.01
        		c-2.06,3.63-5.95,6.091-10.43,6.091c-4.67,0-8.71-2.67-10.68-6.562c2.21-2.14,3.84-4.549,3.84-8.539h12.93
        		C63.983,58.171,65.873,60.633,68.323,62.881z"
				/>
				<line
					fill="none"
					stroke="#8B8AA0"
					strokeWidth="2"
					strokeLinejoin="round"
					strokeMiterlimit="10"
					x1="68.323"
					y1="62.892"
					x2="68.323"
					y2="62.881"
				/>
				<path
					fill="#FFFFFF"
					stroke="#8B8AA0"
					strokeWidth="2"
					strokeLinejoin="round"
					strokeMiterlimit="10"
					d="M70.972,40.175
        		c0,7.456-6.042,16.341-13.5,16.341c-7.456,0-13.5-8.886-13.5-16.341c0-7.458,6.043-13.5,13.5-13.5
        		C64.93,26.675,70.972,32.717,70.972,40.175z"
				/>
				<path
					className="fill"
					fill="#fff"
					stroke="#8B8AA0"
					strokeWidth="2"
					strokeLinejoin="round"
					strokeMiterlimit="10"
					d="M70.972,40.175
        		c0-7.458-6.042-13.5-13.5-13.5c-7.456,0-13.5,6.042-13.5,13.5l0.104,2.124c3.677-2.924,8.333-5.843,13.396-5.843
        		c5.063,0,9.719,2.919,13.398,5.843L70.972,40.175z"
				/>
			</g>
		</>
	</SvgTemplate>
);

icon.defaultProps = {
	width: layout.spacing.four,
	color: layout.color.primary
};

export default icon;

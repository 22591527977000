import * as React from 'react';
import './Header.scss';
import Svg from '../Svg';
import { User as UserModel } from '../../models/User';
import { connect } from 'react-redux';
import routes from '../../router/routes';
import logoutActionCreator from '../../redux/User/UserActions/Logout';
import env from '../../config/env';
import {ButtonIcon} from "../Button";
import {popups, pushPopup} from "../../utils/popups";

type Props = {
	user: UserModel;
	logout: () => any;
};

const Header = (props: Props): JSX.Element => {
	const { user, logout } = props;
	const handleLogout = () => {
		logout();
	};
	const showFAQ = (e : any) => {
		e.preventDefault();
		pushPopup(popups.ViewHelpdesk)
	};
	return (
		<>
			<div className="header__helper" />
			<header>
				<a href={routes.home.base} className="logo">
					<Svg icon="bednblue" />
					<h1>BednBlue</h1>
					<h3>| Backoffice {env.backOffice} | </h3>
					<ButtonIcon
						onClick={showFAQ}
						icon="lifesaver"
						iconWidth={20}
					/>
				</a>
				<div className="header__right">
					<div className="hello_user" >Hello {user.display_name}!</div>
					<div className="logout" onClick={handleLogout}>
						Logout
					</div>
				</div>
			</header>
		</>
	);
};

const mapStateToProps = (store: any) => {
	const { user } = store;
	return { user };
};

const mapDispatchToProps = (dispatch: any) => {
	return {
		logout: () => dispatch(logoutActionCreator())
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Header);

import * as React from 'react';
import './SendEmail.scss';
import Popup from '../Popup';
import { connect } from 'react-redux';
import { hot } from 'react-hot-loader';
import {
	FormikForm,
	FormikButtonSubmit,
	FormGroup,
	FormikInputSelect, FormikInputTextarea, FormikInputText
} from '../../Form';
import { FormikProps, FormikActions } from 'formik';
import { Dispatch, Store } from '../../../models';

import { Contact, Email, Phone } from '../../../models/Contact';
import Yup from '../../../utils/yup';
import { EmailTry, Options, Language } from '../../../models/Options';
import InputCheckbox from '../../Form/InputCheckbox';
import routes from '../../../router/routes';
import { RouteComponentProps } from 'react-router';
import {
	action$SendEmail,
	Action$SendEmail
} from '../../../redux/actions/Email';
import { Request$SendEmail } from '../../../redux/api/Email/models';
import moment from "moment";
import {DateRangePicker} from "react-dates";
import Label from "../../Form/Label";
import Button from "../../Button";

type Props = {
	active: boolean;
	options: Options;
	contact: Contact;
	getEmails: any;
	onDismiss?: () => any;
	sendEmail: Action$SendEmail;
} & RouteComponentProps<{ id: string }>;

type Values = {
	emails: string[];
	phones: string[];
	email_try: string | number;
	language_id: string | number;
	guests_no?: number;
	ba_currency_id?: string;
	ba_intro_type?: number;
	ba_search_url?: string;
	ba_urls?: string[];
	ba_custom_text?: string;
};

type FormProps = {} & Props & FormikProps<Values>;

const SendEmail = (props: Props): JSX.Element => {
	const { active, contact, onDismiss, sendEmail, options } = props;
	// Add country prefix (country_code) to each phone in contact.phones
	contact.phones.forEach(function(phone: any) {
		const country = options!.countries.find(c => c.id === phone.country_id);
		
		if (country) {
			phone.country_code = country.country_phone_code;
		}
	});
	const initialValues = {
		email_try: '',
		emails: contact && contact.emails && contact.emails.map(em => em.email),
		phones: contact && contact.phones && contact.phones.map(em => `${em.country_code}${em.phone}`),
		language_id: '',
		guests_no: 6,
		ba_urls:["","","","",""]
	};
	const validationSchema = Yup.object().shape({
		email_try: Yup.number().required(),
		language_id: Yup.string().required()
	});
	const [startDate, setStartDate]  = React.useState(
		moment().add(2, 'days')
	);
	const [endDate, setEndDate] = React.useState(
		moment().add(7, 'days')
	);

	const [baFormError, setBaFormError] = React.useState(null);
	const [focusedInput, setFocusedInput] = React.useState(null) as any;
	const onDatesChange = ({ startDate, endDate }: any) => {
		setStartDate(startDate);
		setEndDate(endDate);
	};
	const { id } = props.match.params;
	const onSubmit = (values: Values, actions: FormikActions<Values>) => {
		const { email_try, emails, phones, language_id, guests_no, ba_urls, ba_intro_type, ba_currency_id, ba_custom_text, ba_search_url } = values;
		if (options.email_tries.filter(tmp => tmp.id == email_try)[0].can_send_token == 'custom') {
			props.history.push(routes.contact.customEmail.base(id));
			window.popup.pop();
			return;
		}
		let submit_values;
		setBaFormError(null);
		if(options && options.email_tries.filter(tmp => tmp.id == email_try).length > 0) {
			if(options.email_tries.filter(tmp => tmp.id == email_try)[0].can_send_token == 'after_call_inquiry'){
				if (!window.confirm(`Are you sure that you spoke on the phone for a client who wants to book from ${startDate.format('DD/MM/YYYY')} to ${endDate.format('DD/MM/YYYY')} with ${guests_no} guests?`)) {
					actions.setSubmitting(false);
					return;
				}
				submit_values = {
					email_try,
					emails,
					language_id,
					guests_no,
					contact_id: contact.id,
					start_date: startDate.format('YYYY-MM-DD'),
					end_date: endDate.format('YYYY-MM-DD')
				};
			} else if(options.email_tries.filter(tmp => tmp.id == email_try)[0].can_send_token == 'booking_advisor') {
				let validUrls = uniq(ba_urls).filter((url) => {
					return url != "";
				})
				if(!ba_search_url) {
					setBaFormError("Please enter a search URL");
					actions.setSubmitting(false);
					return;
				}
				if(!ba_intro_type) {
					setBaFormError("Please select an intro type");
					actions.setSubmitting(false);
					return;
				}
				if(validUrls.length < 1){
					setBaFormError("Enter more than 1 listing URL");
					actions.setFieldValue('ba_urls', uniq(ba_urls));
					actions.setSubmitting(false);
					return;
				}
				submit_values = {
					email_try,
					emails,
					phones,
					language_id,
					contact_id: contact.id,
					ba_intro_type,
					ba_custom_text: ba_custom_text && ba_custom_text.length > 0 ? ba_custom_text : undefined,
					ba_currency_id,
					ba_search_url,
					ba_urls: validUrls
				};
			} else if(options.email_tries.filter(tmp => tmp.id == email_try)[0].can_send_token == 'after_call_link'){
				if (!window.confirm('Are you sure that you spoke on the phone for a cooperation proposal and not for a client who is interested in booking the boat?')) {
					actions.setSubmitting(false);
					return;
				}
				submit_values = {
					email_try,
					emails,
					language_id,
					contact_id: contact.id
				};
			} else {
				submit_values = {
					email_try,
					emails,
					language_id,
					contact_id: contact.id
				};
			}
		} else {
			submit_values = {
				email_try,
				emails,
				language_id,
				contact_id: contact.id
			};
		}
		sendEmail(submit_values)
			.then(() => {
				window.popup.pop();
				onDismiss && onDismiss();
			})
			.catch(() => {})
			.then(() => {
				actions.setSubmitting(false);
			});
	};
	const Form = (props: FormProps) => {
		const { handleSubmit, options, contact, values, setFieldValue } = props;
		const handleEmailChange = (e: any, index: number): void => {
			const { checked } = e.target;
			const emailClicked = contact.emails[index].email;
			let newEmails = [...values.emails];
			const indexOfEmailClicked = newEmails.indexOf(emailClicked);
			if (checked) {
				if (indexOfEmailClicked < 0) {
					newEmails.push(emailClicked)
				}
			} else {
				newEmails.splice(indexOfEmailClicked, 1);
			}
			setFieldValue('emails', newEmails);
		};
		const handlePhoneChange = (e: any, index: number): void => {
			const { checked } = e.target;
			const phone = contact.phones[index]
			const phoneClicked = phone.country_code + phone.phone;
			let newPhones = [...values.phones];
			const indexOfPhoneClicked = newPhones.indexOf(phoneClicked);
			if (checked) {
				if (indexOfPhoneClicked < 0) {
					newPhones.push(phoneClicked)
				}
			} else {
				newPhones.splice(indexOfPhoneClicked, 1);
			}
			setFieldValue('phones', newPhones);
		};
		const addUrl = (): void => {
			let newUrls = [...values.ba_urls];
			let emptyUrls = newUrls.filter((url) => {
				return url == "";
			})
			if(emptyUrls.length > 0)
				return;
			newUrls.push("")
			setFieldValue('ba_urls', uniq(newUrls));
		};
		return (
			<form onSubmit={handleSubmit}>
				<FormikInputSelect
					flat
					fullWidth
					name="email_try"
					label="Type:"
					number
				>
					{options &&
					options.email_tries &&
					options.email_tries.filter(tmp => tmp.id != 4).map((emailTry: EmailTry) => {
						const { id, email_try } = emailTry;
						return (
							<option key={id} value={id}>
								{email_try}
							</option>
						);
					})}
				</FormikInputSelect>
				{values.email_try && options && (options.email_tries.filter(tmp => tmp.id == values.email_try).length > 0) && (options.email_tries.filter(tmp => tmp.id == values.email_try)[0].can_send_token == 'after_call_inquiry') && (
					<FormGroup>
						<Label title="Booking dates:" />
						<DateRangePicker
							startDate={startDate}
							startDateId="booking_start_date"
							startDatePlaceholderText="Start date"
							endDatePlaceholderText="End date"
							endDate={endDate}
							endDateId="booking_end_date"
							onDatesChange={({ startDate, endDate }) =>
								onDatesChange({ startDate, endDate })
							}
							focusedInput={focusedInput}
							displayFormat="DD-MM-YYYY"
							onFocusChange={focusedInput => setFocusedInput(focusedInput)} // PropTypes.func.isRequired,
							noBorder
							minimumNights={0}
							isOutsideRange={(d: any) => {
								return moment().diff(d, 'days') > 0;
							}}
						/>
						<FormikInputSelect
							flat
							fullWidth
							name="guests_no"
							label="Guests:"
							number
						>
							<option value="2">2</option>
							<option value="3">3</option>
							<option value="4">4</option>
							<option value="5">5</option>
							<option value="6">6</option>
							<option value="7">7</option>
							<option value="8">8</option>
							<option value="9">9</option>
							<option value="10">10</option>
							<option value="11">11</option>
							<option value="12">12</option>
							<option value="13">13</option>
							<option value="14">14</option>
							<option value="15">15</option>
							<option value="16">16</option>
							<option value="17">17</option>
							<option value="18">18</option>
							<option value="19">19</option>
							<option value="20">20</option>
							<option value="21">21</option>
							<option value="22">22</option>
							<option value="23">23</option>
							<option value="24">24</option>
							<option value="25">25</option>
							<option value="26">26</option>
							<option value="27">27</option>
							<option value="28">28</option>
							<option value="29">29</option>
							<option value="30">30</option>
							<option value="31">31</option>
							<option value="32">32</option>
							<option value="33">33</option>
							<option value="34">34</option>
							<option value="35">35</option>
							<option value="36">36</option>
							<option value="37">37</option>
							<option value="38">38</option>
							<option value="39">39</option>
							<option value="40">40</option>
							<option value="41">41</option>
							<option value="42">42</option>
							<option value="43">43</option>
							<option value="44">44</option>
							<option value="45">45</option>
							<option value="46">46</option>
							<option value="47">47</option>
							<option value="48">48</option>
							<option value="49">49</option>
							<option value="50">50</option>
							<option value="51">51</option>
							<option value="52">52</option>
							<option value="53">53</option>
							<option value="54">54</option>
							<option value="55">55</option>
							<option value="56">56</option>
							<option value="57">57</option>
							<option value="58">58</option>
							<option value="59">59</option>
							<option value="60">60</option>
							<option value="61">61</option>
							<option value="62">62</option>
							<option value="63">63</option>
							<option value="64">64</option>
							<option value="65">65</option>
							<option value="66">66</option>
							<option value="67">67</option>
							<option value="68">68</option>
							<option value="69">69</option>
							<option value="70">70</option>
							<option value="71">71</option>
							<option value="72">72</option>
							<option value="73">73</option>
							<option value="74">74</option>
							<option value="75">75</option>
							<option value="76">76</option>
							<option value="77">77</option>
							<option value="78">78</option>
							<option value="79">79</option>
							<option value="80">80</option>
						</FormikInputSelect>
					</FormGroup>
				)}
				{values.email_try && options && (options.email_tries.filter(tmp => tmp.id == values.email_try).length > 0) && (options.email_tries.filter(tmp => tmp.id == values.email_try)[0].can_send_token == 'booking_advisor') && (
				<div>
					<FormGroup>
						<FormikInputText
							style={{marginBottom: 10}}
							className={baFormError == "Please enter a search URL" && (!values.ba_search_url || values.ba_search_url.length < 1) ? 'ba-intro-error' : ''}
							noSpaces
							label="Search URL:"
							name={`ba_search_url`}
							type="text"
							flat
						/>
					</FormGroup>
					<FormGroup>
						<Label title="Listing URLs:" />
						{values.ba_urls && values.ba_urls.map((url: any, index) => {
							return (
								<FormikInputText
									style={{marginBottom: 10}}
									key={index}
									value={url}
									className={baFormError == "Enter more than 1 listing URL" && url == '' ? 'ba-intro-error' : ''}
									noSpaces
									name={`ba_urls.${index}`}
									type="text"
									flat
								/>
							);
						})}
						{values.ba_urls.filter((url) => {
							return url == "";
						}).length == 0 && (
							<Button transparent
									small
									onClick={addUrl}
									className={baFormError == "Enter more than 1 listing URL" && values.ba_urls.length < 1 ? 'ba-intro-error' : ''}
							>
								Add more
							</Button>
						)}
					</FormGroup>
					<FormGroup>
						<FormikInputSelect
							flat
							fullWidth
							name="ba_intro_type"
							label="Intro type:"
							number
							className={baFormError == "Please select an intro type" ? 'ba-intro-error' : ''}
							onChange={() => {
								if(baFormError == "Please select an intro type")
									setBaFormError(null);
							}}
						>
							<option value="1">Introduction</option>
							<option value="2">After First Touch</option>
							<option value="3">Following our communication</option>
							<option value="4">Custom Intro</option>
						</FormikInputSelect>
					</FormGroup>
					{values.ba_intro_type != 3 && (
						<FormGroup>
							<FormikInputTextarea name="ba_custom_text" label="Custom Text:" flat resize />
						</FormGroup>
					)}
					<FormGroup>
						<FormikInputSelect
							flat
							fullWidth
							name="ba_currency_id"
							label="Currency:"
							placeholder="Default"
						>
							<option value="">Default</option>
							<option value="EUR">EUR € </option>
							<option value="GBP">GBP £ </option>
							<option value="USD">USD $ </option>
							<option value="CAD">CAD $ </option>
							<option value="AUD">AUD $ </option>
							<option value="RUB">RUB ₽ </option>
							<option value="NZD">NZD $ </option>
							<option value="MXN">MXN $ </option>
							<option value="SGD">SGD $ </option>
							<option value="JPY">JPY ¥ </option>
							<option value="SEK">SEK kr</option>
							<option value="NOK">NOK kr</option>
							<option value="ISK">ISK kr</option>
							<option value="DKK">DKK kr</option>
							<option value="HRK">HRK kn</option>
							<option value="CZK">CZK Kč</option>
							<option value="RON">RON lei</option>
							<option value="BGN">BGN лв</option>
							<option value="HUF">HUF Ft</option>
							<option value="PLN">PLN zł</option>
							<option value="ILS">ILS ₪ </option>
							<option value="INR">INR Rp</option>
							<option value="KRW">KRW ₩ </option>
							<option value="HKD">HKD $ </option>
							<option value="TRY">TRY TL</option>
							<option value="BRL">BRL R$</option>
							<option value="CNY">CNY ¥ </option>
							<option value="CHF">CHF CHF</option>
							<option value="IDR">IDR Rp</option>
							<option value="MYR">MYR RM</option>
							<option value="PHP">PHP ₱ </option>
							<option value="THB">THB ฿ </option>
							<option value="ZAR">ZAR R </option>
						</FormikInputSelect>
					</FormGroup>
				</div>
				)}
				<FormikInputSelect
					flat
					fullWidth
					name="language_id"
					label="Language:"
					number
				>
					{options &&
					options.languages_on_emails &&
					options.languages_on_emails.map((lang: Language) => {
						const { id, language } = lang;
						return (
							<option value={id} key={id}>
								{language}
							</option>
						);
					})}
				</FormikInputSelect>
				{contact &&
				contact.emails &&
				contact.emails.map((em: Email, i: number) => (
					<FormGroup key={i}>
						<InputCheckbox
							value={values.emails.some(e => e === em.email)}
							onChange={(e: React.SyntheticEvent) => handleEmailChange(e, i)}
							checkboxLabel={em.email}
						/>
					</FormGroup>
				))}
				{values.email_try && options && (options.email_tries.filter(tmp => tmp.id == values.email_try).length > 0) && (options.email_tries.filter(tmp => tmp.id == values.email_try)[0].can_send_token == 'booking_advisor') &&
				contact &&
				contact.phones &&
				contact.phones.map((ph: Phone, i: number) => (
					<FormGroup key={i}>
						<InputCheckbox
							value={values.phones.some(p => p === `${ph.country_code}${ph.phone}`)}
							onChange={(e: React.SyntheticEvent) => handlePhoneChange(e, i)}
							checkboxLabel={`+${ph.country_code} ${ph.phone}`}
						/>
					</FormGroup>
				))}
				{values.email_try && options && (options.email_tries.filter(tmp => tmp.id == values.email_try).length > 0) && (options.email_tries.filter(tmp => tmp.id == values.email_try)[0].can_send_token == 'booking_advisor') && baFormError && (
					<div className="form-input__validation-error">
						{baFormError}
					</div>
				)}
				<FormikButtonSubmit {...props} type={'button'} title="Submit" fullWidth />
			</form>
		);
	};
	return (
		<Popup
			active={active}
			title="Send new email"
			className="popup--send_email"
			width={400}
		>
			<FormikForm
				render={(formProps: FormikProps<Values>) => (
					<Form {...formProps} {...props} />
				)}
				initialValues={initialValues}
				validationSchema={validationSchema}
				onSubmit={onSubmit}
			/>
		</Popup>
	);
};

function uniq(a) {
	let prims = {"boolean":{}, "number":{}, "string":{}}, objs = [];
	return a.filter(function(item) {
		let type = typeof item;
		if(type in prims)
			return prims[type].hasOwnProperty(item) ? false : (prims[type][item] = true);
		else
			return objs.indexOf(item) >= 0 ? false : objs.push(item);
	});
}

const mapStateToProps = (store: Store) => {
	const { options } = store;
	return { options };
};

const mapDispatchToProps = (dispatch: any) => {
	return {
		sendEmail: (data: Request$SendEmail) => dispatch(action$SendEmail(data))
	};
};

export default hot(module)(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(SendEmail)
);

// tslint:disable: max-line-length

import * as React from 'react';
import layout from '../../../../utils/layout';
import SvgTemplate from '../SvgTemplate';
import { Svg } from '../../Svg';

const icon = ({ color, ...rest }: Svg): JSX.Element => (
	<SvgTemplate {...rest} viewBox="0 0 96.26 96.261">
		<g>
			<g id="other_yacht">
				<polyline
					fill="#fff"
					points="63.293,65.167
            59.685,49.264 36.065,49.264 32.619,65.419"
					stroke="#8B8AA0"
					strokeLinejoin="round"
					strokeMiterlimit="10"
					strokeWidth="2"
				/>
				<path
					d="M71.655,68.458
            c0,0-0.692,2.77-2.247,6.524c-0.262,0.646-0.554,1.308-0.877,2.002l-20.7-7.035l-20.714,7.049
            c-2.156-4.726-3.125-8.541-3.125-8.541l23.839-9.59L71.655,68.458z"
					fill="#fff"
					stroke="#8B8AA0"
					strokeLinejoin="round"
					strokeMiterlimit="10"
					strokeWidth="2"
				/>
			</g>
			<g id="other">
				<path
					className="fill"
					d="M68.531,76.984
            c0,0.014-0.016,0.029-0.031,0.06c-0.923,1.985-2.062,4.155-3.432,6.264h-34.49c-1.37-2.093-2.509-4.248-3.432-6.248
            c0-0.016-0.016-0.047-0.031-0.062l20.714-7.049L68.531,76.984z"
					fill="#FFFFFF"
					stroke="#8B8AA0"
					strokeLinejoin="round"
					strokeMiterlimit="10"
					strokeWidth="2"
				/>
			</g>
		</g>
	</SvgTemplate>
);

icon.defaultProps = {
	width: layout.spacing.four,
	color: layout.color.white
};

export default icon;

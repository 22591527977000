import * as React from 'react';
import './Login.scss';
import {
	FormikForm,
	FormikInputText,
	FormikButtonSubmit,
	formikSubmit
} from '../../../components/Form';
import { RouterProps } from 'react-router';
import { FormikProps, FormikActions } from 'formik';
import * as Yup from 'yup';
import { Dispatch } from '../../../models';
import { connect } from 'react-redux';
import loginActionCreator, {
	ActionCreator$Login
} from '../../../redux/User/UserActions/Login';
import { hot } from 'react-hot-loader';
import { Request$Login } from '../../../api/User';

type Props = {
	login: ActionCreator$Login;
} & RouterProps;

interface Values {
	username: string;
	password: string;
}

const Login = (props: Props): JSX.Element => {
	const validationSchema = Yup.object().shape({
		user_email: Yup.string()
			.required()
			.email(),
		password: Yup.string().required()
	});

	const initialValues = {
		user_email: '',
		password: ''
	};

	const onSubmit = (values: Values, actions: FormikActions<Values>): void => {
		const { login } = props;
		formikSubmit(values, actions, login).then(res => {});
	};

	return (
		<div className="page page--login flex--center">
			<FormikForm
				validationSchema={validationSchema}
				initialValues={initialValues}
				onSubmit={onSubmit}
				render={(formProps: FormikProps<Values>) => <Form {...formProps} />}
			/>
		</div>
	);
};

const Form = (props: FormikProps<Values>) => {
	const { handleSubmit } = props;
	return (
		<form onSubmit={handleSubmit}>
			<FormikInputText
				name="user_email"
				placeholder="Email"
				flat
				label="Email:"
			/>
			<FormikInputText
				label="Password:"
				name="password"
				placeholder="Password"
				flat
				type="password"
			/>
			<FormikButtonSubmit {...props} title="Login" />
		</form>
	);
};

const mapDispatchToProps = (dispatch: any) => {
	return {
		login: (data: Request$Login) => dispatch(loginActionCreator(data))
	};
};

export default hot(module)(
	connect(
		null,
		mapDispatchToProps
	)(Login)
);

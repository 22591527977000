import * as React from 'react';
import './Messages.scss';
import Dialog from '../../../../components/Dialog';
import { EditContactProps } from '../EditContact';
import { connect } from 'react-redux';
import {  Action } from '../../../../models';
import { getMessages } from '../../../../redux/actions/Contact';
import { Contact } from '../../../../models/Contact';
import moment from 'moment';
import { popups, pushPopup } from '../../../../utils/popups';
import routes from "../../../routes";
import {Message} from "../../../../models/Message";

type Props = {
	getMessages: (id: string) => any;
	contact: Contact;
} & EditContactProps;

const Messages = (props: Props): JSX.Element => {
	const [messages, setMessages] = React.useState([]);
	const { getMessages, contact } = props;
	const bindMessages = () => {
		getMessages(contact.id).then((res: Action) => {
			setMessages(res.payload);
		});
	};

	React.useEffect(() => {
		contact && bindMessages();
	}, [contact]);

	return (
		<Dialog title="Messages" className="dialog--messages">
			<div className="inner-content">
				{messages.map((message: Message) => {
					const { id, phone, content, language, sent_date, sent_by_user, is_reply, template_id } = message;
					return (
						<div className={`message-row ${ is_reply ? 'recipient' : 'sender' } ${template_id ? 'template' : '' }`} key={id}>
							{phone && (
								<div className="row">
									<span className="row__label">Phone:</span>{' '}
									<span className="row__value">{phone}</span>
								</div>
							)}
							{language && (
								<div className="row">
									<span className="row__label">Language:</span>{' '}
									<span className="row__value">
									{language && language.language}
								</span>
								</div>
							)}
							<div className="row">
								<span className="row__label">Sent on:</span>{' '}
								<span className="row__value">
									{moment(sent_date).format('DD-MM-YYYY - HH:mm')}
								</span>
							</div>
							{sent_by_user && (
								<div className="row">
									<span className="row__label">Sent by:</span>{' '}
									<span className="row__value">
									{sent_by_user && sent_by_user.display_name}
								</span>
								</div>
							)}
							<div className="discussion">
								<div className={`bubble${
									is_reply ? ' recipient' : ' sender'
								}`}>
								<span className="message">
									{ content }
								</span>
								</div>
							</div>
						</div>
					);
				})}
			</div>
			<div className="dialog__footer">
				<div
					className="link"
					onClick={() => {
						pushPopup(popups.SendMessage, {
							...props,
							contact,
							onDismiss: bindMessages
						});
					}}
				>
					Send new SMS
				</div>
			</div>
		</Dialog>
	);
};

const mapDispatchToProps = (dispatch: any) => {
	return {
		getMessages: (id: string) => dispatch(getMessages(id))
	};
};

export default connect(
	null,
	mapDispatchToProps
)(Messages);

// tslint:disable: max-line-length
import * as React from 'react';
import layout from '../../../../utils/layout';
import SvgTemplate from '../SvgTemplate';
import { Svg } from '../../Svg';

const icon = ({ color, ...rest }: Svg): JSX.Element => (
	<SvgTemplate {...rest} viewBox="0 0 20 35">
		<g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<g id="slick-next" fill={color} fillRule="nonzero">
				<path
					d="M3.33831095,0.619894225 C2.65701846,-0.0763879578 1.52311906,-0.0763879578 0.818005145,0.619894225 C0.136712648,1.29265336 0.136712648,2.41235038 0.818005145,3.08354132 L13.5974008,15.7028718 L15.5337322,17.4592593 L0.818005145,31.809886 C0.136712648,32.5061681 0.136712648,33.6274334 0.818005145,34.2986243 C1.52311906,34.9949065 2.65860655,34.9949065 3.33831095,34.2986243 L19.1430264,18.6918669 C19.8481403,18.0191078 19.8481403,16.8994107 19.1430264,16.2282198 L3.33831095,0.619894225 Z"
					id="Shape"
				/>
			</g>
		</g>
	</SvgTemplate>
);

icon.defaultProps = {
	width: layout.spacing.two,
	color: layout.color.primary
};

export default icon;

const routes = {
	home: {
		base: '/'
	},
	profile: {
		base: '/profile'
	},
	contact: {
		base: '/contact',
		add: '/contact/add',
		list: '/contact/list',
		search: '/contact/search',
		show: (id: string | number = ':id') => `/contact/${id}`,
		edit: (id: string | number = ':id') => `/contact/${id}`,
		customEmail: {
			base: (id: string | number = ':id') => `/contact/${id}/custom-email`
		}
	},
	users: {
		base: '/users'
	},
	callCenter: {
		base: '/call-center'
	},
	dashboard: {
		base: '/dashboard'
	},
	toBeChecked: {
		base: '/to-be-checked'
	},
	helpdesk: {
		base: '/helpdesk'
	},
	pendingMerges: {
		base: '/pending-merges'
	}
};

export default routes;

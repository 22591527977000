import * as React from 'react';
import './Filters.scss';
import { FormGroup } from '../../../../components/Form';
import InputCheckbox from '../../../../components/Form/InputCheckbox';
import InputSelect from '../../../../components/Form/InputSelect';
import {
	CallStatus,
	Options,
	CountryOption,
	Region,
	Language,
	HaveSeenEmailStatus,
	LeadStatus,
	EmailStatus,
	BoatType,
} from '../../../../models/Options';
import { Store, Dispatch } from '../../../../models';

import { connect } from 'react-redux';
import MultiSelect from '../../../../components/Form/MultiSelect';
import Label from '../../../../components/Form/Label';
import User from '../../../../redux/entities/User';
import { listUsersActionCreator } from '../../../../redux/UserList/UserListActions';
import Button from '../../../../components/Button';
import {
	deleteContactsFromUser,
	Request$DeleteContactsFromUser
} from '../../../../api/Assignments';
import moment from 'moment';
import { DateRangePicker, FocusedInputShape } from 'react-dates';
import layout from '../../../../utils/layout';
import env from '../../../../config/env';

type Props = {
	onChange: (filters: any) => any;
	options: Options;
	userList: User[];
	fetchUserList: any;
	user: User;
};

class Filters extends React.Component<Props> {
	state = {
		text: '',
		call_status: 0,
		lead_status: 0,
		email_status: 0,
		have_seen_email_status: 0,
		second_langs: [],
		boat_types: [],
		main_language: '',
		to_be_checked: '',
		listed: undefined,
		country: [],
		to_be_called_today: 0,
		assigned_to: '',
		region: [],
		callFocusedInput: null,
		callStartDate: '',
		callEndDate: '',
		emailFocusedInput: null,
		emailStartDate: '',
		emailEndDate: '',
		smsFocusedInput: null,
		smsStartDate: '',
		smsEndDate: ''
	};

	componentDidMount() {
		const { userList, user } = this.props;
		if ((user.role_id <= 2 || user.role_id == 6) && !userList.length) {
			this.props.fetchUserList();
		}
	}

	setCallStatus = (e: any) => {
		const call_status = e.target.value;
		this.setState(
			{
				call_status
			},
			() => {
				this.props.onChange(this.state);
			}
		);
	};

	setMainLanguage = (e: any) => {
		const main_language = e.target.value;
		this.setState(
			{
				main_language
			},
			() => {
				this.props.onChange(this.state);
			}
		);
	};

	setEmailStatus = (e: any) => {
		const email_status = e.target.value;
		this.setState(
			{
				email_status
			},
			() => {
				this.props.onChange(this.state);
			}
		);
	};

	setSeenEmailStatus = (e: any) => {
		const have_seen_email_status = e.target.value;
		this.setState(
			{
				have_seen_email_status
			},
			() => {
				this.props.onChange(this.state);
			}
		);
	};

	setLeadStatus = (e: any) => {
		const lead_status = e.target.value;
		this.setState(
			{
				lead_status
			},
			() => {
				this.props.onChange(this.state);
			}
		);
	};

	setCountry = (country: string) => {
		this.setState(
			{
				country
			},
			() => {
				this.props.onChange(this.state);
			}
		);
	};

	setRegion = (region: string) => {
		this.setState(
			{
				region
			},
			() => {
				this.props.onChange(this.state);
			}
		);
	};

	setBoatTypes = (boat_types: string) => {
		this.setState(
			{
				boat_types
			},
			() => {
				this.props.onChange(this.state);
			}
		);
	};

	setSecondLangs = (second_langs: string) => {
		this.setState(
			{
				second_langs
			},
			() => {
				this.props.onChange(this.state);
			}
		);
	};

	onToBeCheckedChange = (e: any) => {
		const { value } = e.target;
		this.setState(
			{
				to_be_checked: value
			},
			() => {
				this.props.onChange(this.state);
			}
		);
	};

	onListedChange = (e: any) => {
		const { value } = e.target;

		this.setState(
			{
				listed: value.toString()
			},
			() => {
				this.props.onChange(this.state);
			}
		);
	};

	onToBeCalledTodayChange = (e: any) => {
		const { checked } = e.target;

		this.setState(
			{
				to_be_called_today: checked ? 1 : 0
			},
			() => {
				this.props.onChange(this.state);
			}
		);
	};

	// textTimeout: any;
	// onTextChange = (e: any) => {
	// 	clearTimeout(this.textTimeout);
	// 	this.setState(
	// 		{
	// 			text: e.target.value
	// 		},
	// 		() => {
	// 			this.textTimeout = setTimeout(() => {
	// 				this.props.onChange(this.state);
	// 			}, 500);
	// 		}
	// 	);
	// };

	setAssignedTo = (e: any) => {
		const { value } = e.target;
		this.setState(
			{
				assigned_to: value
			},
			() => {
				this.props.onChange(this.state);
			}
		);
	};

	clearAssignments = (user_id: any) => {
		const user = this.props.userList.filter(
			(user: User) => user.id === user_id
		)[0];
		if (!window.confirm(`Clear ${user.display_name}'s assignments?`)) {
			return;
		}
		/**
		 * clear contacts from user
		 */
		deleteContactsFromUser({user_id, contacts: []}).then();
		this.props.onChange(this.state);
	};

	onCallDateChange = ({ startDate, endDate }: any) => {
		this.setState(
			{
				callStartDate: startDate ? startDate.format('YYYY-MM-DD') : startDate,
				callEndDate: endDate ? endDate.format('YYYY-MM-DD') : endDate
			},
			() => {
				this.props.onChange(this.state);
			}
		);
	};

	onEmailDateChange = ({ startDate, endDate }: any) => {
		this.setState(
			{
				emailStartDate: startDate ? startDate.format('YYYY-MM-DD') : startDate,
				emailEndDate: endDate ? endDate.format('YYYY-MM-DD') : endDate
			},
			() => {
				this.props.onChange(this.state);
			}
		);
	};

	onSMSDateChange = ({ startDate, endDate }: any) => {
		this.setState(
			{
				smsStartDate: startDate ? startDate.format('YYYY-MM-DD') : startDate,
				smsEndDate: endDate ? endDate.format('YYYY-MM-DD') : endDate
			},
			() => {
				this.props.onChange(this.state);
			}
		);
	};

	render(): JSX.Element {
		const { options, userList, user } = this.props;

		if(env.backOffice === 'Owners') {
			var title1 = 'Yacht types';
			var title2 = '';
			var title3 = '';
		} else if (env.backOffice === 'Guests') {
			var title1 = 'Actions';
			var title2 = 'Budget amount';
			var title3 = '';
		} else if (env.backOffice === 'Collaborators') {
			var title1 = 'Collaborator type';
			var title2 = '';
			var title3 = '';
		} else {
			var title1 = 'Types';
			var title2 = '';
			var title3 = '';
		}

		const {
			text,
			call_status,
			to_be_checked,
			country,
			boat_types,
			second_langs,
			main_language,
			lead_status,
			email_status,
			have_seen_email_status,
			listed,
			region,
			callFocusedInput,
			callStartDate,
			callEndDate,
			emailFocusedInput,
			emailStartDate,
			emailEndDate,
			smsFocusedInput,
			smsStartDate,
			smsEndDate,
			assigned_to
		} = this.state;
		return (
				<div className="filters flex flex--start--between">
					{/* <InputText
					value={text}
					onChange={this.onTextChange}
					placeholder="name, email, phone, link"
					flat
					className="input--search"
				/> */}
					<div className="filter-section">
					<div style={{ fontSize: '17px', color: '#1f1a4f', paddingBottom: '15px' }}>Filters:</div>
						<div className="filters--row">
						<MultiSelect
							placeholder={title1}
							flat
							value={boat_types}
							onChange={this.setBoatTypes}
							className={
								boat_types.length ? 'multi--select active' : 'multi--select'
							}
						>
							{options &&
								options.boat_types.map((bt: BoatType) => {
									const { id, boat_type } = bt;
									return (
										<option key={`boat_type-${id}`} value={id}>
											{boat_type}
										</option>
									);
								})}
						</MultiSelect>
							<MultiSelect
								placeholder="Country"
								flat
								value={country}
								onChange={this.setCountry}
								className={
									country.length ? 'multi--select active' : 'multi--select'
								}
							>
								{options &&
									options.countries.map((c: CountryOption) => {
										const { id, country } = c;
										return (
											<option key={`country-${id}`} value={id}>
												{country}
											</option>
										);
									})}
							</MultiSelect>
							<MultiSelect
								placeholder="Region"
								flat
								value={region}
								onChange={this.setRegion}
								className={
									region.length ? 'multi--select active' : 'multi--select'
								}
							>
								{options &&
									options.regions.map((c: Region) => {
										const { id, region } = c;
										return (
											<option key={`region-${id}`} value={id}>
												{region}
											</option>
										);
									})}
							</MultiSelect>
							<MultiSelect
								placeholder="Second Languages"
								flat
								value={second_langs}
								onChange={this.setSecondLangs}
								className={
									second_langs.length ? 'multi--select active' : 'multi--select'
								}
							>
								{options &&
									options.languages.map((l: Language) => {
										const { id, country_iso, language } = l;
										return (
											<option key={`second_langs-${id}`} value={country_iso}>
												{language}
											</option>
										);
									})}
							</MultiSelect>
						</div>
						<div className="filters--row">
						<InputSelect
							placeholder="Main language"
							flat
							value={main_language}
							onChange={this.setMainLanguage}
							number
							className={main_language ? 'active' : ''}
							isStringValue
						>
						<option value={''}></option>
							{options &&
								options.languages.map((langs: Language) => {
									const { id, country_iso, language} = langs;
									return (
										<option key={id} value={country_iso}>
											{language}
										</option>
									);
								})}
						</InputSelect>
							<InputSelect
								placeholder={title2}
								flat
								value={email_status}
								onChange={this.setEmailStatus}
								number
								className={email_status ? 'active' : ''}
							>
								<option value={0}></option>
								{options &&
									options.email_status.map((es: EmailStatus) => {
										const { id, email_status } = es;
										return (
											<option key={id} value={id}>
												{email_status}
											</option>
										);
									})}
							</InputSelect>
							<InputSelect
								placeholder={title3}
								flat
								value={have_seen_email_status}
								onChange={this.setSeenEmailStatus}
								number
								className={have_seen_email_status ? 'active' : ''}
							>
								<option value={0}></option>
								{options &&
									options.have_seen_email_status.map((ses: HaveSeenEmailStatus) => {
										const { id, have_seen_email_status } = ses;
										return (
											<option key={id} value={id}>
												{have_seen_email_status}
											</option>
										);
									})}
							</InputSelect>
							<InputSelect
								placeholder="Lead status"
								flat
								value={lead_status}
								onChange={this.setLeadStatus}
								number
								className={lead_status ? 'active' : ''}
							>
								<option value={0}></option>
								{options &&
									options.lead_status.map((ls: LeadStatus) => {
										const { id, lead_status } = ls;
										return (
											<option key={id} value={id}>
												{lead_status}
											</option>
										);
									})}
							</InputSelect>
						</div>
						<div className="filters--row">
							<FormGroup>
								<InputSelect
								 placeholder="Check Status"
									name="to-be-checked"
									onChange={this.onToBeCheckedChange}
									value={to_be_checked.toString()}
									flat
									className={to_be_checked ? 'active' : ''}
								>
									<option value=""></option>
									<option value="0">Approved</option>
									<option value="1">To be checked</option>
									<option value="2">Rejected</option>
								</InputSelect>
							</FormGroup>
							<FormGroup>
								<InputSelect
								  placeholder="List Status"
									onChange={this.onListedChange}
									value={listed}
									flat
									number
									className={listed ? 'active' : ''}
								>
									<option value="" />
									<option value="1">Listed</option>
									<option value="0">Not listed</option>
								</InputSelect>
									</FormGroup>
									<FormGroup>
								<InputSelect
									placeholder="Call status"
									flat
									value={call_status}
									onChange={this.setCallStatus}
									number
									className={call_status ? 'active' : ''}
								>
									<option value={0}></option>
									{options &&
										options.call_status.map((status: CallStatus) => {
											const { id, call_status } = status;
											return (
												<option key={id} value={id}>
													{call_status}
												</option>
											);
										})}
								</InputSelect>
							</FormGroup>
							<FormGroup>
								<InputCheckbox
									checkboxLabel="To be called today"
									onChange={this.onToBeCalledTodayChange}
								/>
							</FormGroup>
						</div>
						{(user.role_id <= 2 || user.role_id == 6) && (
							<FormGroup>
								<Label title="Assigned to user:" />
								<div className="flex">
									<InputSelect
										flat
										value={assigned_to}
										number
										onChange={this.setAssignedTo}
										className={
											assigned_to ? 'multi--select active' : 'multi--select'
										}
									>
										<option value="">-</option>
										{userList &&
											userList.map((user: User) => {
												const { id, display_name } = user;
												return (
													<option key={id} value={id}>
														{display_name}
													</option>
												);
											})}
									</InputSelect>
									{assigned_to && (
										<Button
											style={{ marginLeft: '16px' }}
											small
											danger
											onClick={() => this.clearAssignments(assigned_to)}
										>
											Clear All Assignments from Selected User
										</Button>
									)}
								</div>
							</FormGroup>
						)}
					</div>
					<div className="dates" style={{minWidth: '284px'}}>
						<div style={{ marginBottom: '24px' }}>
							<div className="flex flex--center--between">
								<Label title="Call date:" />
								<Button
									small
									transparent
									onClick={() =>
										this.onCallDateChange({
											callStartDate: '',
											callEndDate: ''
										})
									}
								>
									Clear
								</Button>
							</div>
							<DateRangePicker
								startDate={callStartDate ? moment(callStartDate) : null} // momentPropTypes.momentObj or null,
								startDateId="call_start_date_id" // PropTypes.string.isRequired,
								startDatePlaceholderText="Start date"
								endDatePlaceholderText="End date"
								endDate={callEndDate ? moment(callEndDate) : null} // momentPropTypes.momentObj or null,
								endDateId="call_end_date_id" // PropTypes.string.isRequired,
								onDatesChange={({ startDate, endDate }: any) => {
									this.onCallDateChange({ startDate, endDate });
								}} // PropTypes.func.isRequired,
								focusedInput={callFocusedInput}
								displayFormat="DD-MM-YYYY"
								onFocusChange={(callFocusedInput: FocusedInputShape | null) => {
									this.setState({
										callFocusedInput
									});
								}} // PropTypes.func.isRequired,
								noBorder
								minimumNights={0}
								isOutsideRange={(d: any) => {
									return moment().diff(d, 'days') < 0;
								}}
							/>
						</div>
						<div style={{ marginBottom: '24px' }}>
							<div className="flex flex--center--between">
								<Label title="Email date:" />
								<Button
									small
									transparent
									onClick={() =>
										this.onEmailDateChange({
											emailStartDate: '',
											emailEndDate: ''
										})
									}
								>
									Clear
								</Button>
							</div>
							<DateRangePicker
								startDate={emailStartDate ? moment(emailStartDate) : null} // momentPropTypes.momentObj or null,
								startDateId="mail_start_date_id" // PropTypes.string.isRequired,
								startDatePlaceholderText="Start date"
								endDatePlaceholderText="End date"
								endDate={emailEndDate ? moment(emailEndDate) : null} // momentPropTypes.momentObj or null,
								endDateId="mail_end_date_id" // PropTypes.string.isRequired,
								onDatesChange={({ startDate, endDate }: any) => {
									this.onEmailDateChange({ startDate, endDate });
								}} // PropTypes.func.isRequired,
								focusedInput={emailFocusedInput}
								displayFormat="DD-MM-YYYY"
								onFocusChange={(
									emailFocusedInput: FocusedInputShape | null
								) => {
									this.setState({
										emailFocusedInput
									});
								}} // PropTypes.func.isRequired,
								noBorder
								minimumNights={0}
								isOutsideRange={(d: any) => {
									return moment().diff(d, 'days') < 0;
								}}
							/>
						</div>
						<div>
							<div className="flex flex--center--between">
								<Label title="SMS date:" />
								<Button
									small
									transparent
									onClick={() =>
										this.onSMSDateChange({
											smsStartDate: '',
											smsEndDate: ''
										})
									}
								>
									Clear
								</Button>
							</div>
							<DateRangePicker
								startDate={smsStartDate ? moment(smsStartDate) : null} // momentPropTypes.momentObj or null,
								startDateId="sms_start_date_id" // PropTypes.string.isRequired,
								startDatePlaceholderText="Start date"
								endDatePlaceholderText="End date"
								endDate={smsEndDate ? moment(smsEndDate) : null} // momentPropTypes.momentObj or null,
								endDateId="sms_end_date_id" // PropTypes.string.isRequired,
								onDatesChange={({ startDate, endDate }: any) => {
									this.onSMSDateChange({ startDate, endDate });
								}} // PropTypes.func.isRequired,
								focusedInput={smsFocusedInput}
								displayFormat="DD-MM-YYYY"
								onFocusChange={(
									smsFocusedInput: FocusedInputShape | null
								) => {
									this.setState({
										smsFocusedInput
									});
								}} // PropTypes.func.isRequired,
								noBorder
								minimumNights={0}
								isOutsideRange={(d: any) => {
									return moment().diff(d, 'days') < 0;
								}}
							/>
						</div>
					</div>
				</div>
		);
	}
}

function mapStateToProps(store: any) {
	const { options, userList, user } = store;
	return {
		options,
		userList,
		user
	};
}

function mapDispatchToProps(dispatch: any) {
	return {
		fetchUserList: () => dispatch(listUsersActionCreator())
	};
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Filters);

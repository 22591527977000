// tslint:disable: max-line-length

import * as React from 'react';
import layout from '../../../../utils/layout';
import SvgTemplate from '../SvgTemplate';
import { Svg } from '../../Svg';

const icon = ({ color, ...rest }: Svg): JSX.Element => (
	<SvgTemplate {...rest} viewBox="0 0 16 16">
		<line
			fill="none"
			stroke={color}
			strokeWidth="1.2"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeMiterlimit="10"
			x1="2.2"
			y1="2.2"
			x2="13.8"
			y2="13.8"
		/>
		<line
			fill="none"
			stroke={color}
			strokeWidth="1.2"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeMiterlimit="10"
			x1="13.8"
			y1="2.2"
			x2="2.2"
			y2="13.8"
		/>
	</SvgTemplate>
);

icon.defaultProps = {
	width: layout.spacing.ss,
	color: layout.color.primary
};

export default icon;

import * as React from 'react';
import Popup from '../Popup';
import { hot } from 'react-hot-loader';
import { User } from '../../../models/User';
import MultiSelect from "../../Form/MultiSelect";
import { setSupervisors} from '../../../api/User';
import Button from "../../Button";


interface Props {
	active: boolean;
	user: User;
	supervisors: any;
	userList: User[];
	onClose: () => any;
}

const EditSupervisors = (props: Props): JSX.Element => {
	const { active, user, userList, onClose } = props;
	const to_assign = userList.filter((user: User) => {
		return user.role_id == 6;
	});
	const [currentSelection, setCurrentSelection] = React.useState(
		user.supervised_by.map((item: any) => {
		return item.supervisors.supervisor_id;
	}));
	const supervisorsChanged = (data: any) => {
		setCurrentSelection(data);
	};
	const updateData = () => {
		setSupervisors({ id: user.id, supervisors: currentSelection })
			.then(res => {
				window.popup.pop();
				onClose && onClose();
			})
			.catch(() => {});
	};
	return (
		<Popup
			active={active}
			title={`Edit ${user.display_name}'s supervisors`}
			className="popup--change-user-password"
		>
			<MultiSelect
				placeholder="Select"
				flat
				value={currentSelection}
				onChange={supervisorsChanged}
				className={
					currentSelection.length ? 'multi--select active' : 'multi--select'
				}
			>
				{to_assign &&
				to_assign.map((u: User) => {
					const { id, display_name } = u;
					return (
						<option key={`user-${id}`} value={id}>
							{display_name}
						</option>
					);
				})}
			</MultiSelect>
			<Button
				fullWidth
				style={{
					marginTop: 20
				}}
				onClick={updateData}
			>
				Update
			</Button>
		</Popup>
	);
};

export default hot(module)(EditSupervisors);

import {post} from "../../redux/api/utils";

export type Request$ChangePassword = {
	new_password: string;
	old_password: string;
};

export type Response$ChangePassword = any;

/**
 * API POST Request to logout.
 */
export async function changePassword(
	data: Request$ChangePassword
): Promise<Response$ChangePassword> {
	return post('/profile/change-password', data);
}

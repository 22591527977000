// tslint:disable: max-line-length
import * as React from 'react';
import layout from '../../../../utils/layout';
import SvgTemplate from '../SvgTemplate';
import { Svg } from '../../Svg';

const icon = ({ color, ...rest }: Svg): JSX.Element => (
	<SvgTemplate {...rest} viewBox="0 0 12 8">
		<g
			id="Symbols"
			stroke="none"
			strokeWidth="1"
			fill="none"
			fillRule="evenodd"
		>
			<g
				id="drop_down"
				transform="translate(-178.000000, -41.000000)"
				fill={color}
			>
				<polygon
					id="Rectangle"
					transform="translate(184.010408, 42.010408) rotate(45.000000) translate(-184.010408, -42.010408) "
					points="187.554 37.0533009 188.967514 46.9675144 179.053301 45.5540004"
				/>
			</g>
		</g>
	</SvgTemplate>
);

icon.defaultProps = {
	width: layout.spacing.three,
	color: '#D8D8D8'
};

export default icon;

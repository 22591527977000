import { User } from '../../models/User';
import {post} from "../../redux/api/utils";

export type Request$Login = {
	email: string;
	password: string;
};
export type Response$Login = { data: User };

/**
 * API POST Request to login.
 */
export async function login(data: Request$Login): Promise<Response$Login> {
	return post('/auth/login', data);
}

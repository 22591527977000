import * as React from 'react';
import './Button.scss';
import Loader from '../Loader';

export interface ButtonProps {
	type?: 'submit' | 'reset' | 'button';
	title?: string;
	disabled?: boolean;
	loading?: boolean;
	className?: string;
	onClick?: (event: React.MouseEvent<HTMLButtonElement>) => any;
	component?: React.ComponentType;
	style?: object;
	danger?: any;
	warning?: boolean;
	success?: boolean;
	transparent?: boolean;
	dashed?: boolean;
	borderColor?: string;
	textColor?: string;
	fullWidth?: boolean;
	children?: any;
	opacity?: boolean;
	flat?: boolean;
	grey?: boolean;
	small?: boolean;
	tabIndex?: number;
	dataCy?: string;
}

const Button = (props: ButtonProps): JSX.Element => {
	const {
		disabled,
		onClick,
		type,
		className,
		style,
		danger,
		warning,
		success,
		transparent,
		borderColor,
		dashed,
		textColor,
		fullWidth,
		opacity,
		flat,
		grey,
		small,
		tabIndex,
		dataCy,
		...rest
	} = props;

	let buttonClass = className ? ` ${className}` : '';
	if (disabled) buttonClass += ' disabled';
	if (danger) buttonClass += ' danger';
	if (warning) buttonClass += ' warning';
	if (success) buttonClass += ' success';
	if (transparent) buttonClass += ' transparent';
	if (dashed) buttonClass += ' dashed';
	if (opacity) buttonClass += ' opacity';
	if (flat) buttonClass += ' flat';
	if (grey) buttonClass += ' grey';
	if (small) buttonClass += ' small';

	const buttonStyle: any = { ...style };
	if (borderColor) buttonStyle.borderColor = borderColor;
	if (fullWidth) buttonStyle.width = '100%';

	return (
		<button
			type={type || 'button'}
			disabled={disabled || props.loading}
			onClick={onClick}
			className={`btn ${buttonClass}`}
			style={buttonStyle}
			tabIndex={tabIndex}
			{...(dataCy ? { 'data-cy': dataCy } : {})}
		>
			<Inner {...rest} whiteLoader={!transparent} textColor={textColor} />
		</button>
	);
};

Button.defaultProps = {
	type: 'button'
};

export default Button;

interface ChildComponentProps {
	loading?: boolean;
	textColor?: string;
	whiteLoader?: boolean;
	children?: any;
}

const Inner = (props: ChildComponentProps) => {
	const { loading, whiteLoader, children } = props;
	return (
		<div className="btn__inner">
			<div className="btn__inner__box-shadow" />
			{loading && (
				<div className="loader-wrapper">
					<Loader white={whiteLoader} />
				</div>
			)}
			<div
				className={
					loading ? 'btn__content visibility-hidden' : 'btn__content no-select'
				}
			>
				{children}
			</div>
		</div>
	);
};

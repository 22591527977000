// tslint:disable: max-line-length
import * as React from 'react';
import layout from '../../../../utils/layout';
import SvgTemplate from '../SvgTemplate';
import { Svg } from '../../Svg';

const icon = ({ color, ...rest }: Svg): JSX.Element => (
	<SvgTemplate {...rest} viewBox="0 0 512 512">
		<g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<g id="maximize" fill={color} fillRule="nonzero">
				<path
					d="M491.318,0 L334.439,0 C323.016,0 313.757,9.26 313.757,20.682 C313.757,32.104 323.016,41.364 334.439,41.364 L470.636,41.364 L470.636,177.561 C470.636,188.983 479.895,198.243 491.318,198.243 C502.741,198.243 512,188.983 512,177.561 L512,20.682 C512,9.26 502.741,0 491.318,0 Z"
					id="Shape"
				/>
				<path
					d="M177.561,470.636 L41.365,470.636 L41.365,334.439 C41.365,323.016 32.105,313.757 20.683,313.757 C9.26,313.757 0,323.016 0,334.439 L0,491.318 C0,502.741 9.26,512 20.682,512 L177.561,512 C188.983,512 198.243,502.741 198.243,491.318 C198.244,479.895 188.983,470.636 177.561,470.636 Z"
					id="Shape"
				/>
				<path
					d="M505.943,6.058 C497.866,-2.019 484.771,-2.019 476.694,6.058 L293.669,189.083 C285.593,197.16 285.593,210.255 293.669,218.332 C297.707,222.37 303.001,224.39 308.294,224.39 C313.586,224.39 318.88,222.371 322.919,218.332 L505.943,35.307 C514.019,27.23 514.019,14.135 505.943,6.058 Z"
					id="Shape"
				/>
				<path
					d="M218.332,293.669 C210.256,285.592 197.16,285.592 189.083,293.669 L6.058,476.693 C-2.019,484.77 -2.019,497.865 6.058,505.942 C10.096,509.982 15.39,512 20.682,512 C25.975,512 31.268,509.981 35.307,505.942 L218.332,322.917 C226.409,314.841 226.409,301.746 218.332,293.669 Z"
					id="Shape"
				/>
			</g>
		</g>
	</SvgTemplate>
);

icon.defaultProps = {
	width: layout.spacing.three,
	color: layout.color.primary
};

export default icon;

// tslint:disable: max-line-length
import * as React from 'react';
import layout from '../../../../utils/layout';
import SvgTemplate from '../SvgTemplate';
import { Svg } from '../../Svg';

const icon = ({ color, ...rest }: Svg): JSX.Element => (
	<SvgTemplate {...rest} viewBox="0 0 55 34">
		<g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<g
				id="arrow-left"
				transform="translate(27.500000, 17.000000) rotate(180.000000) translate(-27.500000, -17.000000) "
				fill={color}
				fillRule="nonzero"
			>
				<path
					d="M52.49,14.52 L8.11,14.52 L18.7,4.02 C19.63,3.1 19.63,1.61 18.7,0.69 C17.77,-0.23 16.27,-0.23 15.34,0.69 L0.7,15.21 C-0.23,16.13 -0.23,17.62 0.7,18.54 L15.34,33.06 C15.8,33.52 16.41,33.75 17.02,33.75 C17.63,33.75 18.23,33.52 18.7,33.06 C19.63,32.14 19.63,30.65 18.7,29.73 L8.11,19.23 L52.49,19.23 C53.8,19.23 54.86,18.18 54.86,16.88 C54.86,15.58 53.8,14.52 52.49,14.52 Z"
					id="SvgjsPath1007"
				/>
			</g>
		</g>
	</SvgTemplate>
);

icon.defaultProps = {
	width: layout.spacing.two,
	color: layout.color.primary
};

export default icon;

import * as React from 'react';
import Popup from '../Popup';
import { hot } from 'react-hot-loader';
import {
	FormikForm,
	FormikInputText,
	FormikButtonSubmit,
	FormGroup,
	FormikInputSelect,
	formikSubmit, FormikInputTextarea
} from '../../Form';
import { FormikProps, FormikActions } from 'formik';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import { Store } from '../../../models';
import { getOptions, Action$GetOptions } from '../../../redux/actions';
import {Language, Options} from '../../../models/Options';
import { Request$EditFAQ } from "../../../api/Helpdesk/Api";
import {
	ActionCreator$AddFAQ, ActionCreator$EditFAQ,
	addFAQActionCreator,
	editFAQActionCreator
} from '../../../redux/Helpdesk/HelpdeskActions/Actions';
import { FAQ } from "../../../models/Helpdesk";

interface Props {
	active: boolean;
	options: Options;
	getOptions: Action$GetOptions;
	addFAQ: ActionCreator$AddFAQ;
	editFAQ: ActionCreator$EditFAQ;
	toUpdate?: FAQ;
}

type Values = Request$EditFAQ;

const validationSchema = Yup.object().shape({
	question: Yup.string().required(),
	answer: Yup.string().required(),
	language_id: Yup.number().required()
});

const AddHelpdeskScenario = (props: Props): JSX.Element => {
	const { active, toUpdate , options, getOptions, addFAQ, editFAQ } = props;
	React.useEffect(() => {
		if (!options)
			getOptions().then();
	}, []);

	const onSubmit = (values: Values, actions: FormikActions<Values>) => {
		let method = toUpdate ? editFAQ : addFAQ;
		if(toUpdate)
			values.id = toUpdate.id;
		formikSubmit(values, actions, method)
			.then(() => {
				window.popup.pop();
			})
			.catch(() => {})
			.then(() => {
				actions.setSubmitting(false);
			});
	};

	const initialValues = {
		question: '',
		answer: '',
		language_id: ''
	};

	if(toUpdate) {
		initialValues.question = toUpdate.question;
		initialValues.answer = toUpdate.answer;
		initialValues.language_id = toUpdate.language_id.toString();
	}

	const Form = (props: Props & FormikProps<Values>) => {
		const { handleSubmit, options } = props;
		return (
			<form onSubmit={handleSubmit}>
				<FormGroup>
					<FormikInputSelect label="Language:" name="language_id" flat>
						{options &&
						options.languages &&
						options.languages.map((item: Language) => {
							const { id, language } = item;
							return (
								<option value={id} key={id}>
									{language}
								</option>
							);
						})}
					</FormikInputSelect>
				</FormGroup>
				<FormGroup>
					<FormikInputText name="question" label="Question:" flat />
				</FormGroup>
				<FormGroup style={{'width' : '700px'}}>
					<FormikInputTextarea
						label="Answer:"
						name="answer"
						flat
						resize
					/>
				</FormGroup>
				<FormikButtonSubmit fullWidth {...props} title={ toUpdate ? 'Update' : 'Save' }  />
			</form>
		);
	};

	return (
		<Popup active={active} title={ toUpdate ? 'Edit FAQ' : 'Add new FAQ' } className="popup--add-new-faq">
			<FormikForm
				initialValues={initialValues}
				validationSchema={validationSchema}
				onSubmit={onSubmit}
				render={(formProps: FormikProps<Values>) => (
					<Form {...props} {...formProps} />
				)}
			/>
		</Popup>
	);
};

const mapStateToProps = (store: Store) => {
	const { options } = store;
	return { options };
};

const mapDispatchToProps = (dispatch: any) => {
	return {
		getOptions: () => dispatch(getOptions()),
		addFAQ: (data: Request$EditFAQ) => dispatch(addFAQActionCreator(data)),
		editFAQ: (data:Request$EditFAQ) => dispatch(editFAQActionCreator(data))
	};
};


export default hot(module)(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(AddHelpdeskScenario)
);

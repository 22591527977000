import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import UserReducer from '../User/UserReducer';
import UserListReducer from '../UserList/UserListReducer';
import tokenReducer from './TokenReducer';
import apiErrorReducer from './ApiError';
import optionsReducer from './OptionsReducer';
import contactListReducer from './ContactListReducer';
import StatisticsReducer from './StatisticsReducer';
import userToAssignListReducer from "../UserList/UserToAssignListReducer";
import CCAccountListReducer from "../CCAccountList/CCAccountListReducer";
import FAQReducer from "../Helpdesk/FAQReducer";
import ScenariosReducer from "../Helpdesk/ScenariosReducer";
import PendingMergeReducer from "./PendingMergeReducer/PendingMergeReducer";

export default (history: any) =>
	combineReducers({
		router: connectRouter(history),
		user: UserReducer,
		userList: UserListReducer,
		userToAssignList: userToAssignListReducer,
		token: tokenReducer,
		apiError: apiErrorReducer,
		options: optionsReducer,
		contactList: contactListReducer,
		statistics: StatisticsReducer,
		ccAccountList: CCAccountListReducer,
		faqList: FAQReducer,
		scenarioList: ScenariosReducer,
		pendingMerges: PendingMergeReducer
	});

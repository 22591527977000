import * as React from 'react';
import MultiSelect from '../MultiSelect';
import { FormikProps } from 'formik';
import {DoubleTextSelect} from "../DoubleTextSelect/DoubleTextSelect";

interface Props extends FormikProps<any> {
	optionsArray?: [];
	onChange: any;
	name: string;
	form: { values: any };
	label?: string;
	placeholder?: string;
	optionsKey: string;
	optionsValue: string;
	optionsText: string;
}


class MultiSelectOnForm extends React.Component<Props> {

	state = {
		array_value: []
	};

	arrayValueToString = (value: any): string => {
		const { optionsArray, optionsValue, optionsText } = this.props;
		const labelsSelected = [];
		if (optionsArray && value) {
			for (const child of optionsArray) {
				if (value.some((val: any): boolean => val === child[optionsValue])) {
					labelsSelected.push(child[optionsText]);
				}
			}
		}
		return labelsSelected.join(', ');
	};

	setArrayValue = (array_value: string) => {
		this.setState(
			{
				array_value
			}
		);
		const event = {
			target: {
				value: array_value,
				name: this.props.name
	  		}
		};
		this.props.onChange && this.props.onChange(event);
	};
	render(): JSX.Element {
		const { optionsArray, optionsKey, optionsValue, optionsText, form: { values }, label, placeholder, name } = this.props;

		const { array_value } = this.state;

return (
      <MultiSelect
        flat
        label={label}
        value={values[name] ? values[name] : array_value}
		placeholder={placeholder}
		onChange={this.setArrayValue}
        className="multi--select"
		insertStringValue={this.arrayValueToString(values[name])}
      >
        {optionsArray &&
          optionsArray.map((options: any) => {
            return (
              <option key={options[optionsKey]} value={options[optionsValue]}>
                {options[optionsText]}
              </option>
            );
          })}
      </MultiSelect>
		);
    }
}

export default React.memo(MultiSelectOnForm);

// tslint:disable: max-line-length

import * as React from 'react';
import layout from '../../../../utils/layout';
import SvgTemplate from '../SvgTemplate';
import { Svg } from '../../Svg';

const icon = ({ color, ...rest }: Svg): JSX.Element => (
	<SvgTemplate {...rest} viewBox="0 0 25 25">
		<g
			id="Page-1"
			stroke="none"
			strokeWidth="1"
			fill="none"
			fillRule="evenodd"
			opacity="0.223675272"
		>
			<g id="pass" fill={color} fillRule="nonzero">
				<g id="Page-1">
					<g id="landing_sign_in">
						<g id="keyhole">
							<g id="Capa_1">
								<g id="Group">
									<path
										d="M14.2664516,18.7825637 L10.711129,18.7825637 C10.2627419,18.7825637 9.83637097,18.595621 9.54137097,18.2697452 C9.27040323,17.9703025 9.14548387,17.5920382 9.18967742,17.2046178 C9.31314516,16.123328 9.97362903,13.5432325 10.2766935,12.3919586 C9.41145161,11.740207 8.89491935,10.7378981 8.89491935,9.6508758 C8.89491935,7.73351911 10.5070968,6.17356688 12.4887097,6.17356688 C14.4704032,6.17356688 16.0826613,7.73351911 16.0826613,9.6508758 C16.0826613,10.7378981 15.566129,11.7403662 14.7007258,12.3920382 C15.0039516,13.5433121 15.6644355,16.1231688 15.7879032,17.2044586 C15.8320161,17.5923567 15.7070161,17.970621 15.4360484,18.2699045 C15.1412097,18.5957803 14.7148387,18.7825637 14.2664516,18.7825637 Z M10.8182258,17.190207 L14.1595968,17.190207 C13.9465323,15.768551 13.0019355,12.273965 12.9919355,12.2371019 C12.8933065,11.8731688 13.0666129,11.4908439 13.4070161,11.3208599 C14.0626613,10.9936306 14.4699194,10.3536624 14.4699194,9.65079618 C14.4699194,8.61138535 13.5812097,7.76584395 12.488871,7.76584395 C11.3966129,7.76584395 10.5079839,8.61138535 10.5079839,9.65079618 C10.5079839,10.3535032 10.9152419,10.9934713 11.5708065,11.3209395 C11.9112903,11.4909236 12.0844355,11.8732484 11.9858065,12.2370223 C11.9758065,12.273965 11.0312903,15.7684713 10.8182258,17.190207 Z"
										id="Shape"
									/>
									<path
										d="M21.4062903,24.9781051 L3.57137097,24.9781051 C1.62741935,24.9781051 0.0458064516,23.3952229 0.0458064516,21.4496019 L0.0458064516,3.52834395 C0.0458064516,1.58280255 1.62741935,0 3.57137097,0 L21.4062097,0 C23.3502419,0 24.9319355,1.58280255 24.9319355,3.52834395 L24.9319355,21.4495223 C24.9319355,23.3952229 23.3503226,24.9781051 21.4062903,24.9781051 Z M3.57137097,1.59235669 C2.51677419,1.59235669 1.65870968,2.46082803 1.65870968,3.52834395 L1.65870968,21.4495223 C1.65870968,22.5171178 2.51677419,23.3856688 3.57137097,23.3856688 L21.4062097,23.3856688 C22.4608871,23.3856688 23.3190323,22.5171178 23.3190323,21.4495223 L23.3190323,3.52834395 C23.3190323,2.46082803 22.4608871,1.59235669 21.4062097,1.59235669 L3.57137097,1.59235669 Z"
										id="Shape"
									/>
								</g>
							</g>
						</g>
					</g>
				</g>
			</g>
		</g>
	</SvgTemplate>
);

icon.defaultProps = {
	width: layout.spacing.three,
	color: layout.color.primary
};

export default icon;

import {post} from "../../redux/api/utils";

export type Response$Logout = any;

/**
 * API POST Request to logout.
 */
export async function logout(): Promise<Response$Logout> {
	return post('/auth/logout', {});
}

import * as React from 'react';

interface Props {
	value: any;
	children: string;
	name?: string; // for double input fields (DoubleSelect etc)
	disabled?: boolean;
}

export const Option = (props: Props): JSX.Element => {
	const { value, children, disabled } = props;
	return (
		<option disabled={disabled} value={value}>
			{children}
		</option>
	);
};

export default React.memo(Option);

import * as React from 'react';
import { Options, SipAccount } from '../../../../models/Options';
import { connect } from 'react-redux';
import Label from '../../../../components/Form/Label';
import InputSelect from '../../../../components/Form/InputSelect';
import Dialog from '../../../../components/Dialog';
import User from '../../../../redux/entities/User';
import { FormGroup } from '../../../../components/Form';
import changeSipAccountActionCreator, {ActionCreator$ChangeSipAccount} from "../../../../redux/User/UserActions/ChangeSipAccount";
import {FormData$ChangeSipAccount} from "../../../../redux/entities/User/models";

type CallCenterSettingsProps = {
	options?: Options;
	user: User;
	changeSipAccount: ActionCreator$ChangeSipAccount;
};

const CallCenterSettings = (props: CallCenterSettingsProps) => {
	const { options, changeSipAccount, user } = props;
	const onSelectChange = (e: any) => {
		changeSipAccount({id: user.id, sip_account_id: e.target.value}).then(() => {
			window.location.reload()
		});
	};
	return (
		<Dialog title="Call Center Settings">
			<FormGroup>
				<Label title="Caller SIP Account:" />
				<InputSelect
					name="default_country_id"
					flat
					fullWidth
					number
					placeholder={'Default'}
					onChange={onSelectChange}
					value={user.sip_account_id}
				>
					<option value={null as any}>Default</option>
					{options &&
						options.sip_accounts &&
						options.sip_accounts.map((account: SipAccount) => {
							return (
								<option key={account.id} value={account.id}>
									{account.sip_username}{(account.description ? ' -- ' + account.description : '')}
								</option>
							);
						})}
				</InputSelect>
			</FormGroup>
		</Dialog>
	);
};

function mapStateToProps(store: any) {
	const { options, user } = store;
	return {
		options,
		user
	};
}

function mapDispatchToProps(dispatch: any) {
	return {
		changeSipAccount: (data: FormData$ChangeSipAccount) =>
			dispatch(changeSipAccountActionCreator(data))
	};
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(CallCenterSettings);

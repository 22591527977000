import { Action } from '../../../models';
import { LOADED_OPTIONS } from '../../actions/Options/constants';
import initialState from '../../store/initialState';

function reducer(options = initialState.options, action: Action): any {
	switch (action.type) {
		case LOADED_OPTIONS:
			return action.payload;
		default:
			return options;
	}
}

export default reducer;

import * as React from 'react';
import './InputSelect.scss';
import Svg from '../../Svg';
import layout from '../../../utils/layout';

interface Props {
	required?: boolean;
	className?: string;
	name?: string;
	onChange?: (e: React.SyntheticEvent) => any;
	onFocus?: () => any;
	onBlur?: () => any;
	value?: any;
	form?: any;
	placeholder?: string;
	children: any;
	validationError?: string;
	fullWidth?: boolean;
	flat?: boolean;
	invisible?: boolean;
	invisibleButWorks?: boolean;
	isStringValue?: boolean;
	noPlaceholder?: boolean;
	/* using this to parse InputSelect value from string to number */
	number?: boolean;
	dataCy?: string;
	disabled?: boolean;
}

export const InputSelect = (props: Props): JSX.Element => {
	const {
		className,
		name,
		onChange,
		onFocus,
		onBlur,
		children,
		placeholder,
		validationError,
		fullWidth,
		flat,
		invisible,
		invisibleButWorks,
		noPlaceholder,
		number,
		value,
		isStringValue,
		dataCy,
		disabled,
		form
	} = props;
	let selectClass = 'input-select';
	if (className) selectClass += ` ${className}`;
	if (validationError) selectClass += ' required';
	if (fullWidth) {
		selectClass += ' full-width';
	}
	if (flat) {
		selectClass += ' flat';
	}
	if (invisible) {
		selectClass += ' invisible';
	}
	if (invisibleButWorks) {
		selectClass += ' invisible-but-works';
	}

	const handleChange = (e: any): void => {
		if (number) {
			// pass the following object in `onChange` to convert the e.target.value from string to number
			const a: any = {
				target: {
					name: e.target.name,
					id: e.target.id,
					type: e.target.type,
					value: isStringValue ? e.target.value : (e.target.value ? Number(e.target.value) : '')
				}
			};
			onChange && onChange(a);
			if (form) {
				form.setFieldValue(name, a.target.value);
			}
		} else {
			onChange && onChange(e);
			if (form) {
				form.setFieldValue(name, e.target.value);
			}
		}
	};

	return (
		<React.Fragment>
			<div className={selectClass}>
				<select
					className="box-shadow--input"
					name={name}
					onChange={handleChange}
					onFocus={onFocus}
					onBlur={onBlur}
					value={value || ''}
					disabled={disabled}
					style={ placeholder && !value ? { color: '#b4b2c2' } : {} }
					{...(dataCy ? { 'data-cy': dataCy } : {})}
				>
					{!noPlaceholder && (
						<option disabled value="" hidden>
							{placeholder}
						</option>
					)}
					{children}
				</select>
				<Svg
					width={layout.spacing.ss}
					icon={flat || invisible ? 'carret-down-select' : 'carret-down'}
				/>
			</div>
		</React.Fragment>
	);
};

InputSelect.defaultProps = {
	value: '',
	onChange: () => null
};

export default React.memo(InputSelect);

declare const global: any;

export const requiredVariables = [
	{ nodeEnv: 'NODE_ENV' },
	{ appUrl: 'REACT_APP_URL' },
	{ apiUrl: 'REACT_APP_API_URL' },
	{ backOffice: 'REACT_APP_BACKOFFICE' },
	{ googleApiKey: 'REACT_APP_GOOGLE_API_KEY' },
	{ bucketUrl: 'REACT_APP_BUCKET_URL' },
	// { distUrl: 'REACT_APP_DIST_URL' },
	// { facebookAppId: 'REACT_APP_FACEBOOK_APP_ID' }
];

global.__DEV__ = process.env.NODE_ENV === 'development';
global.__TEST__ = process.env.NODE_ENV === 'test';
global.__PROD__ = process.env.NODE_ENV === 'production';

export function mapEnvVariables(variables: any) {
	const env: any = {}; // create env object
	for (const variable of variables) {
		const key: any = Object.keys(variable);
		const value: any = process.env[variable[key]];
		if (!value) {
			throw new Error(
				`Env variable \`${variable[key]}\` is required and has not been set.`
			);
		}
		env[key] = value;
	}
	return env;
}

const env = mapEnvVariables(requiredVariables);

export default env;

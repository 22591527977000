import {Action$ChangeAccountCountry, Action$ListCCAccounts} from '../CCAccountListActions';
import initialState from '../../store/initialState';
import { CallCenterAccount } from '../../../models/CallCenterAccount';
import { CCAccountListEnum } from '../enums';

type Actions = Action$ListCCAccounts | Action$ChangeAccountCountry;

function CCAccountListReducer(
	ccAccountList: any = initialState.ccAccountList,
	action: Actions
) {
	const list = [...ccAccountList];
	const { type, payload } = action;
	switch (type) {
		case CCAccountListEnum.LOADED_ACCOUNT_LIST:
			return payload.data;
		case CCAccountListEnum.ADDED_ACCOUNT:
			list.push(payload.data);
			return list;
		case CCAccountListEnum.CHANGED_ACCOUNT_COUNTRY:
			return list.map((account: CallCenterAccount) => {
				if (account.id === payload.data.id) {
					account.country_id = payload.data.country_id;
				}
				return account;
			});
		default:
			return ccAccountList;
	}
}

export default CCAccountListReducer;

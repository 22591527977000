import * as React from 'react';
import './StatisticsCard.scss';
import { Icons } from '../../Svg/icons';
import Svg from '../../Svg';
import layout from '../../../utils/layout';

type Props = {
	title: string;
	number: string | number | number[];
	icon: Icons;
	middle?: JSX.Element;
	topStats?: JSX.Element;
};

const StatisticsCard = (props: Props): JSX.Element => {
	const { title, number, icon, middle, topStats } = props;
	return (
		<div className="card--statistics">
			<div className="top">
				{typeof number === 'number' && <span className="number">{number}</span>}
				{middle && React.cloneElement(middle)}
			</div>
			<div className="middle bottom flex flex--center--between">
				<span className="title">{title}</span>
				<Svg icon={icon} color={layout.color.primaryLight} />
			</div>
			<div className="top-stats">
				{topStats && React.cloneElement(topStats)}
			</div>
		</div>
	);
};

export default StatisticsCard;

import { CallCenterAccount } from '../../models/CallCenterAccount';
import {post} from "../../redux/api/utils";

export type Request$AddAccount = {
	sip_username: string;
	sip_password: string;
	country_id: number;
	description?: string;
	is_default?: boolean;
};

export type Response$AddAccount = { data: CallCenterAccount };

/**
 * Creates new sip account in database.
 */
export async function addNewAccount(
	data: Request$AddAccount
): Promise<Response$AddAccount> {
	return post(`/sip-account/new`, data);
}

import * as React from 'react';
import './InputTextarea.scss';
import Svg from '../../../components/Svg';
import { Icons } from '../../../components/Svg/icons';

interface Props {
	value?: string;
	name?: string;
	onBlur?: () => any;
	onFocus?: () => any;
	onChange?: (e: React.SyntheticEvent) => any;
	placeholder?: string;
	validationError?: string;
	icon?: Icons;
	rows: number;
	resize?: boolean;
	autoResize?: boolean;
	maxRows: number;
	flat?: boolean;
	inputRef?: React.RefObject<any>;
	dataCy?: string;
	style?: object;
}

export const InputTextarea = (props: Props): JSX.Element => {
	const {
		name,
		value,
		onChange,
		onFocus,
		onBlur,
		validationError,
		icon,
		placeholder,
		resize,
		autoResize,
		maxRows,
		flat,
		inputRef,
		dataCy,
		style
	} = props;
	let { rows } = props; // using let because row may change
	let inputClass = 'input-textarea box-shadow--input';

	if (validationError) {
		inputClass += ' required';
	}
	if (icon) {
		inputClass += ' has-icon';
	}
	if (resize) {
		inputClass += ' resize';
	}
	if (flat) {
		inputClass += ' flat';
	}

	if (autoResize) {
		rows = getRows(value, rows, maxRows);
	}

	return (
		<div className={inputClass} style={style || {}}>
			<textarea
				ref={inputRef}
				rows={rows}
				name={name}
				value={value || ''}
				onChange={onChange}
				onFocus={onFocus}
				onBlur={onBlur}
				placeholder={placeholder}
				{...(dataCy ? { 'data-cy': dataCy } : {})}
			/>
			{icon && <Svg icon={icon} />}
		</div>
	);
};

InputTextarea.defaultProps = {
	rows: 3,
	maxRows: 10,
	resize: false
};

export default React.memo(InputTextarea);

const getRows = (value: any, defaultRows: number, maxRows: number): number => {
	// count carriage returns of value
	const carriageReturns = value ? value.match(/\n/g) : null;

	// keep minimum size (that we get from defaultProps.rows)
	if (!carriageReturns || carriageReturns.length < defaultRows) {
		return defaultRows;
	}

	// new rows count
	const rows = carriageReturns.length + 1;

	// return new rows count but don't exceed maxRows
	return rows < maxRows ? rows : maxRows;
};

import * as React from 'react'
import { connect } from 'react-redux'
import {
  setPrimaryInput,
  setPrimaryOutput,
  getInputAudioDevices,
  getOutputAudioDevices
} from '../actions/device'
import './Status.scss'
import Select from 'react-select'
// eslint-disable-next-line no-unused-vars
import { PhoneConfig, AppConfig } from '../models'

const settingsIcon = require('../../../assets/img/settings-24px.svg')
const micIcon = require('../../../assets/img/mic-24px.svg')
const soundIcon = require('../../../assets/img/volume_up-24px.svg')

interface Props {
  phoneConfig: PhoneConfig
  appConfig: AppConfig
  name?: string
  inputs: any
  outputs: any
  primaryInput: string
  primaryOutput: string
  setPrimaryInput: Function
  setPrimaryOutput: Function
  getInputAudioDevices: Function
  getOutputAudioDevices: Function
  sessions: any
  sinkIdAllowed: boolean
}

class Status extends React.Component<Props> {
  state = { settingsMenu: false }

  componentDidMount() {
    this.props.getInputAudioDevices()
    this.props.getOutputAudioDevices()
  }

  mapOptions(options: any) {
    const list: any = []
    options.map((option: any) => {
      list.push({ value: option.deviceId, label: option.label })
    })
    return list
  }

  handleChangeDevice(type: string, id: string) {
    if (type === 'out') {
      this.props.setPrimaryOutput(id, this.props.sessions)
    } else {
      this.props.setPrimaryInput(
        id,
        this.props.sessions,
        this.props.sinkIdAllowed
      )
    }
  }

  render() {
    const { props, state } = this
    const inputs = this.mapOptions(props.inputs)
    const outputs = this.mapOptions(props.outputs)
    return (
      <React.Fragment>
        <div className={'container-box'}>
          {props.phoneConfig.disabledFeatures.includes('settings') ? null :(
            <div
              id={'settingsButton'}
              className={state.settingsMenu ? 'on' : ''}
              onClick={() =>
                this.setState({ settingsMenu: !state.settingsMenu })
              }
            >
              <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><g><path d="M0,0h24v24H0V0z" fill="none"/><path d="M19.14,12.94c0.04-0.3,0.06-0.61,0.06-0.94c0-0.32-0.02-0.64-0.07-0.94l2.03-1.58c0.18-0.14,0.23-0.41,0.12-0.61 l-1.92-3.32c-0.12-0.22-0.37-0.29-0.59-0.22l-2.39,0.96c-0.5-0.38-1.03-0.7-1.62-0.94L14.4,2.81c-0.04-0.24-0.24-0.41-0.48-0.41 h-3.84c-0.24,0-0.43,0.17-0.47,0.41L9.25,5.35C8.66,5.59,8.12,5.92,7.63,6.29L5.24,5.33c-0.22-0.08-0.47,0-0.59,0.22L2.74,8.87 C2.62,9.08,2.66,9.34,2.86,9.48l2.03,1.58C4.84,11.36,4.8,11.69,4.8,12s0.02,0.64,0.07,0.94l-2.03,1.58 c-0.18,0.14-0.23,0.41-0.12,0.61l1.92,3.32c0.12,0.22,0.37,0.29,0.59,0.22l2.39-0.96c0.5,0.38,1.03,0.7,1.62,0.94l0.36,2.54 c0.05,0.24,0.24,0.41,0.48,0.41h3.84c0.24,0,0.44-0.17,0.47-0.41l0.36-2.54c0.59-0.24,1.13-0.56,1.62-0.94l2.39,0.96 c0.22,0.08,0.47,0,0.59-0.22l1.92-3.32c0.12-0.22,0.07-0.47-0.12-0.61L19.14,12.94z M12,15.6c-1.98,0-3.6-1.62-3.6-3.6 s1.62-3.6,3.6-3.6s3.6,1.62,3.6,3.6S13.98,15.6,12,15.6z"/></g></svg>
            </div>
          )}
        </div>
        {props.phoneConfig.disabledFeatures.includes('settings') ? null :
          <div
            id={'settingsMenu'}
            className={state.settingsMenu ? '' : 'closed'}
          >
            <hr style={{ width: '100%' }} />
            <div className={'dropdownRow'}>
              <img className={'dropdownIcon'} src={soundIcon} />
              <Select
                placeholder='Select Output...'
                value={
                  outputs.find(
                    (output: any) => output.value === props.primaryOutput
                  ) || null
                }
                onChange={(option) =>
                  this.handleChangeDevice('out', option.value)
                }
                options={outputs}
                id={'dropdowns'}
              />
            </div>
            <div className={'dropdownRow'}>
              <img className={'dropdownIcon'} src={micIcon} />
              <Select
                placeholder='Select Input...'
                value={inputs.find(
                  (input: any) => input.value === props.primaryInput
                )}
                onChange={(option) =>
                  this.handleChangeDevice('in', option.value)
                }
                options={inputs}
                id={'dropdowns'}
              />
            </div>
            <hr style={{ width: '100%' }} />
          </div>
        }

      </React.Fragment>
    )
  }
}
const mapStateToProps = (state: any) => ({
  inputs: state.device.audioInput,
  outputs: state.device.audioOutput,
  primaryInput: state.device.primaryAudioInput,
  primaryOutput: state.device.primaryAudioOutput,
  sessions: state.sipSessions.sessions,
  sinkIdAllowed: state.device.sinkId
})

const actions = {
  setPrimaryInput,
  setPrimaryOutput,
  getInputAudioDevices,
  getOutputAudioDevices
}

export default connect(mapStateToProps, actions)(Status)

import {post} from "../../redux/api/utils";

export type Request$DeleteContactsFromUser = {
	user_id: number;
	contacts: number[];
};

export type Response$DeleteContactsFromUser = {
	message: 'ok';
};

function deleteContactsFromUser(
	data: Request$DeleteContactsFromUser
): Promise<Response$DeleteContactsFromUser> {
	return post('/assignments/delete-from-user', data);
}

export { deleteContactsFromUser };

// tslint:disable: max-line-length

import * as React from 'react';
import layout from '../../../../utils/layout';
import SvgTemplate from '../SvgTemplate';
import { Svg } from '../../Svg';

const icon = ({ color, ...rest }: Svg): JSX.Element => (
	<SvgTemplate {...rest} viewBox="0 0 512 403">
		<g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<g id="list" fill={color} fillRule="nonzero">
				<path
					d="M118.771,146.999 L27.406,146.999 C19.795,146.999 13.322,149.663 7.992,154.993 C2.663,160.32 0,166.791 0,174.407 L0,229.23 C0,236.84 2.663,243.308 7.992,248.636 C13.322,253.965 19.795,256.63 27.406,256.63 L118.771,256.63 C126.382,256.63 132.855,253.965 138.185,248.636 C143.515,243.308 146.177,236.84 146.177,229.23 L146.177,174.407 C146.177,166.791 143.515,160.32 138.185,154.993 C132.855,149.666 126.382,146.999 118.771,146.999 Z"
					id="Shape"
				/>
				<path
					d="M118.771,0.814 L27.406,0.814 C19.795,0.814 13.322,3.477 7.992,8.807 C2.663,14.137 0,20.61 0,28.221 L0,83.042 C0,90.658 2.663,97.133 7.992,102.459 C13.322,107.786 19.795,110.453 27.406,110.453 L118.771,110.453 C126.382,110.453 132.855,107.786 138.185,102.459 C143.515,97.132 146.177,90.661 146.177,83.045 L146.177,28.225 C146.177,20.614 143.515,14.141 138.185,8.808 C132.855,3.48 126.382,0.814 118.771,0.814 Z"
					id="Shape"
				/>
				<path
					d="M118.771,293.177 L27.406,293.177 C19.795,293.177 13.322,295.839 7.992,301.171 C2.663,306.502 0,312.974 0,320.585 L0,375.411 C0,383.021 2.663,389.497 7.992,394.821 C13.322,400.153 19.795,402.812 27.406,402.812 L118.771,402.812 C126.382,402.812 132.855,400.149 138.185,394.821 C143.515,389.497 146.177,383.021 146.177,375.411 L146.177,320.585 C146.177,312.974 143.515,306.502 138.185,301.174 C132.855,295.846 126.382,293.177 118.771,293.177 Z"
					id="Shape"
				/>
				<path
					d="M484.215,146.999 L210.131,146.999 C202.517,146.999 196.047,149.663 190.717,154.993 C185.387,160.323 182.725,166.791 182.725,174.407 L182.725,229.23 C182.725,236.84 185.387,243.308 190.717,248.636 C196.044,253.965 202.517,256.63 210.131,256.63 L484.222,256.63 C491.832,256.63 498.307,253.965 503.632,248.636 C508.964,243.308 511.626,236.84 511.626,229.23 L511.626,174.407 C511.626,166.791 508.964,160.32 503.629,154.993 C498.3,149.663 491.833,146.999 484.215,146.999 Z"
					id="Shape"
				/>
				<path
					d="M484.215,293.177 L210.131,293.177 C202.517,293.177 196.047,295.839 190.717,301.171 C185.387,306.502 182.725,312.971 182.725,320.581 L182.725,375.404 C182.725,383.015 185.387,389.493 190.717,394.821 C196.044,400.149 202.517,402.808 210.131,402.808 L484.222,402.808 C491.832,402.808 498.307,400.146 503.632,394.821 C508.964,389.49 511.626,383.015 511.626,375.404 L511.626,320.581 C511.626,312.971 508.964,306.496 503.629,301.171 C498.3,295.846 491.833,293.177 484.215,293.177 Z"
					id="Shape"
				/>
				<path
					d="M503.629,8.811 C498.3,3.484 491.832,0.818 484.215,0.818 L210.131,0.818 C202.517,0.818 196.047,3.481 190.717,8.811 C185.387,14.141 182.725,20.614 182.725,28.225 L182.725,83.046 C182.725,90.662 185.387,97.129 190.717,102.46 C196.044,107.787 202.517,110.454 210.131,110.454 L484.222,110.454 C491.832,110.454 498.3,107.787 503.632,102.46 C508.964,97.133 511.626,90.662 511.626,83.046 L511.626,28.225 C511.626,20.613 508.964,14.141 503.629,8.811 Z"
					id="Shape"
				/>
			</g>
		</g>
	</SvgTemplate>
);

icon.defaultProps = {
	width: layout.spacing.two,
	color: layout.color.primary
};

export default icon;

import {post} from "../../redux/api/utils";

export type Request$AdminChangePassword = {
	id: string | number;
	password: string;
};

export type Response$AdminChangePassword = { message: 'ok' };

/**
 * Super admin's change password end point
 */
export async function changeUserPassword(
	data: Request$AdminChangePassword
): Promise<Response$AdminChangePassword> {
	const { id, password } = data;
	return post(`/user/${id}/change-password`, { password });
}

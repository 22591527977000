import * as React from 'react';
import './Label.scss';
import Hint from '../../Hint';
import layout from '../../../utils/layout';

interface Props {
	title?: string | JSX.Element;
	component?: any;
	className?: string;
	hint?: string;
	prive?: boolean | string;
}

export const Label = (props: Props): JSX.Element | null => {
	const { title, className, component, hint, prive } = props;
	let labelClass = 'label';
	if (className) labelClass += ` ${className}`;
	if (prive) {
		labelClass += ' private';
	}
	return !title && !component ? null : (
		<label className={labelClass}>
			<React.Fragment>
				{prive && (
					<Hint
						className="hint--private"
						icon="locker"
						iconWidth={layout.spacing.ss}
						text={
							typeof prive === 'string'
								? prive
								: 'This information is private and will never be shown to other users.'
						}
					/>
				)}

				{/* can render a string */}
				{title && typeof title === 'string' && (
					<div className="label__title">{title}</div>
				)}

				{/* or can render a component */}
				{title && typeof title === 'object' && React.cloneElement(title)}

				{hint && <Hint text={hint} />}

				{/* render a custom component */}
				{component && React.cloneElement(component)}
			</React.Fragment>
		</label>
	);
};

export default React.memo(Label);

import * as React from 'react'
import { connect } from 'react-redux'
import SIPAccount from '../lib/SipAccount'
import './Dialstring.scss'
import { PhoneConfig, SipConfig, AppConfig } from '../models'
import { sessionsLimitReached } from '../actions/config'
import Svg from "../../../components/Svg";
import layout from "../../layout";
import {uuid} from "../../functions";

const callIcon = require('../../../assets/img/call-24px.svg')
const callIconLarge = require('../../../assets/img/call-large-40px.svg')

interface Props {
    sipAccount: SIPAccount
    phoneConfig: PhoneConfig
    sipConfig: SipConfig
    appConfig: AppConfig
    sessions: Object
    started: Boolean
    sessionsLimitReached: Function
    attendedTransfersList: Array<string>
    numberList?: Array<any>
    isAdmin?: boolean
    onOutgoingCall?: Function
    needsConfirm?: boolean
}

class Dialstring extends React.Component<Props> {
    state = {
        selectedIndex: 0,
        currentDialString: this.props.numberList && this.props.numberList!.length > 0 ?
            this.props.numberList![0].country.country_phone_code + this.props.numberList![0].phone : '',
        rawDialString: this.props.numberList && this.props.numberList!.length > 0 ? this.props.numberList![0].phone : ''
    }

    handleDial() {
        const sessionsActive: number = Object.keys(this.props.sessions).length
        const attendedTransferActive: number = this.props.attendedTransfersList.length
        const sessionDiff: number = sessionsActive - attendedTransferActive
        // sessionsLimit check
        if (sessionDiff >= this.props.phoneConfig.sessionsLimit) {
            this.props.sessionsLimitReached()
        } else {
            const newCallId = uuid();
            const InviterOptions = !this.props.isAdmin ? {
                extraHeaders: [
                    `X-Call-Internal-Identifier: ${newCallId}`,
                    `X-Call-Origin: ${process.env.REACT_APP_BACKOFFICE}`,
                    `X-Call-Environment: ${process.env.NODE_ENV}`,
                    `X-Call-App-Url: ${process.env.REACT_APP_URL}`
                ]
            } : {};
            let callNumber = '';
            let callNumberRaw = '';
            // strict-mode check
            if (this.props.appConfig.mode === 'strict') {
                callNumber = this.props.phoneConfig.defaultDial;
                callNumberRaw = this.props.phoneConfig.defaultDial;
            } else {
                const numberToCall = this.getDialStringFromListIndex(this.state.selectedIndex);
                callNumber = numberToCall.currentDialString;
                callNumberRaw = numberToCall.rawDialString;
            }
            // Fires new call event for parent if call is established
            const callEstablished = () => {
                if(this.props.onOutgoingCall)
                    this.props.onOutgoingCall({
                        call_id: newCallId,
                        type: 'Call Established',
                        to: callNumberRaw
                    });
            }
            // Fires event on other side hang-up
            const onHangUp = () => {
                if(this.props.onOutgoingCall)
                    this.props.onOutgoingCall({
                        call_id: newCallId,
                        type: 'User hanged up',
                        to: callNumberRaw
                    });
            }
            this.props.sipAccount.makeCall(`${callNumber}`, InviterOptions, callEstablished, onHangUp);
        }
    }

    checkDialstring() {
        return this.state.currentDialString.length === 0
    }

    getDialStringFromListIndex(index: number) {
        return !this.props.isAdmin ? {
            currentDialString: this.props.numberList![index].country.country_phone_code + this.props.numberList![index].phone,
            rawDialString: this.props.numberList![index].phone
        } : {
            currentDialString: this.state.currentDialString,
            rawDialString: this.state.rawDialString
        };
    }

    render() {
        const { props } = this;
        if (props.appConfig.mode.includes('strict') && props.started === true) {
            return (
                <div className={'dialstringContainerStrict'}>
                    <button
                        className={'dialButtonStrict'}
                        onClick={() => this.handleDial()}
                    >
                        <img src={callIconLarge} />
                    </button>
                </div>
            )
        } else if (props.appConfig.mode.includes('strict')) {
            return null
        } else if ((props.numberList && props.numberList.length > 0) || !props.isAdmin) {
            return (
                <div className={'dialstringContainer'}>
                    <select
                        value={this.state.selectedIndex}
                        className={'dialInput'}
                        onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                                this.handleDial()
                                e.preventDefault()
                            }
                        }}
                        onChange={(e) => {
                            this.setState({ selectedIndex: e.target.value })
                        }}
                    >
                        {props.numberList &&
                        props.numberList.map((phone: any, index) => {
                            return (
                                <option key={phone.id} value={index}>
                                    {phone.phone}
                                </option>
                            );
                        })}
                    </select>
                    <Svg
                        width={layout.spacing.ss}
                        icon={'carret-down-select'}
                    />
                    <button
                        className={'dialButton'}
                        disabled={this.checkDialstring()}
                        onClick={() => {
                            if(this.props.needsConfirm) {
                                if(window.confirm(`Are you sure that you want to call ${this.getDialStringFromListIndex(this.state.selectedIndex).rawDialString}?`))
                                    this.handleDial()
                            } else {
                                this.handleDial()
                            }
                        }}
                    >
                        <img src={callIcon} />
                    </button>
                </div>
            )
        } else if(props.isAdmin) {
            return (
                <div className={'dialstringContainer'}>
                    <input
                        className={'dialInput'}
                        onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                                this.handleDial()
                                e.preventDefault()
                            }
                        }}
                        onKeyDown={(e: any) => {
                            if (!/^\d+$/.test(e.key) && e.key != 'Delete' && e.key != 'Backspace' && e.key != 'ArrowLeft' && e.key != 'ArrowRight') {
                                e.preventDefault();
                            }
                        }}
                        placeholder='Enter the number to dial...'
                        onChange={(e) =>
                            this.setState({ currentDialString: e.target.value })
                        }
                    />
                    <button
                        className={'dialButton'}
                        disabled={this.checkDialstring()}
                        onClick={() => this.handleDial()}
                    >
                        <img src={callIcon} />
                    </button>
                </div>
            )
        }
    }
}

const mapStateToProps = (state: any) => ({
    sipAccount: state.sipAccounts.sipAccount,
    sessions: state.sipSessions.sessions,
    started: state.config.appConfig.started,
    attendedTransfersList: state.sipSessions.attendedTransfers
})

const actions = {
    sessionsLimitReached
}

const D = connect(mapStateToProps, actions)(Dialstring)
export default D

import * as React from 'react';
import './SideNavLink.scss';
import { Link, withRouter, RouteComponentProps } from 'react-router-dom';
import Svg from '../../Svg';
import layout from '../../../utils/layout';
import { Icons } from '../../Svg/icons';

interface Props extends RouteComponentProps {
	to: string;
	children: any;
	dataCy?: string;
	icon: Icons;
	exact?: boolean;
}

export const SideNavLink = (props: Props) => {
	const { to, children, location, dataCy, icon, exact } = props;
	let className = 'sidenav-link';
	if (
		(location && location.pathname === to) ||
		(!exact && location.pathname.indexOf(to) > -1)
	) {
		className += ' active';
	}

	return (
		<div className={className} {...(dataCy ? { 'data-cy': dataCy } : {})}>
			<Link to={to}>
				<Svg width="20" icon={icon} color={layout.color.primaryLight} />{' '}
				{children}
			</Link>
		</div>
	);
};

export default withRouter(React.memo(SideNavLink));

import * as React from 'react';
import './History.scss';
import Dialog from '../../../../components/Dialog';
import { EditContactProps } from '../EditContact';
import { connect } from 'react-redux';
import { Action } from '../../../../models';
import { getEditHistory, getViewHistory } from '../../../../redux/actions/Contact';
import { Contact } from '../../../../models/Contact';
import moment from "moment";
const HumanDiff = require('human-object-diff');

type Props = {
	getEditHistory: (id: string) => any;
	getViewHistory: (id: string) => any;
	contact: Contact;
} & EditContactProps;

const History = (props: Props): JSX.Element => {
	const [editHistory, setEditHistory] = React.useState([]);
	const [viewHistory, setViewHistory] = React.useState([]);
	const { getEditHistory, getViewHistory, contact, options } = props;
	const prefilter = (path: any, key: any) => {
		return (path[0] === 'phones' && key === 'id') ||
			(key === 'id') ||
			(key === 'history_captured_at') ||
			(key === 'user_id') ||
			(key === 'user');
	};
	const templates = {
		N: '"FIELD", with a value of "NEWVALUE" (at DOTPATH) was added',
		D: '"FIELD", with a value of "OLDVALUE" (at DOTPATH) was removed',
		E: 'FIELD changed from OLDVALUE to NEWVALUE',
		I: 'NEWVALUE was added to FIELD',
		R: 'removed OLDVALUE from FIELD',
		AE: 'OLDVALUE in FIELD was changed to NEWVALUE',
		NS: '"FIELD" (at DOTPATH) was added',
		DS: '"FIELD" (at DOTPATH) was removed',
		ES: '"FIELD" (at DOTPATH) was changed',
		IS: 'Array "FIELD" (at DOTPATH), had a value inserted at index INDEX',
		RS: 'Array "FIELD" (at DOTPATH), had a value removed at index INDEX',
		AES: 'Array "FIELD" (at DOTPATH), had a value changed at index INDEX'
	};
	const diffOptions = {prefilter, templates};
	const { diff } = new HumanDiff(diffOptions);

	const bindContactHistory = () => {
		getEditHistory(contact.id).then((res: Action) => {
			// let history = res.payload;
			// return setEditHistory(history.map((history: any, index) => {
			// 	const changes = diff(history, history[index - 1]);
			// 	return {
			// 		...history,
			// 		changes
			// 	}
			// }));
			setEditHistory(res.payload);
		});
		getViewHistory(contact.id).then((res: Action) => {
			setViewHistory(res.payload);
		});
	};

	React.useEffect(() => {
		contact && bindContactHistory();
	}, [contact]);
	return (
		<div>
			{editHistory && editHistory.length > 1 && (
				<Dialog title="Edit History" className="dialog--contact-history">
					<div className="inner-content">
						{editHistory.map((editRecord: any, index) => {
							const { id, user, history_captured_at } = editRecord;
							if(index > 0) {
								const changes = diff(editRecord, editHistory[index - 1]);
								if(changes.length > 0) {
									return (
										<div className="record-row" key={index}>
											<div className="row">
												<span className="row__label">User:</span>{' '}
												<span className="row__value">
												{user && user.display_name}
											</span>
											</div>
											<div className="row">
												<span className="row__label">Date:</span>{' '}
												<span className="row__value">
												{moment(history_captured_at).format('DD-MM-YYYY - hh:mm')}
											</span>
											</div>
											<div className="row">
												<span>
											   {changes && (
												   <ul className="history-changes">
													   {changes.map((change: any, i: any) => {
														   return (
															   <li key={i}>{change}</li>
														   );
													   })}
												   </ul>
											   )}
											</span>
											</div>
										</div>
									);
								}
							}
						})}
					</div>
				</Dialog>
			)}
			{viewHistory && (
				<Dialog title="View History" className="dialog--contact-history">
					<div className="inner-content">
						{viewHistory.map((viewRecord: any) => {
							const { id, user, viewed_at, view_count } = viewRecord;
							return (
								<div className="record-row column" key={id}>
									<div className="row">
										<span className="row__label">User:</span>{' '}
										<span className="row__value">{user.display_name}</span>
									</div>
									<div className="row">
										<span className="row__label">Date:</span>{' '}
										<span className="row__value"> {viewed_at} </span>
									</div>
									<div className="row">
										<span className="row__label">View Count:</span>{' '}
										<span className="row__value"> {view_count} </span>
									</div>
								</div>
							);
						})}
					</div>
				</Dialog>
			)}
		</div>
	);
};

const mapDispatchToProps = (dispatch: any) => {
	return {
		getEditHistory: (id: string) => dispatch(getEditHistory(id)),
		getViewHistory: (id: string) => dispatch(getViewHistory(id))
	};
};

export default connect(
	null,
	mapDispatchToProps
)(History);

// tslint:disable: max-line-length
import * as React from 'react';
import layout from '../../../../utils/layout';
import SvgTemplate from '../SvgTemplate';
import { Svg } from '../../Svg';

const icon = ({ color, ...rest }: Svg): JSX.Element => (
	<SvgTemplate {...rest} viewBox="0 0 16 10">
		<g
			id="Messages"
			stroke="none"
			strokeWidth="1"
			fill="none"
			fillRule="evenodd"
		>
			<g
				id="Boat-page-Copy-5"
				transform="translate(-18.000000, -19.000000)"
				fill={color}
			>
				<g id="left-arrow" transform="translate(18.000000, 19.000000)">
					<g id="Capa_1">
						<path
							d="M14.3007468,3.87207074 L2.20911799,3.87207074 L5.09435703,1.09568319 C5.34712234,0.852613538 5.34712234,0.458308542 5.09435703,0.21527485 C4.84159172,-0.0279386268 4.43180101,-0.0279386268 4.17940939,0.21527485 L0.189573983,4.05440894 C-0.0631913277,4.29747859 -0.0631913277,4.69181954 0.189573983,4.93481727 L4.17940939,8.77413114 C4.30575468,8.89577384 4.47133763,8.9565053 4.63688321,8.9565053 C4.8024288,8.9565053 4.96801175,8.89577384 5.09435703,8.77413114 C5.34712234,8.5310615 5.34712234,8.1367565 5.09435703,7.89375876 L2.20911799,5.11722738 L14.3007468,5.11722738 C14.6580711,5.11722738 14.947832,4.83845238 14.947832,4.49463108 C14.947832,4.15080978 14.6581085,3.87207074 14.3007468,3.87207074 Z"
							id="Shape"
						/>
					</g>
				</g>
			</g>
		</g>
	</SvgTemplate>
);

icon.defaultProps = {
	width: layout.spacing.two,
	color: layout.color.primary
};

export default icon;

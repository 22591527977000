import * as React from 'react';
import { Svg } from '../Svg';

interface TemplateProps extends Svg {
	viewBox: string;
	children: JSX.Element | JSX.Element[];
}

const template = ({
	width,
	height,
	viewBox,
	children,
	icon
}: TemplateProps): JSX.Element => {
	return (
		<svg
			width={width}
			height={height}
			viewBox={viewBox}
			xmlns="http://www.w3.org/2000/svg"
		>
			<title>{icon}</title>
			{children}
		</svg>
	);
};

export default template;

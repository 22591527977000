import * as React from 'react';
import './Toggle.scss';

interface Props {
	active: boolean;
	onChange?: (active: boolean) => any;
	disabled?: boolean;
}

export class Toggle extends React.Component<Props> {
	state = {
		active: this.props.active,
		disabled: this.props.disabled
	};

	componentDidUpdate(prevProps: Props) {
		if (prevProps.active !== this.props.active) {
			this.setState({
				active: this.props.active
			});
		}
	}

	toggleActive = () => {
		const { onChange, disabled } = this.props;
		if(!disabled)
			this.setState({ active: !this.state.active }, () => {
				onChange && onChange(this.state.active);
			});
	};

	render(): JSX.Element {
		const { active, disabled } = this.state;
		let cl = 'toggle';
		if (active)
			cl += ' active';
		if (disabled)
			cl += ' disabled';
		return (
			<div className={cl} onClick={this.toggleActive}>
				<div className="toggle__background">
					<div className="toggle__background__left" />
					<div className="toggle__background__right" />
				</div>
				<div className="toggle__circle" />
			</div>
		);
	}
}

export default Toggle;

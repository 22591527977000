import { get, post } from '../utils';

async function getStatistics(params: string): Promise<any> {
	return get(`/statistics/all${params}`);
}

async function myStatistics(params: string): Promise<any> {
	return get(`/statistics/me${params}`);
}

export default { getStatistics, myStatistics };

import { User } from '../../models/User';
import {post} from "../../redux/api/utils";

export type Request$ValidateToken = {
	token: string;
};
export type Response$ValidateToken = { data: User };

/**
 * API POST Request to ValidateToken.
 */
export async function validateToken(
	data: Request$ValidateToken
): Promise<Response$ValidateToken> {
	return post('/auth/validate-token', data);
}

import { post } from '../utils';
import {
	Request$PreviewCustomEmail,
	Request$SendCustomEmail,
	Request$SendEmail
} from './models';

async function previewCustomEmail(
	data: Request$PreviewCustomEmail
): Promise<any> {
	return post(`/email/preview-custom-email`, data);
}

async function sendCustomEmail(data: Request$SendCustomEmail): Promise<any> {
	return post(`/email/send-custom-email`, data);
}

async function sendEmail(data: Request$SendEmail): Promise<any> {
	return post(`/email/send-new-email`, data);
}

export default {
	previewCustomEmail,
	sendCustomEmail,
	sendEmail
};

import { get, post, put, patch, deleteData } from '../utils';
import { Pagination$ListContacts } from '../../actions/Contact';

export interface FormData$AddContact {
	contact_name: string;
	url?: string;
	email: { email: string; is_default: boolean }[];
	region_id?: number;
	country_id: number;
	yachts?: number;
	marina?: string;
	boatTypes?: { count: number; type: number }[];
	comments?: string;
	address: string;
	locations?: { name: string; place_id: string; latlng: any; }[];
}

export interface FormData$CheckDuplicate {
	value: string;
	redirect?: boolean;
}

export interface FormData$ShowContact {
	id: string | number;
}

export type FormData$EditContact = {
	id: string | number;
} & FormData$AddContact;

export interface FormData$AddCall {
	id: string | number;
	cc_id?: string;
	phone: string | number;
	language_id?: number;
	comments?: string;
	date?: Date;
}

export interface FormData$AddCallReview {
	id: string | number;
	cc_id: string;
	language_id?: number;
	comments?: string;
}

export interface FormData$UpdateContact {
	id: string | number;
	to_be_checked?: number;
	check_comments?: string;
	listed?: number;
	call_status_id?: number;
	last_call_date?: Date;
	next_call_date?: Date;
	email_status_id?: number;
	lead_status_id?: number;
	have_seen_emails_status_id?: number;
}

async function addContact(data: FormData$AddContact): Promise<any> {
	return post('/contact', data);
}

async function checkDuplicate(data: FormData$CheckDuplicate): Promise<any> {
	return post('/contact/check-duplicate', data);
}

async function listContacts(params: string): Promise<any> {
	return get(`/contact/list${params}`);
}

async function showContact(data: FormData$ShowContact): Promise<any> {
	const { id } = data;
	return get(`/contact/${id}`);
}

async function editContact(
	data: FormData$AddContact & FormData$EditContact
): Promise<any> {
	const { id, ...rest } = data;
	return put(`/contact/${id}`, rest);
}

async function updateContact(data: FormData$UpdateContact): Promise<any> {
	const { id, ...rest } = data;
	return patch(`/contact/${id}`, rest);
}

async function getCalls(id: string): Promise<any> {
	return get(`/contact/${id}/calls`);
}

async function addCall(data: FormData$AddCall): Promise<any> {
	const { id, ...rest } = data;
	return put(`/contact/${id}/call`, rest);
}

async function addCallReview(data: FormData$AddCallReview): Promise<any> {
	const { id, cc_id, ...rest } = data;
	return patch(`/contact/${id}/call/${cc_id}`, rest);
}

async function getEmails(id: string): Promise<any> {
	return get(`/contact/${id}/emails-sent`);
}

async function getMessages(id: string): Promise<any> {
	return get(`/contact/${id}/messages`);
}

async function getEditHistory(id: string): Promise<any> {
	return get(`/contact/${id}/history`);
}

async function getViewHistory(id: string): Promise<any> {
	return get(`/contact/${id}/views`);
}

async function deleteContact(id: string): Promise<any> {
	return deleteData(`/contact/${id}/`, {});
}

async function getToBeChecked(pagination: {
	page: number;
	limit: number;
}): Promise<any> {
	const { page, limit } = pagination;
	const params = `?page=${page}&limit=${limit}`;
	return get(`/contact/to-be-checked${params}`);
}

export default {
	addContact,
	checkDuplicate,
	listContacts,
	showContact,
	editContact,
	updateContact,
	getCalls,
	addCall,
	addCallReview,
	getEmails,
	getMessages,
	deleteContact,
	getToBeChecked,
	getEditHistory,
	getViewHistory
};

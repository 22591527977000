export function preventBodyScroll(bool: boolean): void {
	const body: HTMLBodyElement | any = document
		? document.querySelector('body')
		: null;
	if (bool) {
		body.classList.add('noscroll');
	} else {
		body.classList.remove('noscroll');
	}
}

// Checking window width against a given break point.
export function isMobile(breakPoint: number | string): boolean {
	return window.innerWidth <= breakPoint;
}

export function scrollTo(selector: string, gutter: number = 0): void {
	const body: HTMLBodyElement | any = document
		? document.querySelector('body')
		: null;
	const element = document.querySelector(selector);
	if (!element) {
		console.warn(
			`Could not scroll to \`${selector}\`. Element was not found using document.querySelector().`
		);
		return;
	}
	const top = element.getBoundingClientRect().top;
	window.scrollTo({ top: top + window.scrollY + gutter, behavior: 'smooth' });
}

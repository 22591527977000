import axios from 'axios';
import env from '../../config/env';
import store, { persistor } from '../store';
import { API_ERROR } from './constants';
import { errorSubject } from '../../utils/streams';

export const ERRORS = [402, 404, 422, 409, 429, 500, 502, 503, 504];

async function bnbFetch(config: object): Promise<any> {
	const body = await axios(config)
		.then(res => {
			return res;
		})
		.catch(ex => {
			const { status, data, statusText } = ex ? ex.response : undefined;

			const errorAction = {
				type: API_ERROR,
				payload: ex.response
			};

			if (data && data.message) {
				errorSubject.next(data.message);
			} else {
				errorSubject.next(statusText);
			}

			if (!ex.response) {
				ex.response = 'Network error.';
			}

			// logout if status is 401
			if (status === 401) {
				if (store.getState().token) {
					// User.logout();
					persistor.purge();
					window.location.reload(true);
				} else {
					store.dispatch(errorAction);
				}
			}

			// dispatch action that shows the error in the Snackbar
			if (ERRORS.indexOf(status) !== -1) {
				store.dispatch(errorAction);
			}

			throw ex.response;
		});
	return body.data;
}

export async function get(url: string, newConfig?: object): Promise<any> {
	return bnbFetch({
		url: env.apiUrl + url,
		method: 'GET',
		headers: defaultHeaders(),
		...newConfig
	});
}

export async function post(
	path: string,
	data: { [key: string]: any },
	newConfig?: { [key: string]: any }
): Promise<any> {
	return bnbFetch({
		data,
		url: env.apiUrl + path,
		method: 'POST',
		headers: defaultHeaders(),
		...newConfig
	});
}

export async function put(
	path: string,
	data: { [key: string]: any },
	newConfig?: { [key: string]: any }
): Promise<any> {
	return bnbFetch({
		data,
		url: env.apiUrl + path,
		method: 'PUT',
		headers: defaultHeaders(),
		...newConfig
	});
}

export async function patch(
	path: string,
	data: { [key: string]: any },
	newConfig?: { [key: string]: any }
): Promise<any> {
	return bnbFetch({
		data,
		url: env.apiUrl + path,
		method: 'PATCH',
		headers: defaultHeaders(),
		...newConfig
	});
}

export async function deleteData(
	path: string,
	data: { [key: string]: any },
	newConfig?: { [key: string]: any }
): Promise<any> {
	return bnbFetch({
		data,
		url: env.apiUrl + path,
		method: 'DELETE',
		headers: defaultHeaders(),
		...newConfig
	});
}

function defaultHeaders() {
	const { token } = store.getState();
	return {
		authorization: `Bearer ${token}`
	};
}

import * as React from 'react';
import './Statuses.scss';
import { RouteComponentProps, RouteProps, RouterProps } from 'react-router';
import {Assignment, Contact} from '../../../../models/Contact';
import { updateContact, showContact  } from '../../../../redux/actions/Contact';
import {
	Options,
	EmailStatus,
	CallStatus,
	HaveSeenEmailStatus,
	LeadStatus
} from '../../../../models/Options';
import { User } from '../../../../models/User';
import Dialog from '../../../../components/Dialog';
import Toggle from '../../../../components/Toggle';
import { FormGroup } from '../../../../components/Form';
import Label from '../../../../components/Form/Label';
import Button from '../../../../components/Button';
import InputSelect from '../../../../components/Form/InputSelect';
import { SingleDatePicker } from 'react-dates';
import moment from 'moment';
import { pushPopup, popups } from '../../../../utils/popups';
import 'flatpickr/dist/themes/dark.css';
import env from '../../../../config/env';

import Flatpickr from 'react-flatpickr';
import { deleteContactsFromUser } from "../../../../api/Assignments";

type Params = {
	id: string;
};

type Props = {
	options: Options;
	user: User;
	contact: Contact;
	setContact: any;
} & RouteComponentProps<Params>;

const Statuses = (props: Props) => {
	const { id } = props.match.params;
	const { role_id } = props.user;
	const { options, contact, user, setContact } = props;

	if (!contact) return null;

	let inputTile = '-';
	let inputTile2 = '-';

	if(env.backOffice === 'Owners') {
		inputTile = '-';
		inputTile2 = '-';
	} else if (env.backOffice === 'Guests') {
		inputTile = 'Budget amount:';
		inputTile2 = 'Importance:';
	} else if (env.backOffice === 'Collaborators') {
		inputTile = 'Email status:';
		inputTile2 = '-';
	}

	/*
	 * Statuses
	 */

	const onCheckedChange = (e: any) => {
		const { value } = e.target;
		if (value == 2) {
			pushPopup(popups.ToBeCheckedComments, {
				contact: { ...contact, to_be_checked: value },
				setContact
			});
		} else {
			updateContact({
				to_be_checked: value,
				id,
				check_comments: ''
			}).then(res => {
				setContact({
					...contact,
					to_be_checked: value,
					check_comments: ''
				});
			});
		}
	};

	const submitAssign = (value: boolean) => {
		showContact({ id }).then((res: any) => {
			setContact({
				...contact,
				assignments: res.payload.assignments
			});
		});
	};

	const handleAssign = () => {
		pushPopup(popups.AssignContactsToUser, {
			contacts: [id],
			onSubmit: submitAssign
		})
	};
	const onListedChange = (value: boolean) => {
		updateContact({ id, listed: value ? 1 : 0});
	};
	const [callStatus, setCallStatus] = React.useState(contact.call_status_id);
	const [emailStatus, setEmailStatus] = React.useState(contact.email_status_id);
	const [haveSeenEmailStatus, setHaveSeenEmailStatus] = React.useState(
		contact.have_seen_emails_status_id
	);
	const [leadStatus, setLeadStatus] = React.useState(contact.lead_status_id);

	const handleCallStatus = (e: any) => {
		const status = e.target.value;
		setCallStatus(status);
		updateContact({ id, call_status_id: status });
	};

	const handleEmailStatus = (e: any) => {
		const status = e.target.value;
		setEmailStatus(status);
		updateContact({ id, email_status_id: status });
	};
	const handleHaveSeenEmailStatus = (e: any) => {
		const status = e.target.value;
		setHaveSeenEmailStatus(status);
		updateContact({ id, have_seen_emails_status_id: status });
	};
	const handleLeadStatus = (e: any) => {
		const status = e.target.value;
		setLeadStatus(status);
		updateContact({ id, lead_status_id: status });
	};

	/*
	 * Call Dates
	 */
	const [lastDate, setLastDate] = React.useState(
		contact.last_call_date ? moment(contact.last_call_date) : null
	);
	const [lastFocused, setLastFocused] = React.useState(false);

	const [nextDate, setNextDate] = React.useState(contact.next_call_date);
	const [nextFocused, setNextFocused] = React.useState(false);

	const handleLastDate = (date: any) => {
		setLastDate(date);
		const last_call_date = date.toDate();
		updateContact({ last_call_date, id });
	};

	const handleNextDate = (date: any) => {
		setNextDate(date[0]);
		// const next_call_date = date.toDate();
		updateContact({ next_call_date: date[0], id });
	};

	const deleteNextDate = () => {
		setNextDate(undefined);
		updateContact({ next_call_date: undefined, id });
	};

	return (
		<Dialog title={ contact && contact.to_be_checked != 1
			? ( <div>
					{'Status - '}
					{ (contact.to_be_checked == 0 || role_id < 3) ?
						(<InputSelect
							invisibleButWorks
							onChange={onCheckedChange}
							value={contact.to_be_checked.toString()}
							disabled={role_id > 3 && contact.to_be_checked != 1}
							>
								<option value="0">Approved</option>
								<option value="1">To be checked</option>
								{user && user.role_id <= 2 && (
									<option value="2">Rejected</option>
								)}
							</InputSelect>)
						: ( "Rejected" )
					}
			</div>
			) : 'Status' }
				description={ contact && contact.checked_by_user && contact.checked_at
					? `Checked by ${contact.checked_by_user.display_name} at ${moment(
						contact.checked_at
					).format('DD-MM-YYYY hh:mm')}` : `${ contact && contact.checked_by_user
						? `Checked by ${contact.checked_by_user.display_name}` : '' }`}
				className="dialog--statuses">
			{contact && contact.to_be_checked == 1 && (role_id < 3 || role_id == 4) && (
				<div>
					<FormGroup>
						<Label title="Check status:" />
						<InputSelect
							flat
							fullWidth
							onChange={onCheckedChange}
							value={contact.to_be_checked.toString()}
						>
							<option value="0">Approved</option>
							<option value="1">To be checked</option>
							<option value="2">Rejected</option>
						</InputSelect>
					</FormGroup>
				</div>
			)}
			{contact && contact.to_be_checked == 1 && role_id > 2 && role_id != 4 && (
				<div>
					<FormGroup>
						<Label title="Check status:" />
						<div className="to-be-checked-label">To be checked</div>
					</FormGroup>
				</div>
			)}
			{contact && contact.check_comments && (
				<div>
					<FormGroup>
						<div style={{ marginTop: 8, color:'red' }}>
							Reject Reason: <span style={{ color:'black' }} className="reason">{contact.check_comments}</span>
						</div>
					</FormGroup>
				</div>
			)}
			{user && user.role_id <= 2 && (
				<div className="toggle-wrapper">
					<Toggle
						active={contact && contact.listed ? true : false}
						onChange={onListedChange}
					/>{' '}
					<span>Listed on BednBlue {contact && contact.listed_at && (
						` at ${moment(contact.listed_at).format('DD-MM-YYYY')}`
					)}</span>
				</div>
			)}
			{user && (user.role_id > 2 && user.role_id != 5) && contact && contact.listed != 0 && (
				<div className="toggle-wrapper" style={{padding: '12px'}} >
					<span style={{ background: '#02c853', padding: '8px', borderRadius: '10px', marginLeft: '3px' }} ></span>
					<span>Listed on BednBlue</span>
				</div>
			)}
			{user && (role_id < 4 || role_id == 6) && contact.assignments && contact.assignments.length > 0 && (
				<FormGroup>
					<Label title="This contact is assigned to:" />
					<div style={{paddingTop: '15px', paddingBottom: '15px', position: 'relative' }} className="toggle-wrapper">
						<div
							className="assignments-list"
						>
							{contact.assignments.map((assignment: Assignment) => {
								const { assigned_to, user } = assignment;
								const handleUnassign = () => {
									if (!window.confirm(`Are you sure that you want to unassign user ${user.display_name} from this contact?`)) {
										return;
									} else {
										deleteContactsFromUser({ user_id: assigned_to, contacts: [parseInt(contact.id)] })
											.then(res => {
												submitAssign && submitAssign(true);
											})
											.catch(() => {});
									}
								};
								return (
									<div
										className={`assignment-single ${(props.user.role_id < 3 || props.user.role_id == 6) ? 'clickable' : ''}`}
										key={assigned_to}
										onClick={props.user.role_id < 3 || props.user.role_id == 6 ? handleUnassign : undefined}
									>
										{user.display_name}
									</div>
								);
							})}
						</div>
						<div style={{ position: 'absolute', background: 'white', right:'9px' }} >
							<Button transparent small onClick={handleAssign}>
								Add User
							</Button>
						</div>
					</div>
				</FormGroup>
			)}
			{contact && contact.assignments && contact.assignments.length == 0 && user && (role_id < 4 || role_id == 6) && (
				<FormGroup>
					<Label title="This contact is assigned to:" />
					<Button transparent small onClick={handleAssign}>
						Add User
					</Button>
				</FormGroup>
			)}
			{user && (role_id < 4 || role_id == 6) && (
				<>
					<FormGroup double>
						<div>
							<Label title="Call status:" />
							{options && options.call_status && (
								<InputSelect
									flat
									fullWidth
									value={callStatus}
									onChange={handleCallStatus}
									number
								>
									<option value="null">-</option>
									{options.call_status.map((status: CallStatus) => {
										const { id, call_status } = status;
										return (
											<option value={id} key={id}>
												{call_status}
											</option>
										);
									})}
								</InputSelect>
							)}
						</div>
						<div>
							<Label title={inputTile} />
							{options && options.email_status && (
								<InputSelect
									flat
									fullWidth
									value={emailStatus}
									onChange={handleEmailStatus}
									number
								>
									<option value="null">-</option>
									{options.email_status.map((status: EmailStatus) => {
										const { id, email_status } = status;
										return (
											<option value={id} key={id}>
												{email_status}
											</option>
										);
									})}
								</InputSelect>
							)}
						</div>
					</FormGroup>
					<FormGroup double>
						<div>
							<Label title={inputTile2} />
							{options && options.have_seen_email_status && (
								<InputSelect
									flat
									fullWidth
									value={haveSeenEmailStatus}
									onChange={handleHaveSeenEmailStatus}
									number
								>
									<option value="null">-</option>
									{options.have_seen_email_status.map(
										(status: HaveSeenEmailStatus) => {
											const { id, have_seen_email_status } = status;
											return (
												<option value={id} key={id}>
													{have_seen_email_status}
												</option>
											);
										}
									)}
								</InputSelect>
							)}
						</div>
						<div>
							<Label title="Lead status" />
							{options && options.lead_status && (
								<InputSelect
									flat
									fullWidth
									value={leadStatus}
									onChange={handleLeadStatus}
									number
								>
									<option value="null">-</option>
									{options.lead_status.map((status: LeadStatus) => {
										const { id, lead_status } = status;
										return (
											<option value={id} key={id}>
												{lead_status}
											</option>
										);
									})}
								</InputSelect>
							)}
						</div>
					</FormGroup>
					<FormGroup double style={{ marginBottom: 0 }}>
						<div>
							<Label title="Last call date:" />
							<SingleDatePicker
								date={lastDate} // momentPropTypes.momentObj or null
								onDateChange={date => handleLastDate(date)} // PropTypes.func.isRequired
								focused={lastFocused} // PropTypes.bool
								onFocusChange={({ focused }: any) => setLastFocused(focused)} // PropTypes.func.isRequired
								id="last_call_date" // PropTypes.string.isRequired,
								disabled
							/>
						</div>
						<div>
							<Label title="Next call date:" />
							<Flatpickr
								data-enable-time
								value={nextDate}
								onChange={date => {
									handleNextDate(date);
								}}
							/>
							{/* <SingleDatePicker
								date={nextDate} // momentPropTypes.momentObj or null
								onDateChange={date => handleNextDate(date)} // PropTypes.func.isRequired
								focused={nextFocused} // PropTypes.bool
								onFocusChange={({ focused }: any) => setNextFocused(focused)} // PropTypes.func.isRequired
								id="next_call_date" // PropTypes.string.isRequired,
							/> */}
							{ nextDate && (
								<button style={{ position: 'absolute', marginLeft: '-25px', marginTop: '13px', opacity: '0.8' }} onClick={() => deleteNextDate()}>X</button>
							)}
						</div>
					</FormGroup>
				</>
			)}
		</Dialog>
	);
};

export default Statuses;

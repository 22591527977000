import {patch} from "../../redux/api/utils";

export type Request$ChangeAccountCountry = {
	id: number;
	country_id: number | string;
};

export type Response$ChangeAccountCountry = any;

/**
 * Change Call Center Account Country
 */
export async function changeAccountCountry (
	data: Request$ChangeAccountCountry
): Promise<Response$ChangeAccountCountry> {
	const { id, country_id } = data;
	return patch(`/sip-account/${id}/change-country`, { country_id });
}

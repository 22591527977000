import * as React from 'react';
import './Multiple.scss';
import FormGroup from '../FormGroup';
import Label from '../Label';
import { FieldArray, FieldArrayRenderProps, Field } from 'formik';
import Button, { ButtonIcon } from '../../Button';
import { hot } from 'react-hot-loader';
import FormError from '../FormError';
import Svg from '../../Svg';
import layout from '../../../utils/layout';

type Props = {
	component: any;
	name: string;
	label: string;
	insertValue: string | number | object;
	noError?: boolean;
    showLink?: boolean;
    className?: string;
};

const Multiple = (props: Props): JSX.Element => {
	const { component, name, label } = props;
	return (
		<FormGroup>
			<Label title={label} />
			<FieldArray
				name={name}
				render={(arrayProps: FieldArrayRenderProps) => (
					<MultipleCompoment {...arrayProps} {...props} />
				)}
			/>
		</FormGroup>
	);
};

const MultipleCompoment = (
	props: FieldArrayRenderProps & Props
): JSX.Element => {
	const {
		component,
		name,
		insert,
		remove,
		insertValue,
    	showLink,
		className,
		form: { values, errors, submitCount },
		noError
	} = props;
	const addField = () => {
		//let index = values && values[name] ? values[name].length : 0;
		let index = 0;
		if (values && values[name]) {
			index = values[name].length;
			for (const value of values[name]) {
				if (typeof value === 'string' && !value) {
					return;
				}
				if (typeof value === 'object') {
					for (const prop in value) {
						if (value.hasOwnProperty(prop)) {
							if (typeof value[prop] === 'string' && !value[prop]){
								return;
							}
						}
					}
				}
			}
		}
		insert(index, insertValue);
	};
	return (
		<div>
			<div className={`multiple__item ${className}`}>
				{values &&
				values[name] &&
				values[name].map(
					(value: any, i: number): JSX.Element => (
						<div key={i} className={`multiple__item__wrapper`}>
							<div className="multiple__item__row">
								<Field
									render={(formProps: any) =>
										React.cloneElement(component, {
											name: `${name}.${i}`,
											value: values[name][i],
											onChange: (val: any) => {
												formProps.field.onChange(val);
											},
											validationError:
												submitCount && errors && errors[name]
													? errors[name][i]
													: ''
										})
									}
								/>
								<ButtonIcon
									tabIndex={-1}
									icon="trash"
									className="btn-remove-item"
									onClick={() => remove(i)}
								/>
							</div>
							{!noError && (
								<FormError
									text={
										submitCount && errors && errors[name] ? errors[name][i] : ''
									}
								/>
							)}
							{showLink && values[name][i] &&
							<a
								href={`http://${values[name][i]}`}
								target="_blank"
								className="link-anchor"
							>
								<Svg icon="hyperlink" width="20" color={layout.color.link} />
							</a>
							}
						</div>
					)
				)}
			</div>
			<Button dashed small onClick={addField} className="btn--add-new">
				+ add new {name}
			</Button>
		</div>
	);
};

export default hot(module)(Multiple);

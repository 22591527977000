import * as React from 'react';
import './SideNav.scss';
import { withRouter, RouteComponentProps } from 'react-router';

interface Props extends RouteComponentProps {
	children: any;
	mobile: boolean;
	footer?: any;
}

export const SideNav = (props: Props) => {
	const { children, history, location, mobile, footer } = props;
	const handleMobileOpenMenu = () => {
		document.getElementById('navMenuMobile')!.style.display = 'block';
		document.getElementById('navMenuHelperMobile')!.style.display = 'block';
		document.getElementById('closeMenu')!.style.display = 'block';
		document.getElementById('openMenu')!.style.display = 'none';
	};

	const handleMobileCloseMenu = () => {
		document.getElementById('navMenuMobile')!.style.display = 'none';
		document.getElementById('navMenuHelperMobile')!.style.display = 'none';
		document.getElementById('openMenu')!.style.display = 'block';
		document.getElementById('closeMenu')!.style.display = 'none';
	};

	return (
		<>
			<button id="openMenu" className="navMenuMobileBtn" onClick={() => handleMobileOpenMenu()}>
				<img style={{ marginRight: '24px', marginLeft: '-4%' }} src="https://d530gosedmutm.cloudfront.net/img/svg/menu-mob.svg" alt="-"/>Open Menu</button>
			<button id="closeMenu" className="navMenuMobileBtn mobileClose" onClick={() => handleMobileCloseMenu()}>
				<img style={{ marginRight: '20px', marginLeft: '-3%', width: '14px', marginBottom: '-2px' }} src="https://d530gosedmutm.cloudfront.net/img/svg/close-white.svg" alt="X"/>
				Close Menu</button>
			<div id="navMenuHelper" className="sidenav__helper" />
			<nav id="navMenu" className="sidenav box-shadow--dialog">{children}</nav>
			<div id="navMenuHelperMobile" className="sidenav__helper" />
			<nav id="navMenuMobile" onClick={() => handleMobileCloseMenu()} className="sidenav box-shadow--dialog">{children}</nav>
		</>
	);
};

export default React.memo(withRouter(SideNav));

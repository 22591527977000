import { UserEnum } from '../enums';
import { Action } from '../../models';
import { Response$Login, Request$Login, login } from '../../../api/User';

export type Action$Login = Action<UserEnum.LOGGED_IN, Response$Login>;

export type ActionCreator$Login = (
	data: Request$Login
) => Promise<Action$Login>;

const loginActionCreator: ActionCreator$Login = async (
	data: Request$Login
): Promise<Action$Login> => {
	const response: Response$Login = await login(data);
	return {
		type: UserEnum.LOGGED_IN,
		payload: response
	};
};

export default loginActionCreator;

import {Response$faqList, Response$scenarioList, listFAQ, listScenarios} from '../../../api/Helpdesk/List';
import { HelpdeskEnum } from '../enums';
import { Action } from '../../models';

export type Action$listFAQ = Action<any, Response$faqList>;

export type Action$listScenarios = Action<any, Response$scenarioList>;

export async function listFAQActionCreator(): Promise<Action$listFAQ> {
	const response = await listFAQ();
	return {
		type: HelpdeskEnum.LOADED_FAQ_LIST,
		payload: response
	};
}

export async function listScenariosActionCreator(): Promise<Action$listScenarios> {
	const response = await listScenarios();
	return {
		type: HelpdeskEnum.LOADED_SCENARIO_LIST,
		payload: response
	};
}

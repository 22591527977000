// tslint:disable: max-line-length
import * as React from 'react';
import layout from '../../../../utils/layout';
import SvgTemplate from '../SvgTemplate';
import { Svg } from '../../Svg';

const icon = ({ color, ...rest }: Svg): JSX.Element => (
	<SvgTemplate {...rest} viewBox="0 0 39 35">
		<g
			id="Page-1"
			stroke="none"
			strokeWidth="1"
			fill="none"
			fillRule="evenodd"
			strokeLinecap="round"
		>
			<g
				id="loading-retry-grey"
				transform="translate(1.000000, 1.000000)"
				stroke={color}
			>
				<polyline
					id="Shape"
					strokeWidth="2"
					strokeLinejoin="round"
					points="36.8 11.4 31.7 18.3 24.8 13.3"
				/>
				<path
					d="M25.4,28.8 C22.8,30.9 19.4,32.1 15.8,32.1 C7.2,32.1 0.2,25.1 0.2,16.5 C0.2,7.9 7.2,0.9 15.8,0.9 C24.4,0.9 31.4,7.9 31.4,16.5"
					id="Shape"
					strokeWidth="2.3"
				/>
			</g>
		</g>
	</SvgTemplate>
);

icon.defaultProps = {
	width: layout.spacing.two,
	color: layout.color.primary
};

export default icon;

import {patch} from "../../redux/api/utils";

export type Request$ChangeRole = {
	id: number;
	role_id: number;
};

export type Response$ChangeRole = any;

/**
 * Super admin change role end point
 */
export async function changeRole(
	data: Request$ChangeRole
): Promise<Response$ChangeRole> {
	const { id, role_id } = data;
	return patch(`/user/${id}/role`, { role_id });
}

// tslint:disable: max-line-length

import * as React from 'react';
import layout from '../../../../utils/layout';
import SvgTemplate from '../SvgTemplate';
import { Svg } from '../../Svg';

const icon = ({ color, ...rest }: Svg): JSX.Element => (
	<SvgTemplate {...rest} viewBox="0 0 56 57">
		<g fill={color} fillRule="nonzero">
			<path d="M55.146,51.887 L41.588,37.786 C45.074,33.642 46.984,28.428 46.984,23 C46.984,10.318 36.666,0 23.984,0 C11.302,0 0.984,10.318 0.984,23 C0.984,35.682 11.302,46 23.984,46 C28.745,46 33.282,44.564 37.161,41.838 L50.822,56.046 C51.393,56.639 52.161,56.966 52.984,56.966 C53.763,56.966 54.502,56.669 55.063,56.129 C56.255,54.982 56.293,53.08 55.146,51.887 Z M23.984,6 C33.358,6 40.984,13.626 40.984,23 C40.984,32.374 33.358,40 23.984,40 C14.61,40 6.984,32.374 6.984,23 C6.984,13.626 14.61,6 23.984,6 Z" />
		</g>
	</SvgTemplate>
);

icon.defaultProps = {
	width: layout.spacing.three,
	color: layout.color.primary
};

export default icon;

// tslint:disable: max-line-length
import * as React from 'react';
import layout from '../../../../utils/layout';
import SvgTemplate from '../SvgTemplate';
import { Svg } from '../../Svg';

const icon = ({ color, ...rest }: Svg): JSX.Element => (
	<SvgTemplate {...rest} viewBox="0 0 308 181">
		<g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<g
				id="caret-symbol"
				transform="translate(154.000000, 90.500000) rotate(180.000000) translate(-154.000000, -90.500000) "
				fill={color}
				fillRule="nonzero"
			>
				<path
					d="M302.445,142.788 L164.63,4.959 C158.494,-1.171 148.556,-1.171 142.427,4.959 L4.597,142.788 C-1.532,148.92 -1.532,158.857 4.597,164.989 L15.698,176.09 C21.827,182.226 31.774,182.226 37.907,176.09 L153.527,60.464 L269.151,176.09 C275.279,182.226 285.221,182.226 291.352,176.09 L302.453,164.989 C308.589,158.85 308.589,148.92 302.445,142.788 Z"
					id="Shape"
				/>
			</g>
		</g>
	</SvgTemplate>
);

icon.defaultProps = {
	width: layout.spacing.three,
	color: layout.color.primary
};

export default icon;

import * as React from 'react';
import { Field } from 'formik';
import formElement from './formElement';

const withFormik = (
	ChildComponent: React.ComponentType | React.MemoExoticComponent<any>
) => {
	const FormElementChild = formElement(ChildComponent);
	return class FormikHOC extends React.PureComponent<any> {
		render(): JSX.Element {
			return <Field component={FormElementChild} {...this.props} />;
		}
	};
};

export default withFormik;

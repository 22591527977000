import * as React from 'react';
import './MakeCallReview.scss';
import Popup from '../Popup';
import { connect } from 'react-redux';
import { hot } from 'react-hot-loader';
import {
	FormikForm,
	FormikInputTextarea,
	FormikButtonSubmit,
	FormGroup,
	formikSubmit,
	FormikInputSelect
} from '../../Form';
import { FormikProps, FormikActions } from 'formik';
import { Action$AddCallReview, addCallReview} from '../../../redux/actions/Contact';
import { FormData$AddCallReview } from '../../../redux/api/Contact';
import Yup from '../../../utils/yup';
import { Options, Language } from '../../../models/Options';
import Countdown, { CountdownApi } from 'react-countdown';
import $ from 'jquery';

interface Props {
	active: boolean;
	onDismiss?: () => any;
	addCallReview: Action$AddCallReview;
	contactId: string | number;
	options: Options;
	callId: string;
}

type Values = {
	comments: string;
};

const MakeCallReview = (props: Props): JSX.Element => {
	const { active, addCallReview, contactId, onDismiss, callId } = props;
	const started = new Date().getTime();
	const initialValues = {
		comments: '',
		language_id: ''
	};

	const validationSchema = Yup.object().shape({
		language_id: Yup.string().required(),
		comments: Yup.string().required().min(4)
	});

	const onSubmit = (values: Values, actions: FormikActions<Values>) => {
		actions.setSubmitting(false);
		let elapsed_seconds = Math.round((new Date().getTime() - started) / 1000);
		window.onbeforeunload = null;
		formikSubmit({ ...values, id: contactId, cc_id: callId, comment_duration: elapsed_seconds }, actions, addCallReview).then(res => {
			onDismiss && onDismiss();
			window && window.popup.pop();
		});
	};
	const timerEnded = () => {
		if(countdownApi){
			$(".positive-timer").hide();
			$(".negative-timer").show();
			$(".overtime-title").show();
			countdownApi.start();
		}
	}
	let countdownApi: CountdownApi | null = null;
	const setRef = (countdown: Countdown | null): void => {
		if (countdown) {
			countdownApi = countdown.getApi();
		}
	};
	return (
		<Popup
			active={active}
			title="Review call"
			className="popup--review-call"
			width={400}
			dontShowClose={true}
		>
			<div className={"countdown-timer"}>
				<Countdown className={'positive-timer'} date={Date.now() + 90000} daysInHours={true} onComplete={timerEnded}/>
				<Countdown className={'negative-timer'}  date={Date.now()} ref={setRef} overtime={true} autoStart={false} daysInHours={true} />
				<div className="overtime-title">overtime</div>
			</div>
			<FormikForm
				render={(formProps: FormikProps<Values>) => (
					<Form {...formProps} {...props} />
				)}
				initialValues={initialValues}
				validationSchema={validationSchema}
				onSubmit={onSubmit}
			/>
		</Popup>
	);
};

type FormProps = {} & Props & FormikProps<Values>;

const Form = (props: FormProps) => {
	const { handleSubmit, options } = props;
	return (
		<form onSubmit={handleSubmit}>
			<FormikInputSelect
				name="language_id"
				label="Language:"
				number
				flat
				fullWidth
			>
				{options &&
					options.languages &&
					options.languages.map((language: Language) => {
						return (
							<option key={language.id} value={language.id}>
								{language.language}
							</option>
						);
					})}
			</FormikInputSelect>
			<FormikInputTextarea name="comments" label="Comments:" flat resize/>
			<FormikButtonSubmit {...props} title="Submit" fullWidth />
		</form>
	);
};

const mapStateToProps = (store: any) => {
	const { options } = store;
	return { options };
};

const mapDispatchToProps = (dispatch: any) => {
	return {
		addCallReview: (data: FormData$AddCallReview) => dispatch(addCallReview(data))
	};
};

export default hot(module)(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(MakeCallReview)
);

import { createStore, applyMiddleware, compose } from 'redux';
import rootReducer from '../reducers';
import storage from 'redux-persist/lib/storage';
import { persistStore, persistReducer } from 'redux-persist';
import promiseMiddleware from 'redux-promise';
import { createBrowserHistory, createMemoryHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';

declare const window: any;
declare const global: any;

export const history = createBrowserHistory();

const initialState = {};

const enhancers: any = [];
const middleware = [promiseMiddleware, routerMiddleware(history)];

const persistLocalStorageConfig = {
	storage,
	key: 'bnb-backoffice',
	whitelist: ['token']
};

export const persistedLocalStorageReducer = persistReducer(
	persistLocalStorageConfig,
	rootReducer(history)
);

if (process.env.NODE_ENV === 'development') {
	const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;
	if (typeof devToolsExtension === 'function') {
		enhancers.push(devToolsExtension());
	}
}

export const composedEnhancers = compose(
	applyMiddleware(...middleware),
	...enhancers
);

const store = createStore(
	persistedLocalStorageReducer,
	initialState,
	composedEnhancers
);

export const persistor = persistStore(store);

if (process.env.NODE_ENV === 'development') {
	/*
	 * Storing `store` to window variable to share it among splitted chunks.
	 */
	window.store = store;
	window.persistor = persistor;
}

export default store;

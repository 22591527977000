import * as React from 'react';
import './InputText.scss';
import Svg from '../../Svg';
import {Icons} from '../../Svg/icons';
import layout from '../../../utils/layout';
import {ButtonIcon} from '../../Button';

interface Props {
    name?: string;
    onChange?: (e: React.SyntheticEvent) => any;
    className?: string;
    style?: object;
    autocomplete?: boolean;
    fullWidth?: boolean;
    placeholder?: string;
    type?: string;
    value?: any;
    icon?: Icons;
    onFocus?: () => any;
    onBlur?: () => any;
    validationError?: string;
    inputRef?: React.Ref<any>;
    disabled?: boolean;
    number?: boolean;
    flat?: boolean;
    iconWidth?: string | number;
    onClick?: () => any;
    min?: number;
    max?: number;
    minLength?: number;
    maxLength?: number;
    dataCy?: string;
    onKeyPress?: (e: React.KeyboardEvent) => void;
    noSpaces?: boolean;
}

export class InputText extends React.Component<Props> {
    static defaultProps = {
        type: 'text',
        iconWidth: layout.spacing.two,
        value: ''
    };

    state = {
        text: this.props.value,
        storedType: this.props.type
    };

    changeText = (e: React.SyntheticEvent): void => {
		let trg = e.target as any;
        if (this.props.noSpaces) {
			trg.value = trg.value.replace(/ /g, '');
        }

        this.setState({text: trg.value});

        const {onChange} = this.props;
        onChange && onChange(e);
    };

    componentDidUpdate(prevProps: Props) {
        if (this.props.value !== this.state.text) {
            this.setState({text: prevProps.value});
        }
    }

    togglePasswordType = () => {
        this.setState({
            storedType: this.state.storedType === 'password' ? 'text' : 'password'
        });
    };

    render(): JSX.Element {
        const {text, storedType} = this.state;
        const {name, onBlur, onFocus, validationError, type} = this.props;
        const {
            placeholder,
            style,
            autocomplete,
            fullWidth,
            icon,
            inputRef,
            disabled,
            number,
            flat,
            iconWidth,
            className,
            onClick,
            min,
            max,
            minLength,
            maxLength,
            dataCy,
            onKeyPress
        } = this.props;

        let inputClass = 'input-text';
        if (className) {
            inputClass += ` ${className}`;
        }
        if (validationError) {
            inputClass += ' required';
        }
        if (disabled) {
            inputClass += ' disabled';
        }
        if (type === 'password') {
            inputClass += ' password';
        }
        if (fullWidth) inputClass += ' full-width';
        if (icon) inputClass += ' has-icon';
        if (number) inputClass += ' number';
        if (flat) {
            inputClass += ' flat';
        } else {
            inputClass += ' box-shadow--input';
        }

        return (
            <div className={inputClass} style={style || {}} onClick={onClick}>
                <input
                    ref={inputRef}
                    type={storedType}
                    name={name}
                    value={text || ''} // using `defaultValue` instead of `value` to prevent React's `uncontrolled input error`
                    onChange={this.changeText}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    placeholder={placeholder}
                    autoComplete={autocomplete ? 'on' : 'off'}
                    disabled={disabled}
                    min={min}
                    max={max}
                    minLength={minLength}
                    maxLength={maxLength}
                    onKeyPress={onKeyPress}
                    {...(dataCy ? {'data-cy': dataCy} : {})}
                />
                {type === 'password' && text && (
                    <ButtonIcon
                        tabIndex={-1}
                        className={`password-eye-icon${icon ? ' has-icon' : ''}`}
                        icon={storedType === 'password' ? 'eye' : 'eye-blind'}
                        onClick={this.togglePasswordType}
                        iconWidth={20}
                    />
                )}
                {icon && <Svg icon={icon} width={iconWidth}/>}
            </div>
        );
    }
}

export default InputText;

import * as React from 'react';
import { SideNav, SideNavLink } from '../../components/SideNav';
import routes from '../routes';
import { User } from '../../models/User';

type Props = {
	user: User;
};

const Sidebar = (props: Props): JSX.Element => {
	const { user } = props;
	return (
		<SideNav mobile={false}>
			<SideNavLink exact to={routes.home.base} icon="dashboard">
				<label>Dashboard</label>
			</SideNavLink>
			<SideNavLink to={routes.contact.add} icon="new-contact">
				<label>Add contact</label>
			</SideNavLink>
			{(user.role_id <= 2 || user.role_id === 4) && (
				<SideNavLink to={routes.toBeChecked.base} icon="hourglass">
					<label>To be checked</label>
				</SideNavLink>
			)}
			{user && (user.role_id <= 3  || user.role_id === 6) && (
				<SideNavLink to={routes.contact.list} icon="list">
					<label>List contacts</label>
				</SideNavLink>
			)}
			<SideNavLink to={routes.pendingMerges.base} icon="duplicate">
				<label>Pending Merges</label>
			</SideNavLink>
			{user && (user.role_id === 1 || user.role_id === 2 || user.role_id === 6) && (
				<SideNavLink to={routes.users.base} icon="members">
					<label>Members</label>
				</SideNavLink>
			)}
			{user && (user.role_id === 1) && (
			<span>
				<SideNavLink to={routes.callCenter.base} icon="phone">
					Call Center
				</SideNavLink>
			</span>
			)}
			{user && (user.role_id === 1 || user.role_id === 6) && (
			<span>
				<SideNavLink to={routes.helpdesk.base} icon="help">
					<label>Helpdesk</label>
				</SideNavLink>
			</span>
			)}
			<SideNavLink to={routes.profile.base} icon="settings">
				<label>Profile settings</label>
			</SideNavLink>
		</SideNav>
	);
};

export default Sidebar;

import * as React from 'react';
import './CallCenter.scss';
import ReactTable from 'react-table';
import { connect } from 'react-redux';
import { Store, Dispatch } from '../../../models';
import Dialog from '../../../components/Dialog';
import Button from '../../../components/Button';
import Svg from '../../../components/Svg';
import { pushPopup, popups } from '../../../utils/popups';
import {CountryOption, Options} from '../../../models/Options';
import { getOptions } from '../../../redux/actions';
import InputSelect from '../../../components/Form/InputSelect';
import { Request$ChangeAccountCountry } from '../../../api/CallCenterAccounts/ChangeAccountCountry';
import {makeAccountDefault, Request$MakeAccountDefault} from "../../../api/CallCenterAccounts/MakeAccountDefault";
import Toggle from '../../../components/Toggle';
import { listCallCenterAccountsActionCreator } from '../../../redux/CCAccountList/CCAccountListActions';
import changeAccountCountryActionCreator from '../../../redux/CCAccountList/CCAccountListActions/ChangeAccountCountry';
import makeAccountDefaultActionCreator from "../../../redux/CCAccountList/CCAccountListActions/MakeAccountDefault";
import routes from "../../routes";

export class CallCenter extends React.Component<any> {
	componentDidMount() {
		const { getAccounts, options, getOptions, user } = this.props;
		getAccounts();
		if (!options) {
			getOptions();
		}
		// Redirects non-admins to home
		if(user.role_id > 1){
			this.props.history.push(routes.home.base);
		}
	}

	changeAccountCountry = (id: number, country_id: number) => {
		const { changeAccountCountry } = this.props;
		changeAccountCountry({ id, country_id });
	};

	makeAccountDefault = (id: number ) => {
		const { makeAccountDefault, ccAccountList } = this.props;
		makeAccountDefault({ id });
		ccAccountList.forEach(function(account: any ){
			if(account.id == id)
				account.is_default = true;
			else
				account.is_default = false;
		});
		this.setState({
			ccAccountList
		});
	};

	renderColumns = () => [
		{
			Header: 'ID',
			accessor: 'id',
			width: 60,
			minResizeWidth: 60,
			Cell: (props: any) => <span className="row__id">{props.original.id}</span>
		},
		{
			Header: 'Username',
			accessor: 'sip_username',
			minResizeWidth: 60,
			Cell: (props: any) => <span>{props.original.sip_username}</span>
		},
		{
			Header: 'Password',
			accessor: 'sip_password',
			minResizeWidth: 60,
			Cell: (props: any) =>
				<span className="flex flex--end--between" style={{ width: '100%', position: "relative", paddingLeft: 8 }}>
				{props.original.sip_password}
				<div
					style={{ right: -5, paddingRight: 10, paddingLeft: 8, top: -5, position: "absolute", background: "white" }}
					onClick={() =>
						pushPopup(popups.ChangeCallCenterAccountPassword, { account: props.original })
					}
				>
					<Svg icon="change-password" />
				</div>
				</span>
		},
		{
			Header: 'Country',
			minResizeWidth: 60,
			accessor: 'country_id',
			width: 230,
			Cell: (props: any) => {
				const { options, user } = this.props;
				return (
					<span>
						<InputSelect
							number
							flat
							placeholder={"Worldwide"}
							fullWidth
							value={props.original.country_id}
							onChange={(e: any) =>
								this.changeAccountCountry(props.original.id, e.target.value)
							}
						>
							<option></option>
							{options &&
								options.countries &&
								options.countries.map((item: CountryOption) => {
									const { id, country } = item;
									return (
										<option value={id} key={id}>
											{country}
										</option>
									);
								})}
						</InputSelect>
					</span>
				);
			}
		},
		{
			Header: 'Description',
			width: 350,
			Cell: (props: any) => <span>{props.original.description}</span>
		},
		{
			Header: 'Is Default',
			width: 120,
			Cell: (props: any) => (
				<span className="flex flex--center" style={{ width: '100%' }}>
					<Toggle
						active={props.original.is_default}
						disabled={props.original.is_default}
						onChange={(e: any) => {
							this.makeAccountDefault(props.original.id);
						}}
					/>
				</span>
			)
		}
	];

	render(): JSX.Element {
		const { ccAccountList, user } = this.props;
		return (
			<div className="page page--call-center">
				<Dialog title="Call Center Management">
				<div className={'top-buttons'}>
					<Button
						small
						className="btn--add-new"
						onClick={() => pushPopup(popups.AddCallCenterAccount)}
					>
						+ Add new Account
					</Button>
					<Button
						small
						className="btn--make-call"
						onClick={() => pushPopup(popups.MakeCall,{
							user: user,
							isAdmin: true
						})}
					>
						Make a Call
					</Button>
				</div>
				<ReactTable
					className={'accounts--table'}
					data={ccAccountList}
					columns={this.renderColumns()}
					sortable={false}
					resizable={true}
					showPagination={true}
					collapseOnPageChange={false}
					multiSort={false}
					manual
				/>
				</Dialog>
			</div>
		);
	}
}

const mapStateToProps = (store: Store) => {
	const { ccAccountList, options, user } = store;
	return { ccAccountList, options, user };
};

const mapDispatchToProps = (dispatch: any) => {
	return {
		getAccounts: () => dispatch(listCallCenterAccountsActionCreator()),
		getOptions: () => dispatch(getOptions()),
		changeAccountCountry: (data: Request$ChangeAccountCountry) =>
			dispatch(changeAccountCountryActionCreator(data)),
		makeAccountDefault: (data: Request$MakeAccountDefault) =>
			dispatch(makeAccountDefaultActionCreator(data))
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(CallCenter);

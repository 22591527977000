import { LOADED_STATISTICS } from './constants';
import { Action } from '../../../models';
import api from '../../api/Statistics';

export type FormData$GetStatistics = {
	user_id?: string | number | null;
	date_from?: string | Date | null;
	date_to?: string | Date | null;
	page?: number | null;
	limit?: number | null;
};

export type Action$GetStatistics = (
	data?: FormData$GetStatistics
) => Promise<Action>;

export const getStatistics = async (
	data: FormData$GetStatistics = {}
): Promise<Action> => {
	const { user_id, date_from, date_to, page, limit } = data;
	let params = '?';
	if (user_id) {
		params += `&user_id=${user_id}`;
	}

	if (date_from) {
		params += `&date_from=${date_from}`;
	}
	if (date_to) {
		params += `&date_to=${date_to}`;
	}

	if (page) {
		params += `&page=${page}`;
	}

	if (limit) {
		params += `&limit=${limit}`;
	}

	const res = await api.getStatistics(params);
	return {
		type: LOADED_STATISTICS,
		payload: res.data
	};
};

export const myStatistics = async (
	data: FormData$GetStatistics = {}
): Promise<Action> => {
	const { user_id, date_from, date_to, page, limit } = data;
	let params = '?';

	if (date_from) {
		params += `&date_from=${date_from}`;
	}
	if (date_to) {
		params += `&date_to=${date_to}`;
	}
	if (page) {
		params += `&page=${page}`;
	}
	if (limit) {
		params += `&limit=${limit}`;
	}

	const res = await api.myStatistics(params);
	return {
		type: LOADED_STATISTICS,
		payload: res.data
	};
};

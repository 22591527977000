const Alert = 'Alert';

const Confirm = 'Confirm';

const NewCall = 'NewCall';

const SendEmail = 'SendEmail';

const ChangeUserPassword = 'ChangeUserPassword';

const AddUser = 'AddUser';

const ToBeCheckedComments = 'ToBeCheckedComments';

const AssignContactsToUser = 'AssignContactsToUser';

const MergeContact = 'MergeContact';

const SendMessage = 'SendMessage';

const MakeCall = 'MakeCall';

const MakeCallReview = 'MakeCallReview';

const AddCallCenterAccount = 'AddCallCenterAccount';

const ChangeCallCenterAccountPassword = 'ChangeCallCenterAccountPassword';

const ViewHelpdesk = 'ViewHelpdesk';

const AddHelpdeskFAQ = 'AddHelpdeskFAQ';

const AddHelpdeskScenario = 'AddHelpdeskScenario';

const EditSupervisors = 'EditSupervisors';

export const popups = {
	Alert,
	Confirm,
	NewCall,
	SendEmail,
	ChangeUserPassword,
	AddUser,
	ToBeCheckedComments,
	AssignContactsToUser,
	MergeContact,
	SendMessage,
	MakeCall,
	MakeCallReview,
	AddCallCenterAccount,
	ChangeCallCenterAccountPassword,
	ViewHelpdesk,
	AddHelpdeskFAQ,
	AddHelpdeskScenario,
	EditSupervisors
};

declare const window: any;

function pushPopup(name: string, props?: any): void {
	window.popup && window.popup.push(name, props);
}

export { pushPopup };

import * as React from 'react';
import { connect } from 'react-redux';
import './PendingMerges.scss';
import { Store } from '../../../models';
import Dialog from '../../../components/Dialog';
import {
	FormData$GetPendingMerges,
	getPendingMerges
} from '../../../redux/actions/PendingMerges';
import {getOptions} from '../../../redux/actions';
import {popups, pushPopup} from "../../../utils/popups";
import Svg from "../../../components/Svg";
import ReactTable from "react-table";
import {Link} from "react-router-dom";
import routes from "../../routes";
import {mergeContacts} from "../../../api/Contacts/MergeContacts";
import {rejectMergeContacts} from "../../../api/Contacts/RejectMergeContacts";

export class PendingMerges extends React.Component<any> {
	state = {
		loading: true,
		page: 1,
		limit: 100,
		pages: 0,
		pendingMergeList: [],
		totalResults: undefined,
	};

	componentDidMount() {
		this.getOptions();
		this.getPendingContacts();
	}

	getOptions = () => {
		const { options, getOptions } = this.props;
		if (!options) {
			getOptions().then();
		}
	};

	getPendingContacts = () => {
		const { getPendingMerges } = this.props;
		const { page, limit } = this.state;
		const data: FormData$GetPendingMerges = {
			page,
			limit
		};
		this.setState({
			loading: true
		});
		getPendingMerges(data)
			.then(res => {
				const { payload } = res;
				this.setState({
					pages: payload.pagination.meta.pages,
					page: payload.pagination.meta.page,
					totalResults: payload.pagination.meta.total,
					pendingMergeList: payload.pagination.contacts
				});
			})
			.catch(() => {
				this.setState({
					loading: false
				});
			})
			.then(() => {
				this.setState({
					loading: false
				});
			});
	};

	handlePageChange = (page: number) => {
		this.setState(
			{
				page: page + 1
			},
			() => {
				this.getPendingContacts();
			}
		);
	};

	handlePageSizeChange = (limit: number) => {
		this.setState(
			{
				limit: limit
			},
			() => {
				this.getPendingContacts();
			}
		);
	};

	confirmMerge = (fromContactId, toContactId) => {
		let data = {
			from_user_id: parseInt(fromContactId),
			to_user_id: parseInt(toContactId),
			commit_merge: true
		};

		mergeContacts(data)
			.then(res => {
				window.popup.pop();
				this.getPendingContacts();
			})
			.catch(() => {
				window.popup.pop();
				alert('Contacts merge failed, please try again.');
			})
			.then(() => {});
	};

	rejectMerge = (fromContactId, toContactId) => {
		let data = {
			from_user_id: parseInt(fromContactId),
			to_user_id: parseInt(toContactId)
		};
		rejectMergeContacts(data)
			.then(res => {
				window.popup.pop();
				this.getPendingContacts();
			})
			.catch(() => {
				window.popup.pop();
				alert('Contacts reject merge failed, please try again.');
			})
			.then(() => {});
	};

	renderColumns = () => {
		const columns = [];
		columns.push(
			{
				Header: 'From Contact',
				accessor: 'from_contact',
				minResizeWidth: 60,
				Cell: (props: any) =>
					<span>
						{(this.props.user.role_id < 3  || this.props.user.role_id == 6 || this.props.user.role_id == 4) && (
							<Link
								className="link"
								target="_blank"
								to={routes.contact.edit(props.original.from_contact.id)}
							>
								<span>{props.original.from_contact.contact_name}</span>
							</Link>
						)}
						{(this.props.user.role_id >= 3 && this.props.user.role_id != 6 && this.props.user.role_id != 4) && (
							<span>{props.original.from_contact.contact_name}</span>
						)}
					</span>
			},
			{
				Header: 'To Contact',
				accessor: 'to_contact',
				minResizeWidth: 60,
				Cell: (props: any) =>
					<span>
						<Link
							className="link"
							target="_blank"
							to={routes.contact.edit(props.original.to_contact.id)}
						>
							<span>{props.original.to_contact.contact_name}</span>
						</Link>
					</span>
			},
			{
				Header: 'Comment',
				accessor: 'comment',
				minResizeWidth: 60,
				Cell: (props: any) => <span>{props.original.comment}</span>
			}
		);

		if(this.props.user.role_id < 3 || this.props.user.role_id == 6 || this.props.user.role_id == 4)
			columns.push(
				{
					Header: 'By User',
					accessor: 'by_user',
					minResizeWidth: 60,
					Cell: (props: any) => <span>{props.original.by_user.display_name}</span>
				},
				{
				Header: 'Actions',
				width: 80,
				Cell: (props: any) => (
					<span className="flex flex--center" style={{ width: '100%' }}>
						<div
							style={{ marginRight: 8, cursor: "pointer" }}
							onClick={() => {
								const confirmMessage = 	<div className="confirm-contact-merge">
									<strong>From: </strong>
									<p>ID: {props.original.from_contact.id}</p>
									<p>Name: {props.original.from_contact.contact_name}</p>
									<br></br>
									<strong>To: </strong>
									<p>ID: {props.original.to_contact.id}</p>
									<p>Name: {props.original.to_contact.contact_name}</p>
								</div>;

								pushPopup(popups.Confirm, {
									title: 'Confirm Merge',
									onConfirm: () => this.confirmMerge(props.original.from_contact.id,props.original.to_contact.id),
									onReject: () => {
										this.rejectMerge(props.original.from_contact.id,props.original.to_contact.id)
									},
									cancelText: 'Reject',
									dangerCancel: true,
									description: 'Are you sure that you want to merge these contacts? **Contact ' + props.original.from_contact.id + ' will be deleted**',
									htmlDescription: confirmMessage
								});
							}}
						>
								<Svg icon="duplicate" />
							</div>
					</span>
				)
			});

		return columns;
	};

	render(): JSX.Element {
		const { options, user, pendingMerges, getPendingMerges } = this.props;
		const {
			loading,
			page,
			pages,
			pendingMergeList,
			limit,
			totalResults,
		} = this.state;

		return (
			<div className="page page--faq">
				<Dialog title={`Pending Merges ${
					totalResults ? `: ${totalResults} results` : ''
				}`} className="dialog">
					<ReactTable
						data={pendingMergeList.map(e => e)}
						columns={this.renderColumns()}
						sortable={false}
						resizable={true}
						showPagination={true}
						page={page - 1}
						pages={pages}
						onPageChange={this.handlePageChange}
						onPageSizeChange={this.handlePageSizeChange}
						pageSizeOptions= {getPageSizeOption(totalResults)}
						collapseOnPageChange={false}
						multiSort={false}
						manual
					/>
				</Dialog>
			</div>
		);
	}
}

const getPageSizeOption = (totalResults :any) => {
	return [20, 50, 100, 200, 300, 500, totalResults].sort((a, b) => a - b);
}

const mapStateToProps = (store: Store) => {
	const { options, user, pendingMerges, userList } = store;
	return { options, user, pendingMerges };
};

const mapDispatchToProps = (dispatch: any) => {
	return {
		getPendingMerges: (data: FormData$GetPendingMerges) => dispatch(getPendingMerges(data)),
		getOptions: () => dispatch(getOptions()),
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(PendingMerges);

// tslint:disable: max-line-length

import * as React from 'react';
import layout from '../../../../utils/layout';
import SvgTemplate from '../SvgTemplate';
import { Svg } from '../../Svg';

const icon = ({ color, ...rest }: Svg): JSX.Element => (
	<SvgTemplate {...rest} viewBox="0 0 96.26 96.261">
		<g>
			<g id="Layer_1">
				<polyline
					fill="#FFFFFF"
					points="
            30.533,66.525 34.972,56.689 61.289,56.689 65.725,66.52  "
					stroke="#8B8AA0"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeMiterlimit="10"
					strokeWidth="2"
				/>
				<line
					fill="#FFFFFF"
					stroke="#8B8AA0"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeMiterlimit="10"
					strokeWidth="2"
					x1="74.198"
					x2="22.063"
					y1="66.525"
					y2="66.525"
				/>
			</g>
			<g id="catam">
				<polygon
					className="fill"
					fill="#FFFFFF"
					points="
            74.203,66.522 74.203,84.082 65.533,84.082 59.463,66.522     "
					stroke="#8B8AA0"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeMiterlimit="10"
					strokeWidth="2"
				/>
				<polygon
					className="fill"
					fill="#FFFFFF"
					points="
            37.243,66.522 31.163,84.082 22.063,84.092 22.063,66.522     "
					stroke="#8B8AA0"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeMiterlimit="10"
					strokeWidth="2"
				/>
				<path
					className="fill"
					d="M55.946,66.525
            c1.562-1.823,5.755-7.691,5.755-13.349c0-18.59-9.901-33.639-13.57-40.088v53.437h6.785H55.946z"
					fill="#FFFFFF"
					stroke="#8B8AA0"
					strokeLinejoin="round"
					strokeMiterlimit="10"
					strokeWidth="2"
				/>
			</g>
		</g>
	</SvgTemplate>
);

icon.defaultProps = {
	width: layout.spacing.three,
	color: layout.color.primary
};

export default icon;

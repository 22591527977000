import {post} from "../../redux/api/utils";

export type Request$MergeContacts = {
	from_user_id: number;
	to_user_id: number;
	commit_merge?: boolean;
	comment?: string;
};

export type Response$MergeContacts = {
	message: 'ok';
};

function mergeContacts(
	data: Request$MergeContacts
): Promise<Response$MergeContacts> {
	return post('/contact/merge-contacts', data);
}

export { mergeContacts };

import * as React from 'react';
import './GoogleMap.scss';
import { styles } from './map-style';
import { LatLng } from '../../../models';
import env from '../../../config/env';

declare const document: any;

interface Props {
	center?: LatLng;
	width?: string | number;
	height?: string | number;
	draggableCursor?: string;
	children?: any;
	className?: string;
	elementId?: string;
	zoom?: number;
	draggable?: boolean;
	onClick?: (e: any) => any;
	getMapRef?: (e: any) => any;
	zoomControl?: boolean;
	fullscreenControl?: boolean;
}

export class GoogleMap extends React.Component<Props> {
	static defaultProps = {
		draggableCursor: '',
		draggable: true,
		zoom: 8,
		width: '100%',
		height: '100%',
		zoomControl: true,
		fullscreenControl: true
	};

	map: any;

	state = {
		map: null
	};

	componentDidMount() {
		this.initLoader();
	}

	initLoader = () => {
		if (!process.env.SERVER) {
			import('../../../utils/google').then(({ GoogleMapsLoader }) => {
				GoogleMapsLoader.load((google: any) => {
					this.initMap(google);
				});
			});
		}
	};

	initMap = (google: any) => {
		const {
			center,
			draggable,
			zoom,
			draggableCursor,
			onClick,
			getMapRef,
			zoomControl,
			fullscreenControl,
			elementId
		} = this.props;
		this.map = new google.maps.Map(document.getElementById(elementId || 'map'), {
			center,
			draggable,
			zoom,
			styles,
			draggableCursor,
			zoomControl,
			fullscreenControl,
			sateliteControl: false,
			streetViewControl: false,
			mapTypeControl: true,
			scrollwheel: false,
			fullscreenControlOptions: {
				position: google.maps.ControlPosition.BOTTOM_RIGHT
			},
			controlSize: 32
		});
		if (onClick) {
			this.map.addListener('click', onClick);
		}
		this.setState({
			map: this.map
		});

		// pass map object to parent
		getMapRef && getMapRef(this.map);
	};

	render(): JSX.Element {
		const { width, height, className, elementId, children } = this.props;
		const mapChildren = React.Children.map(
			children,
			(child: any, i: number): React.ReactNode => {
				if (child && this.map) {
					if (child.type.displayName === 'Marker') {
						return React.cloneElement(child, {
							key: `marker-${i}`,
							map: this.map
						});
					}
					if (child.type.displayName === 'Polyline') {
						return React.cloneElement(child, {
							key: `polyline-${i}`,
							map: this.map
						});
					}
				}
			}
		);
		return (
			<>
				<div id={elementId || 'map'} className={className || ''} style={{ width, height }}>
					{mapChildren}
				</div>

				{/* PRELOAD MARKER ICONS */}

				<div
					className="marker-blue"
					style={{
						backgroundImage: `url('${
							env.bucketUrl
						}/static/img/svg/marker-blue.svg')`
					}}
				/>
				<div
					className="marker-red"
					style={{
						backgroundImage: `url('${
							env.bucketUrl
						}/static/img/svg/marker-red.svg')`
					}}
				/>
				<div
					className="marker-yellow"
					style={{
						backgroundImage: `url('${
							env.bucketUrl
						}/static/img/svg/marker-yellow.svg')`
					}}
				/>
			</>
		);
	}
}

export default GoogleMap;

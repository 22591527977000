// tslint:disable: max-line-length

import * as React from 'react';
import layout from '../../../../utils/layout';
import SvgTemplate from '../SvgTemplate';
import { Svg } from '../../Svg';

const icon = ({ color, ...rest }: Svg): JSX.Element => (
	<SvgTemplate {...rest} viewBox="0 0 75 66">
		<g
			id="Page-1"
			stroke="none"
			strokeWidth="1"
			fill="none"
			fillRule="evenodd"
			strokeLinejoin="round"
		>
			<g
				id="payout"
				transform="translate(-11.000000, -15.000000)"
				stroke={color}
			>
				<g id="Layer_2" transform="translate(12.000000, 16.000000)">
					<g id="Group">
						<path
							d="M67.1,63.5 L5.1,63.5 C2.3,63.5 0.1,61.3 0.1,58.5 L0.1,37 C0.1,34.2 2.3,32 5.1,32 L67.1,32 C69.9,32 72.1,34.2 72.1,37 L72.1,58.4 C72.1,61.2 69.9,63.5 67.1,63.5 Z"
							id="Shape"
							strokeWidth="2"
						/>
						<path d="M36,62.9 L36,38.1" id="Shape" strokeWidth="2" />
						<path
							d="M41.4,54.6 L41.4,48 C41.4,46.3 42.8,45 44.4,45 L62.9,45 C64.5,45 65.9,46.3 65.9,48 L65.9,54.6"
							id="Shape"
							strokeWidth="2"
						/>
						<path
							d="M41.4,56 L41.4,52.9 C41.4,51.2 42.8,49.9 44.4,49.9 L62.9,49.9 C64.5,49.9 65.9,51.2 65.9,52.9 L65.9,56"
							id="Shape"
							strokeWidth="2"
						/>
						<g
							className="fill"
							transform="translate(7.000000, 10.000000)"
							fill="#FFFFFF"
							fillRule="nonzero"
						>
							<path
								d="M4.3,28.7 C2.7,24.5 0.7,19.8 0.7,19.9 C21.2,17.4 43.3,0.9 43.3,0.9 C43.3,0.9 46.6,2.9 50.1,11.7 C53.6,20.5 52.3,23.5 52.3,23.5 C52.3,23.5 44.3,24.7 34.4,27.9"
								id="Shape"
								strokeWidth="2"
							/>
							<ellipse
								id="Oval"
								strokeWidth="1.99997594"
								transform="translate(27.293070, 20.384610) rotate(-21.796090) translate(-27.293070, -20.384610) "
								cx="27.29307"
								cy="20.38461"
								rx="4.99993985"
								ry="3.89995308"
							/>
						</g>
						<g
							className="fill"
							transform="translate(19.000000, 9.000000)"
							fill="#FFFFFF"
							fillRule="nonzero"
						>
							<path
								d="M3.5,29.7 C1.6,25.7 0,22.3 0,22.3 C20.4,18.1 41,0.6 41,0.6 C41,0.6 44.4,2.3 48.7,10.8 C52.9,19.3 51.9,22.4 51.9,22.4 C51.9,22.4 42,24.7 30.9,29.7"
								id="Shape"
								strokeWidth="2"
							/>
							<ellipse
								id="Oval"
								strokeWidth="2.00003021"
								transform="translate(26.606840, 21.403940) rotate(-26.493307) translate(-26.606840, -21.403940) "
								cx="26.60684"
								cy="21.40394"
								rx="5.00007552"
								ry="3.90005891"
							/>
						</g>
						<path
							d="M6.7,38.7 L65.5,38.7"
							id="Shape"
							strokeWidth="2"
							strokeLinecap="round"
						/>
						<path
							d="M16.6,0.8 L16.6,16.1"
							id="Shape"
							strokeWidth="2"
							strokeLinecap="round"
						/>
						<polyline
							id="Shape"
							strokeWidth="2"
							strokeLinecap="round"
							points="23 12.1 16.6 18.5 10.3 12.1"
						/>
					</g>
				</g>
			</g>
		</g>
	</SvgTemplate>
);

icon.defaultProps = {
	width: layout.spacing.four,
	color: layout.color.primary
};

export default icon;

export enum HelpdeskEnum {
	LOADED_FAQ_LIST = 'HELPDESK/LOADED_FAQ_LIST',
	LOADED_SCENARIO_LIST = 'HELPDESK/LOADED_SCENARIO_LIST',
	ADDED_FAQ = 'ADDED_FAQ',
	UPDATED_FAQ = 'UPDATED_FAQ',
	DELETED_FAQ = 'DELETED_FAQ',
 	ADDED_SCENARIO = 'ADDED_SCENARIO',
	UPDATED_SCENARIO = 'UPDATED_SCENARIO',
	DELETED_SCENARIO = 'DELETE_SCENARIO'
}

// tslint:disable: max-line-length

import * as React from 'react';
import layout from '../../../../utils/layout';
import SvgTemplate from '../SvgTemplate';
import { Svg } from '../../Svg';

const icon = ({ color, ...rest }: Svg): JSX.Element => (
	<SvgTemplate {...rest} viewBox="0 0 12 16">
		<g id="trash" fill={color} fillRule="nonzero">
			<path
				d="M1.0106079,16.6132075 C1.0106079,16.8216226 1.17390274,16.990566 1.37534954,16.990566 L10.633769,16.990566 C10.8352158,16.990566 10.9985106,16.8216226 10.9985106,16.6132075 L10.9985106,4.38654717 L1.0106079,4.38654717 L1.0106079,16.6132075 Z M8.056231,6.46728302 L9.42401216,6.46728302 L9.42401216,14.6942453 L8.056231,14.6942453 L8.056231,6.46728302 Z M5.32066869,6.46728302 L6.68844985,6.46728302 L6.68844985,14.6942453 L5.32066869,14.6942453 L5.32066869,6.46728302 Z M2.58510638,6.46728302 L3.95288754,6.46728302 L3.95288754,14.6942453 L2.58510638,14.6942453 L2.58510638,6.46728302 Z"
				id="Shape"
			/>
			<path
				d="M9.07887538,1.73416981 L9.07887538,0.377358491 C9.07887538,0.168943396 8.91558055,0 8.71413374,0 L3.2949848,0 C3.09353799,0 2.93024316,0.168943396 2.93024316,0.377358491 L2.93024316,1.73416981 L2.93024316,1.97001887 L2.93024316,2.20586792 L0.380680851,2.20586792 C0.179234043,2.20586792 0.0159392097,2.37481132 0.0159392097,2.58322642 L0.0159392097,3.53749057 C0.0159392097,3.74588679 0.179234043,3.91484906 0.380680851,3.91484906 L1.0106079,3.91484906 L10.9985106,3.91484906 L11.6284377,3.91484906 C11.8298845,3.91484906 11.9931793,3.74588679 11.9931793,3.53749057 L11.9931793,2.58322642 C11.9931793,2.37481132 11.8298845,2.20586792 11.6284377,2.20586792 L9.07887538,2.20586792 L9.07887538,1.97001887 L9.07887538,1.73416981 Z M7.71109422,2.12828302 L4.29802432,2.12828302 L4.29802432,1.97 L4.29802432,1.73415094 L4.29802432,1.41509434 L7.71109422,1.41509434 L7.71109422,1.73416981 L7.71109422,1.97001887 L7.71109422,2.12828302 Z"
				id="Shape"
			/>
		</g>
	</SvgTemplate>
);

icon.defaultProps = {
	width: layout.spacing.three,
	color: layout.color.primary
};

export default icon;

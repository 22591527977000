import * as React from 'react';
import './AddContact.scss';
import Svg from '../../../components/Svg';
import ContactForm from '../../../components/Forms/ContactForm';
import { FormData$AddContact } from '../../../redux/api/Contact';
import { addContact, Action$AddContact } from '../../../redux/actions/Contact';
import { connect } from 'react-redux';
import User from '../../../redux/entities/User';
import { Action$GetOptions } from '../../../redux/actions';
import CheckDuplicate from './CheckDuplicate';

type Props = {
	user: User;
	getOptions: Action$GetOptions;
	addContact: Action$AddContact;
};

const AddContact = (props: Props): JSX.Element => {
	const { user } = props;
	React.useEffect(() => {
		const { getOptions } = props;
		getOptions && getOptions();
	}, []);

	const initialValues = {
		contact_name: '',
		emails: [],
		phones: [],
		links: [],
		boats: [],
		address: '',
		country_id: user.default_country_id,
		region_id: user.default_region_id,
		locale: user.default_main_language,
		locations: []
	};

	const [success, setSuccess] = React.useState(false);

	const { addContact } = props;

	return (
		<div className="page page--add-contact">
			{success ? (
				<div className="form-success">
					<Svg icon="check-circle" width={56} />
					<div>Contact added successfully!</div>
					<p className="link" onClick={() => setSuccess(false)}>
						Add another
					</p>
				</div>
			) : (
				<>
					<CheckDuplicate />
					<ContactForm
						onSubmit={addContact}
						onSuccess={() => setSuccess(true)}
						initialValues={initialValues}
					/>
				</>
			)}
		</div>
	);
};

function mapStateToProps(store: any) {
	const { user } = store;
	return { user };
}

function mapDispatchToProps(dispatch: any) {
	return {
		addContact: (data: FormData$AddContact) => dispatch(addContact(data))
	};
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(AddContact);

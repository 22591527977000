// tslint:disable: max-line-length
import * as React from 'react';
import layout from '../../../../utils/layout';
import SvgTemplate from '../SvgTemplate';
import { Svg } from '../../Svg';

const icon = ({ color, ...rest }: Svg): JSX.Element => (
	<SvgTemplate {...rest} viewBox="0 0 98.26 92.261">
		<>
			<g id="Layer_1" display="none" opacity="0.3">
				<g display="inline" opacity="0.5">
					<line
						fill="none"
						stroke="#ED1C24"
						strokeWidth="0.25"
						strokeMiterlimit="10"
						x1="15.667"
						y1="0.089"
						x2="15.667"
						y2="96.089"
					/>
					<line
						fill="none"
						stroke="#ED1C24"
						strokeWidth="0.25"
						strokeMiterlimit="10"
						x1="87.667"
						y1="0.089"
						x2="87.667"
						y2="96.089"
					/>
					<line
						fill="none"
						stroke="#ED1C24"
						strokeWidth="0.25"
						strokeMiterlimit="10"
						x1="99.709"
						y1="12.089"
						x2="3.709"
						y2="12.089"
					/>
					<line
						fill="none"
						stroke="#ED1C24"
						strokeWidth="0.25"
						strokeMiterlimit="10"
						x1="99.668"
						y1="84.089"
						x2="3.668"
						y2="84.089"
					/>
				</g>
				<g display="inline" opacity="0.5">
					<line
						fill="none"
						stroke="#00AEEF"
						strokeWidth="0.25"
						strokeMiterlimit="10"
						x1="27.667"
						y1="0.089"
						x2="27.667"
						y2="96.089"
					/>
					<line
						fill="none"
						stroke="#00AEEF"
						strokeWidth="0.25"
						strokeMiterlimit="10"
						x1="51.667"
						y1="0.089"
						x2="51.667"
						y2="96.089"
					/>
					<line
						fill="none"
						stroke="#00AEEF"
						strokeWidth="0.25"
						strokeMiterlimit="10"
						x1="75.667"
						y1="0.089"
						x2="75.667"
						y2="96.089"
					/>
					<line
						fill="none"
						stroke="#00AEEF"
						strokeWidth="0.25"
						strokeMiterlimit="10"
						x1="99.668"
						y1="24.089"
						x2="3.668"
						y2="24.089"
					/>
					<line
						fill="none"
						stroke="#00AEEF"
						strokeWidth="0.25"
						strokeMiterlimit="10"
						x1="99.668"
						y1="48.089"
						x2="3.668"
						y2="48.089"
					/>
					<line
						fill="none"
						stroke="#00AEEF"
						strokeWidth="0.25"
						strokeMiterlimit="10"
						x1="99.668"
						y1="72.089"
						x2="3.668"
						y2="72.089"
					/>
					<line
						fill="none"
						stroke="#00AEEF"
						strokeWidth="0.25"
						strokeMiterlimit="10"
						x1="3.667"
						y1="0.089"
						x2="99.751"
						y2="96.174"
					/>
					<line
						fill="none"
						stroke="#00AEEF"
						strokeWidth="0.25"
						strokeMiterlimit="10"
						x1="3.667"
						y1="96.089"
						x2="99.417"
						y2="0.339"
					/>
				</g>
			</g>
			<g id="Layer_2">
				<g>
					<path
						className="fill"
						fill="#fff"
						stroke="#8B8AA0"
						strokeWidth="2"
						strokeLinejoin="round"
						strokeMiterlimit="10"
						d="M18.718,48.859V26.764
      			c0,0,11.467-7.435,29.399-7.435c17.932,0,29.398,7.435,29.398,7.435v22.095"
					/>
					<path
						fill="#FFFFFF"
						stroke="#8B8AA0"
						strokeWidth="2"
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeMiterlimit="10"
						d="
      			M44.755,44.85c0,2.313-1.875,4.188-4.188,4.188H28.789c-2.312,0-4.188-1.875-4.188-4.188v-5.155c0-2.312,1.875-4.188,4.188-4.188
      			h11.778c2.312,0,4.188,1.876,4.188,4.188V44.85z"
					/>
					<path
						fill="#FFFFFF"
						stroke="#8B8AA0"
						strokeWidth="2"
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeMiterlimit="10"
						d="
      			M71.409,44.85c0,2.313-1.875,4.188-4.188,4.188H55.443c-2.313,0-4.188-1.875-4.188-4.188v-5.155c0-2.312,1.875-4.188,4.188-4.188
      			h11.778c2.312,0,4.188,1.876,4.188,4.188V44.85z"
					/>
					<polygon
						fill="#FFFFFF"
						stroke="#8B8AA0"
						strokeWidth="2"
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeMiterlimit="10"
						points="
      			77.847,45.163 18.414,45.163 15.412,54.965 80.849,54.965 		"
					/>
					<path
						className="fill"
						fill="#fff"
						stroke="#8B8AA0"
						strokeWidth="2"
						strokeLinejoin="round"
						strokeMiterlimit="10"
						d="M80.379,76.934H15.882
      			c-1.65,0-3-1.35-3-3V57.965c0-1.65,1.35-3,3-3h64.498c1.65,0,3,1.35,3,3v15.969C83.379,75.584,82.029,76.934,80.379,76.934z"
					/>
					<line
						fill="none"
						stroke="#8B8AA0"
						strokeWidth="2"
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeMiterlimit="10"
						x1="31.964"
						y1="65.95"
						x2="64.297"
						y2="65.95"
					/>
				</g>
			</g>
		</>
	</SvgTemplate>
);

icon.defaultProps = {
	width: layout.spacing.four,
	color: layout.color.primary
};

export default icon;

import * as React from 'react';
import InputSelect from '../InputSelect';
import InputText from '../InputText';
import './DoubleTextSelect.scss';

export const DoubleTextSelect = (props: any) => {
	const {
		value,
		children,
		validationError,
		placeholder,
		textProps,
		...rest
	} = props;
	if (!value || !children) {
		console.error(
			// tslint:disable-next-line: max-line-length
			'No values passed to DoubleTextSelect! Use an object\'s property as the name and add children <Option /> with the child prop as name! example: <Option name="year" value="2000">2000</Option>'
		);
		return null;
	}
	const keys = Object.keys(props.value);

	if (keys.length !== 2) {
		console.error('Double select must have exactly 2 values.');
		return null;
	}

	return (
		<div className="double-text-select box-shadow--input">
			<InputText
				{...textProps}
				{...rest}
				value={props.value[keys[0]]}
				name={`${props.name}.${keys[0]}`}
				validationError={validationError ? validationError[keys[0]] : null}
				placeholder={placeholder && placeholder[keys[0]]}
			/>
			<div className="double-select__divider">
				<div className="double-select__divider__inner" />
			</div>
			<InputSelect
				{...rest}
				value={props.value[keys[1]]}
				name={`${props.name}.${keys[1]}`}
				validationError={validationError ? validationError[keys[1]] : null}
				placeholder={placeholder && placeholder[keys[1]]}
			>
				{children &&
					children.length > 0 &&
					children.filter((option: any) => {
						return option.props.name === keys[1];
					})}
			</InputSelect>
		</div>
	);
};

export default React.memo(DoubleTextSelect);

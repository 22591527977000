// tslint:disable: max-line-length

import * as React from 'react';
import layout from '../../../../utils/layout';
import SvgTemplate from '../SvgTemplate';
import { Svg } from '../../Svg';

const icon = ({ color, ...rest }: Svg): JSX.Element => (
	<SvgTemplate {...rest} viewBox="0 0 58 58">
		<g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<g id="speedometer" fill={color} fillRule="nonzero">
				<path
					d="M54,43 L57.949,43 C57.728,39.892 56.494,37.066 54.568,34.846 L51.778,37.636 L50.364,36.222 L53.154,33.432 C50.842,31.427 47.872,30.174 44.613,30.031 C42.197,36.814 36.812,42.198 30.029,44.614 C30.187,48.372 31.835,51.75 34.409,54.175 C35.688,52.165 37.718,50.769 40.022,50.246 C40.017,50.163 40,50.083 40,50 C40,47.794 41.794,46 44,46 C44.287,46 44.566,46.033 44.836,46.091 L48.105,39.553 L49.894,40.448 L46.618,47 C47.458,47.734 48,48.8 48,50 C48,50.083 47.983,50.163 47.978,50.245 C50.281,50.768 52.31,52.164 53.589,54.173 C56.069,51.834 57.692,48.607 57.949,45 L54,45 L54,43 Z"
					id="Shape"
				/>
				<path
					d="M44,22 C44,9.869 34.131,0 22,0 C9.869,0 0,9.869 0,22 C0,34.131 9.869,44 22,44 C34.131,44 44,34.131 44,22 Z M22,42 C10.972,42 2,33.028 2,22 C2,10.972 10.972,2 22,2 C33.028,2 42,10.972 42,22 C42,33.028 33.028,42 22,42 Z"
					id="Shape"
				/>
				<path
					d="M36,21 L39.949,21 C39.716,16.792 38.039,12.972 35.395,10.019 L32.606,12.808 L31.192,11.394 L33.981,8.605 C31.029,5.961 27.209,4.284 23,4.051 L23,8 L21,8 L21,4.051 C16.791,4.284 12.971,5.961 10.019,8.605 L12.808,11.394 L11.394,12.808 L8.605,10.019 C5.961,12.972 4.284,16.792 4.051,21 L8,21 L8,23 L4.051,23 C4.36,28.584 7.214,33.488 11.483,36.577 C13.032,35.009 14.959,33.912 17.063,33.379 C17.279,31.664 18.352,30.224 19.852,29.504 L18.982,14.724 L22,10.197 L25.018,14.724 L24.148,29.504 C25.648,30.224 26.721,31.664 26.937,33.379 C29.041,33.912 30.968,35.009 32.517,36.577 C36.785,33.488 39.64,28.584 39.949,23 L36,23 L36,21 Z M6.538,26.125 L8.471,25.611 C8.63,26.209 8.832,26.803 9.071,27.377 L7.224,28.145 C6.951,27.489 6.72,26.81 6.538,26.125 Z M8.172,30.051 L9.901,29.045 C10.211,29.578 10.563,30.098 10.946,30.59 L9.367,31.819 C8.929,31.255 8.527,30.661 8.172,30.051 Z M12.399,34.8 C11.832,34.374 11.289,33.906 10.784,33.409 L12.186,31.983 C12.628,32.418 13.104,32.828 13.601,33.202 L12.399,34.8 Z"
					id="Shape"
				/>
				<path
					d="M19.17,33.045 C19.446,33.026 19.721,33 20,33 L24,33 C24.279,33 24.554,33.026 24.83,33.045 C24.429,31.861 23.319,31 22,31 C20.681,31 19.571,31.861 19.17,33.045 Z"
					id="Shape"
				/>
				<path
					d="M20,35 C17.459,35 15.04,35.968 13.197,37.684 C15.803,39.153 18.802,40 22,40 C25.198,40 28.197,39.153 30.803,37.684 C28.96,35.968 26.541,35 24,35 L20,35 Z"
					id="Shape"
				/>
				<path
					d="M46,52 L42,52 C39.508,52 37.23,53.34 35.982,55.461 C38.255,57.055 41.017,58 44,58 C46.982,58 49.744,57.055 52.018,55.459 C50.769,53.339 48.491,52 46,52 Z M41,56 L39,56 L39,54 L41,54 L41,56 Z M45,56 L43,56 L43,54 L45,54 L45,56 Z M49,56 L47,56 L47,54 L49,54 L49,56 Z"
					id="Shape"
				/>
				<path
					d="M22.174,29.018 L22.982,15.276 L22,13.803 L21.018,15.276 L21.826,29.018 C21.885,29.016 21.94,29 22,29 C22.06,29 22.115,29.016 22.174,29.018 Z"
					id="Shape"
				/>
				<path
					d="M44,48 C42.897,48 42,48.897 42,50 L46,50 C46,48.897 45.103,48 44,48 Z"
					id="Shape"
				/>
			</g>
		</g>
	</SvgTemplate>
);

icon.defaultProps = {
	width: layout.spacing.three,
	color: layout.color.primary
};

export default icon;

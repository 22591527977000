// tslint:disable: max-line-length

import * as React from 'react';
import layout from '../../../../utils/layout';
import SvgTemplate from '../SvgTemplate';
import { Svg } from '../../Svg';

const icon = ({ color, ...rest }: Svg): JSX.Element => (
	<SvgTemplate {...rest} viewBox="0 0 20 23">
		<g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<g
				id="navbar-notifications"
				transform="translate(-1.000000, -1.000000)"
				fill={color}
				fillRule="nonzero"
			>
				<path
					d="M11.0163175,24 C9.72369079,24 8.6660871,22.965 8.6660871,21.7 L13.3665479,21.7 C13.3665479,22.965 12.3089442,24 11.0163175,24 Z"
					id="Combined-Shape-Copy-5"
				/>
				<path
					d="M11.0163175,24 C12.3089442,24 13.3665479,22.965 13.3665479,21.7 L8.6660871,21.7 M18.6545664,17.1 L18.6545664,10.775 C18.6545664,7.21 16.1868244,4.335 12.7789903,3.53 L12.7789903,2.725 C12.7789903,1.805 11.9564097,1 11.0163175,1 C10.0762254,1 9.25364471,1.805 9.25364471,2.725 L9.25364471,3.53 C5.84581061,4.335 3.37806867,7.21 3.37806867,10.775 L3.37806867,17.1 L1.02783826,19.4 L1.02783826,20.55 L21.0047968,20.55 L21.0047968,19.4"
					id="Combined-Shape-Copy-6"
				/>
			</g>
		</g>
	</SvgTemplate>
);

icon.defaultProps = {
	width: layout.spacing.three,
	color: layout.color.primary
};

export default icon;

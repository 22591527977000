import * as React from 'react';
import { FieldArrayRenderProps, FormikProps } from 'formik';
import {
	FormikDoubleSelect,
	Option,
	FormikDoubleTextSelect
} from '../../../Form';
import Button, { ButtonIcon } from '../../../Button';
import './Boats.scss';

interface Boat {
	type: string;
	count: number;
}

const Boats = (
	props: FieldArrayRenderProps & FormikProps<any> & any
): JSX.Element => {
	const { insert, remove, values, options } = props;
	const [addBoatClicked, changeAddBoatValidation] = React.useState(false);

	const addBoat = (i: number): void => {
		// reset boat_type validation
		changeAddBoatValidation(true);
		props.form.validateForm().then(
			(res: any): void => {
				if (res.boat_types) {
					if (res.boat_types[i]) {
						return;
					}
				}
				changeAddBoatValidation(false);
				insert(i + 1, {
					count: '',
					type: ''
				});
			}
		);
	};
	return (
		<div className="boats">
			{values.boats.map(
				(boat_type: any, i: number): JSX.Element => {
					return (
						<div className="boat-field" key={`boat-${i}`}>
							<FormikDoubleTextSelect
								value={boat_type}
								name={`boats.${i}`}
								placeholder={{ type: 'select type', count: 0 }}
								className="select--boats"
								validateWhen={addBoatClicked}
								number={{ count: true }}
							>
								{options &&
									options.boat_types &&
									options.boat_types
										.filter((bvalue: any) => {
											if (values.boats[i].type === bvalue.id) {
												// dont filter out the current value
												return true;
											}

											// filter out the selected values
											const exists = values.boats.some((b: any) => {
												return b.type == bvalue.id;
											});
											return !exists;
										})
										.map((boat: any) => {
											return (
												<Option name="type" value={boat.id} key={boat.id}>
													{boat.boat_type}
												</Option>
											);
										})}
							</FormikDoubleTextSelect>
							<ButtonIcon
								iconWidth={8}
								className="btn--remove-boat"
								icon="close"
								iconColor="#fff"
								onClick={(): void => remove(i)}
							/>

							{i === values.boats.length - 1 && (
								<Button
									type="button"
									transparent
									dashed
									className="btn--add-boat"
									onClick={(): void => {
										addBoat(i);
									}}
								>
									+ add new type
								</Button>
							)}
						</div>
					);
				}
			)}
			{!values.boats.length && (
				<Button
					type="button"
					transparent
					dashed
					className="btn--add-boat"
					onClick={(): void => {
						addBoat(-1);
					}}
				>
					+ add new type
				</Button>
			)}
		</div>
	);
};

export default Boats;

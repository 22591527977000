import * as React from 'react';
import './Helpdesk.scss';
import ReactTable from 'react-table';
import { connect } from 'react-redux';
import { Store } from '../../../models';
import Dialog from '../../../components/Dialog';
import Button from '../../../components/Button';
import { pushPopup, popups } from '../../../utils/popups';
import { Language } from '../../../models/Options';
import { getOptions } from '../../../redux/actions';
import { listFAQActionCreator, listScenariosActionCreator } from '../../../redux/Helpdesk/HelpdeskActions';
import routes from "../../routes";
import Svg from "../../../components/Svg";
import {
	deleteFAQCreator,
	deleteScenarioCreator
} from "../../../redux/Helpdesk/HelpdeskActions/Actions";

export class Helpdesk extends React.Component<any> {
	componentDidMount() {
		const { getScenarios, getFAQ, options, getOptions, user } = this.props;
		getScenarios();
		getFAQ();
		if (!options) {
			getOptions();
		}
		// Redirects non-admins to home
		if(user.role_id > 1 && user.role_id != 6){
			this.props.history.push(routes.home.base);
		}
	}

	renderScenarioColumns = () => [
		{
			Header: 'ID',
			accessor: 'id',
			width: 60,
			minResizeWidth: 60,
			Cell: (props: any) => <span className="row__id">{props.original.id}</span>
		},
		{
			Header: 'Title',
			accessor: 'title',
			width: 200,
			minResizeWidth: 60,
			Cell: (props: any) => <span>{props.original.title}</span>
		},
		{
			Header: 'Content',
			accessor: 'content',
			style: { 'whiteSpace': 'unset', 'height': 'auto', 'maxHeight' : '250px'},
			minResizeWidth: 350,
			Cell: (props: any) =>
				<div style={{ 'padding': '20px', 'overflowY': 'auto', 'width': '100%'}}>
					<div
						dangerouslySetInnerHTML={{ __html: props.original.content }}
					/>
				</div>
		},
		{
			Header: 'Language',
			minResizeWidth: 60,
			accessor: 'language_id',
			width: 150,
			Cell: (props: any) => {
				const { options, user } = this.props;
				const selected_lang = options ? options.languages.find((item: Language) => {
					return item.id == props.original.language_id;
				}) : null;
				return (
					<span>{(selected_lang && selected_lang.language)}</span>
				);
			}
		},
		{
			Header: 'Actions',
			width: 120,
			Cell: (props: any) => (
				<span className="flex flex--center" style={{ width: '100%' }}>
					<div
						style={{ marginRight: 25, cursor: 'pointer' }}
						onClick={() =>
							pushPopup(popups.AddHelpdeskScenario, { toUpdate: props.original })
						}
					>
						<Svg icon="pencil" />
					</div>
					<div
						style={{ marginRight: 8, cursor: 'pointer' }}
						onClick={() => {
							const { deleteScenario } = this.props;
							if(window.confirm(`Are you sure that you want to delete this Scenario?`))
								deleteScenario(props.original.id);
						}}
					>
						<Svg icon="trash" width={14} />
					</div>
				</span>
			)
		}
	];

	renderFAQColumns = () => [
		{
			Header: 'ID',
			accessor: 'id',
			width: 60,
			minWidth: 60,
			Cell: (props: any) => <span className="row__id">{props.original.id}</span>
		},
		{
			Header: 'Question',
			accessor: 'question',
			minWidth: 60,
			width: 350,
			Cell: (props: any) => <span className="question-column">{props.original.question}</span>
		},
		{
			Header: 'Answer',
			accessor: 'answer',
			style: { 'whiteSpace': 'unset', 'height': 'auto', 'maxHeight' : '150px'},
			Cell: (props: any) =>
				<div style={{ 'padding': '10px', 'overflowY': 'auto'}} >
					{props.original.answer}
				</div>
		},
		{
			Header: 'Language',
			minResizeWidth: 60,
			accessor: 'language_id',
			width: 200,
			Cell: (props: any) => {
				const { options, user } = this.props;
				const selected_lang = options ? options.languages.find((item: Language) => {
					return item.id == props.original.language_id;
				}) : null;
				return (
					<span>{(selected_lang && selected_lang.language)}</span>
				);
			}
		},
		{
			Header: 'Actions',
			width: 120,
			Cell: (props: any) => (
				<span className="flex flex--center" style={{ width: '100%'}}>
					<div
						style={{ marginRight: 25, cursor: 'pointer' }}
						onClick={() =>
							pushPopup(popups.AddHelpdeskFAQ, { toUpdate: props.original })
						}
					>
						<Svg icon="pencil" />
					</div>
					<div
						style={{ marginRight: 8, cursor: 'pointer' }}
						onClick={() => {
							const { deleteFAQ } = this.props;
							if(window.confirm(`Are you sure that you want to delete this Scenario?`))
								deleteFAQ(props.original.id);
						}}
					>
						<Svg icon="trash" width={14} />
					</div>
				</span>
			)
		}
	];

	render(): JSX.Element {
		const { faqList, scenarioList } = this.props;
		return (
			<div className="page page--faq">
				<Dialog title="Scenarios" className="small-dialog">
					<div className={'top-buttons'}>
						<Button
							small
							className="btn--add-new"
							onClick={() => pushPopup(popups.AddHelpdeskScenario,{ })}
						>
							+ Add new Scenario
						</Button>
					</div>
					<ReactTable
						className={'scenario--table box-shadow'}
						data={scenarioList}
						columns={this.renderScenarioColumns()}
						sortable={false}
						resizable={true}
						showPagination={true}
						collapseOnPageChange={false}
						multiSort={false}
						manual
					/>
				</Dialog>
				<Dialog title="FAQs" className="small-dialog">
					<div className={'top-buttons'}>
						<Button
							small
							className="btn--add-new"
							onClick={() => pushPopup(popups.AddHelpdeskFAQ,{ })}
						>
							+ Add new FAQ
						</Button>
					</div>
					<ReactTable
						className={'faq--table box-shadow'}
						data={faqList}
						columns={this.renderFAQColumns()}
						sortable={false}
						resizable={true}
						showPagination={true}
						collapseOnPageChange={false}
						multiSort={false}
						manual
					/>
				</Dialog>
			</div>
		);
	}
}

const mapStateToProps = (store: Store) => {
	const { faqList, scenarioList, options, user } = store;
	return { faqList, scenarioList, options, user };
};

const mapDispatchToProps = (dispatch: any) => {
	return {
		getScenarios: () => dispatch(listScenariosActionCreator()),
		getFAQ: () => dispatch(listFAQActionCreator()),
		deleteFAQ: (id:string) => dispatch(deleteFAQCreator(id)),
		deleteScenario: (id: string) => dispatch(deleteScenarioCreator(id)),
		getOptions: () => dispatch(getOptions())
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Helpdesk);

import InputText from './InputText';
import InputCheckbox from './InputCheckbox';
import InputRadioMultiple from './InputRadio/InputRadioMultiple';
import InputSelect from './InputSelect';
import InputTextarea from './InputTextarea';
import DoubleSelect from './DoubleSelect';
import DoubleTextSelect from './DoubleTextSelect';
import Option from './Option';
import GoogleAutoComplete from '../Google/GoogleAutoComplete';
import FormGroup from './FormGroup';
import MultiSelect from "./MultiSelect";
import MultiSelectOnForm from './MultiSelectOnForm';
import FormikForm from './FormikForm';
import withFormik from '../HOC/withFormik';
import formikSubmit from './functions/formikSubmit';
import FormikButtonSubmit from './FormikButtonSubmit';

export { FormikButtonSubmit };

// Exporting our Formik-HOCed Components
export const FormikInputText = withFormik(InputText);
export const FormikInputRadio = withFormik(InputRadioMultiple);
export const FormikInputSelect = withFormik(InputSelect);
export const FormikInputCheckbox = withFormik(InputCheckbox);
export const FormikInputTextarea = withFormik(InputTextarea);
export const FormikDoubleSelect = withFormik(DoubleSelect);
export const FormikDoubleTextSelect = withFormik(DoubleTextSelect);
export const FormikGoogleAutoComplete = withFormik(GoogleAutoComplete);
export const FormikMultiSelect = withFormik(MultiSelect);
export const FormikMultiSelectOnForm = withFormik(MultiSelectOnForm);
export { FormikForm, Option, FormGroup, formikSubmit };

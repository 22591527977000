import * as React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Home from './pages/Home/Home';
import NotFound from './pages//NotFound';
import { RouterProps } from 'react-router';
import Login from './pages/Login';
import withAuth from '../components/HOC/withAuth';
import AddContact from './pages/AddContact';
import Sidebar from './Sidebar';
import routes from './routes';
import ContactList from './pages/ContactList';
import Header from '../components/Header';
import EditContact from './pages/EditContact/EditContact';
import Profile from './pages/Profile';
import Users from './pages/Users';
import Dashboard from './pages/Dashboard';
import CustomEmail from './pages/CustomEmail';
import CallCenter from "./pages/CallCenter/CallCenter";
import Helpdesk from "./pages/Helpdesk/Helpdesk";
import PendingMerges from "./pages/PendingMerges/PendingMerges";
import ToBeChecked from './pages/ToBeChecked';
import { connect } from 'react-redux';
import { Store } from '../models';

const MainRouter = (props: any): JSX.Element => {
	const { user } = props;
	return (
		<Router>
			<Header />
			<main className="router-wrapper">
				<Sidebar {...props} />
				<Switch>
					<Route
						path={routes.home.base}
						render={(routerProps: RouterProps) => (
							// @ts-ignore
							<Dashboard {...routerProps} />
						)}
						exact
					/>
					<Route
						path={routes.contact.add}
						render={(routerProps: RouterProps) => (
							// @ts-ignore
							<AddContact {...routerProps} />
						)}
						exact
					/>
					<Route
						path={routes.contact.list}
						render={(routerProps: RouterProps) => (
							// @ts-ignore
							<ContactList {...routerProps} />
						)}
						exact
					/>
					<Route
						path={routes.pendingMerges.base}
						render={(routerProps: RouterProps) => (
							<PendingMerges {...routerProps} />
						)}
						exact
					/>
					<Route
						path={routes.contact.edit()}
						render={(routerProps: RouterProps) => (
							// @ts-ignore
							<EditContact {...routerProps} />
						)}
						exact
					/>
					<Route
						path={routes.profile.base}
						render={(routerProps: RouterProps) => (
							// @ts-ignore
							<Profile {...routerProps} />
						)}
						exact
					/>
					{(user.role_id === 1 || user.role_id === 2 || user.role_id === 6) && (
						<Route
							path={routes.users.base}
							render={(routerProps: RouterProps) => <Users {...routerProps} />}
							exact
						/>
					)}
					<Route
						path={routes.contact.customEmail.base()}
						render={(routerProps: RouterProps) => (
							// @ts-ignore
							<CustomEmail {...routerProps} />
						)}
					/>
					{(user.role_id <= 2 || user.role_id === 4) && (
						<Route
							path={routes.toBeChecked.base}
							render={(routerProps: RouterProps) => (
								<ToBeChecked {...routerProps} />
							)}
						/>
					)}
					{(user.role_id === 1 || user.role_id === 2) && (
						<Route
							path={routes.callCenter.base}
							render={(routerProps: RouterProps) => (
								<CallCenter {...routerProps} />
							)}
						/>
					)}
					{(user.role_id === 1 || user.role_id === 6) && (
						<Route
							path={routes.helpdesk.base}
							render={(routerProps: RouterProps) => (
								<Helpdesk {...routerProps} />
							)}
						/>
					)}
					<Route
						render={(routerProps: RouterProps) => <NotFound {...routerProps} />}
					/>
				</Switch>
			</main>
		</Router>
	);
};

function mapStateToProps(store: Store) {
	const { user } = store;
	return { user };
}

export default withAuth(connect(mapStateToProps)(MainRouter));

import * as React from 'react';
import './CheckDuplicate.scss';
import { connect } from 'react-redux';
import { RouterProps } from 'react-router';
import InputText from '../../../../components/Form/InputText';
import Label from '../../../../components/Form/Label';
import {Action, Store} from '../../../../models';
import { FormData$CheckDuplicate } from '../../../../redux/api/Contact';
import {
	checkDuplicate,
	Action$CheckDuplicate
} from '../../../../redux/actions/Contact';
import Svg from '../../../../components/Svg';
import { hot } from 'react-hot-loader';
import layout from '../../../../utils/layout';

type Props = {
	checkDuplicate?: Action$CheckDuplicate;
} & RouterProps;

class CheckDuplicate extends React.Component<Props> {
	state = {
		value: '',
		loading: false,
		error: '',
		success: false
	};

	timeout: any = null;

	handleChange = (e: any) => {
		this.setState({
			success: false,
			error: null
		});
		const { value } = e.target;
		clearTimeout(this.timeout);
		this.setState({ value });
		this.timeout = setTimeout(() => this.search(value), 500);
	};

	search = (value: string, redirect?: boolean) => {
		const { checkDuplicate } = this.props;
		if (!value || value.length < 3) return;

		this.setState({
			loading: true
		});

		this.setState({
			success: false
		});

		checkDuplicate &&
			checkDuplicate({ value, redirect })
				.then((res: Action) => {
					if (!res.payload) {
						this.setState({
							success: true
						});
						return;
					}
					const { message, contact_id } = res.payload;
					if(contact_id)
						window.open(`/contact/${contact_id}`, "_blank")
					this.setState({
						error: message
					});
				})
				.catch((ex: any) => {});
	};

	render(): JSX.Element {
		const { value, loading, success, error } = this.state;
		return (
			<div className="check-duplicate">
				<Label
					title="Check for duplicate entries:"
					hint="Type a phone number, an email address, or a url."
				/>
				<InputText
					validationError={error}
					value={value}
					onChange={this.handleChange}
					placeholder="ex. info@some-yachts.com"
					maxLength={500}
				/>
				{success && (
					<div className="success">
						<Svg icon="check" color={layout.color.success} />
						<p className="success__text">Looks ok!</p>
					</div>
				)}
				{error && (
					<div className="error">
						<Svg icon="close" color={layout.color.danger} />
						<p className="error__text">{error}</p>
						<a onClick={() => this.search(value, true)}>view Contact</a>
					</div>
				)}
			</div>
		);
	}
}

const mapStateToProps = (store: any) => {
	const { options, history } = store;
	return { options, history };
};

const mapDispatchToProps = (dispatch: any) => {
	return {
		checkDuplicate: (data: FormData$CheckDuplicate) =>
			dispatch(checkDuplicate(data))
	};
};

export default hot(module)(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(CheckDuplicate)
);

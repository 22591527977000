// tslint:disable: max-line-length

import * as React from 'react';
import layout from '../../../../utils/layout';
import SvgTemplate from '../SvgTemplate';
import { Svg } from '../../Svg';

const icon = ({ color, ...rest }: Svg): JSX.Element => (
	<SvgTemplate {...rest} viewBox="0 0 12 16">
		<g
			id="Page-1"
			stroke="none"
			strokeWidth="1"
			fill="none"
			fillRule="evenodd"
			opacity="0.3"
		>
			<g
				id="Profile/EditProfile"
				transform="translate(-361.000000, -530.000000)"
				fill={color}
			>
				<g id="Group-8" transform="translate(361.000000, 530.000000)">
					<g id="Group-5">
						<path
							d="M1.36870027,4.67904509 L1.36870027,6.30557029 L1.26047745,6.30557029 C0.579310345,6.30557029 0.025464191,6.85941645 0.025464191,7.54058355 L0.025464191,14.3427056 C0.025464191,15.0238727 0.579310345,15.5777188 1.26047745,15.5777188 L10.7522546,15.5777188 C11.4334218,15.5777188 11.9872679,15.0238727 11.9872679,14.3427056 L11.9872679,7.53740053 C11.9872679,6.85623342 11.4334218,6.30238727 10.7522546,6.30238727 L10.7204244,6.30238727 L10.7204244,4.67586207 C10.7204244,2.09761273 8.62281167,0 6.04456233,0 C3.466313,0.00318302387 1.36870027,2.10079576 1.36870027,4.67904509 Z M6.73846154,11.2424403 C6.64297082,11.3124668 6.61750663,11.3793103 6.61750663,11.4907162 C6.62068966,11.9904509 6.62068966,12.4870027 6.62068966,12.9867374 L6.62068966,12.9867374 C6.63023873,13.193634 6.52519894,13.3973475 6.34058355,13.4896552 C5.90450928,13.7124668 5.46843501,13.4005305 5.46843501,12.9867374 L5.46843501,12.9835544 C5.46843501,12.4838196 5.46843501,11.9840849 5.47161804,11.4843501 C5.47161804,11.3824934 5.44933687,11.3156499 5.3602122,11.2488064 C4.90822281,10.9145889 4.75862069,10.3448276 4.98461538,9.84509284 C5.20424403,9.35809019 5.74217507,9.07161804 6.24827586,9.1734748 C6.81167109,9.28806366 7.20318302,9.74005305 7.20954907,10.3034483 C7.20954907,10.6949602 7.0535809,11.0132626 6.73846154,11.2424403 Z M3.41538462,4.67904509 C3.41538462,3.22758621 4.59628647,2.04986737 6.04456233,2.04986737 C7.49602122,2.04986737 8.67374005,3.23076923 8.67374005,4.67904509 L8.67374005,6.30557029 L3.41538462,6.30557029 L3.41538462,4.67904509 Z"
							id="Shape"
						/>
					</g>
				</g>
			</g>
		</g>
	</SvgTemplate>
);

icon.defaultProps = {
	width: layout.spacing.three,
	color: '#EB2726'
};

export default icon;

import * as React from 'react';
import './Popup.scss';
import Svg from '../../Svg';
import layout from '../../../utils/layout';

interface Props {
	children: any;
	active: boolean;
	width?: string | number;
	title?: string;
	header?: JSX.Element;
	footer?: JSX.Element;
	className?: string;
	closeOnClickOutside?: boolean;
	dontShowClose?: boolean;
	onClose?: any;
}

class Popup extends React.Component<Props, {}> {
	/* istanbul ignore next */
	shouldComponentUpdate(nextProps: Props) {
		if (this.props.active !== nextProps.active) return true;
		if (this.props.children !== nextProps.children) return true;
		return false;
	}

	handleClose = () => {
		this.props.onClose && this.props.onClose();
		window && window.popup && window.popup.pop();
	};

	_stopPropagation = (e: React.MouseEvent<HTMLDivElement>) => {
		e && e.stopPropagation && e.stopPropagation();
	};

	render(): JSX.Element {
		const {
			children,
			active,
			width,
			title,
			header,
			footer,
			className,
			closeOnClickOutside,
			dontShowClose
		} = this.props;
		let popupClassName = 'popup';
		if (className) {
			popupClassName += ` ${className}`;
		}
		return (
			<React.Fragment>
				{active && (
					<div className={popupClassName}
						 onClick={ closeOnClickOutside ? this.handleClose: undefined }
					>
						<div className="popup__flex-helper">
							<React.Fragment>
								<div
									className="popup__dialog"
									style={{ width: width ? `${width}px` : 'auto' }}
									onClick={this._stopPropagation}
								>
									{!dontShowClose && (
										<button
											className="popup__close box-shadow"
											onClick={this.handleClose}
										>
											<Svg icon="close" color={layout.color.white} />
										</button>
									)}
									{(title || header) && (
										<div className="popup__header">
											<React.Fragment>
												{title && (
													<h2 className="popup__header__title">{title}</h2>
												)}
												{header}
											</React.Fragment>
										</div>
									)}
									<div className="popup__content">{children}</div>
								</div>
								{footer && (
									<div
										className="popup__footer box-shadow"
										style={{ width: width ? `${width}px` : 'auto' }}
										onClick={this._stopPropagation}
									>
										<React.Fragment>{footer}</React.Fragment>
									</div>
								)}
							</React.Fragment>
						</div>
					</div>
				)}
			</React.Fragment>
		);
	}
}

export default Popup;

// tslint:disable: max-line-length

import * as React from 'react';
import layout from '../../../../utils/layout';
import SvgTemplate from '../SvgTemplate';
import { Svg } from '../../Svg';

const icon = ({ color, ...rest }: Svg): JSX.Element => (
	<SvgTemplate {...rest} viewBox="0 0 8 8">
		<g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<g id="close-white" fill={color} fillRule="nonzero">
				<path
					d="M5.35462948,4.00021373 L7.71944369,1.63556279 C8.09362194,1.26138005 8.09362194,0.654794018 7.71944369,0.280802878 C7.34564866,-0.0931882606 6.73868665,-0.0931882606 6.36489162,0.280802878 L4.00007739,2.64583701 L1.63526316,0.280802878 C1.2614681,-0.0931882606 0.654506137,-0.0931882606 0.28071107,0.280802878 C-0.0934671821,0.65498561 -0.0934671821,1.26157165 0.28071107,1.63556279 L2.6453337,4.00021373 L0.28071107,6.36486469 C-0.0934671821,6.73904742 -0.0934671821,7.34563341 0.28071107,7.71962456 C0.467512809,7.90681175 0.712941558,8.00011794 0.95798712,8.00011794 C1.20284109,8.00011794 1.44846143,7.90681175 1.63526316,7.71962456 L4.00007739,5.35497363 L6.36489162,7.71981614 C6.55188492,7.90700333 6.79712209,8.00030952 7.04216762,8.00030952 C7.28721322,8.00030952 7.53264196,7.90700333 7.71944369,7.71981614 C8.09362194,7.34563341 8.09362194,6.73904742 7.71944369,6.36505627 L5.35462948,4.00021373 Z"
					id="Shape-Copy"
				/>
			</g>
		</g>
	</SvgTemplate>
);

icon.defaultProps = {
	width: layout.spacing.ss,
	color: layout.color.primary
};

export default icon;

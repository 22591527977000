import * as React from 'react';
import { hot } from 'react-hot-loader';
import { Provider } from 'react-redux';
import store from './redux/store';
import MainRouter from './router';
import PopupManager from './components/Popups/PopupManager';
import Snackbar from "./components/Snackbar/Snackbar";

class App extends React.Component<{}> {
	render() {
		return (
			<Provider store={store}>
				<MainRouter />
				<Snackbar />
				<PopupManager />
			</Provider>
		);
	}
}

export default hot(module)(App);

// tslint:disable: max-line-length

import * as React from 'react';
import layout from '../../../../utils/layout';
import SvgTemplate from '../SvgTemplate';
import { Svg } from '../../Svg';

const icon = ({ color, ...rest }: Svg): JSX.Element => (
	<SvgTemplate {...rest} viewBox="0 0 21 19">
		<g
			id="Symbols"
			stroke="none"
			strokeWidth="1"
			fill="none"
			fillRule="evenodd"
		>
			<g id="purple-full" fill={color}>
				<path
					d="M15.3097912,0.14 C13.7797912,0.14 12.1297912,0.77 10.5197912,1.96 C8.8797912,0.73 7.2297912,0.1 5.6897912,0.1 C2.9597912,0.1 0.829791202,2.05 0.249791202,5.09 C-0.610208798,9.6 2.1497912,15.59 10.2497912,18.85 C10.3397912,18.88 10.4297912,18.9 10.5197912,18.9 C10.6097912,18.9 10.6997912,18.88 10.7897912,18.85 C18.8597912,15.52 21.6097912,9.55 20.7497912,5.08 C20.1597912,2.07 18.0397912,0.14 15.3097912,0.14"
					id="Fill-1"
				/>
			</g>
		</g>
	</SvgTemplate>
);

icon.defaultProps = {
	width: layout.spacing.three,
	color: layout.color.primary
};

export default icon;

import { Action$listFAQ } from '../HelpdeskActions';
import initialState from '../../store/initialState';
import { HelpdeskEnum } from '../enums';
import {Action$AddFAQ, Action$DeleteFAQ, Action$EditFAQ} from "../HelpdeskActions/Actions";

type Actions = Action$listFAQ | Action$AddFAQ | Action$EditFAQ | Action$DeleteFAQ;

function FAQReducer(
	faqList: any = initialState.faqList,
	action: Actions
) {
	const list = [...faqList];
	const { type, payload } = action;
	switch (type) {
		case HelpdeskEnum.LOADED_FAQ_LIST:
			return payload.data;
		case HelpdeskEnum.ADDED_FAQ:
			list.push(payload.data);
			return list;
		case HelpdeskEnum.UPDATED_FAQ:
			let res = payload.data as any;
			let updatedFAQ = list.findIndex(x => x.id == res.id);
			list[updatedFAQ] = res;
			return list;
		case HelpdeskEnum.DELETED_FAQ:
			let deleted_id = payload.data;
			for (let i = list.length - 1; i >= 0; --i) {
				if (list[i].id == deleted_id) {
					list.splice(i,1);
				}
			}
			return list;
		default:
			return faqList;
	}
}

export default FAQReducer;

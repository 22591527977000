import { popups } from '../../utils/popups';
import Confirm from './Confirm';
import Alert from './Alert';
import NewCall from './NewCall';
import SendEmail from './SendEmail/SendEmail';
import SendMessage from './SendMessage/SendMessage';
import ChangeUserPassword from './ChangeUserPassword';
import AddUser from './AddUser';
import ToBeCheckedComments from './ToBeCheckedComments';
import AssignContactsToUser from './AssignContactsToUser';
import MergeContact from "./MergeContact/MergeContact";
import MakeCall from "./MakeCall/MakeCall";
import MakeCallReview from "./MakeCallReview/MakeCallReview";
import AddCallCenterAccount from "./AddCallCenterAccount/AddCallCenterAccount";
import ChangeCallCenterAccountPassword from "./ChangeCallCenterAccountPassword/ChangeCallCenterAccountPassword";
import ViewHelpdesk from "./ViewHelpdesk/ViewHelpdesk";
import AddHelpdeskFAQ from "./AddHelpdeskFAQ/AddHelpdeskFAQ";
import AddHelpdeskScenario from "./AddHelpdeskScenario/AddHelpdeskScenario";
import EditSupervisors from "./EditSupervisors/EditSupervisors"

const popupSchema = {
	[popups.Alert]: Alert,
	[popups.Confirm]: Confirm,
	[popups.NewCall]: NewCall,
	[popups.SendEmail]: SendEmail,
	[popups.ChangeUserPassword]: ChangeUserPassword,
	[popups.AddUser]: AddUser,
	[popups.ToBeCheckedComments]: ToBeCheckedComments,
	[popups.AssignContactsToUser]: AssignContactsToUser,
	[popups.MergeContact]: MergeContact,
	[popups.SendMessage]: SendMessage,
	[popups.MakeCall]: MakeCall,
	[popups.MakeCallReview]: MakeCallReview,
	[popups.AddCallCenterAccount]: AddCallCenterAccount,
	[popups.ChangeCallCenterAccountPassword]: ChangeCallCenterAccountPassword,
	[popups.ViewHelpdesk]: ViewHelpdesk,
	[popups.AddHelpdeskFAQ]: AddHelpdeskFAQ,
	[popups.AddHelpdeskScenario]: AddHelpdeskScenario,
	[popups.EditSupervisors]: EditSupervisors

};

export default popupSchema;

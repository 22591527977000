// tslint:disable: max-line-length

import * as React from 'react';
import layout from '../../../../utils/layout';
import SvgTemplate from '../SvgTemplate';
import { Svg } from '../../Svg';

const icon = ({ color, ...rest }: Svg): JSX.Element => (
	<SvgTemplate {...rest} viewBox="0 0 10 21">
		<g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<g id="fb-copy" fill={color}>
				<g id="Page-1">
					<path
						d="M2.08962038,3.94056762 L2.08962038,6.74195219 L0,6.74195219 L0,10.1674961 L2.08962038,10.1674961 L2.08962038,20.347008 L6.38214423,20.347008 L6.38214423,10.1677799 L9.26262704,10.1677799 C9.26262704,10.1677799 9.53239686,8.52525785 9.6631637,6.72932141 L6.3983757,6.72932141 L6.3983757,4.38713911 C6.3983757,4.03707248 6.86639094,3.56618555 7.32896358,3.56618555 L9.66769117,3.56618555 L9.66769117,0 L6.48781732,0 C1.9835138,-0.000236531508 2.08962038,3.42871344 2.08962038,3.94056762 L2.08962038,3.94056762 L2.08962038,3.94056762 Z"
						id="Facebook-Copy"
					/>
				</g>
			</g>
		</g>
	</SvgTemplate>
);

icon.defaultProps = {
	width: layout.spacing.base,
	color: layout.color.primary
};

export default icon;

import { Action } from '../../../models';
import { API_ERROR, CLEAR_API_ERROR } from '../../api/constants';

function apiErrorReducer(state = null, action: Action): any {
	switch (action.type) {
		case API_ERROR:
			return action.payload;
		case CLEAR_API_ERROR:
			return null;
		default:
			return state;
	}
}

export default apiErrorReducer;

import { UserEnum } from '../enums';
import { Action } from '../../models';
import {
	Request$ChangeSipAccount,
	changeSipAccount,
	Response$ChangeSipAccount
} from '../../../api/User';

export type Action$ChangeSipAccount = Action<UserEnum.CHANGED_SIP_ACCOUNT, any>;

export type ActionCreator$ChangeSipAccount = (
	data: Request$ChangeSipAccount
) => Promise<Action$ChangeSipAccount>;

const changeSipAccountActionCreator: ActionCreator$ChangeSipAccount = async (
	data: Request$ChangeSipAccount
): Promise<Action$ChangeSipAccount> => {
	const response: Response$ChangeSipAccount = await changeSipAccount(data);
	return {
		type: UserEnum.CHANGED_SIP_ACCOUNT,
		payload: response
	};
};

export default changeSipAccountActionCreator;

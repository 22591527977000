import { Action } from '../../models';
import { UserEnum } from '../enums';
import {
	Request$UpdateProfile,
	updateProfile,
	Response$UpdateProfile
} from '../../../api/User';

export type Action$UpdateProfile = Action<
	UserEnum.UPDATED_PROFILE,
	Response$UpdateProfile
>;

/**
 * Action that updates user details.
 * Must be dispatched!
 */
export async function updateProfileAction(
	data: Request$UpdateProfile
): Promise<Action$UpdateProfile> {
	const res = await updateProfile(data);
	return {
		type: UserEnum.UPDATED_PROFILE,
		payload: res
	};
}

// tslint:disable: max-line-length

import * as React from 'react';
import layout from '../../../../utils/layout';
import SvgTemplate from '../SvgTemplate';
import { Svg } from '../../Svg';

const icon = ({ color, ...rest }: Svg): JSX.Element => (
	<SvgTemplate {...rest} viewBox="0 0 37 37">
		<g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<g id="phone_icon_nocircle" fill={color} fillRule="nonzero">
				<path
					d="M36.1,29 L35.9,28.5 C35.5,27.3 34.1,26 32.9,25.6 L28.2,24.3 C26.9,24 25.1,24.4 24.2,25.3 L22.5,27 C16.4,25.3 11.6,20.5 9.9,14.4 L11.6,12.7 C12.5,11.8 13,10 12.6,8.7 L11.3,4 C11,2.7 9.7,1.4 8.4,1 L8,0.9 C6.8,0.5 5,0.9 4.1,1.8 L1.6,4.3 C1.2,4.7 0.9,6 0.9,6 C0.8,14 4,21.7 9.6,27.4 C15.2,33 22.9,36.2 30.9,36.1 C30.9,36.1 32.3,35.8 32.7,35.4 L35.2,32.9 C36.1,32 36.5,30.3 36.1,29 Z"
					id="Shape"
				/>
			</g>
		</g>
	</SvgTemplate>
);

icon.defaultProps = {
	width: layout.spacing.three,
	color: layout.color.primary
};

export default icon;

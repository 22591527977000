import {Action$listScenarios} from '../HelpdeskActions';
import initialState from '../../store/initialState';
import { HelpdeskEnum } from '../enums';
import {
	Action$AddScenario,
	Action$DeleteScenario,
	Action$EditScenario
} from "../HelpdeskActions/Actions";

type Actions = Action$listScenarios | Action$EditScenario | Action$AddScenario | Action$DeleteScenario;

function ScenariosReducer(
	scenarioList: any = initialState.scenarioList,
	action: Actions
) {
	const list = [...scenarioList];
	const { type, payload } = action;
	switch (type) {
		case HelpdeskEnum.LOADED_SCENARIO_LIST:
			return payload.data;
		case HelpdeskEnum.ADDED_SCENARIO:
			list.push(payload.data);
			return list;
		case HelpdeskEnum.UPDATED_SCENARIO:
			let res = payload.data as any;
			let updatedScenario = list.findIndex(x => x.id == res.id);
			list[updatedScenario] = res;
			return list;
		case HelpdeskEnum.DELETED_SCENARIO:
			let deleted_id = payload.data as any;
            for (let i = list.length - 1; i >= 0; --i) {
                if (list[i].id == deleted_id) {
					list.splice(i,1);
                }
            }
			return list;
		default:
			return scenarioList;
	}
}

export default ScenariosReducer;

import * as React from 'react';
import './PopupManager.scss';
import { hot } from 'react-hot-loader';
import { preventBodyScroll } from '../../utils/dom';
import popupSchema from './popupSchema';
import { PopupLoader } from '../../components/Loader/Loader';
import { CSSTransition } from 'react-transition-group';

declare global {
	interface Window {
		popup: Popup;
	}
}

interface State {
	popupsPushed: React.FunctionComponentElement<any>[];
}

class Popup extends React.Component<{}, State> {
	state: State = {
		popupsPushed: []
	};

	constructor(props: any) {
		super(props);
		window.popup = this;
		window.addEventListener(
			'keydown',
			(e: any): void => {
				e.which === 27 && this.pop();
			}
		);
	}

	componentDidUpdate() {
		/*
		 * prevent body scroll if popups are in stack
		 * or allow if no popups are in stack
		 */
		const { popupsPushed } = this.state;
		preventBodyScroll(popupsPushed.length > 0);
	}

	push = (name: string, props: any = {}) => {
		/*
		 * Checking if popup exists in schema
		 */
		const popupFromSchema: any= popupSchema[name];
		if (!popupFromSchema) {
			console.error(`Popup \`${name}\` not found in popupSchema.`);
			return;
		}

		/*
		 * Creating an instance of this popup
		 * (to be able to have multiple instances)
		 */
		const popup: any = React.createElement(
			popupFromSchema,
			props
		);

		/*
		 * push the new popup to state
		 */
		const { popupsPushed } = this.state;
		popupsPushed.push(popup);
		this.setState({
			popupsPushed
		});
	};

	pop = () => {
		const { popupsPushed } = this.state;

		const lastPopup = popupsPushed.pop();
		// do whatever you want with lastPopup.

		this.setState({
			popupsPushed
		});
	};

	purge = () => {
		this.setState({
			popupsPushed: []
		});
	};

	render(): JSX.Element {
		const { popupsPushed } = this.state;
		const active = popupsPushed.length > 0;
		return (
			<>
				{/* CSS Transition for the white popup background */}
				<CSSTransition
					in={active}
					timeout={200}
					classNames="fade"
					unmountOnExit
				>
					<div className="popup__background" />
				</CSSTransition>

				{/* Render the async popup components from this.state.popupsPushed */}
				<React.Suspense fallback={<PopupLoader />}>
					{popupsPushed.map((PopupInstance: any, i: number) => {
						return React.cloneElement(PopupInstance, {
							key: i,
							/*
							 * passing `active` prop that gets passed to the inner <Popup> component.
							 * `active` means the popups is shown.
							 *
							 * We use this way to have all the popups from stack rendered,
							 * but show only the last one in stack.
							 *
							 * When the last gets popped, next one comes active,
							 * hence the `popupsPushed.length` condition check.
							 */
							active: i === popupsPushed.length - 1
						});
					})}
				</React.Suspense>
			</>
		);
	}
}

export default hot(module)(Popup);

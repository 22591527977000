// tslint:disable: max-line-length
import * as React from 'react';
import layout from '../../../../utils/layout';
import SvgTemplate from '../SvgTemplate';
import { Svg } from '../../Svg';

const icon = ({ color, ...rest }: Svg): JSX.Element => (
	<SvgTemplate {...rest} viewBox="0 0 16 16">
		<g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<g id="layer-button" fill={color} fillRule="nonzero">
				<path
					d="M3,12 L3,7 C3,4.791 4.791,3 7,3 L11,3 C11.346,3 11.681,2.995 12,2.995 C11.999,1.341 10.657,0 9.003,0 L2.997,0 C1.342,0 0,1.342 0,2.997 L0,9.003 C0,10.658 1.342,12 2.997,12 L3,12 Z"
					id="Shape"
				/>
				<path
					d="M13.003,4 L6.997,4 C5.342,4 4,5.342 4,6.997 L4,13.003 C4,14.658 5.342,16 6.997,16 L13.003,16 C14.658,16 16,14.658 16,13.003 L16,6.997 C16,5.342 14.658,4 13.003,4 Z"
					id="Shape"
				/>
			</g>
		</g>
	</SvgTemplate>
);

icon.defaultProps = {
	width: layout.spacing.three,
	color: layout.color.primary
};

export default icon;

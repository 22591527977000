import { Action } from '../../../models';
import { LOADED_PENDING_MERGES } from '../../actions/PendingMerges/constants';
import initialState from '../../store/initialState';

function reducer(options = initialState.pendingMerges, action: Action): any {
	switch (action.type) {
		case LOADED_PENDING_MERGES:
			return action.payload;
		default:
			return options;
	}
}

export default reducer;

import { Response$CallCenterAccountList, listCallCenterAccounts } from '../../../api/CallCenterAccounts/List';
import { CCAccountListEnum } from '../enums';
import { Action } from '../../models';

export type Action$ListCCAccounts = Action<any, Response$CallCenterAccountList>;

export async function listCallCenterAccountsActionCreator(): Promise<Action$ListCCAccounts> {
	const response = await listCallCenterAccounts();
	return {
		type: CCAccountListEnum.LOADED_ACCOUNT_LIST,
		payload: response
	};
}

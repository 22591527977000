// tslint:disable: max-line-length

import * as React from 'react';
import layout from '../../../../utils/layout';
import SvgTemplate from '../SvgTemplate';
import { Svg } from '../../Svg';

const icon = ({ color, ...rest }: Svg): JSX.Element => (
	<SvgTemplate {...rest} viewBox="0 0 25 20">
		<g
			id="Page-1"
			stroke="none"
			strokeWidth="1"
			fill="none"
			fillRule="evenodd"
			opacity="1"
		>
			<g id="twitter" fill={color} fillRule="nonzero">
				<g id="Page-1">
					<g id="landing_page">
						<g id="Group">
							<path
								d="M25.090228,2.37234308 C24.1699825,2.76792513 23.181256,3.0353242 22.1433912,3.15555198 C23.2026502,2.53997062 24.0162175,1.56522121 24.39936,0.40366653 C23.4078493,0.97374222 22.3099048,1.38764966 21.1411345,1.61067952 C20.2052587,0.64398002 18.8717333,0.04 17.3959703,0.04 C14.5624059,0.04 12.2651628,2.26693661 12.2651628,5.01385005 C12.2651628,5.40370247 12.3105401,5.78332676 12.398022,6.14741946 C8.1338277,5.93992118 4.353153,3.959738 1.8225759,0.95035013 C1.3809168,1.68497546 1.1278493,2.53940239 1.1278493,3.45098368 C1.1278493,5.176695 2.0336365,6.69916977 3.41039,7.59114716 C2.5693228,7.56529274 1.7781756,7.34155259 1.0864773,6.96898381 C1.0859889,6.98972417 1.0859889,7.01055923 1.0859889,7.03153635 C1.0859889,9.44149 2.8546282,11.4517893 5.2017423,11.9088348 C4.7712687,12.0224333 4.3179356,12.0834232 3.8499976,12.0834232 C3.5193638,12.0834232 3.1979618,12.0521706 2.8846192,11.9940218 C3.5375831,13.970038 5.4323898,15.4083202 7.6776127,15.448333 C5.9216243,16.7825345 3.7092743,17.5776289 1.3053531,17.5776289 C0.8911938,17.5776289 0.4827983,17.5541421 0.0813877,17.5079735 C2.3520101,18.9194069 5.0490031,19.7428653 7.946506,19.7428653 C17.3839544,19.7428653 22.5448018,12.1635435 22.5448018,5.59055509 C22.5448018,5.3749122 22.5397219,5.16040577 22.529904,4.9470358 C23.5325025,4.24579392 24.4023884,3.36977429 25.090228,2.37234308 Z"
								id="Shape-Copy-2"
							/>
						</g>
					</g>
				</g>
			</g>
		</g>
	</SvgTemplate>
);

icon.defaultProps = {
	width: layout.spacing.two,
	color: layout.color.primary
};

export default icon;

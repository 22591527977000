import { get } from '../utils';
import {PendingMerge} from "../../../models/PendingMerge";

export type Response$pendingMergeList = { data: PendingMerge[] };

async function getPendingMerges(params: string): Promise<Response$pendingMergeList> {
	return get(`/pending-merges/all${params}`);
}

export default { getPendingMerges };

// tslint:disable: max-line-length

import * as React from 'react';
import layout from '../../../../utils/layout';
import SvgTemplate from '../SvgTemplate';
import { Svg } from '../../Svg';

const icon = ({ color, ...rest }: Svg): JSX.Element => (
	<SvgTemplate {...rest} viewBox="0 0 21 24">
		<g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<g id="navbar-notifications-new" fillRule="nonzero">
				<path
					d="M10.0132593,24 C11.305886,24 12.3634897,22.965 12.3634897,21.7 L7.66302885,21.7 C7.66302885,22.965 8.72063253,24 10.0132593,24 Z M11.6067843,2.01213547 C11.3186558,1.42588421 10.6970422,1 10.0132593,1 C9.0731671,1 8.25058645,1.805 8.25058645,2.725 L8.25058645,3.53 C4.84275235,4.335 2.37501041,7.21 2.37501041,10.775 L2.37501041,17.1 L0.02478,19.4 L0.02478,20.55 L20.0017385,20.55 L20.0017385,19.4 L17.6515081,17.1 L17.6515081,10.775 C17.6515081,10.4757342 17.6341183,10.1813307 17.6002424,9.89260592 C17.248859,9.96304539 16.8853748,10 16.5132593,10 C13.4756931,10 11.0132593,7.53756612 11.0132593,4.5 C11.0132593,3.60462809 11.2272131,2.75922778 11.6067843,2.01213547 Z"
					id="Combined-Shape-Copy-7"
					fill={color}
				/>
				<circle
					id="Oval-5-Copy-5"
					fill="#EB2726"
					cx="16.5132593"
					cy="4.5"
					r="4.5"
				/>
			</g>
		</g>
	</SvgTemplate>
);

icon.defaultProps = {
	width: layout.spacing.three,
	color: layout.color.primary
};

export default icon;

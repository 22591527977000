import {post} from "../../redux/api/utils";

export type Request$AssignContactsToUser = {
	user_id: number;
	contacts: number[];
};

export type Response$AssignContactsToUser = {
	message: 'ok';
};

function assignContactsToUser(
	data: Request$AssignContactsToUser
): Promise<Response$AssignContactsToUser> {
	return post('/assignments/assign-to-user', data);
}

export { assignContactsToUser };

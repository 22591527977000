// tslint:disable: max-line-length

import * as React from 'react';
import layout from '../../../../utils/layout';
import SvgTemplate from '../SvgTemplate';
import { Svg } from '../../Svg';

const icon = ({ color, ...rest }: Svg): JSX.Element => (
	<SvgTemplate {...rest} viewBox="0 0 96.26 96.261">
		<g>
			<g id="Sailing">
				<polygon
					fill="#FFFFFF"
					points="48.138,60.869
    						        48.089,53.082 38.793,53.082 37.293,65.136   "
					stroke="#8B8AA0"
					strokeLinejoin="round"
					strokeMiterlimit="10"
					strokeWidth="2"
				/>
				<path
					d="M71.962,70.458
    						        c0,0-0.691,2.77-2.247,6.523c-0.261,0.646-0.554,1.309-0.877,2.002l-20.7-7.035l-20.715,7.049
    						        c-2.155-4.726-3.125-8.541-3.125-8.541l23.84-9.59L71.962,70.458z"
					fill="#fff"
					stroke="#8B8AA0"
					strokeLinejoin="round"
					strokeMiterlimit="10"
					strokeWidth="2"
				/>
				<line
					fill="#fff"
					stroke="#8B8AA0"
					strokeLinejoin="round"
					strokeMiterlimit="10"
					strokeWidth="2"
					x1="48.133"
					x2="48.133"
					y1="60.688"
					y2="72.087"
				/>
			</g>
			<g id="sailing">
				<path
					className="fill"
					d="M48.13,60.982V13.088
    						        c3.669,6.449,13.57,21.498,13.57,40.087c0,3.185-1.475,7.992-2.96,11.961L48.13,60.982z"
					fill="#FFFFFF"
					stroke="#8B8AA0"
					strokeLinejoin="round"
					strokeMiterlimit="10"
					strokeWidth="2"
				/>
				<path
					className="fill"
					d="M68.838,78.984
    						        c0,0.014-0.017,0.029-0.03,0.061c-0.924,1.984-2.062,4.154-3.433,6.264H30.886c-1.37-2.094-2.508-4.248-3.432-6.248
    						        c0-0.016-0.016-0.047-0.031-0.062l20.715-7.049L68.838,78.984z"
					fill="#FFFFFF"
					stroke="#8B8AA0"
					strokeLinejoin="round"
					strokeMiterlimit="10"
					strokeWidth="2"
				/>
			</g>
		</g>
	</SvgTemplate>
);

icon.defaultProps = {
	width: layout.spacing.three,
	color: layout.color.primary
};

export default icon;

// tslint:disable: max-line-length
import * as React from 'react';
import layout from '../../../../utils/layout';
import SvgTemplate from '../SvgTemplate';
import { Svg } from '../../Svg';

const icon = ({ color, ...rest }: Svg): JSX.Element => (
	<SvgTemplate {...rest} viewBox="0 0 262 262">
		<g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<g id="profile-default-circle-male" fillRule="nonzero">
				<rect
					id="Rectangle-path"
					fill="#9E9BB3"
					x="0"
					y="0.4"
					width="262"
					height="261"
					rx="130.5"
				/>
				<g
					id="Group"
					transform="translate(30.000000, 52.000000)"
					fill="#FFFFFF"
				>
					<path
						d="M142.9,39.9 C142.9,63 124.2,81.7 101.1,81.7 C78,81.7 59.3,63 59.3,39.9 C59.3,27.2 62.5,18.6 71.4,11 C78.7,4.7 89.3,1.2 97.5,0.7 C106.2,0.2 121.6,3.1 124,11.1 C136.8,12.3 142.9,27 142.9,39.9 Z"
						id="Shape"
					/>
					<path
						d="M142.9,39.3 C142.9,81.8 129.8,116.3 101.1,116.3 C72.4,116.3 59.3,81.8 59.3,39.3"
						id="Shape"
					/>
					<path
						d="M0.5,210 L0.5,186.3 C0.5,156.6 48.6,149 65.3,140.3 C81.9,131.6 79.4,110.8 73.3,100.3 L128.8,100.3 C122.6,110.8 120.1,131.6 136.8,140.3 C153.4,149 201.6,156.6 201.6,186.3 L201.6,210"
						id="Shape"
					/>
					<path
						d="M62.2,66.1 C59.3,65 57.3,70.6 58.6,76.2 C59.9,81.8 60.2,89.4 67.9,90.1"
						id="Shape"
					/>
					<path
						d="M138.9,66.1 C141.8,65 143.8,70.6 142.5,76.2 C141.2,81.8 140.9,89.4 133.2,90.1"
						id="Shape"
					/>
					<path
						d="M129.9,141.9 L72.2,141.9 C70.5,141.9 69.2,140.6 69.2,138.9 L71.8,128 C71.8,126.3 73.1,125 74.8,125 L127.5,125 C129.2,125 130.5,126.3 130.5,128 L133.1,138.9 C132.9,140.5 131.6,141.9 129.9,141.9 Z"
						id="Shape"
					/>
				</g>
			</g>
		</g>
	</SvgTemplate>
);

icon.defaultProps = {
	width: layout.spacing.six,
	color: '#9E9BB3'
};

export default icon;

const initialState = {
	user: null,
	token: null,
	options: null,
	contactList: [],
	contact: null,
	userList: [],
	userToAssignList: [],
	statistics: null,
	ccAccountList: [],
	faqList: [],
	scenarioList: [],
	pendingMerges: []
};

export default initialState;

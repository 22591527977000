import { Action } from '../../../models';
import messageApi from '../../api/Message';
import { SENT_MESSAGE } from './constants';
import {Request$SendSMSMessage} from "../../api/Message/models";

export type Action$SendSMSMessage = (data: Request$SendSMSMessage) => Promise<Action>;

export async function action$SendSMSMessage(data: Request$SendSMSMessage) {
	const res = await messageApi.sendSMSMessage(data);
	return {
		type: SENT_MESSAGE,
		payload: res
	};
}

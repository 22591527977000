import * as React from 'react'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import './styles.scss'
import SipWrapper from './SipWrapper'
import Status from './components/Status'
import PhoneSessions from './components/PhoneSessions'
import Dialstring from './components/Dialstring'
import { SipConfig, SipCredentials, PhoneConfig, AppConfig } from './models'

import { defaultStore, persistor } from './store/configureStore'

interface Props {
    width?: number
    height?: number
    name?: string
    phoneConfig: PhoneConfig
    sipCredentials: SipCredentials
    sipConfig: SipConfig
    appConfig: AppConfig
    containerStyle?: any
    isAdmin?: boolean
    numberList?: Array<string>
    onOutgoingCall?: Function
    onCallEnded?: Function
    needsConfirm?: boolean
}

export const phoneStore = defaultStore

export const ReactSipPhone = ({
                                  name,
                                  width = 300,
                                  phoneConfig,
                                  sipConfig,
                                  appConfig,
                                  sipCredentials,
                                  numberList,
                                  isAdmin,
                                  onOutgoingCall,
                                  onCallEnded,
                                  containerStyle = {},
                                  needsConfirm
                              }: Props) => {
    // If no store is passed into component, default store is used
    return (
        <Provider store={phoneStore}>
            <PersistGate loading={null} persistor={persistor}>
                <SipWrapper
                    sipConfig={sipConfig}
                    sipCredentials={sipCredentials}
                    phoneConfig={phoneConfig}
                    appConfig={appConfig}
                >
                    <div className={'container'}
                         style={{
                             ...containerStyle,
                             width: `${width < 300 ? 300 : width}px`
                         }}
                    >
                        {phoneConfig.disabledFeatures.includes('dialstring') ? null : (
                            <Dialstring
                                sipConfig={sipConfig}
                                phoneConfig={phoneConfig}
                                appConfig={appConfig}
                                numberList={numberList}
                                isAdmin={isAdmin}
                                onOutgoingCall={onOutgoingCall}
                                needsConfirm={needsConfirm}
                            />
                        )}
                        <Status
                            phoneConfig={phoneConfig}
                            appConfig={appConfig}
                            name={name}
                        />
                        <PhoneSessions phoneConfig={phoneConfig} onCallEnded={onCallEnded}  />
                        <audio id='tone' autoPlay />
                    </div>
                </SipWrapper>
            </PersistGate>
        </Provider>
    )
}

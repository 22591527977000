// tslint:disable: max-line-length

import * as React from 'react';
import layout from '../../../../utils/layout';
import SvgTemplate from '../SvgTemplate';
import { Svg } from '../../Svg';

const icon = ({ color, ...rest }: Svg): JSX.Element => (
	<SvgTemplate {...rest} viewBox="0 0 513 370">
		<g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<g id="driving" fill={color} fillRule="nonzero">
				<path
					d="M447.2,250.3 L269.9,250.3 C266.6,250.5 263.9,253.2 263.7,256.5 C263.5,260.1 266.3,263.3 269.9,263.5 L447.1,263.5 C450.4,263.3 453.1,260.6 453.3,257.3 C453.6,253.6 450.8,250.5 447.2,250.3 Z"
					id="Shape"
				/>
				<path
					d="M467.9,0.6 L44.1,0.6 C19.8,0.7 0.1,20.4 0,44.7 L0,325.3 C0.1,349.6 19.8,369.3 44.1,369.4 L468,369.4 C492.3,369.3 512,349.6 512.1,325.3 L512.1,44.7 C511.9,20.4 492.2,0.7 467.9,0.6 Z M498.8,325.3 C498.7,342.3 485,356.1 467.9,356.2 L44.1,356.2 C27.1,356.1 13.3,342.4 13.2,325.3 L13.2,44.7 C13.3,27.7 27,13.9 44.1,13.8 L468,13.8 C485,13.9 498.8,27.6 498.9,44.7 L498.9,325.3 L498.8,325.3 Z"
					id="Shape"
				/>
				<path
					d="M447.2,106.6 L269.9,106.6 C266.6,106.8 263.9,109.5 263.7,112.8 C263.5,116.4 266.3,119.6 269.9,119.8 L447.1,119.8 C450.4,119.6 453.1,116.9 453.3,113.6 C453.6,109.9 450.8,106.8 447.2,106.6 Z"
					id="Shape"
				/>
				<path
					d="M447.2,178.4 L269.9,178.4 C266.6,178.6 263.9,181.3 263.7,184.6 C263.5,188.2 266.3,191.4 269.9,191.6 L447.1,191.6 C450.4,191.4 453.1,188.7 453.3,185.4 C453.6,181.8 450.8,178.6 447.2,178.4 Z"
					id="Shape"
				/>
				<path
					d="M447.2,142.5 L269.9,142.5 C266.6,142.7 263.9,145.4 263.7,148.7 C263.5,152.3 266.3,155.5 269.9,155.7 L447.1,155.7 C450.4,155.5 453.1,152.8 453.3,149.5 C453.6,145.8 450.8,142.7 447.2,142.5 Z"
					id="Shape"
				/>
				<path
					d="M447.2,214.3 L269.9,214.3 C266.6,214.5 263.9,217.2 263.7,220.5 C263.5,224.1 266.3,227.3 269.9,227.5 L447.1,227.5 C450.4,227.3 453.1,224.6 453.3,221.3 C453.6,217.7 450.8,214.6 447.2,214.3 Z"
					id="Shape"
				/>
				<path
					d="M208.7,113.8 C206.7,107.7 200.6,103.6 194.5,103.6 L82.6,103.6 C75.5,103.6 70.4,107.7 68.4,113.8 L47.1,174.8 L47.1,256.1 C47.1,262.2 51.2,266.3 57.3,266.3 L67.5,266.3 C73.6,266.3 77.7,262.2 77.7,256.1 L77.7,246 L199.7,246 L199.7,256.2 C199.7,262.3 203.8,266.4 209.9,266.4 L220.1,266.4 C226.2,266.4 230.3,262.3 230.3,256.2 L230.3,174.9 L208.7,113.8 Z M82.6,215.5 C74.5,215.5 67.4,208.4 67.4,200.3 C67.4,192.2 74.4,185 82.6,185 C90.8,185 97.8,192.1 97.8,200.2 C97.8,208.3 90.7,215.5 82.6,215.5 Z M194.4,215.5 C186.3,215.5 179.2,208.4 179.2,200.3 C179.2,192.2 186.3,185.1 194.4,185.1 C202.5,185.1 209.6,192.2 209.6,200.3 C209.6,208.4 202.6,215.5 194.4,215.5 Z M67.3,164.7 L82.5,118.9 L194.3,118.9 L209.5,164.7 L67.3,164.7 Z"
					id="Shape"
				/>
			</g>
		</g>
	</SvgTemplate>
);

icon.defaultProps = {
	width: layout.spacing.three,
	color: layout.color.primary
};

export default icon;

import * as React from 'react';
import './MultiSelect.scss';
import InputCheckbox from '../InputCheckbox';
import layout from '../../../utils/layout';
import InputText from '../InputText';
import withClickOutside from '../../HOC/withClickOutside/withClickOutside';
import { FormikProps } from 'formik';
import Label from '../../../components/Form/Label';

interface Props extends FormikProps<any> {
	children: any;
	flat?: boolean;
	close: () => any;
	value?: any;
	onChange: any;
	name?: string;
	label?: string;
	placeholder?: string;
	dataCy?: string;
	className?: string;
	insertStringValue?: string;
}

interface OptionProps {
	value: string;
	children: string;
}

export class MultiSelect extends React.Component<Props> {
	/*
	 * This function takes a value array and returns a comma separated string.
	 * It loops through all children options and checks if the child's value exists in the `value` prop array.
	 * If the value exists, it returns the child option's label as value.
	 *
	 * Example:
	 * value passed: [1, 2]
	 *
	 * value returned: 'English, Greek'
	 */
	arrayValueToString = (value: any): string => {
		const { children } = this.props;
		const labelsSelected : any[] = [];
		if (children && value) {
			for (const child of children) {
				if (value.some((val: any): boolean => val === child.props.value)) {
					labelsSelected.push(child.props.children);
				}
			}
		}
		return labelsSelected.join(', ');
	};

	state = {
		expanded: false,
		stringValue: this.arrayValueToString(this.props.value)
	};

	toggle = () => {
		this.setState({
			expanded: !this.state.expanded
		});
	};

	handleChange = (e: any, childProps: OptionProps) => {
		const { value, children, name, setFieldValue, onChange } = this.props;

		const currentIndex = value.indexOf(childProps.value);

		const cache = [...value];

		if (currentIndex > -1) {
			// if exists remove it
			cache.splice(currentIndex, 1);
		} else {
			// else add it
			cache.push(childProps.value);
		}

		const newValue: any[] = [];

		// make a new ORDERED value
		if (children) {
			for (const child of children) {
				if (cache.indexOf(child.props.value) > -1) {
					newValue.push(child.props.value);
				}
			}
		}

		/* istanbul ignore next */
		name && setFieldValue && setFieldValue(name, newValue);

		this.setState(
			{
				stringValue: this.arrayValueToString(newValue)
			},
			() => {
				onChange && onChange(newValue);
			}
		);
	};

	close = () => {
		this.setState({
			expanded: false
		});
	};

	render(): JSX.Element {
		const { expanded, stringValue } = this.state;
		const { flat, placeholder, label, dataCy, className, insertStringValue } = this.props;
		let cl = 'multi-select';
		if (expanded) {
			cl += ' expanded';
		}
		if (flat) {
			cl += ' flat';
		}

		if (className) {
			cl += ` ${className}`;
		}

		return (
			<div>
			{label && (
			<Label title={label} />
			)}
			<div className={cl} {...(dataCy ? { 'data-cy': dataCy } : {})}>
				<InputText
					flat
					icon="carret-down-select"
					placeholder={placeholder}
					iconWidth={layout.spacing.ss}
					className="multi-select__input"
					onClick={this.toggle}
					value={stringValue ? stringValue : insertStringValue}
				/>
				{expanded && (
					<ExpandedWithClick
						{...this.props}
						onChange={this.handleChange}
						close={this.close}
						clickOutsideActive={expanded}
					/>
				)}
			</div>
			</div>
		);
	}
}

export default withClickOutside(MultiSelect);

interface ExpandedProps {
	close: () => any;
	onChange: (e: any, t: any) => any;
	children: any;
	value: any;
}

const Expanded = (props: ExpandedProps) => {
	const { children, value } = props;
	return (
		<div className="multi-select__values-container">
			{React.Children.map(
				children,
				(child: any): JSX.Element => {
					return (
						<InputCheckbox
							key={`mc-${child.props.value}`}
							checkboxLabel={child.props.children}
							name={child.props.value}
							onChange={(e: React.SyntheticEvent) =>
								props.onChange(e, child.props)
							}
							value={value.some(
								(val: any): boolean => val === child.props.value
							)}
						/>
					);
				}
			)}
		</div>
	);
};

const ExpandedWithClick = withClickOutside(Expanded);
